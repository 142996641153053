import React from 'react';
import Page from "../Page";
import {Featured} from "../../api/types";
import {Link} from "../../api/injectors";
import filterHelper from "../../api/filterHelper";
import adminRequests from "../../api/adminRequests";
import Notification from "../../api/notification";
import ConfirmModal from "../../components/ConfirmModal";
import Helmet from "../../components/Helmet";

type Props = {
    featured: {data: Featured[] }
};

type State = {
    featured: Featured[]
    search: string
    deleteFeaturedId: number
};

class FeaturedImages extends Page<Props, State> {
    readonly state = {
        featured: this.props.featured.data,
        search: '',
        deleteFeaturedId: -1,
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    deleteFeatured = () => {
        adminRequests.deleteFeatured(this.state.deleteFeaturedId).then(() => {
            Notification.success("Personnalité à la une retirée avec succès");
            this.setState(prevState => {
                const nState = {...prevState};
                for (let i = 0; i < prevState.featured.length; i++) {
                    if (prevState.featured[i].featuredId === this.state.deleteFeaturedId) {
                        nState.featured = [...nState.featured];
                        nState.featured.splice(i, 1);
                        nState.deleteFeaturedId = -1;
                        break;
                    }
                }
                return nState;
            });
        }, function() {
            Notification.error("Erreur lors de la suppression de la personnalité à la une")
        })
    }

    openDeleteConfirm = (thumbnailId: number) => {
        this.setState(prevState => ({
            ...prevState,
            deleteFeaturedId: thumbnailId,
        }));
    }

    setDeleteIdToNull = () => {
        this.setState(prevState => ({
            ...prevState,
            deleteFeaturedId: -1,
        }));
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'À la une | Paanteon'} />
                            <h1 className="page-title">
                                À la une
                            </h1>

                            <div>
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>
                                <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                            </div>

                            <div className="uk-grid uk-grid-small margin-top">
                                {
                                    filterHelper.deepSearch(this.state.featured, this.state.search).map(featured =>
                                        <div className="uk-width-1-1" key={featured.featuredId}>
                                            {featured.desc}: {featured.Thumbnail?.label}
                                            <button className="btn btn-outlined light" onClick={() => this.openDeleteConfirm(featured.featuredId)}>Supprimer</button>
                                        </div>
                                    )
                                }
                            </div>

                            {
                                this.state.deleteFeaturedId !== -1 && <ConfirmModal onCancel={this.setDeleteIdToNull} onConfirm={this.deleteFeatured}>
                                    Voulez-vous vraiment supprimer cette personnalité à la une ?
                                </ConfirmModal>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FeaturedImages;
