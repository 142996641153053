import React from 'react';
import {Collection, Gallery, Thumbnail, Tribe, Tribute, User, UserInfos} from "../api/types";
import Page from "./Page";
import {Link} from "../api/injectors";
import {UIRouterReact} from "@uirouter/react";
import {AxiosError} from "axios";
import TributeView from "../components/TributeView";
import UserSidebar from "../components/UserSidebar";

interface OwnProps {
    user: UserInfos
    galleries: (Gallery & { userCollections: Collection[] })[]
    tribute: { data: Tribute & {Thumbnail: Thumbnail & {Tributes: []}}} | AxiosError<{userId: number}>
    userTributes: {tributeId: number, archivedAt: string, uniqueName: string}[]
    router: UIRouterReact
    engraves: { data: { authorFriends: User[], myFriends: User[]} }
    discover?: (Thumbnail & {Tributes: Tribute[]})[]
    popular?: (Thumbnail & {Tributes: Tribute[]})[]
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type Props = OwnProps;

class TributeDetails extends Page<Props, any> {
    render() {
        if ((this.props.tribute as {data: Tribute}).data)
            return <TributeView {...this.props} tribute={this.props.tribute as { data: Tribute & {Thumbnail: Thumbnail & {Tributes: []}}}}/>
        else
            return <div className="black-bg" style={{minHeight: '65vh'}}>
                <div className="uk-container">
                    {
                        typeof this.props.user === 'object' && <UserSidebar user={this.props.user} showCover showButtons={true} showBio={true} collapsible={true} popular={this.props.popular} discover={this.props.discover}/>
                    }
                    <div className="main-content">
                        <div className="pop-up page">
                            <div className="pop-up-title centered">
                                {
                                    (this.props.tribute as AxiosError).response?.status === 409 ?
                                        `Le profil de ce membre est privé. L’accès à son paanteon est fermé ou réservé à une communauté restreinte. Vous avez toujours la possibilité de demander ce membre en ami ou bien de le suivre pour voir ses publications.`
                                        :
                                        'Contenu non disponible'
                                }
                            </div>

                            <div className="h-center">
                                <Link to="connected.home" className="btn btn-outlined black">Retour</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }
}

export default TributeDetails;
