import React from "react";
import { Criteria as CriteriaType, Gallery, TagCategory } from "../../api/types";

const Criteria = ({
    galleries,
    criteria,
    tagCategories,
    index,
    tribeId,
    disabled,
    nbCriteria,
    newCriteriaCollection,
    newCriteriaTag,
    updateCriteriaCollection,
    updateCriteriaTag,
    removeCriteria,
}:{
    galleries: {data : Gallery[]},
    criteria: CriteriaType,
    tagCategories: {data: TagCategory[]},
    index: number,
    tribeId?: number,
    disabled?: boolean,
    nbCriteria: number,
    newCriteriaCollection?: (e: React.ChangeEvent<HTMLSelectElement>, criteriaIndex:number) => void,
    newCriteriaTag?: (e: React.ChangeEvent<HTMLSelectElement>, criteriaIndex: number) => void,
    updateCriteriaCollection?: (e: React.ChangeEvent<HTMLSelectElement>, tribeId:number, criteriaIndex:number) => void,
    updateCriteriaTag?: (e: React.ChangeEvent<HTMLSelectElement>, tribeId:number, criteriaIndex: number) => void,
    removeCriteria: (criteriaIndex: number) => void,
}) => {
    return (
        <div className="flex-center margin-bottom">
            <div className="flex-colomn margin-right">
                <div>
                    <label className="flex space-between">Ajouter une collection</label>
                    <select
                        className=""
                        name="collection"
                        onChange={(e)=> {
                            newCriteriaCollection ?
                            newCriteriaCollection(e, index)
                            : updateCriteriaCollection!(e, tribeId!, index)
                        }}
                        disabled={disabled}
                        defaultValue={criteria.collectionId === null ? "-1" : criteria.collectionId}
                    >
                        <option value="-1">aucune collection</option>
                        {
                            galleries.data.map((gallery) =>
                                <optgroup label={gallery.label} key={gallery.galleryId + gallery.code}>
                                    {gallery.Collections.map((collection) =>
                                        <option value={collection.collectionId} key={collection.collectionId}>{collection.label}</option>
                                    )}
                                </optgroup>
                            )
                        }
                    </select>
                </div>
                <div>
                    <label className="flex space-between">Ajouter un tag</label>
                    <select
                        className=""
                        name="tag"
                        onChange={(e)=> {
                            newCriteriaTag ?
                            newCriteriaTag(e, index)
                            : updateCriteriaTag!(e, tribeId!, index)
                        }}
                        disabled={disabled}
                        defaultValue={criteria.tagId === null ? "-1" : criteria.tagId}
                    >
                        <option value="-1">aucun tag</option>
                        {
                            tagCategories.data.map((tagCategory) =>
                                <optgroup label={tagCategory.label} key={tagCategory.tagCategoryId}>
                                    {tagCategory.Tags.map((tag) =>
                                        <option value={tag.tagId} key={tag.tagId}>{tag.label}</option>
                                    )}
                                </optgroup>
                            )
                        }
                    </select>
                </div>
            </div>
            {nbCriteria > 1 && <button className="btn btn-borderless white" onClick={()=>removeCriteria(index)}>X</button>}
        </div>
    );
};

export default Criteria;

