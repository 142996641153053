import React from 'react';
import Page from "./Page";
import Share from "../components/Publication/Share";
import Post from "../components/Publication/Post";
import TributePost from "../components/Publication/TributePost";
import {Publication, Testimonial, Tribute} from "../api/types";
import {injectUIRouter} from "../api/injectors";
import {UIRouterReact} from "@uirouter/react";
import TestimonialPost from '../components/Publication/TestimonialPost';

type Props = {
    publication: {data: Publication }
    onDelete?(publicationId: number, from: 0 | 1 | 2): void
    router: UIRouterReact
}

class PublicationPost extends Page<Props, {openComment: boolean}> {
    constructor(props: Props) {
        super(props);

        const {hash} = window.location;
        if (hash !== '' && hash.slice(1).startsWith('comment')) {
            this.state = {
                openComment: true,
            };
        } else {
            this.state = {
                openComment: false,
            }
        }
    }

    onDelete = (publicationId: number, from: 0 | 1 | 2):void => {
        if (this.props.onDelete) {
            return this.props.onDelete(publicationId, from);
        }
        this.props.router.stateService.go('connected.inspiration-feed', {}, {reload: true});
    }

    componentDidMount() {
        const {hash} = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const e = document.getElementById(hash.slice(1, hash.length));
                if (e) e.scrollIntoView(true);
            }, 0);
        }
    }

    render() {
        if (this.props.publication.data.publicationRefId) {
            return <Share onDelete={this.onDelete} commentOpen={this.state.openComment} key={this.props.publication.data.publicationId} publication={this.props.publication.data} footer={!!this.props.publication.data.content && this.props.publication.data.content.length > 0}><Post item={this.props.publication.data.PublicationRef as Publication} footer={!this.props.publication.data.content || this.props.publication.data.content.length <= 0}/></Share>
        } else if (this.props.publication.data.type === 0) {
                return <Post onDelete={this.onDelete} commentOpen={this.state.openComment} item={this.props.publication.data} key={this.props.publication.data.publicationId} footer/>
            } else if (this.props.publication.data.type === 1) {
                return <TributePost onDelete={this.onDelete} commentOpen={this.state.openComment} key={this.props.publication.data.publicationId} item={this.props.publication.data as Publication & {Tribute: Tribute}} footer/>
            } else if (this.props.publication.data.type === 2) {
                return <Share onDelete={this.onDelete} commentOpen={this.state.openComment} publication={this.props.publication.data} key={this.props.publication.data.publicationId} footer={!!this.props.publication.data.content && this.props.publication.data.content.length > 0}>
                    {
                        this.props.publication.data.Tribute ?
                            <TributePost onDelete={this.onDelete} commentOpen={this.state.openComment} item={this.props.publication.data as Publication & {Tribute: Tribute}} footer={!this.props.publication.data.content || this.props.publication.data.content.length <= 0}/>
                            :
                            <div style={{color: 'black'}}><i>Contenu non disponible</i></div>
                    }
                </Share>
            } else if (this.props.publication.data.type === 3) {
                return <TestimonialPost onDelete={this.onDelete} commentOpen={this.state.openComment} key={this.props.publication.data.publicationId} item={this.props.publication.data as Publication & {Testimonial: Testimonial}} footer/>
            } else {
                return <></>;
            }
    }
}

export default injectUIRouter(PublicationPost);
