import React, { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports
import {HelmetProps, Helmet as RHelmet} from "react-helmet";

const Helmet: React.FC<HelmetProps> = (props) => {
    return useMemo(() => {
        return <RHelmet {...props} onChangeClientState={(...args) => {
            window.sp_track();
            if (props.onChangeClientState) return props.onChangeClientState(...args);
        }}/>
        // eslint-disable-next-line
    }, [props.title]);
}

export default Helmet;
