import React from "react";
import AnonymityAndCertification from "../AnonymityAndCertification/";
import {User} from "../../api/types";
import {FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import SelectCountry from "../SelectCountry";
import moment from "moment/moment";

type Props = {
    user: User;
    update(user: User): any;
    nextStep(event: React.SyntheticEvent): any;
} & InjectedIntlProps;

interface StateInterface {
    user: User;
    anonymityChecked: boolean;
    certifyChecked: boolean;
    isAgeAllowed: boolean;
    isDateValid: boolean;
}

class FormPersonalInfo extends React.Component<Props, StateInterface> {
    public form = React.createRef<HTMLFormElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            user: props.user,
            anonymityChecked: false,
            certifyChecked: false,
            isAgeAllowed: true,
            isDateValid: true,
        };

        this.handleUserInput = this.handleUserInput.bind(this);
    }

    handleUserInput(e: React.FormEvent<HTMLInputElement>) {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        this.setState(prevState => {
            let isAgeAllowed = prevState.isAgeAllowed;
            let isDateValid = prevState.isDateValid;

            switch (name) {
                case 'day':
                    if (prevState.user.month && prevState.user.year) {
                        const date = moment([prevState.user.year, parseInt(prevState.user.month) - 1, value]);
                        isAgeAllowed = moment().diff(date, 'years') >= 15;
                        isDateValid = date.isValid();
                    }
                    break;
                case 'month':
                    if (prevState.user.day && prevState.user.year) {
                        const date = moment([prevState.user.year, parseInt(value) - 1, prevState.user.day]);
                        isAgeAllowed = moment().diff(date, 'years') >= 15;
                        isDateValid = date.isValid();
                    }
                    break;
                case 'year':
                    if (prevState.user.day && prevState.user.month) {
                        const date = moment([value, parseInt(prevState.user.month) - 1, prevState.user.day]);
                        isAgeAllowed = moment().diff(date, 'years') >= 15;
                        isDateValid = date.isValid();
                    }
                    break;
            }

            return {
                ...prevState,
                user: {
                    ...prevState.user,
                    [name]: value,
                },
                isAgeAllowed,
                isDateValid,
            };
        });
    }

    handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        this.setState(state => ({
            user: {
                ...state.user,
                country: value,
            },
        }));
    }

    componentDidUpdate(prevProps: Props, prevState: StateInterface, snapshot: any) {
        this.props.update(this.state.user);
    }

    setAnonymityChecked = (e: React.FormEvent<HTMLInputElement>) => {
        const value = Boolean(e.currentTarget.checked);
        this.setState(prevState => ({
            ...prevState,
            anonymityChecked: value,
        }));
    }

    setCertifyCheck = (e: React.FormEvent<HTMLInputElement>) => {
        const value = Boolean(e.currentTarget.checked);
        this.setState(prevState => ({
            ...prevState,
            certifyChecked: value,
        }));
    }

    get canSubmit(): boolean {
        return (!!this.form.current && this.form.current.checkValidity())
            && this.state.anonymityChecked;
    }

    render() {
        const langNextStep = this.props.intl.formatMessage({id: 'general.form.nextStep'});
        return (
            <>
                <div className="steps">
                    <div className="step current">1</div>
                    <div className="sep"></div>
                    <div className="step">2</div>
                </div>
                <h2 className="title-3 h-center margin-xl"><FormattedMessage id="personalInfos.myPersonalInfos"/></h2>
                <form name="personalInfo" onSubmit={this.props.nextStep} ref={this.form}>
                    <div className="uk-grid uk-grid-large">
                        <div className="uk-width-2-3@m">
                            <div>
                                <label htmlFor=""
                                       className="margin-right-l"><FormattedMessage
                                    id="general.user.civility"/>*</label>
                                <input type="radio" name="gender"
                                       value="2"
                                       checked={this.state.user.gender === "2"}
                                       onChange={this.handleUserInput}
                                       required/>
                                <small>Mme</small>
                                <input type="radio" name="gender"
                                       onChange={this.handleUserInput}
                                       checked={this.state.user.gender === "1"}
                                       value="1"/>
                                <small>Mr</small>
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.lastname"/>*</label><br/>
                                <input className="input big block dark" type="text"
                                       value={this.state.user.lastname}
                                       name="lastname"
                                       onChange={this.handleUserInput}
                                       required/>
                            </div>
                            <div className="uk-grid uk-grid-small margin">
                                <div className="uk-width-1-2@m">
                                    <label htmlFor=""><FormattedMessage id="general.user.firstname"/>*</label><br/>
                                    <input className="input big block dark" type="text"
                                           onChange={this.handleUserInput}
                                           name="firstname"
                                           value={this.state.user.firstname}
                                           required/>
                                </div>
                                <div className="uk-width-1-2@m">
                                    <label htmlFor=""><FormattedMessage id="general.user.birthdate"/>*</label><br/>
                                    <div className="uk-grid uk-grid-small">
                                        <div className="uk-width-1-3">
                                            <input type="number" max="31" min="1"
                                                   placeholder="JJ"
                                                   className="input big block dark"
                                                   onChange={this.handleUserInput}
                                                   value={this.state.user.day}
                                                   name="day"
                                                   required/>
                                        </div>
                                        <div className="uk-width-1-3">
                                            <input type="number" max="12" min="1"
                                                   placeholder="MM"
                                                   className="input big block dark"
                                                   onChange={this.handleUserInput}
                                                   value={this.state.user.month}
                                                   name="month"
                                                   required/>
                                        </div>
                                        <div className="uk-width-1-3">
                                            <input type="number"
                                                   placeholder="AAAA"
                                                   className="input big block dark"
                                                   onChange={this.handleUserInput}
                                                   value={this.state.user.year}
                                                   min="1900"
                                                   name="year"
                                                   required/>
                                        </div>
                                    </div>
                                    <div className="text-red margin-top-s">
                                        { !this.state.isDateValid ? "La date saisie n'est pas valide" : !this.state.isAgeAllowed && "Vous devez avoir plus de 15 ans pour vous inscrire sur Paanteon" }
                                    </div>
                                </div>
                            </div>
                            <div className="uk-grid uk-grid-small margin">
                                <div className="uk-width-1-2@m">
                                    <label htmlFor=""><FormattedMessage id="general.user.postalCode"/>*</label><br/>
                                    <input className="input big block dark"
                                           type="text"
                                           onChange={this.handleUserInput}
                                           value={this.state.user.postalCode}
                                           name="postalCode" required/>
                                </div>
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.city"/>*</label><br/>
                                <input className="input big block dark" type="text"
                                       onChange={this.handleUserInput}
                                       value={this.state.user.city}
                                       name="city"
                                       required/>
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.country"/>*</label><br/>
                                <SelectCountry country={this.state.user.country} onChange={this.handleCountryChange}>
                                </SelectCountry>
                            </div>
                            <div className="margin right">
                                <small>* <FormattedMessage id="general.form.requiredFields"/></small>
                            </div>
                            <div className="margin-top-l">
                                <label className="blue large checkbox-custom">
                                    <input type="checkbox" required onChange={this.setCertifyCheck}/><div className="checkbox black"></div>
                                    <FormattedMessage id="general.form.exactFields"/>
                                </label>
                            </div>
                            <div className="margin-top-m margin-large-bottom">
                                <label className="blue large checkbox-custom">
                                    <input type="checkbox" required onChange={this.setAnonymityChecked}/><div className="checkbox black"></div>
                                    <FormattedMessage id="register.certification.confirm"/>
                                </label>
                            </div>
                            <div className="margin-large right">
                                <input type="submit" className="btn btn-1 btn-big"
                                       value={langNextStep} disabled={!this.canSubmit || !this.state.isAgeAllowed}/>
                            </div>
                    </div>
                    <AnonymityAndCertification className="uk-width-1-3@m" />
                </div>
            </form>
        </>
        );
    }
}

export default injectIntl(FormPersonalInfo);
