import {User, UserNotificationSettings, UserNotificationSettingValue} from "./types";

export function hasDisabledNotifications(notificationSettings: UserNotificationSettings, type: UserNotificationSettingValue): boolean {
    return (
        (notificationSettings.love & type) === 0 &&
        (notificationSettings.comment & type) === 0 &&
        (notificationSettings.share & type) === 0 &&
        (notificationSettings.friendship & type) === 0 &&
        /* (notificationSettings.mention & type) === 0 && */
        (notificationSettings.tribute & type) === 0 &&
        (notificationSettings.follower & type) === 0
    );
}

export function hasValidEmail(user: User) {
    return user.validEmail === 1;
}

export function hasOneYearAccount(user: User) {
    let timeDiff = new Date(user.lastLoginDate!).getTime() - new Date(user.createdAt!).getTime()
    return timeDiff / (1000 * 3600 * 24) >= 365
}
