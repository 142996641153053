import React from 'react';
import Page from "./Page";
import {Chat, Friendship, Message, User, UserInfos, Tribe} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";
import moment from "moment";
import Autosuggest from "react-autosuggest";
import localStorage from "../api/localStorage";
import filterHelper from "../api/filterHelper";
import {updateUser} from "../components/PublicHeader";
import {pictoCamera, pictoNewMessage} from "../api/pictos";
import {acceptableImages, processLinks, processMentions} from "../api/domHelper";
import {stopPropagation} from "../api/helpers";
import smoothscroll from 'smoothscroll-polyfill';
import { Link } from '../api/injectors';
import {Mention, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem} from "react-mentions";
import MentionUser, {MentionableUsersContext} from "../components/MentionUser";
import Helmet from "../components/Helmet";
smoothscroll.polyfill();

type Props = {
    chats: {data: Chat[] }
    selfUser: {data: UserInfos}
    userFriends: {data: Friendship[]}
    newUserChat: (Chat & {Messages: Message[]}) | null
    contactableUsers: {data: User[]}
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = {
    chats: Chat[]
    selectedChat: (Chat & {Messages: Message[]}) | null
    content: string
    newMessage: boolean
    suggestedFriends: ({lastname: string, firstname: string, userId: number})[]
    newChat: string
    unreadMessages: number
    textareaExpanded: boolean
    img: string | null
    selectedImage: number | null
}

const getFriendSuggestionValue = (user: User) => user.firstname + ' ' + user.lastname;

const renderFriendSuggestion = (user: User) => <div>{user.firstname} {user.lastname}</div>

class Messages extends Page<Props, State> {
    private user: User = localStorage.getLocalUser();
    private userConversations: number[] = this.props.chats.data.map(chat => chat.destId === this.user.userId ? chat.sendId : chat.destId);
    private contactableUsers = this.props.contactableUsers.data
        .filter(user => this.userConversations.indexOf(user.userId as number) === -1)
        .map(f => ({
            lastname: f.lastname || '',
            firstname: f.firstname || '',
            userId: f.userId as unknown as number,
        }));
    readonly state = {
        chats: this.props.newUserChat && this.props.newUserChat.chatId === -1 ? [...this.props.chats.data, this.props.newUserChat] : this.props.chats.data,
        selectedChat: this.props.newUserChat,
        content: '',
        newMessage: !this.props.newUserChat && this.props.chats.data.length === 0,
        suggestedFriends: [],
        newChat: '',
        unreadMessages: this.props.chats.data.reduce((a: number, b: Chat) => a + b.unread, 0),
        textareaExpanded: false,
        img: null,
        selectedImage: null,
    };
    private reader: FileReader = new FileReader();

    constructor(props: Props) {
        super(props);

        this.reader.onloadend = () => {
            this.setState(prevState => ({
                ...prevState,
                img: this.reader.result as string,
            }));
        }
    }

    selectChat = (chatId: number) => {
        // @ts-ignore
        if (this.state.selectedChat === null || this.state.selectedChat.chatId !== chatId || this.state.newMessage) {
            if (chatId !== -1) {
                paanteon.getConversation(chatId).then(res => {
                    this.setState(prevState => {
                        const nState = {
                            ...prevState,
                            selectedChat: {...res.data, unread: 0},
                            newMessage: false,
                            chats: prevState.chats.filter(c => c.chatId !== -1),
                        };

                        for (let i = 0; i < nState.chats.length; i++) {
                            if (nState.chats[i].chatId === res.data.chatId) {
                                nState.chats[i] = {...nState.chats[i], unread: 0};
                                break;
                            }
                        }
                        nState.unreadMessages = nState.chats.reduce((a: number, b: Chat) => a + b.unread, 0);
                        if (nState.unreadMessages !== prevState.chats.reduce((a, b) => a + b.unread, 0)) {
                            updateUser()
                        }

                        return nState;
                    }, () => {
                        const feed = document.getElementById('conversation-feed');
                        if (feed) {
                            feed.scrollTo({top: feed.scrollHeight, behavior: "auto"});
                        }
                    });
                }, function() {
                    Notification.error("Erreur lors de la récupération des messages");
                });
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    selectedChat: this.state.chats.find(c => c.chatId === chatId) as (Chat & {Messages: Message[]}),
                    newMessage: false,
                }));
            }
        }
    }

    setContent: OnChangeHandlerFunc = (e) => {
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            content: val,
        }));
    }

    publicationPicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) this.reader.readAsDataURL(e.target.files[0]);
    }

    sendMessage = () => {
        paanteon.postMessage({
            content: this.state.content,
            // @ts-ignore
            destId: this.state.selectedChat.destId === this.user.userId ? this.state.selectedChat?.sendId : this.state.selectedChat?.destId,
            img: this.state.img,
        }).then((res: {data: Chat & {Messages: Message[]}}) => {
            this.setState(prevState => ({
                ...prevState,
                selectedChat: res.data,
                content: '',
                chats: this.state.chats.map(c => c.chatId !== -1 ? c : res.data),
                img: null,
            }), () => {
                const feed = document.getElementById('conversation-feed');
                if (feed) {
                    feed.scrollTo({top: feed.scrollHeight, behavior: "smooth"});
                }
            });
        }, function() {
            Notification.error("Erreur lors de l'envoi du message");
        });
    }

    toggleNewMessage = () => {
        this.setState(prevState => ({
            ...prevState,
            newMessage: !prevState.newMessage,
            selectedChat: null,
            chats: prevState.chats.filter(c => c.chatId !== -1),
        }));
    }

    setNewChat = (event: React.FormEvent<HTMLInputElement>, {newValue}: {newValue: string}) => {
        this.setState(prevState => ({
            ...prevState,
            newChat: newValue,
        }));
    }

    searchFriends = (payload: {value: string, reason: "input-changed" | "input-focused" | "escape-pressed" | "suggestions-revealed" | "suggestion-selected"}) => {
        this.setState(prevState => ({
            ...prevState,
            suggestedFriends: filterHelper.deepSearch(this.contactableUsers, payload.value),
        }));
    }

    clearSuggestedFriends = () => {
        this.setState(prevState => ({
            ...prevState,
            suggestedFriends: [],
        }));
    }

    selectedUserForNewChat = (event: React.FormEvent<HTMLInputElement>, payload: {suggestion: User}) => {
        this.setState(prevState => {
            const nChat: (Chat & {Messages: Message[]}) = {
                Dest: payload.suggestion,
                Messages: [],
                Sender: this.user,
                chatId: -1,
                createdAt: new Date().toISOString(),
                destId: payload.suggestion.userId as number,
                sendId: this.user.userId as number,
                updatedAt: new Date().toISOString(),
                unread: 0,
            };

            return {
                ...prevState,
                chats: [...prevState.chats, nChat],
                selectedChat: nChat,
                newMessage: false,
                newChat: '',
            }
        });
    }

    componentDidMount() {
        if (!this.props.newUserChat && this.props.chats.data.length) {
            this.selectChat(this.state.chats.sort((a, b) => moment(b.updatedAt).diff(a.updatedAt))[0]?.chatId);
        }
    }

    focusTextarea = () => {
        this.setState(prevState => ({
            ...prevState,
            textareaExpanded: true,
        }));
    }

    blurTextarea = () => {
        setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                textareaExpanded: false,
            }))
        }, 50);
    }

    selectImage = (messageId: number | null) => {
        this.setState(prevState => ({
            ...prevState,
            selectedImage: messageId,
        }));
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <UserSidebar user={this.props.selfUser.data} showCover={true}/>

                    <div className="main-content">
                        <div className="uk-container">
                            <div className="msg-layout">
                                <Helmet title={`Messages (${this.state.unreadMessages}) | Paanteon`} />
                                <h2 className="msg-header title-1">
                                    Messages <span className="smaller">({this.state.unreadMessages})</span>
                                </h2>

                                <div className="msg-content">
                                    <div className="msg-list">
                                        <button className="btn new-msg-btn" onClick={this.toggleNewMessage}>
                                            <div>
                                                <img className="picto" alt="picto-new-message" src={pictoNewMessage}/>
                                            </div>
                                            <div>Nouveau message</div>
                                        </button>

                                        {
                                            this.state.chats.sort((a, b) => moment(b.createdAt).diff(a.createdAt)).map(chat =>
                                            <button className={`btn ${chat.unread > 0 ? "unread" : ""} ${this.state.selectedChat?.chatId === chat.chatId ? "active" : ""}`}
                                                    onClick={() => this.selectChat(chat.chatId)} key={chat.chatId}>
                                                        <div>
                                                            {/* <img className="profile-pic" src={chat.sendId === this.user.userId ? chat.Dest.picturePath : chat.Sender.picturePath} alt="profile pic"/>*/}
                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + (chat.sendId === this.user.userId ? chat.Dest.picturePath : chat.Sender.picturePath) + ')'}}/>
                                                        </div>
                                                        <div>
                                                            {chat.sendId === this.user.userId ? chat.Dest.firstname + ' ' + chat.Dest.lastname : chat.Sender.firstname + ' ' + chat.Sender.lastname}
                                                        </div>
                                            </button>)
                                        }
                                    </div>

                                    {
                                        this.state.selectedChat && !this.state.newMessage &&
                                        <div className="msg-conversation">
                                            <div className="conversation-head">
                                                {/* <img className="profile-pic" src={this.state.selectedChat.sendId === this.user.userId ? this.state.selectedChat.Dest.picturePath : this.state.selectedChat.Sender.picturePath} alt="profile pic"/>*/}
                                                <div className="profile-pic" style={{backgroundImage: 'url(' + (this.state.selectedChat.sendId === this.user.userId ? this.state.selectedChat.Dest.picturePath : this.state.selectedChat.Sender.picturePath) + ')'}}/>
                                                <div>
                                                    <div className="username">
                                                        <Link to="connected.paanteon.user" params={{userId: this.state.selectedChat.sendId === this.user.userId ? this.state.selectedChat.destId : this.state.selectedChat.sendId, username: this.state.selectedChat.sendId === this.user.userId ? this.state.selectedChat.Dest.username : this.state.selectedChat.Sender.username}}>
                                                        {
                                                            // @ts-ignore
                                                            this.state.selectedChat.sendId === this.user.userId ?
                                                                this.state.selectedChat.Dest.firstname + ' ' + this.state.selectedChat.Dest.lastname
                                                                :
                                                                this.state.selectedChat.Sender.firstname + ' ' + this.state.selectedChat.Sender.lastname
                                                        }
                                                        </Link>
                                                    </div>
                                                    {
                                                        this.state.selectedChat.Messages.length > 0 && <div className="latest-msg">
                                                            {
                                                                // @ts-ignore
                                                                moment.duration(moment(this.state.selectedChat.Messages[this.state.selectedChat.Messages.length - 1].createdAt).diff()).humanize(true)
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className={`conversation-feed ${this.state.selectedChat.Messages.length === 0 ? "empty" : ""}`} id="conversation-feed">
                                                {
                                                    // @ts-ignore
                                                    this.state.selectedChat.Messages.map(message => <div key={message.messageId} className={"msg" + (message.userId === this.user.userId ? ' from-me' : '')}>
                                                        {/* <img className="profile-pic" src={message.userId === this.state.selectedChat?.destId ? this.state.selectedChat?.Dest.picturePath : this.state.selectedChat?.Sender.picturePath} alt="profile pic"/>*/}
                                                        <div>
                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + (message.userId === this.state.selectedChat?.destId ? this.state.selectedChat?.Dest.picturePath : this.state.selectedChat?.Sender.picturePath) + ')'}}/>
                                                        </div>
                                                        <div className="msg-container">
                                                            <div className="msg-content">
                                                                <span style={{whiteSpace: 'pre-wrap'}}>{processMentions(processLinks([message.content]))}</span>
                                                            </div>
                                                            {
                                                                message.picturePath !== null &&
                                                                    <div className={"msg-image" + (this.state.selectedImage === message.messageId ? ' lightbox' : '')} onClick={() => this.selectImage(message.messageId)}>
                                                                        <button className="btn close" onClick={(e) => {
                                                                            this.selectImage(null);
                                                                            stopPropagation(e);
                                                                        }}>X</button>
                                                                        <img src={message.picturePath} className="uploaded-img" alt="message"/>
                                                                    </div>
                                                            }
                                                            <div className="msg-date">{moment(message.createdAt).format('LL - LT')}</div>
                                                        </div>
                                                    </div>)
                                                }
                                            </div>

                                            <div className="conversation-input">
                                                <MentionableUsersContext.Consumer>
                                                    {
                                                        (mentionUsers) => <MentionsInput value={this.state.content}
                                                                                         onChange={this.setContent} onFocus={this.focusTextarea}
                                                                                         placeholder={(this.state.selectedChat as Chat & {Messages: Message[]}).chatId === -1 || (this.state.selectedChat as Chat & {Messages: Message[]}).Messages.length === 0 ? "Écrire un nouveau message" : "Répondre"}
                                                                                         className={"textarea expandable textarea-with-mentions" + (this.state.textareaExpanded ? ' expanded' : '')}>
                                                            <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                                     renderSuggestion={(suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>}/>
                                                        </MentionsInput>
                                                    }
                                                </MentionableUsersContext.Consumer>
                                                <div className="custom-upload">
                                                    <input type="file" name="publication-pic" id="publication-pic" onChange={this.publicationPicChange} accept={acceptableImages}/>
                                                    <label htmlFor="publication-pic">
                                                        <img src={pictoCamera} alt="picto-camera"/>
                                                    </label>
                                                </div>
                                                {
                                                    this.state.img !== null &&
                                                        <div className="uploaded-img">
                                                            <img src={this.state.img as unknown as string} alt=""/>
                                                        </div>
                                                }
                                                <div className="right">
                                                    <button className="btn btn-1 btn-wider" onClick={this.sendMessage}>Envoyer</button>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.newMessage &&
                                            <div className="new-msg-user-search">
                                                <Autosuggest
                                                    inputProps={{
                                                        value: this.state.newChat,
                                                        onChange: this.setNewChat,
                                                        placeholder: "Rechercher un ami",
                                                    }}
                                                    suggestions={this.state.suggestedFriends}
                                                    getSuggestionValue={getFriendSuggestionValue}
                                                    onSuggestionsFetchRequested={this.searchFriends}
                                                    onSuggestionsClearRequested={this.clearSuggestedFriends}
                                                    renderSuggestion={renderFriendSuggestion}
                                                    onSuggestionSelected={this.selectedUserForNewChat}
                                                    theme={{
                                                        container: 'react-autosuggest__container',
                                                        containerOpen: 'react-autosuggest__container--open',
                                                        input: "input big no-border block",
                                                        inputOpen: 'react-autosuggest__input--open',
                                                        inputFocused: 'react-autosuggest__input--focused',
                                                        suggestionsContainer: 'react-autosuggest__suggestions-container',
                                                        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                                                        suggestionsList: 'react-autosuggest__suggestions-list',
                                                        suggestion: 'react-autosuggest__suggestion',
                                                        suggestionFirst: 'react-autosuggest__suggestion--first',
                                                        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                                                        sectionContainer: 'react-autosuggest__section-container',
                                                        sectionContainerFirst: 'react-autosuggest__section-container--first',
                                                        sectionTitle: 'react-autosuggest__section-title',
                                                    }}
                                                />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Messages;
