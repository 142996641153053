import React from "react";

export function deleteFromArrayAndReturn<T>(array: Array<T>, index: number): Array<T> {
    let newArray = array.slice(0);
    newArray.splice(index, 1);
    return newArray;
}

export function stopPropagation(event: React.SyntheticEvent) {
    event.stopPropagation();
    event.preventDefault();
}
