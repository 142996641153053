import localStorage from "../api/localStorage";
import {Collection, Comment, CommentToSend, Gallery, Thumbnail, Tribe, Tribute, User, UserInfos} from "../api/types";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";
import filterHelper from "../api/filterHelper";
import UserSidebar from "./UserSidebar";
import {UIRouterReact, UIView} from "@uirouter/react";
import {injectUIRouter, Link} from "../api/injectors";
import pictoMagnifyPlus from "../assets/img/pictos/picto-magnify-plus.svg";
import pictoPaanteon from "../assets/img/pictos/picto-paanteon.svg";
import pictoEngraveWhite from "../assets/img/pictos/picto-engrave-white.svg";
import {pictoEdit, pictoHeart, pictoHeartBlue, pictoLink, pictoSpeechBubbleBlue, pictoWikipedia} from "../api/pictos";
import OptionsMenu from "./OptionsMenu";
import ReportModal from "./ReportModal";
import ConfirmModal from "./ConfirmModal";
import moment from "moment";
import ShareModal from "./ShareModal";
import Recommendations from "./Recommendations";
import SlideSwitch from "./SlideSwitch";
import Comments from "./Comments";
import React, {Component} from "react";
import Helmet from "./Helmet";
import { updateUser } from "./PublicHeader";

interface OwnProps {
    user: UserInfos
    galleries: (Gallery & { userCollections: Collection[] })[]
    tribute: { data: Tribute & {Thumbnail: Thumbnail & {Tributes: []}}}
    userTributes: {tributeId: number, archivedAt: string, uniqueName: string}[]
    router: UIRouterReact
    engraves: { data: { authorFriends: User[], myFriends: User[]} }
    discover?: (Thumbnail & {Tributes: Tribute[]})[]
    popular?: (Thumbnail & {Tributes: Tribute[]})[]
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type Props = OwnProps;

type State = Readonly<{
    // galleries: (Gallery & { userCollections: Collection[] })[]
    selectedGallery?: Gallery
    showLightbox: boolean
    liked: boolean
    likeCount: number
    comments: Comment[]
    shareCount: number
    showConfirmDelete: boolean
    showAllEngravesAuthor: boolean
    showAllEngravesFriends: boolean
    openedArchiveModal: boolean
    displayRecommendations: boolean
}>;

class TributeView extends Component<Props, State> {
    readonly state: State = {
        showLightbox: false,
        liked: Boolean(this.props.tribute.data.liked),
        likeCount: this.props.tribute.data.likeCount,
        comments: this.props.tribute.data.Comments ? this.props.tribute.data.Comments : [],
        shareCount: this.props.tribute.data.shareCount,
        showConfirmDelete: false,
        showAllEngravesAuthor: false,
        showAllEngravesFriends: false,
        openedArchiveModal: false,
        displayRecommendations: false,
    };
    private isMyPaanteon = this.props.user.userId === localStorage.getLocalUser().userId;
    private readonly nextTribute?: {tributeId: number, thumbnailUniqueName: string};
    private readonly prevTribute?: {tributeId: number, thumbnailUniqueName: string};
    private user: User = localStorage.getLocalUser();
    private hasTribute: boolean;


    showLightbox = () => {
        this.setState(prevState => ({...prevState, showLightbox: !prevState.showLightbox}));
    };

    toggleLike = () => {
        if (this.state.liked) {
            paanteon.unlike({tributeId: this.props.tribute.data.tributeId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    liked: false,
                    likeCount: prevState.likeCount - 1,
                }));
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        } else {
            paanteon.like({tributeId: this.props.tribute.data.tributeId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    liked: true,
                    likeCount: prevState.likeCount + 1,
                }));
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        }
    };

    focusComment = () => {
        const ft = document.getElementById('first-textarea');
        if (ft) ft.focus();
    };

    openSharePopup = (open: boolean) => {
        return () => {
            this.setState(prevState => ({
                ...prevState,
                sharePopupOpen: open,
            }));
        }
    };

    onShareSent = () => {
        this.setState(prevState => ({
            ...prevState,
            shareCount: prevState.shareCount + 1,
        }));
    };

    sendComment = (comment: CommentToSend) => {
        const comm = {
            ...comment,
            tributeId: this.props.tribute.data.tributeId,
        };
        const answerId = comm.answerId;
        if (comm.answerId === -1) delete comm.answerId;
        return paanteon.comment(comm).then((res: {data: Comment}) => {
            this.setState(prevState => {
                if (!res.data.Answers) res.data.Answers = [];
                let nState = {
                    ...prevState,
                };
                if (answerId === -1) {
                    nState.comments = [...nState.comments, res.data];
                } else {
                    const comm = nState.comments.find(comm => comm.commentId === answerId);
                    if (comm && comm.Answers) comm.Answers = [...comm.Answers, res.data];
                }
                return nState;
            });
            return true;
        }, function() {
            Notification.error("Erreur lors de la création du commentaire");
            return false;
        });
    };

    constructor(props: Props) {
        super(props);

        const tributes = props.tribute.data.archivedAt ?
            props.userTributes.filter(t => !!t.archivedAt)
            :
            props.userTributes.filter(t => !t.archivedAt);
        let pos: number;
        for (pos = 0; pos < tributes.length; pos++) {
            if (tributes[pos].tributeId === this.props.tribute.data.tributeId) {
                break;
            }
        }
        if (tributes[pos + 1]) {
            this.nextTribute = {tributeId: tributes[pos + 1].tributeId, thumbnailUniqueName: tributes[pos + 1].uniqueName};
        }
        if (tributes[pos - 1]) {
            this.prevTribute = {tributeId: tributes[pos - 1].tributeId, thumbnailUniqueName: tributes[pos - 1].uniqueName};
        }

        this.hasTribute = !!this.props.tribute.data.Thumbnail.Tributes.find(t => t.userId === this.user.userId);
    }

    componentDidMount(): void {
        for (let index = 0; index < this.props.galleries.length; index++) {
            const gallery = this.props.galleries[index];
            if (this.props.tribute.data.yearly && gallery.yearly && String(gallery.label) === String(this.props.tribute.data.year)) { // select year virtual gallery instead of db gallery
                this.setState({
                    selectedGallery: gallery,
                });
            } else if (!this.props.tribute.data.yearly) { // normal selection we look for the gallery containing the collection
                const collection = gallery.Collections.filter((el) => {
                    return el.collectionId === this.props.tribute.data.collectionId
                })[0];
                if (collection) {
                    this.setState({
                        selectedGallery: gallery,
                    });
                    // $scope.selectedGallery = gallery;
                    /* $scope.selectedCollection = collection.collectionId;
                    $scope.selectedCollectionLabel = collection.label;
                    $scope.yearlyGalleryCollectionsFilter.galleryId = collection.galleryId;*/
                    break;
                }
            }
        }

        const {hash} = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const e = document.getElementById(hash.slice(1, hash.length));
                if (e) e.scrollIntoView(true);
            }, 0);
        }
    }

    deleteComment = (commentId: number) => {
        return paanteon.deleteComment(commentId).then(() => {
            this.setState(prevState => ({
                ...prevState,
                comments: prevState.comments.filter(c => c.commentId !== commentId).map(c => {
                    if (c.Answers) c.Answers = c.Answers.filter(a => a.commentId !== commentId);
                    return c;
                }),
            }));
            return true;
        });
    }

    closeConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            showConfirmDelete: false,
        }));
    }

    openConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            showConfirmDelete: true,
        }));
    }

    confirmDelete = () => {
        paanteon.deleteTribute(this.props.tribute.data.tributeId).then(() => {
            Notification.success("Hommage supprimé avec succès");
            updateUser();
            if (this.props.userTributes.length === 1)
                this.props.router.stateService.go('connected.tutorial');
            else
                this.props.router.stateService.go('connected.paanteon.user.galleries.condensed', {userId: this.props.tribute.data.userId});
        }, function() {
            Notification.error("Erreur lors de la suppression de l'hommage")
        });
    }

    showAllEngravesAuthor = () => {
        this.setState(prevState => ({
            ...prevState,
            showAllEngravesAuthor: true,
        }))
    }

    showAllEngravesFriends = () => {
        this.setState(prevState => ({
            ...prevState,
            showAllEngravesFriends: true,
        }))
    }

    archiveTribute = () => {
        paanteon.archiveTribute(this.props.tribute.data.tributeId).then(() => {
            this.props.router.stateService.go('connected.paanteon');
            Notification.success("Hommage archivé avec succès")
        }, function() {
            Notification.error("Erreur lors de l'archivage")
        });
    }

    closeArchiveModal = () => {
        this.setState(prevState => ({
            ...prevState,
            openedArchiveModal: false,
        }));
    }

    openArchiveModal = () => {
        this.setState(prevState => ({
            ...prevState,
            openedArchiveModal: true,
        }));
    }

    toggleDisplayRecommendations = () => {
        this.setState(prevState => ({
            ...prevState,
            displayRecommendations: !prevState.displayRecommendations,
        }));
    }

    transformTributeContent = (content: string): string => {
        let transformedContent = content;

        // NOTE: replace external links to open in a new tab https://regexr.com/6o5r7
        transformedContent = transformedContent.replaceAll(/<a(\s*.*?href="http.+?")>(\s*.+?\s*)<\/a>/g, "<a$1 target=\"_blank\" rel=\"noopener noreferrer\">$2</a>");

        return transformedContent;
    }

    render() {
        const links = this.props.galleries.sort(filterHelper.orderByField<Gallery, 'galleryId'>('galleryId')).map((gallery: Gallery) => {
            return <Link to="connected.paanteon.user.galleries.all" params={{'#': "gallery-" + gallery.code, userId: this.props.user.userId, username: this.props.user.username}} className={"tab-item" + (this.state.selectedGallery && this.state.selectedGallery.galleryId === gallery.galleryId ? ' active' : '')} key={gallery.galleryId}>{gallery.label}</Link>
        });
        return (
            <>
                <div className="dark-bg">
                    <div className="uk-container">
                        <Helmet title={`${(this.isMyPaanteon
                          ? (this.props.tribute.data.archivedAt ? 'Mes Archives' : 'Mon Paanteon')
                          : `Le Paanteon de ${this.props.user.firstname} ${this.props.user.lastname}`)} - ${this.props.tribute.data.Thumbnail.label}`} />
                        <div className="page-head" style={{backgroundImage: 'url(' + this.props.user.coverPath + ')'}}>
                            {this.isMyPaanteon ?
                                <h1><span className="text-finish">{this.props.tribute.data.archivedAt ? 'Mes Archives' : 'Mon Paanteon'}</span></h1>
                                :
                                <h1>Le Paanteon <span className="ptn-of">de {this.props.user.firstname} {this.props.user.lastname}</span></h1>
                            }
                        </div>
                        <UserSidebar user={this.props.user}
                            showLastTributes={true}
                            showCover={false}
                            showButtons={true}
                            showBio={true}
                            collapsible={true}
                            popular={this.props.popular}
                            discover={this.props.discover}
                        >
                            <div className="sidebar-engraved-thumbnail sidebar-block">
                                <p className="block-title cap-first-letter no-border">{this.props.tribute.data.Thumbnail.label}<br/>a été gravé...</p>
                                {
                                    !this.isMyPaanteon ?
                                        <>
                                            <p className="block-title left-aligned">Par {this.props.engraves.data.authorFriends.length} amis de {this.props.user.firstname}</p>
                                            <div className="users-list">
                                                {
                                                    this.props.engraves.data.authorFriends.map(friend => (
                                                        <div className="user-item" key={friend.userId}>
                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + friend.picturePath + ')'}}/>
                                                            <p>{friend.firstname + ' ' + friend.lastname}</p>
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    this.props.engraves.data.authorFriends.length > 3 && !this.state.showAllEngravesAuthor &&
                                                    <button className="btn link-btn blue" onClick={this.showAllEngravesAuthor}>&gt; voir tous</button>
                                                }
                                            </div>
                                        </>
                                        : null
                                }

                                <br/>
                                <p className="block-title left-aligned">Par {this.props.engraves.data.myFriends.length} de mes amis</p>
                                <div className="users-list">
                                    {
                                        (this.state.showAllEngravesFriends ? this.props.engraves.data.myFriends : this.props.engraves.data.myFriends.slice(0, 3)).map(friend => (
                                            <div className="user-item" key={friend.userId}>
                                                <div className="profile-pic" style={{backgroundImage: 'url(' + friend.picturePath + ')'}}/>
                                                <p>{friend.firstname + ' ' + friend.lastname}</p>
                                            </div>
                                        ))
                                    }

                                    {
                                        this.props.engraves.data.myFriends.length > 3 && !this.state.showAllEngravesFriends &&
                                        <button className="btn link-btn blue" onClick={this.showAllEngravesFriends}>&gt; voir tous</button>
                                    }
                                </div>
                            </div>
                        </UserSidebar>

                        <div className="main-content">
                            <div className="uk-container">
                                <UIView><>
                                    <div className="link-tabs">
                                        {links}
                                    </div>

                                    <div className="margin-large">
                                        <h2 className="title-1 title-with-nav no-margin-bottom">
                                            {
                                                this.prevTribute ?
                                                    <Link className="prev arrow" to="connected.paanteon.tribute.details" params={this.prevTribute}/>
                                                    : null
                                            }
                                            <span className="cap-first-letter">{this.props.tribute.data.Thumbnail.label}</span>
                                            {
                                                this.nextTribute ?
                                                    <Link className="next arrow" to="connected.paanteon.tribute.details" params={this.nextTribute}/>
                                                    : null
                                            }
                                        </h2>
                                        <h3 className="collection-title no-margin h-center">{this.props.tribute.data.Collection.label}</h3>
                                    </div>

                                    <div>
                                        <div className="banner-img-2">
                                            <span className="img-container">
                                                <img className="picto" src={pictoMagnifyPlus} alt="magnify picto" onClick={this.showLightbox}/>
                                                <img className="main-img" alt="tribute" src={this.props.tribute.data.picturePath}/>
                                            </span>
                                        </div>
                                        {/* temporary mechanism*/}
                                        {
                                            this.state.showLightbox ?
                                                <div className="lightbox" ng-show="lightboxImg" onClick={this.showLightbox}>
                                                    <span className="close">X</span>
                                                    <img className="main-img" alt="tribute" src={this.props.tribute.data.picturePath}/>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className="tribute-quick-actions" ng-show="!(editMode || createMode)">
                                        {
                                            this.props.tribute.data.Thumbnail.tributeCount && this.props.tribute.data.Thumbnail.tributeCount > 1 ?
                                                <Link className="ghost-link" to="connected.paanteon.thumbnail.tributes"
                                                      params={{thumbnailId: this.props.tribute.data.thumbnailId, thumbnailUniqueName: this.props.tribute.data.Thumbnail.uniqueName}}>
                                                    {this.props.tribute.data.Thumbnail.tributeCount}<img
                                                    src={pictoPaanteon} className="picto" alt="picto paanteon"/>
                                                </Link>
                                                :
                                                <span>
                                                    {this.props.tribute.data.Thumbnail.tributeCount}
                                                    <img src={pictoPaanteon}
                                                         className="picto"
                                                         alt="picto paanteon"/>
                                                </span>
                                        }
                                        {
                                            !this.isMyPaanteon && !this.hasTribute ?
                                                <span>
                                                    <Link to="connected.paanteon.user.new-tribute"
                                                          params={{thumbnailId: this.props.tribute.data.Thumbnail.thumbnailId, collectionId: this.props.tribute.data.collectionId}}>
                                                        <img className="picto" src={pictoEngraveWhite} alt="picto engrave white"/>
                                                    </Link>
                                                </span>
                                                : null
                                        }
                                    </div>

                                    <div className="tile margin-large tribute-writing-bloc">
                                        <div className="tile-head">
                                            <div className="uk-grid uk-grid-small">
                                                <div className="uk-width-expand">
                                                    {
                                                        this.props.tribute.data.Thumbnail.thumbnailUserId && <><Link to="connected.paanteon.user" params={{userId: this.props.tribute.data.Thumbnail.thumbnailUserId, username: this.props.tribute.data.Thumbnail.thumbnailUsername}}>&gt; Voir son paanteon</Link><br/></>
                                                    }
                                                    {
                                                        this.props.tribute.data.Thumbnail.InMemoriam?.inMemoriamId && <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: this.props.tribute.data.Thumbnail.uniqueName, memoriamId: this.props.tribute.data.Thumbnail.InMemoriam.inMemoriamId}}>&gt; Voir le livre d'or</Link>
                                                    }
                                                </div>
                                                <div>
                                                    {
                                                        this.props.tribute.data.Thumbnail.wikipedia && <><a href={this.props.tribute.data.Thumbnail.wikipedia} target="_blank" rel="noopener noreferrer"><img src={pictoWikipedia} className="picto" alt="picto wikipedia"/> Wikipédia</a><br/></>
                                                    }
                                                    {
                                                        this.props.tribute.data.Thumbnail.website && <a href={this.props.tribute.data.Thumbnail.website} target="_blank" rel="noopener noreferrer"><img src={pictoLink} className="picto" alt="picto website"/> Site officiel</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tile-body" style={{position: 'relative'}}>
                                            <OptionsMenu>
                                                <span className="options-menu">
                                                    <ul>
                                                        {
                                                            this.props.tribute.data.archivedAt === null && !this.isMyPaanteon && <li>
                                                                <ReportModal tributeId={this.props.tribute.data.tributeId}><button className="btn link-btn">Signaler</button></ReportModal>
                                                            </li>
                                                        }
                                                        {
                                                            this.props.tribute.data.userId === this.user.userId &&
                                                            <>
                                                                <li><button className="btn link-btn" onClick={this.openConfirmDelete}>Supprimer</button></li>
                                                                {
                                                                    this.props.tribute.data.archivedAt === null && <li>
                                                                        <button className="btn link-btn" onClick={this.openArchiveModal}>Archiver</button>
                                                                        {
                                                                            this.state.openedArchiveModal &&
                                                                            <ConfirmModal confirmMessage="Archiver" onCancel={this.closeArchiveModal} onConfirm={this.archiveTribute}>
                                                                                Voulez-vous vraiment archiver cet hommage ?
                                                                            </ConfirmModal>
                                                                        }
                                                                    </li>
                                                                }
                                                            </>
                                                        }

                                                        {
                                                            (this.user.admin && this.user.userId !== this.props.tribute.data.userId) &&
                                                            <>
                                                                <li><button className="btn link-btn" onClick={this.openConfirmDelete}>Supprimer</button></li>
                                                            </>
                                                        }
                                                    </ul>
                                                </span>
                                            </OptionsMenu>
                                            <div className="action-icons" style={{right: '12px'}}>
                                                {
                                                    this.isMyPaanteon && this.props.tribute.data.archivedAt === null ?
                                                        <Link to="connected.paanteon.tribute.details.edit" className="margin-top">
                                                            <img src={pictoEdit} alt="picto edit"/>
                                                        </Link>
                                                        : null
                                                }
                                            </div>
                                            <div className="article">
                                                <p className="published">
                                                    Publié le {moment(this.props.tribute.data.createdAt).format('LL') /* | date: 'short'*/}
                                                </p>
                                                {this.props.tribute.data.title.length > 0 && <div className="article-title">{this.props.tribute.data.title}</div>}
                                                {this.props.tribute.data.content.replaceAll(/(<br>|<\/?p>|&nbsp;| )/g, "").length === 0 && this.isMyPaanteon && this.props.tribute.data.archivedAt === null
                                                  ? <Link to="connected.paanteon.tribute.details.edit">
                                                      <input type="text" className="input block"
                                                             placeholder="Je rédige un hommage" value="" />
                                                    </Link>
                                                  : <div dangerouslySetInnerHTML={{__html: this.transformTributeContent(this.props.tribute.data.content)}}/>
                                                }
                                                <div className="article-footer">
                                                    <span style={{color: "#37a8db"}}>
                                                        <button onClick={this.toggleLike} className="btn btn-icon">
                                                            {
                                                                this.state.liked ?
                                                                    <img src={pictoHeart} className="picto blue" alt="picto heart"/>
                                                                    :
                                                                    <img src={pictoHeartBlue} className="picto blue" alt="picto heart"/>
                                                            }
                                                            <span style={{color: '#37a8db'}} className="regular">{this.state.likeCount}</span>
                                                        </button>
                                                    </span>
                                                    <span><button className="btn btn-icon" onClick={this.focusComment}><img src={pictoSpeechBubbleBlue} className="picto blue" alt="picto comment"/><span style={{color: "#37a8db"}}>{this.state.comments.length}</span></button></span>

                                                    <span>
                                                        <ShareModal onSent={this.onShareSent} tribute={this.props.tribute.data} shareCount={this.state.shareCount} shared={this.props.tribute.data.shared === 1}/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.props.tribute.data.Recommendations && (this.props.tribute.data.Recommendations.length > 0 || this.state.displayRecommendations) && <Recommendations editable={this.user.userId === this.props.tribute.data.userId && this.props.tribute.data.archivedAt === null} recommendations={this.props.tribute.data.Recommendations} tributeId={this.props.tribute.data.tributeId as number}/>
                                    }
                                    {
                                        this.props.tribute.data.Recommendations && this.props.tribute.data.Recommendations.length === 0 && (this.user.userId === this.props.tribute.data.userId && this.props.tribute.data.archivedAt === null) && !this.state.displayRecommendations &&
                                        <div className={"tile dark-blue switchable margin-large "}>
                                            <div className="tile-head">
                                                <h3 className="tile-title no-after">Mes recommandations pour aller plus loin</h3>
                                                <div className={"h-center"}>
                                                    <span className={"load-more"} onClick={this.toggleDisplayRecommendations}/>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="tile light-blue margin-large">
                                        <div className="tile-body">
                                            {
                                                !this.isMyPaanteon && <h3 className="tile-title">Et vous, que vous inspire <span className="cap-first-letter">{this.props.tribute.data.Thumbnail.label}</span>&nbsp;?</h3>
                                            }
                                            <div className="margin-large h-center">
                                                {/* returns 1,2,3 for now, but can easily be changed to another
                                            codification in the directive
                                            ATTN, kind of counter-intuitive -> 1 = Love, 2 = Admiration, 3 = Respect*/}
                                                {/* <div slide-switch model="tribute.Thumbnail" ng-if="tribute.tributeId"/>*/}
                                                <SlideSwitch thumbnail={this.props.tribute.data.Thumbnail} static={this.isMyPaanteon}/>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        (this.props.tribute.data.archivedAt === null || this.state.comments.length > 0) && <>
                                            <div>
                                                <h3 className="tile-title">Commentaires ({this.state.comments.length})</h3>
                                            </div>

                                            <div className="margin-large">
                                                <Comments onDelete={this.deleteComment} comments={this.state.comments} selfUser={this.user} onSend={this.props.tribute.data.archivedAt === null ? this.sendComment : undefined}/>
                                            </div>
                                        </>
                                    }
                                </></UIView>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.showConfirmDelete && <ConfirmModal onCancel={this.closeConfirmDelete} onConfirm={this.confirmDelete}>Êtes-vous sûr(e) de vouloir supprimer définitivement cet hommage de votre paanteon ?</ConfirmModal>
                }
            </>
        );
    }
}

export default injectUIRouter(TributeView);
