import React, {Component, ReactElement} from 'react';
import {stopPropagation} from "../../api/helpers";

class OptionsMenu extends Component<{ children: ReactElement<{ className?: string }> }, { open: boolean }> {
    constructor(props: { children: ReactElement }) {
        super(props);
        this.state = {
            open: false,
        };
    }

    toggle = (e: React.MouseEvent<HTMLSpanElement>) => {
        this.setState(prevState => {
            return {open: !prevState.open}
        });
    };

    render() {
        return React.cloneElement(this.props.children, {className: this.props.children.props.className + (this.state.open ? " open" : ""), onClick: stopPropagation}, <><span className="icon" onClick={this.toggle}/>{(this.props.children).props.children}</>);
    }
}

export default OptionsMenu;
