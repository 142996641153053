import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import Helmet from "../../components/Helmet";
import { Link } from "../../api/injectors";
import { Tribe, Gallery, MetaTribe, TagCategory, Criteria as CriteriaType} from "../../api/types";
import ConfirmModal from "../../components/ConfirmModal";
import adminRequests from "../../api/adminRequests";
import Notification from "../../api/notification";
import { deleteFromArrayAndReturn } from "../../api/helpers";
import Criteria from "../../components/Tribes/Criteria";
import Modal from "../../components/Modal";
import NewTribeForm from "./NewTribeForm";

const Tribes = (
    {
        tagCategories,
        galleries,
        ...props
    }:{
        tagCategories: {data: TagCategory[]},
        galleries: {data: Gallery[]},
        [x:string]:any
    }):JSX.Element => {
    const [newMetaTribeLabel, setNewMetaTribeLabel] = useState<string>("");
    const [openedMetaTribes, setOpenedMetaTribes] = useState<number[]>([]);

    const [openedTribes, setOpenedTribes] = useState<{
        tribeId: number|null,
        criteriaArray :Array<CriteriaType>,
    }[]>([]);

    const [metaTribeToDelete, setMetaTribeToDelete] = useState<MetaTribe | null>(null);
    const [metaTribes, setMetaTribes] = useState<MetaTribe[]>(props.metaTribes.data);
    const [tribeToDelete, setTribeToDelete] = useState<Tribe | null>(null);

    const [openTribeModal, setOpenTribeModal] = useState<boolean>(false);

    const openDeleteMetaTribeModal = useCallback((metaTribe: MetaTribe) => {
        setMetaTribeToDelete(metaTribe);
    }, [])

    const closeDeleteMetaTribeModal = useCallback(() => {
        setMetaTribeToDelete(null);
    }, [])

    const openDeleteTribeModal = useCallback((tribe: Tribe) => {
        setTribeToDelete(tribe);
    }, [])

    const closeDeleteTribeModal = useCallback(() => {
        setTribeToDelete(null);
    }, [])

    const isOpenedMetaTribe = useCallback((metaTribeId: number) => {
        return openedMetaTribes.includes(metaTribeId);
    }, [openedMetaTribes]);

    const isOpenedTribe = useCallback((tribeId: number) => {
        return openedTribes.find((t) => t.tribeId === tribeId) !== undefined;
    }, [openedTribes]);

    const getOpenedTribe = useCallback((tribeId: number) => {
        return openedTribes.find((t) => t.tribeId === tribeId)!;
    }, [openedTribes]);

    const createMetaTribe = useCallback(() => {
        adminRequests.createMetaTribe({label: newMetaTribeLabel}).then((res) => {
            const metaTribe = {...res.data, Tribes: res.data.Tribes || []};

            setNewMetaTribeLabel("");
            setMetaTribes((prev) => [...prev, metaTribe])

            Notification.success("La méta-tribu a été créée avec succès");
        }, () => Notification.error("Échec lors de la création de la méta-tribu"))
    }, [newMetaTribeLabel])

    const deleteMetaTribe = useCallback((metaTribe: MetaTribe) => {
        adminRequests.deleteMetaTribe(metaTribe.metaTribeId)
            .then(() => {
                setMetaTribes((prev) => prev.filter((mt) => mt.metaTribeId !== metaTribe.metaTribeId))
                Notification.success("La méta-tribu a été supprimée avec succès");
                closeDeleteMetaTribeModal();
            }, ()=>{
                Notification.error("Échec lors de la suppression de la méta-tribu")
                closeDeleteMetaTribeModal();
            })
    }, [closeDeleteMetaTribeModal])

    const updateMetaTribe = useCallback((metaTribe: MetaTribe) => {
        adminRequests.updateMetaTribe(metaTribe.metaTribeId, metaTribe)
            .then(() => {
                Notification.success("La méta-tribu a été mise à jour avec succès");
            }, ()=>Notification.error("Échec lors de la mise à jour de la méta-tribu"))
    }, [])

    const updateMetaTribeLabel = useCallback((newLabel:string, metaTribeId:number)=>{
        const newMetaTribes = [...metaTribes];
        const metaTribeIdx = metaTribes.findIndex((mt) => mt.metaTribeId === metaTribeId);
        newMetaTribes[metaTribeIdx].label = newLabel;
        setMetaTribes(newMetaTribes);
    }, [metaTribes])

    const updateNewMetaTribeLabel = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        setNewMetaTribeLabel(val);
    }, [])

    const createTribe = useCallback((metaTribeId: MetaTribe["metaTribeId"], label: string, criteria: CriteriaType[], minCriteria: number) => {
        adminRequests.createTribe({
            label: label,
            metaTribeId: metaTribeId,
            Criteria: criteria,
            minCriteria: minCriteria,
        }).then((res) => {
            const tribe = {...res.data, Criteria: res.data.Criteria || []};
            const newMetaTribes = [...metaTribes];
            const metaTribeIdx = metaTribes.findIndex((mt) => mt.metaTribeId === metaTribeId);
            newMetaTribes[metaTribeIdx].Tribes.push(tribe);
            setMetaTribes(newMetaTribes);
            Notification.success("La tribu a été créée avec succès");
        }, (res) => {
            if (res.response.status === 400)
                Notification.error("Échec lors de la création de la tribu: données manquantes")
            else
                Notification.error("Échec lors de la création de la tribu")
        })
    }, [metaTribes])


    const updateTribe = useCallback((tribe: Tribe) => {
        const tribeToUpdate = {...tribe, Criteria: getOpenedTribe(tribe.tribeId).criteriaArray};
        adminRequests.updateTribe(tribe.tribeId, tribeToUpdate)
            .then((tribe) => {
                setOpenedTribes((prev) => prev.map((t) => {
                    if (t.tribeId === tribe.data.tribeId) {
                        return {
                            ...t,
                            criteriaArray: tribe.data.Criteria,
                        }
                    } else {
                        return t;
                    }
                }
                ))
                Notification.success("La tribu a été mise à jour avec succès");
            }, ()=>Notification.error("Échec lors de la mise à jour de la tribu"))
    }, [getOpenedTribe])

    const deleteTribe = useCallback((tribe: Tribe) => {
        adminRequests.deleteTribe(tribe.tribeId)
            .then(() => {
                const newMetaTribes = [...metaTribes];
                const metaTribeIdx = metaTribes.findIndex((mt) => mt.metaTribeId === tribe.metaTribeId);
                // newMetaTribes[metaTribeIdx].Tribes = newMetaTribes[metaTribeIdx].Tribes.filter((t) => t.tribeId !== tribe.tribeId);
                newMetaTribes[metaTribeIdx].Tribes = deleteFromArrayAndReturn(newMetaTribes[metaTribeIdx].Tribes, newMetaTribes[metaTribeIdx].Tribes.findIndex((t) => t.tribeId === tribe.tribeId));
                setMetaTribes(newMetaTribes);
                Notification.success("La tribu a été supprimée avec succès");
                closeDeleteTribeModal();
            }, ()=>{
                Notification.error("Échec lors de la suppression de la tribu")
                closeDeleteTribeModal();
            })
    }, [metaTribes, closeDeleteTribeModal])

    const updateTribeLabel = useCallback((newLabel: string, tribe: Tribe) => {
        const newMetaTribes = [...metaTribes];
        const metaTribeIdx = metaTribes.findIndex((mt) => mt.metaTribeId === tribe.metaTribeId);
        const tribeIdx = metaTribes[metaTribeIdx].Tribes.findIndex((t) => t.tribeId === tribe.tribeId);
        newMetaTribes[metaTribeIdx].Tribes[tribeIdx].label = newLabel;
        setMetaTribes(newMetaTribes);
    }, [metaTribes])


    const updateCriteriaCollection = useCallback((e: ChangeEvent<HTMLSelectElement>, tribeId: number, criteriaIdx: number) => {
        const val = e.target.value;
        const newCriterias = [...getOpenedTribe(tribeId).criteriaArray];
        newCriterias[criteriaIdx].collectionId = val === "-1" ? null : parseInt(val);
        setOpenedTribes((prev) => {
            return prev.map((t) => {
                if (t.tribeId === tribeId) {
                    return {
                        ...t,
                        criteriaArray: newCriterias,
                    }
                } else {
                    return t;
                }
            })
        })
    }, [getOpenedTribe])

    const updateCriteriaTag = useCallback((e: ChangeEvent<HTMLSelectElement>, tribeId: number, criteriaIdx: number) => {
        const val = e.target.value;
        const newCriterias = [...getOpenedTribe(tribeId).criteriaArray];
        newCriterias[criteriaIdx].tagId = val === "-1" ? null : parseInt(val);
        setOpenedTribes((prev) => {
            return prev.map((t) => {
                if (t.tribeId === tribeId) {
                    return {
                        ...t,
                        criteriaArray: newCriterias,
                    }
                } else {
                    return t;
                }
            })
        })
    }, [getOpenedTribe])

    const updateTribeMinCriteria = useCallback((e: ChangeEvent<HTMLInputElement>, tribe: Tribe) => {
        const val = e.target.value;
        const newMetaTribes = [...metaTribes];
        const metaTribeIdx = metaTribes.findIndex((mt) => mt.metaTribeId === tribe.metaTribeId);
        const tribeIdx = metaTribes[metaTribeIdx].Tribes.findIndex((t) => t.tribeId === tribe.tribeId);
        newMetaTribes[metaTribeIdx].Tribes[tribeIdx].minCriteria = parseInt(val);
        setMetaTribes(newMetaTribes);
    }, [metaTribes])

    const nbTotalTribe = useMemo(() => {
        return metaTribes.reduce((acc, metaTribe) => {
            return acc + metaTribe.Tribes.length;
        }, 0)
    }, [metaTribes])

    const openTribe = useCallback((tribe: Tribe) => {
        !openedTribes.find((t) => t.tribeId === tribe.tribeId) ?
            setOpenedTribes((prev)=>
            [...prev, {tribeId: tribe.tribeId, criteriaArray: tribe.Criteria}])
        : setOpenedTribes((prev) =>
            prev.filter((t) => t.tribeId !== tribe.tribeId))
    }, [openedTribes]);

    return (
        <div className = "dark-bg">
            <div className ="uk-container">
                <div className ="main-content">
                    <div className ="uk-container">
                        <Helmet title = { `Tribus (${ nbTotalTribe }) | Paanteon` } />
                        <h1 className ="page-title">
                            Tribus ({ nbTotalTribe })
                        </h1>
                        <div className="flex align-center space-between">
                            <div className="margin-bottom">
                                <Link to="^">&lt; Retour</Link>
                            </div>
                            <button className="btn btn-1" onClick={()=>setOpenTribeModal((prev)=> !prev)}>Liste des Tribus</button>
                        </div>
                        <div>
                            <h4>Ajouter une méta-tribu</h4>
                            <input className="input" size={50} placeholder="Nom de la méta-tribu" onChange={updateNewMetaTribeLabel} value={newMetaTribeLabel}/>
                            <button className="btn btn-1" onClick={createMetaTribe} disabled={newMetaTribeLabel === ""}>Créer</button>
                        </div>
                        <div className="margin-top">
                                <h2>Liste des méta-tribus</h2>
                                {
                                    metaTribes.map((metaTribe: MetaTribe) => {
                                        return <div className="margin-bottom" key={metaTribe.metaTribeId}>
                                            <div className="uk-grid" data-uk-grid="">
                                                <div style={{width: "6em"}}>
                                                <button
                                                        onClick={() => {
                                                            setOpenedMetaTribes((prev) => {
                                                                if (prev.includes(metaTribe.metaTribeId)) {
                                                                    return prev.filter((id) => id !== metaTribe.metaTribeId)
                                                                } else {
                                                                    return [...prev, metaTribe.metaTribeId]
                                                                }
                                                            })
                                                        }}
                                                        className={`collapsible-tribe-head clickable ${isOpenedMetaTribe(metaTribe.metaTribeId) ? "opened" : ""}`}>
                                                </button>
                                                    ID: {metaTribe.metaTribeId}
                                                </div>
                                                <div className="uk-width-expand">
                                                    <input className="input block" value={metaTribe.label} onChange={(event) => updateMetaTribeLabel(event.target.value, metaTribe.metaTribeId)}/>
                                                </div>
                                                <div>
                                                    <button className="btn btn-1" onClick={() => updateMetaTribe(metaTribe)} disabled={metaTribe.label === ""}>Sauvegarder</button>
                                                    <button className="btn btn-1" onClick={() => openDeleteMetaTribeModal(metaTribe)}>Supprimer</button>
                                                </div>
                                            </div>
                                            { isOpenedMetaTribe(metaTribe.metaTribeId) &&
                                                <div>
                                                    <h4>Tribus</h4>
                                                    <NewTribeForm
                                                        metaTribe={metaTribe}
                                                        galleries={galleries}
                                                        tagCategories={tagCategories}
                                                        onSubmit={(label, criteria, minCriteria)=>createTribe(metaTribe.metaTribeId, label, criteria, minCriteria)}
                                                    />
                                                    {
                                                        metaTribe.Tribes.map((tribe: Tribe) => {
                                                            return <div className="margin-bottom" key={tribe.tribeId} data-uk-grid="">
                                                                <div className="uk-grid uk-grid-small margin-bottom">
                                                                    <div style={{width: "4em"}}/>
                                                                    <div style={{width: "5em"}}>
                                                                    <button
                                                                            onClick={()=> openTribe(tribe)}
                                                                            className={`collapsible-tribe-head clickable ${getOpenedTribe(tribe.tribeId) ? "opened" : ""}`}>
                                                                    </button>
                                                                        ID: {tribe.tribeId}
                                                                    </div>
                                                                    <div className="flex align-center">
                                                                        <label className="padding-h">Nom de la tribu :</label>
                                                                        <input className="input block" value={tribe.label} onChange={(event) => updateTribeLabel(event.target.value, tribe)}/>
                                                                    </div>
                                                                    <div className="flex align-center">
                                                                        <label className="padding-h">Nombre de critères minimum :</label>
                                                                        <input
                                                                            type="number"
                                                                            className="input"
                                                                            onChange={(e)=>updateTribeMinCriteria(e, tribe)}
                                                                            value={tribe.minCriteria}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <button className="btn btn-1" onClick={() => openDeleteTribeModal(tribe)}>Supprimer</button>
                                                                    </div>
                                                                </div>
                                                                {isOpenedTribe(tribe.tribeId) &&
                                                                <div className="flex-colomn align-center">
                                                                    <div className="flex-colomn flex-center block-l">
                                                                        {
                                                                            getOpenedTribe(tribe.tribeId).criteriaArray.map((criteria, index) =>{
                                                                                if (criteria.criteriaId !== undefined) {
                                                                                    return <div key={criteria.criteriaId} className="block-m flex space-between margin-bottom">
                                                                                        <span>Critère {index + 1}:</span>
                                                                                        <div>
                                                                                            {criteria.collectionId !== null && <span className="margin-right">Collection: {
                                                                                                galleries.data.find(gallery =>
                                                                                                    gallery.Collections.find(collection => collection.collectionId === criteria.collectionId)
                                                                                                )?.Collections.find(collection => collection.collectionId === criteria.collectionId)?.label
                                                                                            }</span>}
                                                                                            {criteria.tagId !== null && <span>Tag: {
                                                                                                tagCategories.data.find(tagCategory =>
                                                                                                    tagCategory.Tags.find(tag => tag.tagId === criteria.tagId)
                                                                                                )?.Tags.find(tag => tag.tagId === criteria.tagId)?.label
                                                                                            }</span>}
                                                                                        </div>
                                                                                        {getOpenedTribe(tribe.tribeId).criteriaArray.length > 1 &&
                                                                                            <button
                                                                                                className="btn btn-borderless white"
                                                                                                onClick={()=> setOpenedTribes((prev) =>
                                                                                                    prev.map((t) => {
                                                                                                        if (t.tribeId === tribe.tribeId) {
                                                                                                            return {
                                                                                                                ...t,
                                                                                                                criteriaArray: t.criteriaArray.filter((c, i) =>
                                                                                                                    i !== index,
                                                                                                                )}
                                                                                                        } else {
                                                                                                            return t;
                                                                                                        }
                                                                                                    })
                                                                                                )
                                                                                            }>X</button>
                                                                                        }
                                                                                    </div>
                                                                                } else {
                                                                                    return <Criteria
                                                                                        key={index}
                                                                                        galleries={galleries}
                                                                                        criteria={criteria}
                                                                                        index={index}
                                                                                        tribeId={tribe.tribeId}
                                                                                        nbCriteria={getOpenedTribe(tribe.tribeId).criteriaArray.length}
                                                                                        updateCriteriaCollection={updateCriteriaCollection}
                                                                                        updateCriteriaTag={updateCriteriaTag}
                                                                                        tagCategories={tagCategories}
                                                                                        removeCriteria={(criteriaIndex) =>{
                                                                                            setOpenedTribes((prev) =>
                                                                                                prev.map((t) => {
                                                                                                    if (t.tribeId === tribe.tribeId) {
                                                                                                        return {
                                                                                                            ...t,
                                                                                                            criteriaArray: t.criteriaArray.filter((c, i) =>
                                                                                                                i !== criteriaIndex,
                                                                                                            )}
                                                                                                    } else {
                                                                                                        return t;
                                                                                                    }
                                                                                                })
                                                                                            )
                                                                                        }}
                                                                                        disabled={criteria.criteriaId !== undefined}
                                                                                    />
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                    <div className="flex align-center space-between">
                                                                        <button className="btn btn-1" onClick={
                                                                                () => setOpenedTribes((prev) =>
                                                                                    prev.map((t) => {
                                                                                        if (t.tribeId === tribe.tribeId) {
                                                                                            return {
                                                                                                ...t,
                                                                                                criteriaArray: [...t.criteriaArray, {collectionId: null, tagId: null}],
                                                                                            }
                                                                                        } else {
                                                                                            return t;
                                                                                        }
                                                                                    })
                                                                                )
                                                                            }>
                                                                                Ajouter un critère
                                                                        </button>
                                                                        <button className="btn btn-1"
                                                                            onClick={() => updateTribe(tribe)}
                                                                            disabled={
                                                                                tribe.label === "" || (tribe.minCriteria < 1)
                                                                            }
                                                                        >Sauvegarder</button>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                            <hr/>
                                        </div>
                                    })
                                }
                            </div>

                            {
                                metaTribeToDelete !== null && <ConfirmModal onCancel={closeDeleteMetaTribeModal} onConfirm={() => deleteMetaTribe(metaTribeToDelete!)}>
                                    Voulez-vous vraiment supprimer la méta-tribu "{metaTribeToDelete.label}" ?
                                </ConfirmModal>
                            }
                            {
                                tribeToDelete !== null && <ConfirmModal onCancel={closeDeleteTribeModal} onConfirm={() => deleteTribe(tribeToDelete!)}>
                                    Voulez-vous vraiment supprimer la tribu "{tribeToDelete.label}" ?
                                </ConfirmModal>
                            }

                            {
                                openTribeModal && <Modal closeButton onClose={() => setOpenTribeModal((prev)=> !prev)}>
                                    <div className="pop-up-title centered">
                                        Liste des tribus existantes
                                    </div>
                                    <div>
                                        {
                                            metaTribes.map(metaTribe => {
                                                return <div key={metaTribe.metaTribeId}>
                                                    <span className='semibold'>{metaTribe.label.toUpperCase()}</span>
                                                    <div style={{margin: '5px 0 5px 0'}}>
                                                        {
                                                            metaTribe.Tribes.map(tribe => <div key={tribe.tribeId} className="flex align-center">
                                                                <span className="collapsible-tribe-head"/>
                                                                <span style={{paddingLeft: '25px'}}>
                                                                    {tribe.label} | {tribe.Criteria.length} critère(s)
                                                                </span>
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </Modal>
                            }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Tribes;
