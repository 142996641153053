import React, { useMemo } from "react";
import { Tribe } from "../../api/types";
import UserCard from "./UserCard";
import { Link } from "../../api/injectors";
import { useTribe } from "./tribeProvider";
import MemberPicto from "pictos/picto-friends.svg";

const UserTribe = ({
    tribe,
    userId,
    connectedUserScore,
    handleClick,
}: {
    tribe: Tribe,
    userId: number,
    connectedUserScore: number,
    handleClick: (tribeId: number) => void,
    [x:string]:any
}) => {

    const { openTribeId } = useTribe();

    const tribeMembers = useMemo(()=> {
        return tribe.Users.filter((user)=>user.userId !== userId)
    }, [tribe.Users, userId])

    const userAffinities = useMemo(()=>{
        const affinityMap = new Map<number, number>()
        tribeMembers.forEach(user => {
            const sumCommonData = user.totalCommonCollection! + user.totalCommonTag! + user.totalCommonThumbnail!;
            const scoreAffinityConnectedUser = sumCommonData / connectedUserScore;
            const scoreAffinityUser = sumCommonData / user.totalUser!;
            affinityMap.set(user.userId!, parseFloat((((scoreAffinityConnectedUser + scoreAffinityUser) / 2) * 100).toFixed(0)));
        })
        return affinityMap;
    }, [connectedUserScore, tribeMembers]);

    const sortedTribeMembers = useMemo(() => {
        let membersArray = [...tribeMembers];
        membersArray.sort((a, b) => {
            return userAffinities.get(b.userId!)! - userAffinities.get(a.userId!)!
        })
        return membersArray;
    }, [tribeMembers, userAffinities])

    return (
        <div className="tribe-card">
            <div className={`tribe-card-head ${openTribeId === tribe.tribeId ? "active" : ""}`} onClick={()=>handleClick(tribe.tribeId)}>
                <span className="tribe-name">{tribe.label}</span>
                <div className="flex-center">
                    <span className="tribe-nb-members">{
                        tribe.Users.length > 10 ** 6 ? tribe.Users.length / 10 ** 6 + "M" :
                        tribe.Users.length > 10 ** 3 ? tribe.Users.length / 10 ** 3 + "K" :
                        tribe.Users.length
                    }
                    </span>
                    <img className="member-picto" src={MemberPicto} alt="members icon"/>
                </div>
            </div>
            {openTribeId === tribe.tribeId && (
                <div className="tribe-card-body">
                    <p className="block text-dark margin-top">{sortedTribeMembers.length > 0 ? "Membres (par affinité)" : "Il n’y a pas encore de membres dans cette tribu."}</p>
                    <div className={`tribe-card-member-list uk-grid uk-grid-medium ${tribeMembers.length > 8 ? "scroll" : ""}`}>
                        {sortedTribeMembers.map(
                            (user, i) => (
                                <Link key={i} to="connected.paanteon.user.galleries.condensed" params={{userId: user.userId, username: user.username}} className="user-name">
                                    <UserCard user={user}/>
                                </Link>
                            )
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserTribe;
