import React from 'react';
import {Recommendation, Thumbnail, ThumbnailDetails, Tribute, UserInfos, Tribe} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import pictoMagnifyPlus from "../assets/img/pictos/picto-magnify-plus.svg";
import {pictoHeart, pictoHeartBlue, pictoLink, pictoPaanteonLightBlue, pictoWikipedia} from "../api/pictos";
import {injectUIRouter, Link} from "../api/injectors";
import pictoPaanteon from "../assets/img/pictos/picto-paanteon.svg";
import pictoEngraveDark from "../assets/img/pictos/picto-engrave-dark.svg";
import pictoEngraveWhite from "../assets/img/pictos/picto-engrave-white.svg";
import pictoSpeechBubbleBlue from "../assets/img/pictos/picto-speech-bubble-blue.svg";
import pictoSharingBlue from "../assets/img/pictos/picto-sharing-blue.svg";
import Page from "./Page";
import {UIRouterReact} from "@uirouter/react";
import {getCategoryFromId} from "../components/Recommendations";
import Toggleable from "../components/Toggleable";
import {stopPropagation} from "../api/helpers";
import moment from "moment";
import shave from "shave";
import Helmet from "../components/Helmet";

type Props = {
    thumbnail: { data: Thumbnail }
    thumbnailDetails: { data: ThumbnailDetails }
    selfUser: {data: UserInfos}
    router: UIRouterReact
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = {
    showLightbox: boolean
    tributes: Tribute[]
    selectedOrder: string | null
    filterFriends: boolean
}

const sortTributes = function(a: Tribute, b: Tribute): number {
    if (b.title && b.title.length && b.content && b.content.length && (!a.title || !a.title.length || !a.content || !a.content.length)) return 1;
    if (a.title && a.title.length && a.content && a.content.length && (!b.title || !b.title.length || !b.content || !b.content.length)) return -1;
    if (b.title && b.title.length && (!a.title || !a.title.length)) return 1;
    if (a.title && a.title.length && (!b.title || !b.title.length)) return -1;
    if (b.content && b.content.length && (!a.content || !a.content.length)) return 1;
    if (a.content && a.content.length && (!b.content || !b.content.length)) return -1;
    return 0;
}

class ThumbnailTributes extends Page<Props, State> {
    private hasTribute: boolean = false;
    state = {
        showLightbox: false,
        tributes: this.props.thumbnailDetails.data.tributes,
        selectedOrder: 'inspirational',
        filterFriends: false,
    };

    constructor(props: Props) {
        super(props);

        this.hasTribute = !!props.thumbnailDetails.data.tributes.find(t => t.userId === props.selfUser.data.userId);
    }

    showLightbox = () => {
        this.setState(prevState => ({...prevState, showLightbox: !prevState.showLightbox}));
    };

    goToTribute = (tributeId: number, thumbnailUniqueName: string, username: string) => {
        this.props.router.stateService.go('connected.paanteon.tribute.details', {tributeId, thumbnailUniqueName, username});
    }

    setSelectedOrder = (order: string | null) => {
        this.setState(prevState => ({
            ...prevState,
            selectedOrder: order,
        }));
    }

    toggleFilterFriends = () => {
        this.setState(prevState => ({
            ...prevState,
            filterFriends: !prevState.filterFriends,
        }));
    }

    clampCards(): void {
        shave(".feed-item .feed-body", 60);
    }

    componentDidMount() {
        super.componentDidMount();
        this.clampCards();
    }

    render() {
        let tributes = [...this.state.tributes];

        if (this.state.filterFriends) {
            const friends = this.props.selfUser.data.Friends.map(f => f.friendId).concat(this.props.selfUser.data.FriendsOf.map(f => f.userId));
            tributes = tributes.filter(t => friends.indexOf(t.userId as number) >= 0);
        }

        if (this.state.selectedOrder) {
            if (this.state.selectedOrder === 'youngest') tributes.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
            if (this.state.selectedOrder === 'inspirational') tributes.sort((a, b) => b.likeCount - a.likeCount);
        } else {
            tributes.sort(sortTributes);
        }

        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <UserSidebar user={this.props.selfUser.data} showCover={true} >
                        <div className="sidebar-block dark-blue">
                            <p className="block-title">
                                Les recommandations à découvrir
                                <Link to={"connected.paanteon.thumbnail.recommendations"}>&gt; Toutes les recommandations ({this.props.thumbnailDetails.data.recommendations.length})</Link>
                            </p>

                            {/* <div className="content-card">
                                <div className="thumbnail">
                                    <img src="https://upload.wikimedia.org/wikipedia/en/0/05/Invictus-poster.png" alt="invictus"/>
                                </div>
                                <h3 className="card-title">Invictus</h3>
                                <p className="category">FILM</p>
                                <p className="author">De Clint Eastwood</p>
                                <p className="body-m">Recommandé par <a href="">68K</a></p>
                                <div className="card-footer">

                                    <a href="">105,8 K</a>
                                    <img src={pictoEngraveDark}
                                        className="picto"
                                        alt="graver"
                                        title="graver"/>
                                </div>
                            </div>*/}

                            {
                                this.props.thumbnailDetails.data.recommendations.slice(0, 3).map((reco: Recommendation) => (
                                    <div className="content-card" key={reco.recommendationId}>
                                        <div className="card-body">
                                            <div className="thumbnail no-fixed-height">
                                                <img src={reco.picturePath} alt={reco.title}/>
                                            </div>
                                            <div className="card-title">
                                                {reco.title}
                                            </div>
                                            <p className="category">{getCategoryFromId(reco.type)}</p>
                                            <p className="author">De {reco.author}</p>
                                        </div>
                                        <div className="card-footer">
                                            <p className="body-m">Recommandé par {reco.recommendCount}</p>
                                            {
                                                reco.engraveCount === 0 ? <span className="link">
                                                        {reco.engraveCount}
                                                        <img src={pictoPaanteonLightBlue}
                                                             className="picto"
                                                             alt="gravé (nb)"
                                                             title="gravé (nb)"/>
                                                    </span>
                                                    :
                                                    <Link to="connected.paanteon.thumbnail.tributes"
                                                          params={{thumbnailId: reco.recommendationThumbnailId, thumbnailUniqueName: this.props.thumbnail.data.uniqueName}} className="link">
                                                        {reco.engraveCount}
                                                        <img src={pictoPaanteonLightBlue}
                                                             className="picto"
                                                             alt="gravé (nb)"
                                                             title="gravé (nb)"/>
                                                    </Link>
                                            }
                                            <Link className="action" to="connected.paanteon.user.new-tribute" params={{name: reco.title, username: this.props.selfUser.data.username}}>
                                                <img src={pictoEngraveDark}
                                                    className="picto action-picto"
                                                    alt="graver"
                                                    title="graver"/>
                                            </Link>
                                        </div>
                                    </div>
                                ))
                            }
                            <Link className="btn btn-outlined light btn-block" to={"connected.paanteon.thumbnail.recommendations"}>Voir tout ({this.props.thumbnailDetails.data.recommendations.length})</Link>
                        </div>
                    </UserSidebar>

                    <div className="main-content">
                        <div className="uk-container">
                            <div className="margin-large">

                                <Helmet title={`Tous les hommages à ${this.props.thumbnail.data.label} | Paanteon`} />
                                <h2 className="title-1 no-margin-bottom">
                                    <span className="pre-title">Tous les hommages à</span>
                                    {this.props.thumbnail.data.label}
                                </h2>
                            </div>

                            <div>
                                {
                                    this.props.thumbnailDetails.data.tributes.length &&
                                    <div className="banner-img-2">
                                        <span className="img-container">
                                            <img className="picto" src={pictoMagnifyPlus} alt="magnify picto" onClick={this.showLightbox}/>
                                            <img className="main-img" alt="tribute" src={this.props.thumbnailDetails.data.tributes[0].picturePath}/>
                                        </span>
                                    </div>
                                }
                                {/* temporary mechanism*/}
                                {
                                    this.state.showLightbox ?
                                        <div className="lightbox" ng-show="lightboxImg" onClick={this.showLightbox}>
                                            <span className="close">X</span>
                                            <img className="main-img" alt="tribute" src={this.props.thumbnailDetails.data.tributes[0].picturePath}/>
                                        </div>
                                        : null
                                }
                            </div>

                            <div className="tribute-quick-actions margin-large-bottom">
                                    <span>
                                        {this.props.thumbnailDetails.data.tributes.length} <img src={pictoPaanteon}
                                                                                         className="picto"
                                                                                         alt="picto paanteon"/>
                                    </span>
                                {
                                    !this.hasTribute &&
                                    <span>
                                        <Link to="connected.paanteon.user.new-tribute"
                                              params={{thumbnailId: this.props.thumbnail.data.thumbnailId, thumbnailUniqueName: this.props.thumbnail.data.uniqueName, username: this.props.selfUser.data.username}}>
                                            <img className="picto" src={pictoEngraveWhite}
                                                 alt="picto engrave white"/>
                                        </Link>
                                    </span>
                                }
                            </div>

                            {
                                (this.props.thumbnail.data.thumbnailUserId || this.props.thumbnail.data.InMemoriam || this.props.thumbnail.data.wikipedia || this.props.thumbnail.data.website) && <div className="tile margin-large tribute-writing-bloc">
                                    <div className="tile-head">
                                        <div className="uk-grid uk-grid-small">
                                            <div className="uk-width-expand">
                                                {
                                                    this.props.thumbnail.data.thumbnailUserId && <><Link to="connected.paanteon.user" params={{userId: this.props.thumbnail.data.thumbnailUserId, username: this.props.thumbnail.data.thumbnailUsername}}>&gt; Voir son paanteon</Link><br/></>
                                                }
                                                {
                                                    this.props.thumbnail.data.InMemoriam && <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: this.props.thumbnail.data.uniqueName, memoriamId: this.props.thumbnail.data.InMemoriam.inMemoriamId}}>&gt; Voir le livre d'or</Link>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    this.props.thumbnail.data.wikipedia && <><a href={this.props.thumbnail.data.wikipedia} target="_blank" rel="noopener noreferrer"><img src={pictoWikipedia} className="picto" alt="picto wikipedia"/> Wikipédia</a><br/></>
                                                }
                                                {
                                                    this.props.thumbnail.data.website && <a href={this.props.thumbnail.data.website} target="_blank" rel="noopener noreferrer"><img src={pictoLink} className="picto" alt="picto website"/> Site officiel</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="link-tabs" style={{position: 'relative'}}>
                                <Toggleable>
                                    <div key={0} className="tab-item active toggle-popin-menu">
                                        <span className="toggle-button active margin-right">Filtrer par</span>

                                        {
                                            this.state.selectedOrder &&
                                            <span className="active-filter-chip" style={{position: 'relative'}}
                                                  onClick={stopPropagation}>
                                                  {
                                                      this.state.selectedOrder === 'youngest' ?
                                                          'Les plus récents'
                                                          :
                                                          'Les plus inspirants'
                                                  }

                                                <button className="btn btn-icon btn-icon-close"
                                                        onClick={() => this.setSelectedOrder(null)}/>
                                            </span>
                                        }

                                        {
                                            this.state.filterFriends &&
                                            <span className="active-filter-chip" style={{position: 'relative'}}
                                                  onClick={stopPropagation}>
                                                  Mes amis

                                                <button className="btn btn-icon btn-icon-close"
                                                        onClick={this.toggleFilterFriends}/>
                                            </span>
                                        }

                                        <div className="popin-menu block" onClick={stopPropagation}>
                                            <div className="buttons-bar">
                                                <button className={"btn btn-block light" + (this.state.selectedOrder === 'inspirational' ? ' btn-1' : ' btn-outlined')} onClick={() => this.setSelectedOrder('inspirational')}>Les plus inspirants</button>
                                                <button className={"btn btn-block light" + (this.state.selectedOrder === 'youngest' ? ' btn-1' : ' btn-outlined')} onClick={() => this.setSelectedOrder('youngest')}>Les plus récents</button>
                                                <button className={"btn btn-block light" + (this.state.filterFriends ? ' btn-1' : ' btn-outlined')} onClick={this.toggleFilterFriends}>Mes amis</button>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment key={1}/>
                                </Toggleable>

                            </div>

                            <div className="margin-top">
                                <div className="feed-list">
                                    {
                                        tributes.map(tribute => (
                                            <div className="feed-item pointer" key={tribute.tributeId} onClick={() => this.goToTribute(tribute.tributeId, this.props.thumbnail.data.uniqueName, tribute.User.username as string)}>
                                                <div className="profile-pic" style={{backgroundImage: 'url(' + tribute.User.picturePath + ')'}}/>
                                                <div>
                                                    <p>
                                                        <em className="ptn-em"><Link to="connected.paanteon.user.galleries.condensed"
                                                                    params={{userId: tribute.User.userId, username: tribute.User.username}}>{tribute.User.firstname} {tribute.User.lastname}</Link></em>
                                                        {/* <small>&nbsp;-&nbsp;{moment(tribute.createdAt).format('LL')}</small>*/}
                                                    </p>
                                                    <h3 className="item-title">{tribute.title}</h3>
                                                    <div className="feed-body" dangerouslySetInnerHTML={{__html: tribute.content}}/>
                                                    <div className="feed-footer">
                                                        <span style={{color: "#37a8db"}}>
                                                            {
                                                                tribute.liked ?
                                                                    <img src={pictoHeart} className="picto blue" alt="picto heart"/>
                                                                    :
                                                                    <img src={pictoHeartBlue} className="picto blue" alt="picto heart"/>
                                                            }
                                                            {tribute.likeCount}
                                                        </span>
                                                        <span style={{color: "#37a8db"}}>
                                                            <img src={pictoSpeechBubbleBlue}
                                                                    className="picto blue"
                                                                    alt="picto-speech-bubble-blue"/>
                                                            {tribute.commentCount}
                                                        </span>
                                                        <span style={{color: "#37a8db"}}>
                                                            <img src={pictoSharingBlue}
                                                                    className="picto blue"
                                                                    alt="picto-sharing-blue"/>
                                                            {tribute.shareCount}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectUIRouter(ThumbnailTributes);
