/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import 'slick-carousel';
import pictoEngraveWhite from '../../assets/img/pictos/picto-engrave-white.svg';
import imagesAPI from "../../api/images";
import {Featured} from "../../api/types";
import {Link} from "../../api/injectors";
import {pictoPaanteonLightBlue} from "../../api/pictos";

const slickSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
};

class FeaturedCarousel extends React.Component<{ 'simple-caption'?: boolean }, { slides: Featured[] }> {
    constructor(props: { 'simple-caption'?: boolean }) {
        super(props);
        this.state = {
            slides: [],
        }
    }

    componentDidMount() {
        imagesAPI.getFeatured().then(res => {
            this.setState({
                slides: res.data,
            });
            $(ReactDOM.findDOMNode(this) as HTMLDivElement).slick(slickSettings);
        }, err => {
            console.log(err);
        });
    }

    render() {
        return (
            <div className="carousel featured-carousel">
                {
                    this.state.slides.map(slide => {
                        return slide.picture && slide.Thumbnail && (
                            <div className="slide" style={{backgroundImage: 'url(' + slide.picture + ')'}}
                                 key={slide.featuredId}>
                                <div className="caption">
                                    <div className="caption-title">{slide.desc}</div>
                                    <div className="caption-content cap-first-letter">{slide.Thumbnail.label}</div>
                                    {
                                        !this.props['simple-caption'] ?
                                            <div className="caption-footer">
                                                {
                                                    slide.count && slide.count > 0 ?
                                                        <Link className="chip ghost-link" to="connected.paanteon.thumbnail.tributes" params={{thumbnailId: slide.thumbnailId, thumbnailUniqueName: slide.Thumbnail.uniqueName}}>
                                                            {slide.count}
                                                            <img className="picto" alt="picto-paanteon" src={pictoPaanteonLightBlue}/>
                                                        </Link>
                                                        :
                                                        <span className="chip">
                                                            {slide.count}
                                                            <img className="picto" alt="picto-paanteon" src={pictoPaanteonLightBlue}/>
                                                        </span>
                                                }
                                                <Link
                                                    to={"connected.paanteon.user.new-tribute"}
                                                    params={{thumbnailId: slide.Thumbnail.thumbnailId, collectionId: slide.collectionId}}
                                                ><img
                                                    className="picto" alt="picto-engrave-white"
                                                    src={pictoEngraveWhite}/></Link>
                                            </div>
                                            : null
                                    }
                                </div>
                                { slide.Thumbnail.copyright && <div className="img-copyright">
                                    { slide.Thumbnail.copyright }
                                </div> }
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default FeaturedCarousel;
