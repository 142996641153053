import React, {Component} from 'react';
import {UserInfos, UserNotificationSettings, UserNotificationSettingValue} from "../../api/types";
import Toggle from "../../components/Toggle";
import {
    pictoHeart,
    pictoSharingFullBlue,
    pictoSpeechBubbleFilledBlue,
} from "../../api/pictos";
import userRequests from "../../api/userRequests";
import Notification from "../../api/notification";
import {UIRouterReact} from "@uirouter/react";
import {injectUIRouter} from "../../api/injectors";
import Helmet from "../../components/Helmet";

type Props = {
    user: UserInfos
    router: UIRouterReact
};

type State = {
    notificationSettings: UserNotificationSettings
}

const settings: {
    name: string,
    picto?: string,
    variableName: string,
}[] = [{
    name: 'Love',
    picto: pictoHeart,
    variableName: 'love',
}, {
    name: 'Commentaire',
    picto: pictoSpeechBubbleFilledBlue,
    variableName: 'comment',
}, {
    name: 'Partage',
    picto: pictoSharingFullBlue,
    variableName: 'share',
}, {
    name: "Demande d'ami acceptée",
    variableName: 'friendship',
}, {
    name: "Mention dans une publication",
    variableName: 'mention',
}, {
    name: "Être gravé dans un hommage",
    variableName: 'tribute',
}, {
    name: "Nouvel abonné",
    variableName: 'follower',
}];

class NotificationSettings extends Component<Props, State> {
    readonly state: State = {
        notificationSettings: this.props.user.NotificationSettings,
    };

    handleNotificationChange = (variableName: string, value: number) => {
        this.setState(prevState => ({
            ...prevState,
            notificationSettings: {
                ...prevState.notificationSettings,
                [variableName]: (prevState.notificationSettings[variableName as keyof UserNotificationSettings] & value) > 0 ? prevState.notificationSettings[variableName as keyof UserNotificationSettings] ^ value : prevState.notificationSettings[variableName as keyof UserNotificationSettings] | value,
            },
        }));
    }

    save = () => {
        userRequests.updateNotificationSettings(this.props.user.userId, this.state.notificationSettings).then(() => {
            this.props.router.stateService.reload('connected.parameters');
            Notification.success("Paramètres de notification mis à jour avec succès");
        }, () => {
            Notification.error("Erreur lors de la mise à jour des paramètres de notification");
        })
    }

    render() {
        return (
            <>
                <Helmet title={'Paramètres - Notifications | Paanteon'} />
                <div className="tile parameters-tile tile-padding-xl">
                    <div className="tile-body">
                        <h2 className="tile-title">Comment souhaitez-vous être alerté(e) ?</h2>

                        <div>
                            {
                                settings.map(setting => <div key={setting.variableName} className="parameter-list-item">
                                    <div className="parameter-name tile-title">
                                        {!!setting.picto && <img src={setting.picto} className="picto picto-notif" alt=""/>} {setting.name}
                                    </div>
                                    <div className="uk-grid">
                                        <div className="uk-width-1-2">
                                            <label className="black">Dans votre page Notifications</label>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <Toggle checked={(this.state.notificationSettings[setting.variableName as keyof UserNotificationSettings] & 1) > 0} onChange={(ev) => this.handleNotificationChange(setting.variableName, UserNotificationSettingValue.NOTIFICATION_PAGE)}/>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <label className="black">Par e-mail</label>
                                        </div>
                                        <div className="uk-width-1-2">
                                            <Toggle checked={(this.state.notificationSettings[setting.variableName as keyof UserNotificationSettings] & 2) > 0} onChange={() => this.handleNotificationChange(setting.variableName, UserNotificationSettingValue.EMAIL)}/>
                                        </div>
                                    </div>
                                </div>)
                            }
                        </div>
                        <div className="right margin">
                            <button className="btn btn-1" onClick={this.save}>SAUVEGARDER</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default injectUIRouter(NotificationSettings);
