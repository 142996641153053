import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Thumbnail, Tribute, TributeToSend } from "../../api/types";
import Notification from "../../api/notification";
import paanteon from "../../api/paanteonRequests";
import { Collection } from "../../api/types";
import Autosuggest from "react-autosuggest";
import { acceptableImages } from "../../api/domHelper";
import { pictoCamera } from "../../api/pictos";
import { Gallery } from "../../api/types";
import { StateObject, Transition } from "@uirouter/react";
import { hasOneYearAccount } from "../../api/user";

const getThumbnailSuggestionValue = (thumbnail: Thumbnail) => thumbnail.label;
// stateChangeStart

const renderThumbnailSuggestion = (suggestion: Thumbnail) => <div>{suggestion.label}</div>

const shouldRenderThumbnailSuggestions = (value: string) => {
    if (value.trim)
    return value.trim().length > 2;
    else return true;
}

const Step2 = ({...props})=>{
	const [searchedTributes, setSearchedTributes] = useState<(Thumbnail & {Tribute: Tribute})[]>([])
	const [modified] = useState(false)
	const [openConfirmModal, setOpenConfirmModal] = useState(false)
	const [saved, setSaved] = useState(false)
	const [tribute, setTribute] = useState<TributeToSend>({
		thumbnail:  props.stateParams.name ? decodeURIComponent(props.stateParams.name) : "",
		yearly: false,
		content: "",
		title: "",
	})
	const [picUploadLabel, setPicUploadLabel] = useState("Téléversez une photo depuis votre ordinateur.\nOu glissez-déposez ici.")
	const selectedCollection = Number(props.stateParams.collectionId);

	const selectedGallery = useMemo(():Gallery=>{
		return props.galleries.find((gallery:Gallery)=>
			gallery.Collections.find((collection:Collection)=>
				collection.collectionId === selectedCollection
			)?.galleryId === gallery.galleryId
		)
	}, [props, selectedCollection])

	const selectedCollectionLabel = useMemo(()=>{
			const collection = selectedGallery.Collections.find(
				(collection:Collection)=>collection.collectionId === selectedCollection
				)
			return collection ? collection.label : ""
		}, [selectedCollection, selectedGallery])

	const saveTribute = (draft?: boolean) => {
		// handle yearly virtual gallery
		if (!tribute.picturePath) {
				Notification.warning("Veuillez d'abord téléverser une image");
				return;
		}
		if (!tribute.thumbnail) {
				Notification.warning('Formulaire incomplet! Veuillez renseigner le nom et une photo.');
				return;
		}
		if (draft && tribute.draft !== 0) { // no draft mode if tribute is already published
				setTribute(prevState => {
						let newSate = {...prevState};
						newSate = {
								...prevState,
								draft: 0, // was 1 previously, remove draft mode
						};
						return newSate;
				});
		} else {
				setTribute(prevState => {
					let newSate = {...prevState};
					newSate = {
							...prevState,
							draft: 0, // was 1 previously, remove draft mode
					};
					return newSate;
				});
		}
		let tributeToSend = {...tribute};
		let request = paanteon.createTribute(selectedCollection, tributeToSend);
		request.then((result) => {
				Notification.success('Hommage enregistré');
				setSaved(true);
				if (result.data.tributeId) {
					hasOneYearAccount(props.user) && !props.user.certificationDate ? props.router.stateService.go('connected.certification') :
					props.router.stateService.go('connected.paanteon.tribute.details',
					{tributeId: result.data.tributeId,
						thumbnailUniqueName: result.data.Thumbnail.uniqueName,
						username: props.user.username,
					}, {reload: true})
				}
		}, function(error) {
				if (error.response && error.response.status === 400) {
						Notification.warning('Formulaire incomplet! Veuillez renseigner le nom et une photo.');
				} else if (error.response && error.response.status === 412) {
						Notification.warning('Vous disposez du maximum de 12 vignettes dans votre collection!');
				} else if (error.response && error.response.status === 409) {
						Notification.error(`${tributeToSend.thumbnail} est déjà gravé dans votre Paanteon`);
				} else {
						Notification.error('Erreur lors de la sauvegarde de l\'hommage');
				}
		});
};

	const searchTributes = (payload: {value: string, reason: "input-changed" | "input-focused" | "escape-pressed" | "suggestions-revealed" | "suggestion-selected"}) => {
		paanteon.searchTributes(payload.value).then((tributesData: { data: (Thumbnail & {Tribute: Tribute})[] }) => {
			setSearchedTributes(tributesData.data)
		}, () => {});
	}

	const setThumbnail = (event: React.FormEvent<HTMLInputElement>, {newValue}: {newValue: string}) => {
		const val = newValue;
		setTribute(prevState => {
				let newSate = {...prevState};
				newSate = {
						...prevState,
						thumbnail: val,
				};
				return newSate;
		});
	};

	const clearThumbnailSuggestions = () => {
		setSearchedTributes([])
	}

	useEffect(()=>{

		const picInput = document.getElementById('tribute-pic') as HTMLInputElement;
		if (picInput) {
				// handling tribute's picture upload
				var reader = new FileReader();
				picInput.addEventListener( 'change', ( e: Event ) => {
						// @ts-ignore
						if (e.currentTarget && (e.currentTarget as HTMLInputElement).files !== null && (e.currentTarget as HTMLInputElement).files.length > 0) {
								// @ts-ignore
								const name = (e.currentTarget as HTMLInputElement).files[0].name;
								setPicUploadLabel(name);
								// @ts-ignore
								reader.readAsDataURL((e.currentTarget as HTMLInputElement).files[0]);
						}
				});
				reader.onloadend = () => {
						setTribute(prevState => {
								let nState = {...prevState};
								nState = {
										...prevState,
										img: reader.result ? reader.result : '',
										picturePath: reader.result ? reader.result : '',
								};
								return nState;
						});
				}

				const uploadBox = document.getElementById('upload-box');
				if (uploadBox) {
						uploadBox.ondragover = uploadBox.ondragenter = evt => {
								evt.stopPropagation();
								evt.preventDefault();
						}

						const dropReader = new FileReader();
						uploadBox.ondrop = event => {
								event.stopPropagation();
								event.preventDefault();
								if (event.dataTransfer) {
										// picInput.files = event.dataTransfer.files;

										// @ts-ignore
										const name = event.dataTransfer.files[0].name;
										if (event.dataTransfer.files[0].type.length && acceptableImages.includes(event.dataTransfer.files[0].type)) {
												setPicUploadLabel(name);
												// @ts-ignore
												reader.readAsDataURL(event.dataTransfer.files[0]);
										} else {
												Notification.error("Format non supporté");
										}
								}
						}
						dropReader.onloadend = () => {
								setTribute(prevState => {
										let nState = {...prevState};
										nState = {
												...prevState,
												img: reader.result ? reader.result : '',
												picturePath: reader.result ? reader.result : '',
										};
										return nState;
								});
						}
				}
		}

		props.router.transitionService.onStart({
				from: (state?: StateObject) => {
						return state?.name === 'connected.tutorial' && modified && !openConfirmModal && !saved;
				},
		}, (transition:Transition) => {
				setOpenConfirmModal(true)
				return false
		})
}, [modified, openConfirmModal, props, saved])


	return (
		<>
			<div className="steps">
				<div className="step done">1</div>
				<div className="sep"></div>
				<div className="step current">2</div>
			</div>
			<h2 className="title-3 h-center text-uppercase margin-large-top flex-colomn flex-center"><FormattedMessage id="tutorial.stepTwo.1"/>
			<FormattedMessage id="tutorial.stepTwo.2"/>
			<FormattedMessage id="tutorial.stepTwo.3"/></h2>
			<div className={"dark-bg step2"}>
				<div className="margin-large-top">
					<Autosuggest
							inputProps={{
									value: tribute.thumbnail,
									onChange: setThumbnail,
									placeholder: "Entrer le nom de votre inspiration",
							}}
							suggestions={searchedTributes}
							getSuggestionValue={getThumbnailSuggestionValue}
							onSuggestionsFetchRequested={searchTributes}
							onSuggestionsClearRequested={clearThumbnailSuggestions}
							renderSuggestion={renderThumbnailSuggestion}
							shouldRenderSuggestions={shouldRenderThumbnailSuggestions}
							theme={{
									container: 'react-autosuggest__container',
									containerOpen: 'react-autosuggest__container--open',
									input: "input big no-border block",
									inputOpen: 'react-autosuggest__input--open',
									inputFocused: 'react-autosuggest__input--focused',
									suggestionsContainer: 'react-autosuggest__suggestions-container',
									suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
									suggestionsList: 'react-autosuggest__suggestions-list',
									suggestion: 'react-autosuggest__suggestion',
									suggestionFirst: 'react-autosuggest__suggestion--first',
									suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
									sectionContainer: 'react-autosuggest__section-container',
									sectionContainerFirst: 'react-autosuggest__section-container--first',
									sectionTitle: 'react-autosuggest__section-title',
							}}
					/>
				<h3 className="collection-title margin h-center">{selectedCollectionLabel}</h3>
				</div>

				<div>
						<div id={"upload-box"} className={"upload-box" + (!!tribute.picturePath ? ' preview' : '')} style={{backgroundImage: 'url(' + tribute.picturePath + ')'}}>
								<input type="file" name="tribute-pic" id="tribute-pic" accept={acceptableImages}/>
								<label htmlFor="tribute-pic">
										<img src={pictoCamera} alt="picto camera"/>
										<br/>
										<span style={{whiteSpace: 'pre-wrap'}}>{tribute.picturePath ? null : picUploadLabel}</span>
								</label>
						</div>

						<div className="margin-large h-center">
								<button onClick={() => saveTribute()} className="btn btn-1">JE GRAVE</button>
						</div>
				</div>
			</div>
		</>
	)
}

export default Step2
