import React from 'react';
import Page from "./Page";
import {Publication, Tribute, User, UserInfos} from "../api/types";
import localStorage from "../api/localStorage";
import Share from "../components/Publication/Share";
import Post from "../components/Publication/Post";
import TributePost from "../components/Publication/TributePost";
import {deleteFromArrayAndReturn} from "../api/helpers";
import pictoCamera from "../assets/img/pictos/picto-camera.svg";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";
import {acceptableImages} from "../api/domHelper";
import {UIView} from "@uirouter/react";
import MentionUser, {MentionableUsersContext} from "../components/MentionUser";
import {Mention, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem} from "react-mentions";
import Helmet from "../components/Helmet";

type Props = {
    feed: Publication[]
    user: UserInfos
}

type State = {
    feed: Publication[]
    publication: { img?: string, content?: string }
    textareaExpanded: boolean
}

class MyPublications extends Page<Props, State> {
    private user: User = localStorage.getLocalUser();
    private reader: FileReader = new FileReader();

    constructor(props: Props) {
        super(props);

        let feed: Publication[] = props.feed
            .filter(publi => publi.userId === this.user.userId)
            .sort(function(a, b) {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            });

        this.state = {
            feed: feed,
            publication: {},
            textareaExpanded: false,
        }

        this.reader.onloadend = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    publication: {
                        ...prevState.publication,
                        img: this.reader.result as string,
                    },
                }
            });
        }
    }

    /**
     * @param from 0 for post, 1 for tribute, 2 for share
     */
    deletePublication = (publicationId: number, from: 0 | 1 | 2) => {
        this.setState(prevState => {
            const nState = {...prevState};
            for (let i = 0; i < nState.feed.length; i++) {
                if (nState.feed[i].publicationId === publicationId) {
                    if (from === 0 || from === 1)
                        nState.feed = deleteFromArrayAndReturn(nState.feed, i);
                    else
                        nState.feed[i] = {...nState.feed[i], Tribute: undefined}
                    break;
                }

                if (nState.feed[i].PublicationRef?.publicationId === publicationId) {
                    nState.feed[i] = {...nState.feed[i], PublicationRef: null, publicationRefId: null};
                    break;
                }
            }

            return nState;
        });
    }

    handlePublicationContentInput: OnChangeHandlerFunc = (e) => {
        const value = e.target.value.substr(0, 500);
        this.setState(state => {
            let nState = {...state};
            if (nState.publication) nState.publication = {
                ...nState.publication,
                content: value,
            };
            return nState;
        });
    };

    publicationPicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) this.reader.readAsDataURL(e.target.files[0]);
    };

    addPublication = () => {
        paanteon.createPublication(this.state.publication).then((result) => {
            this.setState(prevState => {
                let nState = {...prevState};
                nState.feed.unshift(result.data);
                nState.publication = {
                    content: "",
                };
                return nState;
            });
            Notification.success('Publication enregistrée');
        }, function() {
            Notification.error('Erreur lors de la publication');
        });
    };

    focusTextarea = () => {
        this.setState(prevState => ({
            ...prevState,
            textareaExpanded: true,
        }));
    }

    blurTextarea = () => {
        setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                textareaExpanded: false,
            }))
        }, 50);
    }

    render() {
        const f = this.state.feed.map((publication: Publication) => {
            if (publication.publicationRefId) {
                return <Share onDelete={this.deletePublication} key={publication.publicationId} publication={publication} footer={!!publication.content && publication.content.length > 0}><Post onDelete={this.deletePublication} item={publication.PublicationRef as Publication} footer={!publication.content || publication.content.length <= 0}/></Share>
            } else if (publication.type === 0) {
                    return <Post onDelete={this.deletePublication} item={publication} key={publication.publicationId} footer/>
                /* } else if (publication.type === 1 && publication.Tribute) {
                    return <TributePost onDelete={this.deletePublication} key={publication.publicationId} item={publication as Publication & {Tribute: Tribute}} footer/>*/
                } else if (publication.type === 2) {
                    return <Share onDelete={this.deletePublication} publication={publication} key={publication.publicationId} footer={!!publication.content && publication.content.length > 0}>
                        {
                            publication.Tribute ?
                                <TributePost onDelete={this.deletePublication} item={publication as Publication & {Tribute: Tribute}} footer={!publication.content || publication.content.length <= 0}/>
                                :
                                <div className="info minor">Contenu non disponible</div>
                        }
                    </Share>
                } else {
                    return <React.Fragment key={publication.publicationId} />;
                }
        });

        return <UIView><>
            <Helmet title={'Mes publications | Paanteon'} />
            <div className="tile margin-bottom">
                <div className="tile-body">
                    <div className="feed-item write-comment">
                        <div className="profile-pic"
                             style={{backgroundImage: "url(" + this.props.user.picturePath + ")"}}/>
                        <div className="comment-form">

                            <MentionableUsersContext.Consumer>
                                {
                                    (mentionUsers) => <MentionsInput value={this.state.publication.content || ''}
                                                                     onChange={this.handlePublicationContentInput}
                                                                     onFocus={this.focusTextarea}
                                                                     onBlur={this.blurTextarea} maxLength={500}
                                                                     placeholder={"Ce qui m'inspire aujourd'hui..."}
                                                                     className={"textarea expandable textarea-with-mentions" + (this.state.textareaExpanded ? ' expanded' : '')}>
                                        <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                 renderSuggestion={(suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>}/>
                                    </MentionsInput>
                                }
                            </MentionableUsersContext.Consumer>
                            <div className="custom-upload">
                                <input type="file" name="publication-pic" id="publication-pic"
                                       onChange={this.publicationPicChange} accept={acceptableImages}/>
                                <label htmlFor="publication-pic">
                                    <img src={pictoCamera} alt="picto-camera"/>
                                </label>
                            </div>
                            {
                                this.state.publication.img !== undefined && <img src={this.state.publication.img} className="uploaded-img"
                                                                                 alt=""/>
                            }
                        </div>
                    </div>
                    { (this.state.textareaExpanded || (this.state.publication.content && this.state.publication.content.length)) &&
                    <div className="right">
                        <button className="btn btn-1 right" onClick={this.addPublication}
                                disabled={!this.state.publication.content}>JE PUBLIE
                        </button>
                    </div>
                    }
                </div>
            </div>

            {f}
        </></UIView>
    }
}

export default MyPublications;
