import React, {Component} from 'react';
import {User, UserInfos, UserPrivacy} from "../../api/types";
import {PrivacyMessages, PrivacyPaanteon} from "../../api/privacyService";
import auth from "../../api/authentication";
import Notification from "../../api/notification";
import Toggle from "../../components/Toggle";
import {injectUIRouter} from "../../api/injectors";
import {UIRouterReact} from "@uirouter/react";
import Helmet from "../../components/Helmet";

type Props = {
    user: UserInfos
    router: UIRouterReact
};

type State = {
    user: User & UserPrivacy
    openedPrivacyPaanteon: boolean
    openedPrivacyMessages: boolean
}

class Privacy extends Component<Props, State> {
    readonly state: State = {
        user: this.props.user,
        openedPrivacyPaanteon: false,
        openedPrivacyMessages: false,
    };

    togglePrivacyPaanteon = () => {
        this.setState(prevState => ({
            ...prevState,
            openedPrivacyPaanteon: !prevState.openedPrivacyPaanteon,
        }));
    }

    togglePrivacyMessages = () => {
        this.setState(prevState => ({
            ...prevState,
            openedPrivacyMessages: !prevState.openedPrivacyMessages,
        }));
    }

    getPrivacyPaanteonToggle = (e: PrivacyPaanteon) => {
        if (e === PrivacyPaanteon.ONLY_ME || e === PrivacyPaanteon.ALL_MEMBERS || e === PrivacyPaanteon.MY_COMMUNITY) {
            return () => {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        privacyPaanteon: e,
                    },
                }));
            }
        } else {
            return () => {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        privacyPaanteon: prevState.user.privacyPaanteon ^ e,
                    },
                }));
            }
        }
    }

    getPrivacyMessagesToggle = (e: PrivacyMessages) => {
        if (e === PrivacyMessages.ALL_MEMBERS || e === PrivacyMessages.MY_COMMUNITY) {
            return () => {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        privacyMessage: e,
                    },
                }));
            }
        } else {
            return () => {
                this.setState(prevState => ({
                    ...prevState,
                    user: {
                        ...prevState.user,
                        privacyMessage: prevState.user.privacyMessage ^ e,
                    },
                }));
            }
        }
    }

    saveUser = <T extends keyof (User & UserPrivacy)>(p: T) => {
        auth.updateUserById({
            userId: this.state.user.userId,
            [p]: this.state.user[p],
        }).then(() => {
            this.props.router.stateService.reload('connected.parameters');
            Notification.success("Confidentialité mise à jour avec succès");
        }, function() {
            Notification.error("Erreur lors de la mise à jour de votre confidentialité");
        });
    }

    render() {
        return (
            <>
                <Helmet title={'Paramètres - Confidentialité | Paanteon'} />
                <div className="tile tile-padding-xl dark-blue">
                    <div className="tile-body" style={{color: 'white'}}>
                        <p>
                            Vous avez la possibilité de déterminer qui peut voir votre Paanteon
                            et qui peut vous contacter par messagerie.
                        </p>
                    </div>
                </div>
                <div className="tile tile-padding-xl">
                    <div className="tile-body">

                        <div>
                            <button onClick={this.togglePrivacyPaanteon} className={`collapsible-section-head ${this.state.openedPrivacyPaanteon ? "opened" : ""}`}>
                                Qui peut voir mon paanteon
                            </button>
                        </div>

                        {
                            this.state.openedPrivacyPaanteon && <div>
                                <div>
                                    <input type="radio" id="all-members" className="radio-custom" checked={(this.state.user.privacyPaanteon & PrivacyPaanteon.ALL_MEMBERS) > 0} onChange={this.getPrivacyPaanteonToggle(PrivacyPaanteon.ALL_MEMBERS)}/>
                                    <label htmlFor="all-members">
                                        Tous les membres
                                    </label>
                                </div>
                                <div className="margin-top">
                                    <input type="radio" id="my-community" className="radio-custom" checked={(this.state.user.privacyPaanteon & PrivacyPaanteon.MY_COMMUNITY) > 0} onChange={this.getPrivacyPaanteonToggle(PrivacyPaanteon.MY_COMMUNITY)}/>
                                    <label htmlFor="my-community">
                                        Ma communauté
                                    </label>
                                </div>
                                <fieldset className="fieldset padding-left margin-top-s" disabled={(this.state.user.privacyPaanteon & PrivacyPaanteon.MY_COMMUNITY) <= 0}>
                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle checked={(this.state.user.privacyPaanteon & PrivacyPaanteon.MY_FRIENDS) > 0} onChange={this.getPrivacyPaanteonToggle(PrivacyPaanteon.MY_FRIENDS)}/>
                                            Mes amis
                                        </label>
                                    </div>

                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle checked={(this.state.user.privacyPaanteon & PrivacyPaanteon.FRIENDS_FRIENDS) > 0} onChange={this.getPrivacyPaanteonToggle(PrivacyPaanteon.FRIENDS_FRIENDS)}/>
                                            Les amis de mes amis
                                        </label>
                                    </div>

                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle disabled/>
                                            <span>Les membres de mes tribus</span>
                                        </label>
                                    </div>
                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle checked={(this.state.user.privacyPaanteon & PrivacyPaanteon.MY_FOLLOWERS) > 0} onChange={this.getPrivacyPaanteonToggle(PrivacyPaanteon.MY_FOLLOWERS)}/>
                                            Mes abonnés
                                        </label>
                                    </div>
                                </fieldset>
                                <div className="margin">
                                <input type="radio" id="only-me" className="radio-custom" checked={this.state.user.privacyPaanteon === 0} onChange={this.getPrivacyPaanteonToggle(PrivacyPaanteon.ONLY_ME)}/>
                                    <label htmlFor="only-me">
                                        Seulement moi
                                    </label>
                                </div>

                                <div className="right margin">
                                    <button className="btn btn-1" onClick={() => this.saveUser('privacyPaanteon')}>SAUVEGARDER</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="tile tile-padding-xl" style={{marginTop: '1px'}}>
                    <div className="tile-body">

                        <div>
                            <button onClick={this.togglePrivacyMessages} className={`collapsible-section-head ${this.state.openedPrivacyMessages ? "opened" : ""}`}>
                                Qui peut m'envoyer des messages
                            </button>
                        </div>

                        {
                            this.state.openedPrivacyMessages && <div>
                                <div>
                                    <input type="radio" id="msg-all-members" className="radio-custom" checked={(this.state.user.privacyMessage & PrivacyMessages.ALL_MEMBERS) > 0} onChange={this.getPrivacyMessagesToggle(PrivacyMessages.ALL_MEMBERS)}/>
                                    <label htmlFor="msg-all-members">
                                        Tous les membres
                                    </label>
                                </div>
                                <div className="margin">
                                    <input type="radio" id="msg-my-community" className="radio-custom" checked={(this.state.user.privacyMessage & PrivacyMessages.MY_COMMUNITY) > 0} onChange={this.getPrivacyMessagesToggle(PrivacyMessages.MY_COMMUNITY)}/>
                                    <label htmlFor="msg-my-community">
                                        Ma communauté
                                    </label>
                                </div>
                                <fieldset className="fieldset padding-left margin-top-s" disabled={(this.state.user.privacyMessage & PrivacyMessages.MY_COMMUNITY) <= 0}>
                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle disabled checked/>
                                            <span>Mes amis</span>
                                        </label>
                                    </div>

                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle checked={(this.state.user.privacyMessage & PrivacyMessages.FRIENDS_FRIENDS) > 0} onChange={this.getPrivacyMessagesToggle(PrivacyMessages.FRIENDS_FRIENDS)}/>
                                            Les amis de mes amis
                                        </label>
                                    </div>

                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle disabled/>
                                            <span>Les membres de mes tribus</span>
                                        </label>
                                    </div>
                                    <div className="margin-xs">
                                        <label className="black">
                                            <Toggle checked={(this.state.user.privacyMessage & PrivacyMessages.MY_FOLLOWERS) > 0} onChange={this.getPrivacyMessagesToggle(PrivacyMessages.MY_FOLLOWERS)}/>
                                            Mes abonnés
                                        </label>
                                    </div>
                                </fieldset>

                                <div className="right margin">
                                    <button className="btn btn-1" onClick={() => this.saveUser('privacyMessage')}>SAUVEGARDER</button>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default injectUIRouter(Privacy);
