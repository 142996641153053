import React from "react";
import { useState } from "react";
import { Link } from "../../api/injectors";
import { FormattedMessage } from "react-intl";
import { Gallery } from "../../api/types";

type Props = {
	galleries: Gallery[]
}

const Step1 = (props: Props)=>{

	const [yearlyGalleryCollectionsFilter, setYearlyGalleryCollectionsFilter] = useState(0)
	return (
		<>
			<div className="steps">
				<div className="step current">1</div>
				<div className="sep"></div>
				<div className="step">2</div>
			</div>
			<h2 className="title-3 h-center text-uppercase margin-large-top"><FormattedMessage id="tutorial.stepOne"/></h2>
			<div className={"step1"}>
				<div key={0} className={"tutorial-popin-menu block"}>
					<div className={"link-tabs" + (yearlyGalleryCollectionsFilter > 0 ? " margin-bottom" : "")}>
					{
						props.galleries.map(galleryItem => {
								return !galleryItem.yearly && galleryItem.label.toLowerCase() !== 'jardin secret' ?
										<button className={"tutorial-tab-item" + (yearlyGalleryCollectionsFilter === galleryItem.galleryId ? ' active' : '')} key={galleryItem.galleryId}
														onClick={() => setYearlyGalleryCollectionsFilter(galleryItem.galleryId)}>
												{galleryItem.label}
										</button>
										: null
						})
					}
					</div>
					<div className={"collection-items cols-3" + (yearlyGalleryCollectionsFilter !== 0 ? ' opened' : '')}>
						{props.galleries[4].Collections.filter(collection => {
								return collection.galleryId === yearlyGalleryCollectionsFilter;
						}).map(collection => {
								return <Link
											className={"item"}
											key={collection.collectionId}
											to="connected.tutorial"
											params={{
													collectionId: collection.collectionId,
													type: null,
													year: null,
										}}>{collection.label}</Link>
							})
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Step1;
