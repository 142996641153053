import React from 'react';
import Page from "../Page";
import {Link} from "../../api/injectors";
import filterHelper from "../../api/filterHelper";
import {InMemoriam as InMemoriamType, InMemoriamToSend} from "../../api/types";
import adminRequests from "../../api/adminRequests";
import Notification from "../../api/notification";
import ConfirmModal from "../../components/ConfirmModal";
import moment from "moment";
import Helmet from "../../components/Helmet";

type Props = {
    memoriam: InMemoriamType[]
};

type State = {
    memoriam: InMemoriamType[]
    search: string
    newInMemoriam: InMemoriamToSend
    deleteId: number | null
};

class InMemoriam extends Page<Props, State> {
    readonly state: State = {
        memoriam: this.props.memoriam,
        search: '',
        newInMemoriam: {
            firstname: '',
            lastname: '',
            deathYear: moment(new Date()).format('YYYY-MM-DD'),
            birthYear: 1990,
            country: '',
            picture: '',
        },
        deleteId: null,
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    updateNewInMemoriam = (event: React.ChangeEvent<HTMLInputElement>) => {
        const attr = event.currentTarget.name;
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            newInMemoriam: {
                ...prevState.newInMemoriam,
                [attr]: val,
            },
        }));
    }

    createOrUpdateInMemoriam = () => {
        if (this.state.newInMemoriam.firstname === '') {
            Notification.error("Veuillez renseigner un prénom");
            return;
        }
        if (this.state.newInMemoriam.lastname === '') {
            Notification.error("Veuillez renseigner un nom");
            return;
        }
        if (this.state.newInMemoriam.picture === '') {
            Notification.error("Veuillez renseigner une image");
            return;
        }
        if (this.state.newInMemoriam.country === '') {
            Notification.error("Veuillez renseigner un pays");
            return;
        }
        if (this.state.newInMemoriam.inMemoriamId !== undefined) {
            const id = this.state.newInMemoriam.inMemoriamId;
            adminRequests.updateInMemoriam(this.state.newInMemoriam.inMemoriamId, this.state.newInMemoriam).then(res => {
                this.setState(prevState => {
                    let nState = {...prevState};
                    for (let i = 0; i < nState.memoriam.length; i++) {
                        if (nState.memoriam[i].inMemoriamId === id) {
                            nState.memoriam[i] = {...nState.memoriam[i], ...nState.newInMemoriam};
                            break;
                        }
                    }
                    return nState;
                });
                Notification.success("Livre d'or créé avec succès");
            }, function() {
                Notification.error("Erreur lors de la création du livre d'or");
            });
        } else {
            adminRequests.createInMemoriam(this.state.newInMemoriam).then(res => {
                this.setState(prevState => ({
                    ...prevState,
                    memoriam: [res.data, ...prevState.memoriam],
                }));
                Notification.success("Livre d'or créé avec succès");
            }, function() {
                Notification.error("Erreur lors de la création du livre d'or");
            });
        }
    }

    openDeletePopup = (inMemoriamId: number) => {
        return () => {
            this.setState(prevState => ({
                ...prevState,
                deleteId: inMemoriamId,
            }));
        }
    }

    setDeleteIdToNull = () => {
        this.setState(prevState => ({
            ...prevState,
            deleteId: null,
        }));
    }

    deleteInMemoriam = () => {
        const id = this.state.deleteId;
        adminRequests.deleteInMemoriam(id as unknown as number).then(() => {
            this.setState(prevState => {
                const nState = {...prevState};

                for (let i = 0; i < prevState.memoriam.length; i++) {
                    if (prevState.memoriam[i].inMemoriamId === id) {
                        nState.memoriam = [...nState.memoriam];
                        nState.memoriam.splice(i, 1);
                        nState.deleteId = null;
                        break;
                    }
                }

                return nState;
            });
            Notification.success("Livre d'or supprimé avec succès");
        }, function() {
            Notification.error("Erreur lors de la suppression du livre d'or")
        });
    }

    setCurrentInMemoriam = (inMemo: InMemoriamType) => {
        this.setState(prevState => ({
            ...prevState,
            newInMemoriam: {
                ...inMemo,
                country: inMemo.country || '',
                deathYear: moment(inMemo.deathYear).format('YYYY-MM-DD'),
            },
        }));
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={"Livres d'or | Paanteon"} />
                            <h1 className="page-title">
                                Livres d'or ({this.state.memoriam.length})
                            </h1>

                            <div className="uk-grid uk-child-width-1-2">
                                <div className="uk-width-1-1">
                                    <h2>Création livre d'or</h2>
                                </div>
                                <div>
                                    <label>Prénom</label>
                                    <input onChange={this.updateNewInMemoriam} value={this.state.newInMemoriam.firstname} name="firstname" type="text" placeholder="Prénom" className="input block"/>
                                </div>
                                <div>
                                    <label>Nom</label>
                                    <input onChange={this.updateNewInMemoriam} value={this.state.newInMemoriam.lastname} name="lastname" type="text" placeholder="Nom" className="input block"/>
                                </div>
                                <div>
                                    <label>Image</label>
                                    <input onChange={this.updateNewInMemoriam} value={this.state.newInMemoriam.picture} name="picture" type="url" placeholder="URL de l'image" className="input block"/>
                                </div>
                                <div>
                                    <label>Pays</label>
                                    <input onChange={this.updateNewInMemoriam} value={this.state.newInMemoriam.country} name="country" type="text" placeholder="Pays" className="input block"/>
                                </div>
                                <div>
                                    <label>Année de naissance</label>
                                    <input onChange={this.updateNewInMemoriam} value={this.state.newInMemoriam.birthYear} name="birthYear" type="number" placeholder="Année de naissance" className="input block"/>
                                </div>
                                <div>
                                    <label>Année de mort</label>
                                    <input onChange={this.updateNewInMemoriam} value={this.state.newInMemoriam.deathYear} name="deathYear" type="date" placeholder="Année de mort" className="input block"/>
                                </div>
                                <div className="uk-width-1-1 h-center">
                                    <button className="btn btn-1 margin-top" onClick={this.createOrUpdateInMemoriam}>{this.state.newInMemoriam.inMemoriamId !== undefined ? 'Sauvegarder' : 'Créer'}</button>
                                </div>
                            </div>

                            <div>
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>
                                <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                            </div>

                            <div className="uk-grid uk-grid-small margin-top">
                                {
                                    filterHelper.deepSearch(this.state.memoriam, this.state.search).map(memoriam =>
                                        <div className="uk-width-1-1" key={memoriam.inMemoriamId}>
                                            <div>
                                                {memoriam.firstname} {memoriam.lastname}
                                            </div>

                                            <button className="btn btn-outlined light" onClick={this.openDeletePopup(memoriam.inMemoriamId)}>Supprimer</button>
                                            <button className="btn btn-outlined light" onClick={() => this.setCurrentInMemoriam(memoriam)}>Éditer</button>
                                        </div>
                                    )
                                }
                            </div>

                            {
                                this.state.deleteId && <ConfirmModal onCancel={this.setDeleteIdToNull} onConfirm={this.deleteInMemoriam}>
                                    Êtes-vous sûrs de vouloir supprimer ce livre d'or ?
                                </ConfirmModal>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InMemoriam;
