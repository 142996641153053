import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import Helmet from "../../components/Helmet";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../../api/injectors";
import filterHelper from "../../api/filterHelper";
import {InMemoriam, Testimonial} from "../../api/types";
import {AxiosResponse} from "axios";
import moment from "moment";
import OptionsMenu from "../../components/OptionsMenu";
import ReportModal from "../../components/ReportModal";
import ConfirmModal from "../../components/ConfirmModal";
import adminRequests from "../../api/adminRequests";

interface Props {
  testimonials: AxiosResponse<Testimonial[]>,
}

const DEFAULT_TESTIMONIALS_SHOWN = 100;
const DEFAULT_TESTIMONIALS_INCREMENTS = 12;

const TestimonialsPage: FunctionComponent<Props & InjectedUIRouterProps> = ({testimonials, router}) => {
  const [localTestimonials, setLocalTestimonials] = useState(testimonials.data);
  const [searchOnIds, setSearchOnIds] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [maxShownTestimonials, setMaxShownTestimonials] = useState(DEFAULT_TESTIMONIALS_SHOWN);
  const [testimonialToDelete, setTestimonialToDelete] = useState<null | Testimonial>(null);

  const filteredTestimonials = useMemo(() => {
    let filteredTestimonials = [...localTestimonials];

    if (searchOnIds) filteredTestimonials = filteredTestimonials.filter(testimonial => testimonial.testimonialId.toString(10) === searchQuery)
    else filteredTestimonials = filterHelper.deepSearch(filteredTestimonials, searchQuery);

    return filteredTestimonials;
  }, [localTestimonials, searchOnIds, searchQuery]);

  const shownTestimonials = useMemo(
    () => filteredTestimonials.slice(0, maxShownTestimonials),
    [filteredTestimonials, maxShownTestimonials],
  );

  const deleteTestimonial = useCallback(() => {
    if (testimonialToDelete)
      adminRequests.deleteTestimonial(testimonialToDelete.testimonialId).then(() => {
        setLocalTestimonials(prevTestimonials => {
          const newTestimonials = [...prevTestimonials];
          newTestimonials.splice(newTestimonials.indexOf(testimonialToDelete), 1);

          setTestimonialToDelete(null);
          return newTestimonials;
        });
      });
  }, [testimonialToDelete]);

  return (
    <div className="dark-bg">
      <div className="uk-container">
        <div className="main-content">
          <div className="uk-container">
            <Helmet title={'Témoignages | Paanteon'}/>
            <h1 className="page-title">
              Témoignages
            </h1>

            <div className={"margin-bottom"}>
              <div className="margin-bottom">
                <Link to="^">&lt; Retour</Link>
              </div>

              <label>
                <input type="checkbox" checked={searchOnIds} onChange={() => setSearchOnIds(!searchOnIds)}/>
                Rechercher uniquement sur les IDs
              </label>

              <input type="search" placeholder="Rechercher..." className="input block"
                     onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery}/>

              <div className={"tile"}>
                <div className={"tile-body"}>
                  {shownTestimonials.map((testimonial, index) => {
                    return (
                      <div key={testimonial.testimonialId}>
                        <div className="feed-item let-overflow" key={testimonial.testimonialId}>
                          <div className="profile-pic" style={{backgroundImage: 'url(' + testimonial.User.picturePath + ')'}}/>
                          <div>
                            <p>
                              <em className="ptn-em"><Link to="connected.paanteon.user.galleries.condensed"
                                                           params={{userId: testimonial.User.userId, username: testimonial.User.username}}>{testimonial.User.firstname} {testimonial.User.lastname}</Link></em>
                              <small>&nbsp;-&nbsp;{moment(testimonial.createdAt)
                                .format('LL')}</small>
                              <small>&nbsp;-&nbsp;<a
                                className={"link"}
                                href={router.stateService.href(
                                  "connected.in-memoriam.details",
                                  {
                                    memoriamUniqueName: (testimonial.InMemoriam as InMemoriam).Thumbnail.uniqueName,
                                    memoriamId: testimonial.inMemoriamId,
                                  },
                                )}
                                target={"_blank"}
                              >
                                ID: {testimonial.testimonialId}
                              </a></small>
                            </p>
                            <p>
                              {testimonial.content}
                            </p>
                            <OptionsMenu>
                          <span className="options-menu">
                            <ul>
                              <li>
                                <ReportModal testimonialId={testimonial.testimonialId}><button
                                  className="btn link-btn">Signaler</button></ReportModal>
                              </li>
                              <li><button className="btn link-btn"
                                          onClick={() => setTestimonialToDelete(testimonial)}>Supprimer</button></li>
                            </ul>
                          </span>
                            </OptionsMenu>
                          </div>
                        </div>
                        {shownTestimonials.length !== index + 1 && <hr/>}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div>
                {
                  maxShownTestimonials < filteredTestimonials.length ?
                    <div className="h-center margin">
                      <button
                        className="btn btn-outlined light-blue"
                        onClick={() => setMaxShownTestimonials(maxShownTestimonials + DEFAULT_TESTIMONIALS_INCREMENTS)}>
                        VOIR PLUS DE TÉMOIGNAGES
                      </button>
                    </div>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        testimonialToDelete && <ConfirmModal
              onCancel={() => setTestimonialToDelete(null)}
              onConfirm={deleteTestimonial}
          >
              Êtes-vous sûr(e) de vouloir supprimer définitivement ce témoignage ?
          </ConfirmModal>
      }
    </div>
  );
};

export default injectUIRouter(TestimonialsPage);
