// import 'react-notifications/lib/notifications.css';
// @ts-ignore
import {NotificationManager} from "react-notifications";

const Notification = {
    success: function(text: string, title?: string, timeOut?: number, callback?: () => void, priority?: boolean) {
        let disappeared = false;
        let time: number;
        if (callback) time = window.setTimeout(function() {
            if (!disappeared && callback) callback();
            disappeared = true;
        }, 5000);
        NotificationManager.success(title ? title : '', text, timeOut, function() {
            if (!disappeared && callback) callback();
            disappeared = true;
            clearTimeout(time);
        }, priority);
    },

    error: function(text: string, title?: string, timeOut?: number, callback?: () => void, priority?: boolean) {
        NotificationManager.error(title ? title : '', text, timeOut, callback, priority);
        if (callback) setTimeout(callback, 5000);
    },

    warning: function(text: string, title?: string, timeOut?: number, callback?: () => void, priority?: boolean) {
        NotificationManager.warning(title ? title : '', text, timeOut, callback, priority);
        if (callback) setTimeout(callback, 5000);
    },

    info: function(text: string, title?: string, timeOut?: number, callback?: () => void, priority?: boolean) {
        NotificationManager.info(title ? title : '', text, timeOut, callback, priority);
        if (callback) setTimeout(callback, 5000);
    },
};

export default Notification;
