import React from 'react';
import Page from "./Page";
import UserSidebar from "../components/UserSidebar";
import {UserInfos, Tribe} from "../api/types";
import {InjectedUIRouterProps, injectUIRouter} from "../api/injectors";
import Helmet from "../components/Helmet";

type Props = {
    user: UserInfos,
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
} & InjectedUIRouterProps;

type State = {
    sectionOpened: string,
    questionOpened: Set<string>,
}

class Help extends Page<Props, State> {
    readonly state: State = {
        sectionOpened: '',
        questionOpened: new Set(),
    };

    readonly sections = {
        general: {
            name: "QUESTIONS GÉNÉRALES",
            questions: [
                {
                    question: "Qu'est-ce que Paanteon ?",
                    response: "Paanteon est le premier réseau social inspirationnel.\nVous pouvez créer votre paanteon personnel et y graver votre univers d'inspirations positives et vous lier ensuite avec tous ceux qui partagent les mêmes affinités électives.<br/><br/><a href=\"https://paanteon.com/presentation\" target=\"_blank\">Voir la présentation</a>",
                },
                {
                    question: "Qu'entendez-vous par inspiration ?",
                    response: "L'inspiration revêt des sens très divers pour chacun d’entre nous : c’est un souffle créatif qui nous anime, un élan spontané qui nous parcourt, une révélation qui nous subjugue, une réminiscence qui réveille quelque chose en nous ou tout simplement le fruit d’une recherche qui soudainement nous éclaire, etc. En définitive, toutes ces inspirations successives tissent le fil de nos vies et sont créatrices de sens. Essentielles, elles nous construisent autant qu’elles nous aident à grandir et à nous élever. Elles font de nous ce que nous sommes. Parfois même, lorsqu’elles deviennent des influences majeures, elles ont le pouvoir de changer nos vies.",

                },
                {
                    question: "Pourquoi avoir choisi ce nom ?",
                    response: "Le Panthéon est le « temple de tous les dieux » - du grec πᾶν (pãn) et θεός (theos) - que les Grecs et les Romains consacraient à leurs divinités. Cette institution antique a perduré à travers les siècles, tout en se sécularisant.\nNotre plateforme s’appuie sur ce concept universel et propose de rendre hommage aux hommes et femmes illustres mais pas seulement. Il nous a semblé important de pouvoir inclure aussi dans Paanteon tout ce qui nous relie aussi aux autres, aux œuvres, à la nature et au monde.\nC’est pourquoi nous avons choisi de prendre un nom spécifique évocateur du concept mais plus englobant.",
                },
                {
                    question: "Pourquoi avoir mis deux « a » dans votre nom et votre logo ?",
                    response: "Les deux lettres « a » graphiquement stylisées symbolisent deux yeux ouverts dans une démarche double qui est celle du réseau social : un effort de connaissance de soi et un esprit d’ouverture sur le monde.\nNul ne l’a mieux dit que le peintre Amedeo Modigliani : « D’un œil, observer le monde extérieur, de l’autre regarder au fond de soi-même ».",
                },
                {
                    question: "Que fait-on sur Paanteon ?",
                    response: "Dans un premier temps, vous construisez votre paanteon personnel en « gravant » dans des galeries et collections virtuelles vos inspirations et influences majeures.\nVous pouvez y penser en quelque sorte comme votre « musée intérieur », composé de grandes galeries et de salles de collection, pour accrocher aux murs des portraits d’hommes et de femmes à vos yeux dignes d’admiration. C’est aussi pour vous une façon de pouvoir exprimer votre attachement à ce qui, dans le monde, vous touche et vous émeut ; ou encore de garder une trace de ce qui, au cours de votre vie, vous a marqué durablement.\nUne fois construit ce « musée iconique » forcément sélectif, vous aurez peut-être plaisir à le partager sur le réseau social avec d’autres membres et à échanger avec eux autour de vos affinités communes.\nChaque jour aussi, nous pouvons ressentir des émotions et faisons des découvertes incroyables. Un portrait, une promenade, une exposition, un film, un livre, un documentaire, un voyage, une photo, une rencontre vont nous captiver. Pour vous permettre de partager ces instants, nous avons créé pour vous un fil d’inspiration. Un endroit pour inspirer les autres et se laisser inspirer !",
                },
                {
                    question: "Combien de temps cela prend-il pour construire son Paanteon ?",
                    response: "Sur Paanteon, nous privilégions la réflexion sur la réaction, le temps long sur l’instantané, la profondeur sur la superficialité. Construire son Paanteon personnel peut s’avérer une œuvre de longue haleine mais néanmoins toujours émouvante et passionnante car elle évoluera avec votre parcours de vie.\nConstruire son Paanteon, c’est prendre le temps de mettre son esprit à demeure, de se poser, de réfléchir, de penser, de rêver.",
                },
                {
                    question: "Qu’est-ce qui n’a pas sa place sur Paanteon ?",
                    response: "Paanteon n’est pas un réseau dédié à l’actualité ni un réseau de la vie quotidienne, et encore moins un réseau pour s’exhiber en permanence. D’autres plateformes sont disponibles pour cela. Ce n’est pas non plus un site de critiques ou de notation, où l’on vient donner son avis ou mettre une note.\nLa mission de Paanteon est de promouvoir la connaissance de soi, le savoir et la découverte autour de l’histoire, du présent, de la nature, de la culture et des styles de vie, dans un esprit positif d’élévation personnel et collectif.\nNous promouvons des valeurs de fraternité, de tolérance et de bienveillance. Sur cette base, si la liberté d’expression doit prévaloir, cela doit toujours être dans le respect de la diversité des goûts, des préférences et des opinions des uns et des autres.\nLa liberté de tous implique donc la responsabilité de chacun. S’il est toujours possible d’exprimer un désaccord ou une critique, les échanges se doivent d’être constructifs et de toujours rester civils et courtois.",
                },
                {
                    question: "En quoi ce réseau est-il différent des autres plateformes ?",
                    response: "Paanteon possède trois spécificités par rapport aux autres réseaux sociaux.\nLa première, c’est l’authenticité. Nous bannissons l’anonymat, un poison qui autorise toutes les dérives. C’est pourquoi nous demandons à nos membres de certifier leur compte sous un délai d’un an. Ainsi, vous avez la garantie que vous échangez avec de « vrais » gens. La certification vous offre un droit de membre à vie sur la plateforme, à condition bien entendu d’en respecter les règles.\nLa deuxième, c’est le respect de votre vie privée. Contrairement à nombre de plateformes qui vous espionnent de façon permanente et insidieuse, nous nous engageons ainsi à n’effectuer aucun traçage et à ne capturer aucune information ou donnée vous concernant en dehors de la plateforme.\nLa troisième, c’est que sur Paanteon, vous restez propriétaire de vos données et maitrisez la gestion de vos paramètres de confidentialité et d’échanges de façon transparente.",
                },
                {
                    question: "Quelle discipline appliquez-vous sur la plateforme ?",
                    response: "Sur Paanteon, nous souhaitons retrouver l’esprit de communautés civiles et bienveillantes. Nous privilégions des conversations passionnantes et inspirées, des échanges civils, respectueux et courtois, dans le respect des différences des goûts, des préférences et des opinions de chacun.\nC’est pourquoi nous appliquons une discipline stricte de gestion des contenus. Ceux jugés contraires à nos règles d’utilisation, inappropriés ou illicites, ne seront pas tolérés et seront retirés. Les membres qui enfreignent les règles de la plateforme ou les lois en vigueur pourront être suspendus temporairement ou définitivement exclus.",
                },
                {
                    question: "Qui peut devenir membre de Paanteon ?",
                    response: "Le réseau Paanteon est ouvert à tous. L'âge minimum est fixé à 15 ans.",
                },
                {
                    question: "Existe-t-il une application mobile ?",
                    response: "Paanteon est d’abord un site web accessible depuis votre ordinateur, votre tablette ou votre smartphone. Une application Paanteon sera lancée prochainement.",
                },
                {
                    question: "J’adore le concept de votre réseau social, comment puis-je vous aider ?",
                    response: "Nous sommes ravis que vous adoriez Paanteon. Vous pouvez nous aider en en parlant autour de vous et en invitant vos amis à vous rejoindre.",
                },
            ],
        },
        paanteon: {
            name: "MON PAANTEON",
            questions: [
                {
                    question: "Que puis-je mettre dans mon paanteon personnel ?",
                    response: "Dans votre espace Mon paanteon, vous disposez, à la manière d’un grand musée, de cinq galeries au choix comprenant chacune un certain nombre de collections pour y graver vos inspirations et influences majeures et leur rendre hommage.\nDans la galerie Humanité, vous pouvez rendre hommage à des personnalités historiques ou vivantes que vous admirez.\nDans la galerie Planète, vous pouvez graver les êtres et choses de la nature et du monde qui vous émerveillent.\nDans la galerie Culture, les œuvres culturelles et artistiques qui vous émeuvent.\nDans la galerie Lifestyle, tout ce qui vous comble au quotidien.\nDans la galerie 2021, vos découvertes et coups de cœur de l’année.",
                },
                {
                    question: "Comment puis-je graver un hommage ?",
                    response: `C'est très simple. Vous sélectionnez une galerie, puis une collection et vous pouvez créer une page hommage en cliquant sur une vignette vide. 
                    Ensuite, vous entrez le nom de l'inspiration que vous souhaitez graver, puis ajoutez une photo, avant de cliquer sur le bouton "Je grave". 
                    Bravo, vous avez gravé votre première vignette !\nSur chaque page hommage, un espace blog est à votre disposition si vous souhaitez raconter l'histoire qui vous lie à ce sujet et en quoi cela a été ou est toujours pour vous une inspiration majeure.
                    <a href="` + this.props.router.stateService.href("connected.howToTribute") + `" target="_blank">En savoir plus</a>
                    `,
                },
                {
                    question: "Suis-je obligé d’écrire un hommage lorsque je grave une vignette ?",
                    response: "Rien ne vous y oblige ! Mais tentez l’exercice de raconter l’histoire qui vous lie à ce que vous venez de graver et vous verrez que cela produit beaucoup de bienfaits !",
                },
                {
                    question: "Pourquoi les collections sont-elles limitées à 12 vignettes ?",
                    response: "Chaque collection ne peut pas comprendre plus de 12 hommages, donc 12 vignettes au maximum. Il vous faut donc réfléchir à ce qui est réellement le plus important pour vous.",
                },
                {
                    question: "Une de mes collections est déjà pleine. Que puis-je faire ?",
                    response: "Si vous souhaitez ajouter une vignette à une collection déjà pleine, il vous faudra d'abord faire de la place. Vous pouvez soit en supprimer une ou plusieurs ou bien la ou les archiver.",
                },
                {
                    question: "Lorsque je grave le titre d'une œuvre, par exemple le titre d’un roman, dois-je aussi mettre le nom de son auteur ?",
                    response: "Non, il n'est pas nécessaire de préciser le nom de son auteur, le nom de l'œuvre suffit.",
                },
                {
                    question: "Lorsque je grave un hommage concernant une œuvre internationale, est-ce que je dois mettre le titre original ou le titre français ?",
                    response: "Il est préférable de toujours graver le titre d'une ouvre en français. Toutefois, si vous y tenez, vous pouvez le graver dans sa langue d'origine.",
                },
                {
                    question: "A quoi servent les recommandations sur une page hommage ?",
                    response: "L'espace Recommandations situé sous chaque hommage vous sert à mettre en avant des œuvres complémentaires pour aller plus loin et donner envie à vos lecteurs qui souhaiteraient eux aussi découvrir cette inspiration.",
                },
                {
                    question: "Je ne trouve pas la collection que je recherche. Puis-je faire une demande d'ajout de collection ?",
                    response: "Si vous pensez qu’il manque une collection dans la liste proposée par le réseau, vous pouvez nous contacter via le formulaire de contact ici. Lors de votre demande, merci de préciser dans quelle galerie aimeriez-vous trouver cette collection. Notre équipe étudiera votre demande.",
                },
                {
                    question: "Je souhaite mettre un hommage existant dans une autre collection ?",
                    response: "Il n’est pas possible de déplacer un hommage d’une collection à une autre pour le moment. Si vous souhaitez changer de collection pour un de vos hommages, nous vous invitons à supprimer votre hommage et à le graver de nouveau dans la collection souhaitée.",
                },
                {
                    question: "Comment puis-je changer l'ordre des hommages à l'intérieur de chaque collection ?",
                    response: "L'ordre des vignettes par défaut est chronologique. Il n'est pas possible pour l'instant de changer l'ordre des hommages.",
                },
            ],
        },
        archives: {
            name: "MES ARCHIVES",
            questions: [
                {
                    question: "A quoi servent mes archives ?",
                    response: "Si vous souhaitez supprimer un hommage mais en garder la trace, vous pouvez l'archiver. Vos archives constitueront une \"mémoire\" temporelle de ce qui vous a inspiré à une certaine époque de votre vie et vous pourrez la revisiter. Les archives ne sont accessibles qu'à vous-même.",
                },
                {
                    question: "Que deviennent les vignettes gravées dans la galerie de l'année en cours ?",
                    response: "Cette galerie vous sert à graver vos coups de cœur et vos découvertes de l'année. Une fois l'année terminée, l’année écoulée apparaîtra automatiquement dans vos archives. Comme cela, vous pourrez en conserver la trace.",
                },
            ],
        },
        timeline: {
            name: "LE FIL D’INSPIRATION",
            questions: [
                {
                    question: "A quoi sert le fil d'inspiration ?",
                    response: "Le fil d'inspiration est le cœur battant de Paanteon, un lieu d’échange pour inspirer les autres et se laissez inspirer !\nRetrouvez-y toutes les actualités des membres, des groupes et des pages que vous suivez. Vous y trouverez aussi les informations et les événements susceptibles de correspondre à vos passions et centres d’intérêt.",
                },
                {
                    question: "Que puis-je mettre sur le fil d'inspiration ?",
                    response: "Sur le fil d’inspiration, vous avez la possibilité de publier du contenu (texte, photo, lien, etc.) via l’éditeur. Cet espace est réservé au partage d’inspirations et aux discussions de centres d’intérêt avec votre communauté.",
                },
            ],
        },
        memoriam: {
            name: "IN MEMORIAM",
            questions: [
                {
                    question: "Comment sont choisies les personnalités de la section In Memoriam ?",
                    response: "Elles sont choisies par notre comité de sélection interne parmi les personnalités dont nous estimons qu'elles ont eu un rayonnement important, voire exceptionnel, dans leur domaine.",
                },
                {
                    question: "Puis-je proposer l'ouverture d'un nouveau livre d'or ?",
                    response: "Vous pouvez proposer l'ouverture d'un nouveau livre d'or pour une personnalité décédée en vous rendant sur la page In Memoriam et dans l'espace \"Suggestion\". Nous étudierons votre proposition. Vous pouvez aussi rendre hommage à cette personnalité directement dans votre paanteon personnel.",
                },
            ],
        },
        circle: {
            name: "LES CERCLES DE DISCUSSION",
            questions: [
                {
                    question: "A quoi servent les cercles de discussion ?",
                    response: "Les cercles de discussion offrent la possibilité d’échanger et de se tenir informés autour d’une thématique particulière, d’un sujet qui vous passionne. Par exemple si vous êtes fan d’une personnalité, d’une œuvre ou d’un courant d’idées, passionné par un certain type de littérature, un hobby particulier, un courant artistique singulier, ou bien encore une cause qui vous tient à cœur.",
                },
                {
                    question: "Qui peut créer un cercle de discussion ?",
                    response: "Tout membre peut créer un cercle de discussion sur un thème de son choix et inviter les autres membres partageant le même centre d’intérêt à le rejoindre.",
                },
                {
                    question: "Comment rejoindre un cercle de discussion ?",
                    response: "Si le cercle que vous souhaitez rejoindre à un statut public, l’adhésion est libre et automatique.\nSi le cercle est privé, il faudra faire une demande d’adhésion à l’administrateur du cercle en question.",
                },

            ],
        },
        tribes: {
            name: "LES TRIBUS",
            questions: [
                {
                    question: "A quoi servent les tribus ?",
                    response: "Paanteon est le premier réseau des tribus numériques post-modernes : des communautés inspirées par de mêmes affinités électives et toujours bienveillantes.\nLes tribus vous permettent de retrouver facilement ceux qui ont les mêmes goûts et préférences que vous, que vous soyez fan d’un artiste, d’un genre littéraire, musical ou cinématographique ou que vous aimiez des choses similaires ou proches.\nC’est un nouveau moyen de vous relier aux autres à travers des critères affinitaires et donc de dépasser les critères identitaires traditionnels (ethnie, religion, préférence politique, etc.) qui causent tant de divisions et parfois de haine.\nAu fur et à mesure vous rejoindrez des tribus, cela nous permettra de vous proposer une expérience toujours personnalisée sur Paanteon.",
                },
                {
                    question: "Comment rejoindre une tribu ?",
                    response: "Pour rejoindre une tribu, vous n’avez rien à faire. Vos tribus se constituent au fur et à mesure que vous construirez votre Paanteon.\nSi vous partagez les mêmes inspirations, les mêmes goûts et les mêmes intérêts que d’autres membres, il y a fort à parier que vous vous retrouverez dans la même tribu. Vous pourrez ainsi accéder en un clic et échanger avec tous ceux qui partagent vos passions.",
                },
                {
                    question: "Pourquoi ne vois-je pas mes tribus s’afficher ?",
                    response: "C’est sans doute parce que votre paanteon n’est pas encore assez rempli et ne permet pas encore de vous ouvrir les portes d’un tipi ! Alors, continuez à remplir votre paanteon. Vos premières tribus apparaîtront bientôt.",
                },
            ],
        },
        mods: {
            name: "MODÉRATION",
            questions: [
                {
                    question: "Quelles sont les règles de modération ?",
                    response: "La liberté d'expression est la règle sur Paanteon dans le respect d'autrui.\nNous promouvons des valeurs de fraternité, de tolérance et de bienveillance. Sur cette base, si la liberté d’expression doit prévaloir, cela doit toujours être dans le respect de la diversité des goûts, des préférences et des opinions des uns et des autres.\nLa liberté de tous implique donc la responsabilité de chacun. S’il est toujours possible d’exprimer un désaccord ou une critique, les échanges se doivent d’être constructifs et de rester toujours civils et courtois.\nNous appliquons une discipline stricte de gestion des contenus. Les contenus inappropriés ou illicites qui encouragent la violence, la haine, l’insulte publique, les injures, les propos dégradants et humiliants, le harcèlement, la cruauté envers les gens ou les animaux ne seront pas tolérés et pourront être retirés. Nous vous encourageons à nous les signaler.\nLes membres qui enfreignent les règles pourront être suspendus temporairement ou indéfiniment.",
                },
                {
                    question: "Je ne comprends pas pourquoi ma publication a été supprimée ?",
                    response: "Si une publication a fait l’objet d’un signalement, une enquête est menée pour savoir si la publication en question viole ou pas les règles de bonne conduite de la plateforme. Si notre équipe juge que cette publication s’inscrit en violation de ces règles, une décision peut être prise de la supprimer. Une décision de suspension temporaire ou définitive du membre peut s’ensuivre. Un message est envoyé au membre pour l’en informer.",
                },
                {
                    question: "Que faire en cas de contenu inapproprié ?",
                    response: "Vous pouvez signaler un contenu que vous jugez inapproprié en indiquant quel est, selon vous, la nature du problème soulevé. Votre signalement fera l'objet d'un examen par nos équipes et s'il contrevient aux règles du réseau, il pourra éventuellement être supprimé et le membre faire l’objet d’une mesure de suspension temporaire ou définitive.\nSi nous décidons toutefois que ce contenu est acceptable, vous aurez toujours la possibilité de bloquer le membre à l'origine de ce contenu afin de ne plus voir ses publications.",
                },
                {
                    question: "L’orthographe d’une de mes vignettes a été modifiée. Pourquoi ?",
                    response: "L'intitulé de votre vignette a pu être modifié par le système en cas d'une coquille d'orthographe ou si nous déterminons qu'il a mal été renseigné, soit de par la ponctuation utilisée, soit par l'ajout de mentions inutiles.\nCela nous permet aussi d'harmoniser sur l'ensemble du site les noms des divers hommages ou de différencier entre eux des hommages homonymes afin de pouvoir comptabiliser la popularité de chaque inspiration. Dans tous les cas, cela n'a pas d'impact sur votre hommage.",
                },

            ],
        },
        certif: {
            name: "CERTIFICATION",
            questions: [
                {
                    question: "De combien de temps je dispose pour faire certifier mon compte ?",
                    response: "Vous disposez d'un délai d'un an à compter de votre inscription pour faire certifier votre compte.",
                },
                {
                    question: "Est-il obligatoire de faire certifier son profil ?",
                    response: "Oui. Cette procédure simple, rapide et respectueuse de votre vie privée. Cette procédure assure que l’identité de chaque membre est bien réelle. Elle est un gage d’authenticité et aidera à prévenir les abus. Une fois votre profil certifié, une pastille de couleur bleu apparaîtra à côté de votre nom dans votre encart de profil.",
                },
                {
                    question: "Que se passe-t-il si je n’ai pas certifié mon compte dans ce délai ?",
                    response: "Passé ce délai, vous ne pourrez plus utiliser les fonctionnalités du site et votre compte sera temporairement inaccessible. Vous pourrez de nouveau y accéder une fois votre certification accomplie.",
                },
                {
                    question: "Comment se passe le processus de certification ?",
                    response: "Le processus est très simple et ne prend que quelques secondes. Nous vous demanderons de nous envoyer une copie de votre carte d’identité recto-verso ou de la double page de votre passeport. Nous prendrons ensuite contact avec vous afin de vérifier vos informations.",
                },
                {
                    question: "Combien de temps conservez-vous les informations obtenues lors de ma certification ?",
                    response: "Les documents d’identité utilisés pour procéder à votre certification de compte restent strictement confidentiels et sont stockés de façon sécurisée uniquement durant le temps nécessaire à la procédure de certification, et au plus durant un mois maximum.",
                },
                {
                    question: "Pourquoi dois-je payer deux euros lorsque je certifie mon compte ?",
                    response: "Lors de votre certification, nous vous demanderons de régler la somme de deux euros au titre de l’adhésion au réseau Paanteon. Cette cotisation symbolique vous ouvre un droit de membre à vie sur la plateforme.",
                },
            ],
        },
        params: {
            name: "PARAMÈTRES DE COMPTE",
            questions: [
                {
                    question: "Comment puis-je accéder à mes paramètres de compte ?",
                    response: "Vous pouvez gérer vos paramètres de compte en vous rendant sur le menu déroulant de votre profil en haut à droite de la barre de menu et en cliquant sur Paramètres, ensuite sélectionnez l'onglet Compte.",
                },
                {
                    question: "J'ai oublié mon mot de passe. Comment puis-je le réinitialiser ?",
                    response: "Vous avez la possibilité de changer de mot de passer en cliquant sur « Mot de passe oublié » dans l’espace de connexion. Un mail vous sera envoyé pour vous informer de la procédure à suivre.\nSi vous êtes déjà connecté et souhaitez changer votre mot de passe, il vous suffit de vous rendre dans vos paramètres de compte et de sélectionner l’onglet Compte et Informations de connexion.",
                },
                {
                    question: "Comment modifier mes informations de connexion ?",
                    response: "Vos informations de connexion sont accessibles depuis votre espace « paramètres de compte » dans la rubrique « Informations de connexion ». Vous avez la possibilité de modifier votre adresse e-mail et votre mot de passe. Attention, si votre compte était déjà certifié, la modification de ces informations nécessite une vérification par nos services.",
                },
                {
                    question: "Je pense que mon identité a été usurpée. Que puis-je faire ?",
                    response: `Si vous pensez que votre identité a été usurpée, vous pouvez nous contacter via le formulaire de contact présent <a href="${this.props.router.stateService.href('contact')}">ici</a>. Lors de votre signalement, pensez à indiquer le lien du compte qui a usurpé votre identité. Notre équipe vous contactera dans les plus brefs délais.`,
                },
                {
                    question: "Je pense que mon compte a été piraté. Que puis-je faire ?",
                    response: "Si vous pensez que votre compte a été piraté, vous pouvez nous contacter via le formulaire de contact. Lors de votre signalement, pensez à indiquer le lien du compte qui a été piraté. Notre équipe vous contactera dans les plus brefs délais.",
                },
                {
                    question: "Comment signaler le compte d’une personne décédée ?",
                    response: `Si une personne de votre communauté est décédée, vous pouvez nous contacter via le formulaire de contact <a href="${this.props.router.stateService.href('contact')}">ici</a>. Au sein de votre message, pensez à indiquer le lien du paanteon de la personne décédée. Notre équipe vous contactera dans les plus brefs délais.`,
                },
                {
                    question: "Comment supprimer mon compte ?",
                    response: "Il est tout à fait possible de supprimer à tout moment son compte Paanteon. Pour cela, veuillez vous rendre dans vos Paramètres de compte et dans la rubrique Suppression de compte, puis cliquez sur Supprimer.\nAttention, votre compte sera supprimé définitivement au bout de 30 jours. À tout moment, pendant cette période, si votre compte a été supprimé accidentellement ou par erreur, vous pouvez le récupérer simplement en vous connectant avec vos identifiants.",
                },
            ],
        },
        confidentiality: {
            name: "PARAMÈTRES DE CONFIDENTIALITÉ",
            questions: [
                {
                    question: "Comment puis-je accéder à mes paramètres de confidentialité ?",
                    response: "Vous pouvez gérer vos paramètres de confidentialité en vous rendant sur le menu déroulant de votre profil en haut à droite de la barre de menu et en cliquant sur paramètres, ensuite sélectionnez l'onglet \"confidentialité\".",
                },
                {
                    question: "Qui peut voir le contenu du réseau Paanteon ?",
                    response: "Seuls les membres du réseau peuvent accéder aux contenus du site.",
                },
                {
                    question: "Qui peut voir mon paanteon personnel ?",
                    response: "Par défaut, tous les membres peuvent voir votre paanteon personnel. Cependant, vous avez la possibilité de de restreindre son accès aux membres que vous souhaitez. Pour cela, vous pouvez vous rendre sur votre page Paramètres.",
                },
                {
                    question: "Qui peut voir mes publications sur le fil d'inspiration ?",
                    response: "Vos publications sur le fil d'inspiration sont visibles par vos amis et vos abonnés. Elles peuvent ensuite être partagées auprès de leur propre réseau d'amis et d'abonnés.",
                },
                {
                    question: "Qui peut m’envoyer des messages ?",
                    response: "Vos informations personnelles de contact ne sont pas visibles par les autres membres du réseau.",
                },
                {
                    question: "Qui peut voir mes informations de contact ?",
                    response: "Vous pouvez gérer votre messagerie et choisir qui peut vous contacter, en vous rendant sur le menu déroulant de votre profil en haut à droite de la barre de menu et en cliquant sur Paramètres, ensuite sélectionnez l'onglet Confidentialité, puis Qui peut m'envoyer des messages.",
                },
                {
                    question: "Quelles données personnelles utilisez-vous et comment ?",
                    response: "Sur Paanteon, nous préservons votre vie privée. Contrairement à d’autres réseaux sociaux, nous nous engageons donc à ne faire aucun traçage ni aucune capture disproportionnée de vos données personnelles, hormis les informations minimales nécessaires lorsque vous utilisez la plateforme. Ce que vous faites au quotidien, les endroits où vous vous rendez, ce que vous mangez, achetez, les sites internet que vous visitez, tout cela ne nous intéresse pas. Tout simplement, parce que nous n’en avons pas besoin.\nVous restez propriétaire de vos données. Si vous nous accordez une licence pour publier tout le contenu que vous êtes susceptible de générer sur Paanteon, vous en restez maître et pouvez à tout moment décider de les supprimer de la plateforme et les récupérer.\nNous vous donnons aussi de larges possibilités de paramétrages d’usage et de confidentialité qui vous permettront de garder la maîtrise de votre visibilité, de votre temps passé et de vos notifications.",
                },
            ],
        },
    }

    setSectionOpened(section: string) {
        this.setState(prevState => {
            const newSectionState = this.state.sectionOpened === section ? '' : section;
            return {
                ...prevState,
                sectionOpened: newSectionState,
            };
        });
    }

    toggleSectionOpened(question: string) {
        this.setState(prevState => {
            const newQuestionOpened = new Set(this.state.questionOpened);
            if (this.state.questionOpened.has(question)) {
                newQuestionOpened.delete(question);
            } else {
                newQuestionOpened.add(question);
            }
            return {
                ...prevState,
                questionOpened: newQuestionOpened,
            };
        });
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <UserSidebar user={this.props.user} showCover={true}/>

                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'Aide | Paanteon'} />
                            <h1 className="page-title">
                                Aide
                            </h1>

                            <div className="help-section">

                                {
                                    Object.entries(this.sections).map(([sectionKey, section]) => {
                                        return (
                                            <div className="tile tile-padding-xl" key={sectionKey}>
                                                <div className="tile-body">
                                                    <div className="collapsible-section-head-container">
                                                        <button onClick={() => this.setSectionOpened(sectionKey)}
                                                                className={`collapsible-section-head ${this.state.sectionOpened === sectionKey ? "opened" : ""}`}>
                                                            { section.name }
                                                        </button>
                                                    </div>

                                                    {
                                                        this.state.sectionOpened === sectionKey && <div>
                                                            {
                                                                section.questions.map((question) => {
                                                                    const questionIdent = sectionKey + question.question;
                                                                    return (
                                                                        <div key={questionIdent}>
                                                                            <div className="collapsible-section-head-container">
                                                                                <button onClick={() => this.toggleSectionOpened(questionIdent)}
                                                                                        className={`collapsible-subsection-head ${this.state.questionOpened.has(questionIdent) ? "opened" : ""}`}>
                                                                                    { question.question }
                                                                                </button>
                                                                            </div>

                                                                            {
                                                                                this.state.questionOpened.has(questionIdent) && <p dangerouslySetInnerHTML={{__html: question.response}}/>
                                                                            }
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectUIRouter(Help);
