import config from "./config";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
// @ts-ignore
import LoadingBar from 'react-top-loading-bar';
import localStorage from "./localStorage";
import Notification from "./notification";
import auth from "./authentication";
import {CancelablePromise} from "./types";

const makeCancelable = <T>(promise: Promise<T>) => {
    let hasCanceled_ = false;

    const wrappedPromise = new CancelablePromise<T>((resolve, reject) => {
        promise.then(
            val => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
            error => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
        );
    });

    wrappedPromise.cancel = function() {
        hasCanceled_ = true;
    };

    return wrappedPromise;
};

const backendConfig = {
    baseURL: config.backend,
    timeout: config.httpTimeout,
    xsrfCookieName: "XSRF-TOKEN",
    url: '/',
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
} as AxiosRequestConfig;

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (localStorage.isSetLocalUser()) {
        let nConf = {
            ...config,
        };
        nConf.headers['Authorization'] = 'Bearer ' + localStorage.getLocalUser().xsrfToken;
        return nConf;
    }
    return config;
});

let timeout = false;
axios.interceptors.response.use(undefined, error => {
    if (error.response) {
        if (error.response.status === 403) {
            Notification.error("Access denied !");
        } else if (error.response.status === 401) {
            auth.logout();
        }
    }
    if (!timeout && !error.response) {
        timeout = true;
        Notification.error("Server is unreachable or does not respond properly !", "Timeout", undefined, () => {
            timeout = false;
        });
    }
    return Promise.reject(error);
});

const request = {
    get: <T = any>(endpoint: string, external = false): CancelablePromise<AxiosResponse<T>> => {
        return makeCancelable(axios.get<T>(endpoint, external ? undefined : backendConfig));
    },

    post: (endpoint: string, data?: any, external = false) => {
        return makeCancelable(axios.post(endpoint, data, external ? undefined : backendConfig));
    },

    put: (endpoint: string, data?: any, external = false) => {
        return makeCancelable(axios.put(endpoint, data, external ? undefined : backendConfig));
    },

    delete: (endpoint: string, external = false) => {
        return makeCancelable(axios.delete(endpoint, external ? undefined : backendConfig));
    },

    head: (endpoint: string, external = false) => {
        return makeCancelable(axios.head(endpoint, external ? undefined : backendConfig));
    },

    getResource: <T = any>(endpoint: string, external = false): CancelablePromise<AxiosResponse<T>> => {
        return makeCancelable(axios.get<T>(endpoint, external ? undefined : {
            ...backendConfig,
            responseType: 'arraybuffer',
        }));
    },

    requestNumber: 0,
    setLoadingInterceptor: (loadingBar: LoadingBar) => {
        axios.interceptors.request.use((config: AxiosRequestConfig) => {
            request.requestNumber++;
            loadingBar.continuousStart();
            return config;
        }, error => {
            return Promise.reject(error);
        });

        axios.interceptors.response.use(response => {
            request.requestNumber--;
            if (request.requestNumber === 0) loadingBar.complete();
            return response;
        }, error => {
            request.requestNumber--;
            if (request.requestNumber === 0) loadingBar.complete();
            return Promise.reject(error);
        });
    },
};

export default request;
