import React from 'react';
import {Recommendation, Thumbnail, ThumbnailDetails, Tribute, User, UserInfos, Tribe} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import pictoMagnifyPlus from "../assets/img/pictos/picto-magnify-plus.svg";
import {
    pictoHeart,
    pictoHeartBlue,
    pictoLink,
    pictoPaanteonLightBlue,
    pictoSharingBlue,
    pictoSpeechBubbleBlue,
    pictoWikipedia,
} from "../api/pictos";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../api/injectors";
import pictoPaanteon from "../assets/img/pictos/picto-paanteon.svg";
import pictoEngraveWhite from "../assets/img/pictos/picto-engrave-white.svg";
import pictoEngraveDark from "../assets/img/pictos/picto-engrave-dark.svg";
import Toggleable from "../components/Toggleable";
import Page from "./Page";
import {getCategoryFromId} from "../components/Recommendations";
import {stopPropagation} from "../api/helpers";

type Props = {
    thumbnail: { data: Thumbnail }
    thumbnailDetails: { data: ThumbnailDetails }
    selfUser: {data: UserInfos}
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
} & InjectedUIRouterProps

type State = {
    showLightbox: boolean
    tributes: Tribute[]
    filterCategory: number | null
}

class ThumbnailRecommendations extends Page<Props, State> {
    private hasTribute: boolean = false;
    state = {
        showLightbox: false,
        tributes: this.props.thumbnailDetails.data.tributes.map(tribute => {
            const t = {...tribute};
            if (t.content) {
                t.content = t.content.replace(/<\/?[^>]+(>|$)/g, "");
                t.content = t.content.replace(/&nbsp;/g, " ");
                t.content = t.content.replace(/&amp;/g, "&");
                if (t.content.length > 240) {
                    t.content = t.content.slice(0, 240) + '...';
                }
            }
            return t;
        }),
        filterCategory: null,
    };

    constructor(props: Props) {
        super(props);

        this.hasTribute = !!props.thumbnailDetails.data.tributes && !!props.thumbnailDetails.data.tributes.find(t => t.userId === props.selfUser.data.userId);
    }

    showLightbox = () => {
        this.setState(prevState => ({...prevState, showLightbox: !prevState.showLightbox}));
    };

    setFilterCategory = (category: number | null) => {
        this.setState(prevState => ({
            ...prevState,
            filterCategory: category,
        }));
    }

    goToTribute = (tributeId: number, thumbnailUniqueName: string, username: string) => {
        this.props.router.stateService.go('connected.paanteon.tribute.details', {tributeId, thumbnailUniqueName, username});
    }

    render() {
        let recommendations: Recommendation[] = this.props.thumbnailDetails.data.recommendations;

        if (this.state.filterCategory) recommendations = recommendations.filter((reco: Recommendation) => reco.type === this.state.filterCategory);

        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <UserSidebar user={this.props.selfUser.data} showCover={true}>
                        <div className="sidebar-block dark-blue">
                            <p className="block-title">
                                Les hommages
                                <Link to={"connected.paanteon.thumbnail.tributes"}>&gt; Tous les hommages ({this.props.thumbnailDetails.data.tributes.length})</Link>
                            </p>
                            {
                                this.state.tributes.slice(0, 5).map(tribute => {
                                    return (
                                        <div className="content-card pointer" key={tribute.tributeId} onClick={() => this.goToTribute(tribute.tributeId, this.props.thumbnail.data.uniqueName, tribute.User.username as string)}>
                                            <div className="feed-item card-display">
                                                <div className="item-head">
                                                    <div className="profile-pic" style={{backgroundImage: "url(" + tribute.User.picturePath + ')'}}/>
                                                    <p>
                                                        <em className="ptn-em">
                                                            <Link to="connected.paanteon.user.galleries.condensed"
                                                                    params={{userId: tribute.User.userId, username: tribute.User.username}}>{tribute.User.firstname} {tribute.User.lastname}
                                                            </Link>
                                                        </em>
                                                    </p>
                                                </div>
                                                <h3 className="item-title">{tribute.title}</h3>
                                                <p dangerouslySetInnerHTML={{__html: tribute.content}}/>
                                                <div className="feed-footer">
                                                    <span style={{color: "#37a8db"}}>
                                                        {
                                                            tribute.liked ?
                                                                <img src={pictoHeart} className="picto blue" alt="picto heart"/>
                                                                :
                                                                <img src={pictoHeartBlue} className="picto blue" alt="picto heart"/>
                                                        }
                                                        {tribute.likeCount}
                                                    </span>
                                                    <span style={{color: "#37a8db"}}>
                                                        <img src={pictoSpeechBubbleBlue}
                                                                className="picto blue"
                                                                alt="picto-speech-bubble-blue"/>
                                                        {tribute.commentCount}
                                                    </span>
                                                    <span style={{color: "#37a8db"}}>
                                                        <img src={pictoSharingBlue}
                                                                className="picto blue"
                                                                alt="picto-sharing-blue"/>
                                                        {tribute.shareCount}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <Link className="btn btn-outlined light btn-block" to={"connected.paanteon.thumbnail.tributes"}>Voir tout ({this.props.thumbnailDetails.data.tributes.length})</Link>
                        </div>
                    </UserSidebar>

                    <div className="main-content">
                        <div className="uk-container">
                            <div className="margin-large">

                                <h2 className="title-1 no-margin-bottom">
                                    <span className="pre-title">Toutes les recommandations pour découvrir</span>
                                    {this.props.thumbnail.data.label}
                                </h2>
                            </div>

                            <div>
                                {
                                    this.props.thumbnailDetails.data.tributes.length &&
                                    <div className="banner-img-2">
                                        <span className="img-container">
                                            <img className="picto" src={pictoMagnifyPlus} alt="magnify picto" onClick={this.showLightbox}/>
                                            <img className="main-img" alt="tribute" src={this.props.thumbnailDetails.data.tributes[0].picturePath}/>
                                        </span>
                                    </div>
                                }
                                {/* temporary mechanism*/}
                                {
                                    this.state.showLightbox ?
                                        <div className="lightbox" ng-show="lightboxImg" onClick={this.showLightbox}>
                                            <span className="close">X</span>
                                            <img className="main-img" alt="tribute" src={this.props.thumbnailDetails.data.tributes[0].picturePath}/>
                                        </div>
                                        : null
                                }
                            </div>

                            <div className="tribute-quick-actions">
                                    <span>
                                        {this.props.thumbnailDetails.data.tributes.length} <img src={pictoPaanteon}
                                                                                         className="picto"
                                                                                         alt="picto paanteon"/>
                                    </span>
                                {
                                    !this.hasTribute &&
                                    <span>
                                        <Link to="connected.paanteon.user.new-tribute"
                                              params={{thumbnailId: this.props.thumbnail.data.thumbnailId, username: this.props.selfUser.data.username}}>
                                            <img className="picto" src={pictoEngraveWhite}
                                                 alt="picto engrave white"/>
                                        </Link>
                                    </span>
                                }
                            </div>

                            {
                                (this.props.thumbnail.data.thumbnailUserId || this.props.thumbnail.data.InMemoriam || this.props.thumbnail.data.wikipedia || this.props.thumbnail.data.website) && <div className="tile margin-large tribute-writing-bloc">
                                    <div className="tile-head">
                                        <div className="uk-grid uk-grid-small">
                                            <div className="uk-width-expand">
                                                {
                                                    this.props.thumbnail.data.thumbnailUserId && <><Link to="connected.paanteon.user" params={{userId: this.props.thumbnail.data.thumbnailUserId, username: this.props.thumbnail.data.thumbnailUsername}}>&gt; Voir son paanteon</Link><br/></>
                                                }
                                                {
                                                    this.props.thumbnail.data.InMemoriam && <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: this.props.thumbnail.data.uniqueName, memoriamId: this.props.thumbnail.data.InMemoriam.inMemoriamId}}>&gt; Voir le livre d'or</Link>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    this.props.thumbnail.data.wikipedia && <><a href={this.props.thumbnail.data.wikipedia} target="_blank" rel="noopener noreferrer"><img src={pictoWikipedia} className="picto" alt="picto wikipedia"/> Wikipédia</a><br/></>
                                                }
                                                {
                                                    this.props.thumbnail.data.website && <a href={this.props.thumbnail.data.website} target="_blank" rel="noopener noreferrer"><img src={pictoLink} className="picto" alt="picto website"/> Site officiel</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="link-tabs" style={{position: "relative"}}>
                                <Toggleable>
                                    <div className="tab-item active toggle-popin-menu" key={0}>
                                        <span className="toggle-button active margin-right">Filtrer par</span>

                                        {
                                            this.state.filterCategory &&
                                                <span onClick={stopPropagation} className="active-filter-chip" style={{position: 'relative'}}>
                                                    {getCategoryFromId(this.state.filterCategory as unknown as number)}

                                                    <button className="btn btn-icon-close" onClick={() => this.setFilterCategory(null)}/>
                                                </span>
                                        }

                                        <div className="popin-menu block">
                                            <div className="uk-grid uk-grid-small" onClick={stopPropagation}>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(1)} className="btn btn-outlined light btn-block">Article</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(2)} className="btn btn-outlined light btn-block">Documentaire</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(3)} className="btn btn-outlined light btn-block">Film</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(4)} className="btn btn-outlined light btn-block">Interview</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(5)} className="btn btn-outlined light btn-block">Livre</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(6)} className="btn btn-outlined light btn-block">Série</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(7)} className="btn btn-outlined light btn-block">Vidéo</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(8)} className="btn btn-outlined light btn-block">Oeuvre</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(9)} className="btn btn-outlined light btn-block">Lieu</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(10)} className="btn btn-outlined light btn-block">Site web</button>
                                                </div>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button onClick={() => this.setFilterCategory(11)} className="btn btn-outlined light btn-block">Album</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment key={1}/>
                                </Toggleable>
                            </div>

                            <div className="margin-top">
                                {
                                    recommendations.map((reco: Recommendation) => {
                                        return <div className="content-card list-display pointer" key={reco.recommendationId} onClick={() => this.goToTribute(reco.tributeId, (reco.Tribute as Tribute).Thumbnail.uniqueName, (reco.User as User).username as string)}>
                                            <div className="thumbnail">
                                                <img src={reco.picturePath} alt={reco.title}/>
                                            </div>
                                            <div className="card-body">
                                                <h3 className="card-title">{reco.title}</h3>
                                                <p className="category">{getCategoryFromId(reco.type)}</p>
                                                <p className="author">De {reco.author}</p>
                                                <div className="card-footer" onClick={stopPropagation}>
                                                    <p className="body-m">
                                                        Recommandé par {reco.recommendCount}
                                                    </p>
                                                    <div onClick={stopPropagation}>
                                                        <span className="ptn-em">
                                                            {reco.engraveCount}
                                                            <img src={pictoPaanteonLightBlue}
                                                                className="picto"
                                                                alt="gravé (nb)"
                                                                title="gravé (nb)"/>
                                                        </span>
                                                    </div>
                                                    <Link to="connected.paanteon.user.new-tribute" params={{name: reco.title, username: this.props.selfUser.data.username}}>
                                                        <img src={pictoEngraveDark}
                                                             className="picto"
                                                             alt="graver"
                                                             title="graver"/>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }

                                {/* <div className="content-card list-display">
                                    <div className="thumbnail">
                                        <img src="https://upload.wikimedia.org/wikipedia/en/0/05/Invictus-poster.png" alt="invictus"/>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Invictus</h3>
                                        <p className="category">FILM</p>
                                        <p className="author">De Clint Eastwood</p>
                                        <div className="card-footer">
                                            <p className="body-m">Recommandé par <a href="">68K</a></p>
                                            <div>
                                                <a href="">105,8 K</a>
                                            </div>
                                            <div>
                                                <img src={pictoEngraveDark}
                                                className="picto"
                                                alt="graver"
                                                title="graver"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-card list-display">
                                    <div className="thumbnail">
                                        <img src="https://upload.wikimedia.org/wikipedia/en/0/05/Invictus-poster.png" alt="invictus"/>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">Invictus</h3>
                                        <p className="category">FILM</p>
                                        <p className="author">De Clint Eastwood</p>
                                        <div className="card-footer">
                                            <p className="body-m">Recommandé par <a href="">68K</a></p>
                                            <div>
                                                <a href="">105,8 K</a>
                                            </div>
                                            <div>
                                                <img src={pictoEngraveDark}
                                                className="picto"
                                                alt="graver"
                                                title="graver"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectUIRouter(ThumbnailRecommendations);
