import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import Modal from "./Modal";
import TwitterLogo from "../assets/img/twitter-logo.png";
import FacebookLogo from "../assets/img/facebook-logo.svg";
import LinkedinLogo from "../assets/img/linkedin-logo.png";
import formatValidation from "../api/formatValidation";
import Notification from "../api/notification";
import userRequests from "../api/userRequests";
import config from "../api/config";

const encodedFrontendUrl = encodeURIComponent(config.publicFrontendUrl);

interface Props {
  onClose?(): void;
}

export interface ExternalFriend {
  firstname: string,
  lastname: string,
  email: string,
}

const InviteFriendsModal: FunctionComponent<Props> = ({onClose}) => {
  const [friends, setFriends] = useState<ExternalFriend[]>([...Array(5).keys()].map(() => ({firstname: "", lastname: "", email: ""})));

  const updateFriend = useCallback(<Field extends keyof ExternalFriend>(friendIndex: number, field: Field) => {
    return (ev: React.ChangeEvent<HTMLInputElement>) => {
      const value = ev.target.value;

      setFriends(prevFriends => {
        const newFriends = [...prevFriends];
        newFriends[friendIndex] = {...newFriends[friendIndex], [field]: value};
        return newFriends;
      });
    }
  }, []);

  const errors = useMemo(() => {
    return friends.map(friend => {
      const errors = [];

      if (Object.values(friend).join("").length > 0) {
        if (friend.firstname.length === 0) errors.push("Le prénom est requis");
        if (friend.lastname.length === 0) errors.push("Le nom de famille est requis");
        if (friend.email.length === 0) {
          errors.push("L'adresse e-mail est requise")
        } else {
          if (!formatValidation.validEmail(friend.email)) errors.push("Cette adresse e-mail n'est pas valide")
          if (formatValidation.getEmailHasForbiddenDomain(friend.email)) errors.push("Cette adresse e-mail n'est pas acceptée sur Paanteon");
        }
      }

      return errors;
    });
  }, [friends]);

  const isFriendEmpty = useCallback((friend: ExternalFriend) => {
    return Object.values(friend).join("").length === 0;
  }, []);

  const isEmpty = useMemo(() => {
    return friends.every(isFriendEmpty);
  }, [friends, isFriendEmpty]);

  const hasErrors = useMemo(() => {
    return errors.flat().length > 0;
  }, [errors]);

  const inviteFriends = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!hasErrors) {
      userRequests.inviteFriends(friends.filter(friend => !isFriendEmpty(friend))).then(
        () => {
          setFriends([...Array(5).keys()].map(() => ({firstname: "", lastname: "", email: ""})));
          Notification.success("Vos amis ont été invités avec succès");
        },
        () => Notification.error("Une erreur est survenue lors de l'invitation de vos amis"),
      );
    }
  }, [friends, hasErrors, isFriendEmpty]);

  return <Modal closeButton onClose={onClose}>
    <div>
      <span className={"pop-up-title text-uppercase text-finish"}>Je parraine mes amis</span>
      <div className={"body"}>
        Invitez vos amis à vous rejoindre sur Paanteon et faites-leur découvrir ce qui vous inspire !
      </div>
    </div>

    <form onSubmit={inviteFriends}>
      <div className="margin">
        {friends.map((friend, index) => {
          return (<div className={"uk-grid uk-grid-small margin-bottom"} key={index}>
            <div className={"uk-width-1-4@m"}>
              <input className={"input block"} value={friend.firstname} onChange={updateFriend(index, "firstname")} placeholder={"Prénom"} />
            </div>
            <div className={"uk-width-1-4@m"}>
              <input className={"input block"} value={friend.lastname} onChange={updateFriend(index, "lastname")} placeholder={"Nom"} />
            </div>
            <div className={"uk-width-1-2@m"}>
              <input className={"input block"} value={friend.email} onChange={updateFriend(index, "email")} placeholder={"E-mail"} />
            </div>
            {errors[index].length > 0 && <div className={"uk-width-1-1@m"}>
                <ul className={"text-red body-s"}>
                  {errors[index].map(error => (<li>{error}</li>))}
                </ul>
            </div>}
          </div>);
        })}
      </div>
      <i className={"body-s"}>Un courriel automatique sera envoyé à vos amis. Aucune information ne sera conservée par Paanteon.</i>
      <div className="margin margin-bottom-m body">
        Ou partagez sur les autres réseaux sociaux
        <div className={"social-logo-bar"}>
          <a href={`https://twitter.com/intent/tweet?text=Je%20vous%20invite%20à%20me%20rejoindre%20sur%20Paanteon%20et%20à%20découvrir%20ce%20nouveau%20réseau%20social%20inspirationnel.%0AÀ%20très%20vite%20!%0APaanteon.com%0A%23LiveInspired%20%23Paanteon`} target={"_blank"} rel={"noopener noreferrer"}>
            <img src={TwitterLogo} alt={"Twitter logo"} className={"social-logo"} />
          </a>
          <a href={`https://www.facebook.com/share.php?u=${encodedFrontendUrl}`} target={"_blank"} rel={"noopener noreferrer"}>
            <img src={FacebookLogo} alt={"Facebook logo"} className={"social-logo"} />
          </a>
          <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedFrontendUrl}`} target={"_blank"} rel={"noopener noreferrer"}>
            <img src={LinkedinLogo} alt={"Linkedin logo"} className={"social-logo"} />
          </a>
        </div>
      </div>

      <div className="pop-up-buttons flex space-between">
        <div><div className="liveinspired dark boxed padding-h padding-v-xs">
          <span>#live</span><span>inspired</span>
        </div></div>
        <button className="btn btn-1 text-uppercase" disabled={isEmpty} type={"submit"}>J'invite</button>
      </div>
    </form>
  </Modal>
}

export default InviteFriendsModal;
