import * as React from "react";
import Page from "./Page";
import {ActiveLink, Link} from "../api/injectors";
import {Publication, Testimonial, Thumbnail, Tribute, User, UserInfos, Tribe} from "../api/types";
import pictoCamera from "../assets/img/pictos/picto-camera.svg";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";
import UserSidebar from "../components/UserSidebar";
import Post from "../components/Publication/Post";
import TributePost from "../components/Publication/TributePost";
import localStorage from "../api/localStorage";
import Share from "../components/Publication/Share";
import {UIView} from "@uirouter/react";
import {deleteFromArrayAndReturn} from "../api/helpers";
import {acceptableImages} from "../api/domHelper";
import {Mention, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem} from "react-mentions";
import MentionUser, {MentionableUsersContext} from "../components/MentionUser";
import Helmet from "../components/Helmet";
import { useState } from "react";
import TestimonialPost from "../components/Publication/TestimonialPost";

type Props = {
    user: UserInfos
    discover: (Thumbnail & {Tributes: []})[],
    popular: (Thumbnail & {Tributes: []})[],
    feed: Publication[]
    new: Thumbnail[]
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = Readonly<{
    feed: Publication[],
    publication: { img?: string, content?: string },
    textareaExpanded: boolean
}>

class InspirationFeed extends Page<Props, State> {
    private reader: FileReader = new FileReader();
    private user: User = localStorage.getLocalUser();

    constructor(props: Props) {
        super(props);
        let feed: Publication[] = props.feed;
        feed = feed.sort(function(a, b) {
            // Turn strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        this.state = {
            feed: feed,
            publication: {},
            textareaExpanded: false,
        };


        this.reader.onloadend = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    publication: {
                        ...prevState.publication,
                        img: this.reader.result as string,
                    },
                }
            });
        }
    }

    handlePublicationContentInput: OnChangeHandlerFunc = (e) => {
        const value = e.target.value.substr(0, 500);
        this.setState(state => {
            let nState = {...state};
            if (nState.publication) nState.publication = {
                ...nState.publication,
                content: value,
            };
            return nState;
        });
    };

    publicationPicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) this.reader.readAsDataURL(e.target.files[0]);
    };

    addPublication = () => {
        paanteon.createPublication(this.state.publication).then((result) => {
            this.setState(prevState => {
                let nState = {...prevState};
                nState.feed.unshift(result.data);
                nState.publication = {
                    content: "",
                };
                return nState;
            });
            Notification.success('Publication enregistrée');
        }, function() {
            Notification.error('Erreur lors de la publication');
        });
    };

    focusTextarea = () => {
        this.setState(prevState => ({
            ...prevState,
            textareaExpanded: true,
        }));
    }

    blurTextarea = () => {
        if ( !this.state.publication.content ) { // don't retract if there is some content
            setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    textareaExpanded: false,
                }))
            }, 50);
        }
    }

    toggleLike = (publication: Publication) => {
        return () => {
            if (publication.liked) {
                paanteon.unlike({publicationId: publication.publicationId}).then(() => {
                    this.setState(prevState => {
                        let nState = {...prevState};
                        const i = nState.feed.indexOf(publication);
                        nState.feed[i] = {...nState.feed[i], liked: false, likeCount: nState.feed[i].likeCount - 1};
                        return nState;
                    })
                }, () => {
                    Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
                });
            } else {
                paanteon.like({publicationId: publication.publicationId}).then(() => {
                    this.setState(prevState => {
                        let nState = {...prevState};
                        const i = nState.feed.indexOf(publication);
                        nState.feed[i] = {...nState.feed[i], liked: true, likeCount: nState.feed[i].likeCount + 1};
                        return nState;
                    })
                }, () => {
                    Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
                });
            }
        };
    }

    /**
     * @param from 0 for post, 1 for tribute, 2 for share
     */
    deletePublication = (publicationId: number, from: 0 | 1 | 2) => {
        this.setState(prevState => {
            const nState = {...prevState};
            for (let i = 0; i < nState.feed.length; i++) {
                if (nState.feed[i].publicationId === publicationId) {
                    if (from === 0 || from === 1)
                        nState.feed = deleteFromArrayAndReturn(nState.feed, i);
                    else
                        nState.feed[i] = {...nState.feed[i], Tribute: undefined}
                    break;
                }

                if (nState.feed[i].PublicationRef?.publicationId === publicationId) {
                    nState.feed[i] = {...nState.feed[i], PublicationRef: null, publicationRefId: null};
                    break;
                }
            }

            return nState;
        });
    }

    render() {
        return (
            <>
                <Helmet title={"Fil d'inspiration | Paanteon"} />
                <div className="dark-bg">
                    <div className="uk-container">
                        {/* left sidebar */}
                        <UserSidebar user={this.props.user} popular={this.props.popular}
                                     showCover={true}/>

                        {/* right sidebar */}
                        <div className="sidebar sidebar-right">
                            <div style={{marginTop: "75px"}}/>
                            {/* TODO: refactor that awful dirty layout */}
                            <div className="sidebar-paanteon-entering sidebar-block">
                                <p className="block-title">Ils font leur<br/>entrée dans Paanteon</p>
                                <div className="uk-grid uk-grid-small">
                                    {
                                        this.props.new.length && this.props.new.slice(0, 4).map((card: Thumbnail) => {
                                            return (
                                                <div className="uk-width-1-2 uk-grid-margin" key={card.thumbnailId}>
                                                    <Link to="connected.paanteon.thumbnail.tributes" params={{
                                                        thumbnailId: card.thumbnailId,
                                                        thumbnailUniqueName: card.uniqueName,
                                                    }}
                                                          className="card expand small blue no-hover-anim"
                                                          style={{backgroundImage: 'url(' + card.Tributes![0].picturePath + ')'}}>
                                                        <canvas width="250" height="350"/>
                                                        <div className="card-footer">
                                                            <p className="card-name">{card.label}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="main-content with-sidebar-right">
                            <div className="uk-container inspiration-feed-wall">
                                <div className="margin-bottom">
                                    <div style={{width: "35%", marginLeft: "10%", display: "inline-block"}}><ActiveLink
                                        className="btn btn-outlined btn-block light" to="connected.inspiration-feed"
                                        class="active" exact options={{reload: true}}>FIL D'INSPIRATION</ActiveLink></div>
                                    <div style={{width: "35%", marginLeft: "10%", display: "inline-block"}}><ActiveLink
                                        className="btn btn-outlined btn-block light" to="connected.inspiration-feed.my-publications"
                                        class="active">MES PUBLICATIONS</ActiveLink></div>
                                </div>

                                <UIView><>
                                    <div className="tile margin-bottom">
                                        <div className="tile-body">
                                            <div className="feed-item write-comment">
                                                <div className="profile-pic"
                                                     style={{backgroundImage: "url(" + this.props.user.picturePath + ")"}}/>
                                                <div className="comment-form">

                                                    <MentionableUsersContext.Consumer>
                                                        {
                                                            (mentionUsers) => <MentionsInput value={this.state.publication.content || ''}
                                                                                      onChange={this.handlePublicationContentInput}
                                                                                      onFocus={this.focusTextarea}
                                                                                      onBlur={this.blurTextarea} maxLength={500}
                                                                                      placeholder={"Ce qui m'inspire aujourd'hui..."}
                                                                                      className={"textarea expandable textarea-with-mentions" + (this.state.textareaExpanded ? ' expanded' : '')}>
                                                                <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                                         renderSuggestion={(suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>}/>
                                                            </MentionsInput>
                                                        }
                                                    </MentionableUsersContext.Consumer>
                                                    <div className="custom-upload">
                                                        <input type="file" name="publication-pic" id="publication-pic"
                                                               onChange={this.publicationPicChange} accept={acceptableImages}/>
                                                        <label htmlFor="publication-pic">
                                                            <img src={pictoCamera} alt="picto-camera"/>
                                                        </label>
                                                    </div>
                                                    {
                                                        this.state.publication.img !== undefined && <img src={this.state.publication.img} className="uploaded-img"
                                                             alt=""/>
                                                    }
                                                </div>
                                            </div>
                                            { (this.state.textareaExpanded || (this.state.publication.content && this.state.publication.content.length)) &&
                                                <div className="right">
                                                    <button className="btn btn-1 right" onClick={this.addPublication}
                                                            disabled={!this.state.publication.content}>JE PUBLIE
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <Feed inspirationFeed={this.state.feed} onDelete={this.deletePublication} />
                                </></UIView>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const Feed: React.FC<{inspirationFeed: Publication[], onDelete?(publicationId: number, type: 0 | 1 | 2): any}> = ({inspirationFeed, onDelete}) => {

        const [limit, setLimit] = useState(60);

        const loadMore = (size: number) => {
            setLimit(limit + size)
        };

        return <>{
            inspirationFeed.slice(0, limit).map((publication: Publication) => {
            if (publication.publicationRefId) {
                return <Share onDelete={onDelete} key={publication.publicationId} publication={publication} footer={!!publication.content && publication.content.length > 0}><Post onDelete={onDelete} item={publication.PublicationRef as Publication} footer={!publication.content || publication.content.length <= 0}/></Share>
            } else if (publication.type === 0) {
                return <Post onDelete={onDelete} item={publication} key={publication.publicationId} footer/>
            } else if (publication.type === 1 && publication.Tribute) { // do not display if it was archived
                return <TributePost onDelete={onDelete} key={publication.publicationId} item={publication as Publication & {Tribute: Tribute}} footer/>
            } else if (publication.type === 2) {
                return <Share onDelete={onDelete} publication={publication} key={publication.publicationId} footer={!!publication.content && publication.content.length > 0}>
                    {
                        publication.Tribute ?
                            <TributePost onDelete={onDelete} item={publication as Publication & {Tribute: Tribute}} footer={!publication.content || publication.content.length <= 0}/>
                            :
                            publication.Testimonial ? <TestimonialPost onDelete={onDelete} item={publication as Publication & {Testimonial: Testimonial}}/> :
                            <div className="info minor">Contenu non disponible</div>
                    }
                </Share>
            } else if (publication.type === 3) {
                return <TestimonialPost onDelete={onDelete} key={publication.publicationId} item={publication as Publication & {Testimonial: Testimonial}} footer/>
            } else {
                return <React.Fragment key={publication.publicationId}/>;
            }
        })
        }<div className="text-center margin-top">
            <button className="btn btn-1" onClick={() => loadMore(20)}>Load more</button>
        </div></>
}

export default InspirationFeed;
