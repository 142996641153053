import React, {PureComponent} from 'react';
import {GalleryContext} from "../../pages/PaanteonUser";
import localStorage from "../../api/localStorage";
import {Collection, Gallery, User} from "../../api/types";
import {Link} from "../../api/injectors";
import domHelper from "../../api/domHelper";
import {StateObject} from '@uirouter/core';

interface OwnProps {
    user: User
    $state$: StateObject
}

type Props = OwnProps;

type State = Readonly<{
    expandedGalleries: { [id: number]: boolean }
    addCollectionGalleryId: number | null
    yearlyGalleryCollectionsFilter: number
}>;

class PaanteonCollectionsView extends PureComponent<Props, State> {
    readonly state: State = {
        expandedGalleries: {},
        addCollectionGalleryId: null,
        yearlyGalleryCollectionsFilter: 1,
    };
    private isMyPaanteon = this.props.user.userId === localStorage.getLocalUser().userId;
    private screenWidth = window.innerWidth;
    private condensed = this.props.$state$.name === "connected.paanteon.user.galleries.condensed";

    expandGallery = (galleryId: number) => {
        this.setState(prevState => {
            let nExp = {...prevState.expandedGalleries};
            nExp[galleryId] = !prevState.expandedGalleries[galleryId];
            return {...prevState, expandedGalleries: nExp};
        });
    };

    toggleAddCollection = (galleryId: number | null) => {
        this.setState(prevState => ({...prevState, addCollectionGalleryId: prevState.addCollectionGalleryId === galleryId ? null : galleryId}));
    };

    setYearlyGalleryCollectionsFilter = (galleryId: number) => {
        this.setState(prevState => ({...prevState, yearlyGalleryCollectionsFilter: galleryId}));
    };

    componentDidMount(): void {
        const {hash} = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const e = document.getElementById(hash.slice(1, hash.length));
                if (e) e.scrollIntoView(true);
            }, 0);
        }
    }

    render() {
        return <GalleryContext.Consumer>
            {
                (galleries) => (
                    <>
                        <div className="link-tabs">
                            {
                                galleries.map(gallery => {
                                    return <Link className="tab-item" key={gallery.galleryId}
                                                 to={"connected.paanteon.user." + (this.condensed ? "condensed" : "collections")} params={{'#': "gallery-" + gallery.code}}
                                                 onClick={() => domHelper.scrollToAnchor("gallery-" + gallery.code)}>
                                        {gallery.label}
                                    </Link>
                                })
                            }
                        </div>

                        {
                            galleries.map((gallery: Gallery & { userCollections: Collection[] }) => {
                                return <div className="gallery" id={"gallery-" + gallery.code} key={gallery.galleryId}>
                                    <div className="gallery-head">
                                        <h2 className="gallery-title">
                                            {gallery.label}
                                            <span className="gallery-subtitle">{ this.isMyPaanteon && gallery.description }</span>
                                        </h2>
                                        {
                                            this.isMyPaanteon ?
                                                <div>
                                                    <span className={"btn link-btn light btn-icon-plus" + (this.state.addCollectionGalleryId === gallery.galleryId ? ' btn-icon-minus' : '')}
                                                    onClick={() => this.toggleAddCollection(gallery.galleryId)}>Ajouter une collection</span>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className={"uk-grid uk-grid-small gallery-grid" + (this.condensed && !this.state.expandedGalleries[gallery.galleryId] ? ' condensed' : '')}>

                                        {
                                            gallery.Collections.map(collection => {
                                                return collection.Tributes && collection.Tributes.length ?
                                                    <div className="uk-width-1-2 uk-width-1-3@m uk-width-1-4@l" key={collection.collectionId}>
                                                        <Link to="connected.paanteon.user.galleries.all" params={{'#': "collection-" + collection.collectionId + (gallery.yearly ? 'y' + gallery.label : '')}} className="card expand"
                                                              style={{backgroundImage: 'url(' + collection.Tributes[0].picturePath + ')'}}>
                                                            <canvas width="250" height="350"/>
                                                            <div className="card-footer">
                                                                <p className="card-title big">{collection.label}</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    : null
                                            })
                                        }

                                        {
                                            !gallery.userCollections.length ?
                                                <div className="uk-width-1-2 uk-width-1-3@m uk-width-1-4@l">
                                                    <div ui-sref="" className="card empty expand no-hover-anim">
                                                        <canvas width="250" height="350"/>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    {
                                        this.condensed && ((this.screenWidth < 1200 && gallery.userCollections.length > 3) || (this.screenWidth >= 1200 && gallery.userCollections.length > 4)) ?
                                            <div className="h-center">
                                                <span onClick={() => this.expandGallery(gallery.galleryId)}
                                                      className={"load-more" + (this.state.expandedGalleries[gallery.galleryId] ? ' reverse' : '')}/>
                                            </div>
                                            : null
                                    }

                                    {
                                        this.state.addCollectionGalleryId === gallery.galleryId ?
                                            <div className="popin-menu block chevron-right">
                                                {
                                                    !!gallery.yearly ?
                                                        <div className="link-tabs margin-bottom">
                                                            {/* TODO: replace display condition for "jardin secret" when existing flag in DB*/}

                                                            {
                                                                galleries.map(galleryItem => {
                                                                    return !galleryItem.yearly && galleryItem.label.toLowerCase() !== 'jardin secret' ?
                                                                        <button className={"tab-item" + (this.state.yearlyGalleryCollectionsFilter === galleryItem.galleryId ? ' active' : '')} key={galleryItem.galleryId}
                                                                                onClick={() => this.setYearlyGalleryCollectionsFilter(galleryItem.galleryId)}>
                                                                            {galleryItem.label}
                                                                        </button>
                                                                        : null
                                                                })
                                                            }
                                                        </div>
                                                        : null
                                                }
                                                <div className="cols-3">
                                                    {
                                                        gallery.Collections.filter(collection => {
                                                            return gallery.yearly ? collection.galleryId === this.state.yearlyGalleryCollectionsFilter : true;
                                                        }).map(collection => {
                                                            return collection.Tributes && collection.Tributes.length ?
                                                                <span className="item disabled" key={collection.collectionId}>{collection.label}</span>
                                                                :
                                                                <Link
                                                                    className={"item" + (collection.Tributes && collection.Tributes.length && !gallery.yearly ? ' disabled' : '')}
                                                                    key={collection.collectionId}
                                                                    to="connected.paanteon.user.new-tribute"
                                                                    params={{
                                                                        collectionId: collection.collectionId,
                                                                        type: gallery.yearly ? 'yearly' : null,
                                                                        year: gallery.yearly ? gallery.label : null,
                                                                    }}>{collection.label}</Link>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            })
                        }
                    </>
                )
            }
        </GalleryContext.Consumer>
    }
}

export default PaanteonCollectionsView;
