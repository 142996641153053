import * as React from "react";
import {UIRouterConsumer, UIRouterReact, UISref, UISrefActive} from "@uirouter/react";

// Props the HOC adds to the wrapped component
export type InjectedUIRouterProps = {
    router: UIRouterReact
}

export type ExcludeRouterProps<P> = Pick<P,
    Exclude<keyof P, keyof InjectedUIRouterProps>>

export function injectUIRouter<P>(
    Component: React.ComponentType<P & InjectedUIRouterProps>,
): React.ComponentClass<ExcludeRouterProps<P>> {
    class InjectedUIRouter extends React.Component<ExcludeRouterProps<P>> {
        render() {
            return <UIRouterConsumer>{router => <Component router={router} {...this.props as any}/>}</UIRouterConsumer>
        }
    }

    return InjectedUIRouter
}


export function Link(props: any) {
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <UISref to={props.to} params={props.params} options={props.options}><a {...props}>{props.children}</a></UISref>
}

export function ActiveLink(props: any) {
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    return <UISrefActive class={props.class} exact={props.exact} options={props.options}><UISref {...props}><a title={props.title}>{props.children}</a></UISref></UISrefActive>
}
