import request from "./request";
import {
    CommentToSend,
    Gallery, MessageToSend, NotificationBase,
    RecommendationToSend,
    ReportToSend,
    ShareToSend, TestimonialToSend,
    Thumbnail,
    TributeToSend,
} from "./types";
const galleries: Gallery[] = [];
const discover: Thumbnail[] = [];
const inMemoriam: Object[] = [];
let notifications: {unread: number, notifications: NotificationBase[]} | null = null;

const paanteon = {
    getGalleries: function() {
        if (galleries.length) {
            return Promise.resolve(galleries);
        } else {
            return request.get('/paanteon/galleries', false).then(function(result) {
                galleries.push(...result.data);
                return Promise.resolve(result.data);
            });
        }
    },

    getDiscover: function() {
        if (discover.length) {
            return Promise.resolve(discover);
        } else {
            return request.get('/paanteon/discover').then(function(result) {
                discover.push(...result.data);
                return Promise.resolve(discover);
            });
        }
    },

    getInspirationFeed: function() {
        return request.get('/paanteon/inspirations');
    },

    getMyLastTributes: function() {
        return request.get('/paanteon/tributes/last');
    },

    getInMemoriam: function() {
        if (inMemoriam.length) {
            return Promise.resolve(inMemoriam);
        } else {
            return request.get('/paanteon/in-memoriam').then(function(result) {
                inMemoriam.push(...result.data);
                return Promise.resolve(result.data);
            });
        }
    },

    like: function(like: Object) {
        return request.post('/paanteon/likes', like, false);
    },

    unlike: function(like: Object) {
        return request.post('/paanteon/unlikes', like, false);
    },

    comment: function(comment: CommentToSend) {
        return request.post('/paanteon/comments', comment);
    },

    share: function(share: ShareToSend) {
        return request.post('/paanteon/shares', share);
    },

    createTribute: function(collectionId: number, tribute: Object) {
        return request.post('/paanteon/collections/' + collectionId + '/tributes', tribute, false);
    },

    createPublication: function(publication: Object) {
        return request.post('/paanteon/publications', publication, false);
    },

    updateTribute: function(tribute: TributeToSend & {tributeId: number}) {
        return request.put('/paanteon/tributes/' + tribute.tributeId, tribute, false);
    },

    getTribute: function(tributeId: number) {
        return request.get('/paanteon/tributes/' + tributeId, false);
    },

    getThumbnail: function(thumbnailId: number) {
        return request.get('/paanteon/thumbnails/' + thumbnailId, false);
    },

    getThumbnailDetails: function(thumbnailId: number) {
        return request.get('/paanteon/thumbnails/' + thumbnailId + '/details');
    },

    getThumbnailTributes: function(thumbnailId: number) {
        return request.get('/paanteon/thumbnails/' + thumbnailId + '/tributes');
    },

    getThumbnailRecommendations: function(thumbnailId: number) {
        return request.get('/paanteon/thumbnails/' + thumbnailId + '/recommendations');
    },

    createRecommendation: function(tributeId: number, recommendation: RecommendationToSend) {
        return request.post('/paanteon/tributes/' + tributeId + '/recommendations', recommendation);
    },

    updateRecommendation: function(tributeId: number, recommendationId: number, recommendation: RecommendationToSend) {
        return request.put('/paanteon/tributes/' + tributeId + '/recommendations/' + recommendationId, recommendation);
    },

    deleteRecommendation: function(tributeId: number, recommendationId: number) {
        return request.delete('/paanteon/tributes/' + tributeId + '/recommendations/' + recommendationId);
    },

    getUserPaanteon: function(userId: number) {
        return request.get('/users/' + userId + '/paanteon', false);
    },

    searchTributes: function(search: string) {
        return request.get('/paanteon/tributes/search?search=' + search, false);
    },

    getPublication: function(publicationId: number) {
        return request.get('/paanteon/publications/' + publicationId);
    },

    sendReport: function(report: ReportToSend) {
        return request.post('/paanteon/reports', report);
    },

    deleteComment: function(commentId: number) {
        return request.delete('/paanteon/comments/' + commentId);
    },

    deleteTribute: function(tributeId: number) {
        return request.delete('/paanteon/tributes/' + tributeId);
    },

    deletePublication: function(publicationId: number) {
        return request.delete('/paanteon/publications/' + publicationId);
    },

    getTributeEngraves: function(tributeId: number) {
        return request.get('/paanteon/tributes/' + tributeId + '/engraves');
    },

    getInMemoriamDetails: function(inMemoriamId: number) {
        return request.get('/paanteon/in-memoriam/' + inMemoriamId);
    },

    createTestimonial: function(inMemoriamId: number, testimonial: TestimonialToSend) {
        return request.post('/paanteon/in-memoriam/' + inMemoriamId + '/testimonials', testimonial);
    },

    deleteTestimonial: function(inMemoriamId: number, testimonialId: number) {
        return request.delete('/paanteon/in-memoriam/' + inMemoriamId + '/testimonials/' + testimonialId);
    },

    archiveTribute: function(tributeId: number) {
        return request.post('/paanteon/tributes/' + tributeId + '/archive');
    },

    getArchives: function(userId: number) {
        return request.get('/users/' + userId + '/paanteon/archives');
    },

    suggestInMemoriam: function(suggest: {proposition: string}) {
        return request.post('/paanteon/in-memoriam', suggest);
    },

    postMessage: function(message: MessageToSend) {
        return request.post('/paanteon/conversations/messages', message);
    },

    getAllConversations: function() {
        return request.get('/paanteon/conversations');
    },

    getConversation: function(conversationId: number) {
        return request.get('/paanteon/conversations/' + conversationId);
    },

    search: function(search: string) {
        return request.get('/paanteon/search?search=' + encodeURIComponent(search));
    },

    getNotifications: function(read?: boolean): Promise<{data: {unread: number, notifications: NotificationBase[]}}> {
        return request.get('/paanteon/notifications' + (read ? '?read=true' : ''));
    },

    getContactableUsers() {
        return request.get('/paanteon/conversations/contactable-users');
    },

    getPublicHomeData() {
        return request.get('/paanteon/public-home');
    },
};

export default paanteon;
