import {User} from "./types";

const localStorage = {
    /**
     * Public method used to set a local user (in local storage).
     * @param {object} user {email, password}
     * @returns {boolean}
     */
    setLocalUser: (user: User) => {
        if (window.localStorage) window.localStorage.setItem("user", JSON.stringify(user));
        return localStorage.isSetLocalUser();
    },

    /**
     * Public method used to test if there is a local user (from local storage).
     * @returns {boolean}
     */
    isSetLocalUser: function() {
        return window.localStorage && !!window.localStorage.getItem('user');
    },

    /**
     * Public method used to get the local user (from local storage).
     * @returns {object} {user}
     */
    getLocalUser: function() {
        return window.localStorage && JSON.parse(window.localStorage.getItem('user') as string);
    },

    /**
     * Public method used to delete a local user (from local storage).
     * @returns {boolean}
     */
    deleteLocalUser: function() {
        if (window.localStorage) window.localStorage.removeItem('user');
        return !this.isSetLocalUser();
    },
};

export default localStorage;
