interface ConfigInterface {
    backendStatic: string;
    backend: string;
    publicFrontendUrl: string;
    debug: boolean;
    serverErrorMessage: string;
    minimalPasswordSize: number;
    httpTimeout: number;
    httpTimeoutMessage: string;
    httpUnauthorizedMessage: string;
    loaderDelay: number;
    stripeAPIKey: string;
    forbiddenDomains: string[];
}

const config = {} as ConfigInterface;

config.publicFrontendUrl = "https://paanteon.com/";
// var backendStatic = 'http://vps.simplx.fr:7212'; // PREPROD.
config.backendStatic = 'https://paanteon.com'; // DEV.
// config.backendStatic = 'http://vps.simplx.fr:7212'; // DEV.
// config.backendStatic = 'http://192.168.68.91:7212'; // Nico's pc.
// var backendStatic = 'http://192.168.68.121:8097'; // Nico's wifi.
// config.backendStatic = 'http://localhost:7210'; // Myself.

config.backend = config.backendStatic + '/api/v1';
// Debug mode (show/hide debug messages).
config.debug = true;
// Unknown server error message.
config.serverErrorMessage = 'Internal server error !';
// Minimal password size (on register).
config.minimalPasswordSize = 5;
// HTTP Timeout for AJAX requests (ms).
config.httpTimeout = 50000;
// HTTP Timeout error message.
config.httpTimeoutMessage = 'Server is unreachable or does not respond properly (timeout) !';
// HTTP Unauthorized message.
config.httpUnauthorizedMessage = 'Access denied !';
// Loader min delay (ms).
config.loaderDelay = 300;

// Paanteon forbidden domains
config.forbiddenDomains = ['slmails.com', '@yopmail.com', '@yopmail.fr', '@yopmail.net', '@cool.fr.nf', '@jetable.fr.nf', '@courriel.fr.nf', '@moncourrier.fr.nf', '@monemail.fr.nf', '@monmail.fr.nf', '@speedmail.ze.cx', '@hide.biz.st', '@mymail.infos.st', '@sharklasers.com', '@grr.la', '@guerrillamail.biz', '@guerrilamail.com', '@guerrilamail.de', '@guerrilamail.net', '@guerrilamail.org', '@guerrilamailblock.com', '@spam4.me', 'e3b.org'];

// Test key - uncomment on local env
// config.stripeAPIKey = 'pk_test_51ItUvcKNUgl9fSntHt0bQRM1rr9zGI4meRQ3y1QQFIGySCFUm3RuAfitLlzVJ8Jn3tjHyHTbD5VcaKz2eYNG8bD400rSlqUNNM';

config.stripeAPIKey = 'pk_live_51Ix7PZB2PJec4OkypXr1Z2xb1WNfxecWYAt44rHK3XjGWwqpjM17GLIYtw33YatkpOp8xWFUrbcDA53gHnCoD4Hn001DeWdj61';

export default config;
