import * as React from 'react';
import './assets/less/main.less';
import routes from "./api/routes";
import {UIRouter, UIView, pushStateLocationPlugin, UIRouterReact} from "@uirouter/react";
import {IntlProvider, addLocaleData} from "react-intl";
// @ts-ignore
import LoadingBar from "./components/LoadingBar"
// @ts-ignore
import {NotificationContainer} from "react-notifications";
import request from "./api/request";
import * as moment from "moment";
import ScrollToTop from "./components/ScrollToTop";
import CookieBanner from "./components/CookieBanner";
import { withProvideTribe } from './components/Tribes/tribeProvider';

const routerConfig = (router: UIRouterReact) => {
    router.urlService.rules.otherwise('/home/');

    router.transitionService.onFinish({}, (t) => {
      const from = t.from().name;
      if (from) document.body.classList.remove(...from.split('.'));
      const to = t.to().name;
      if (to) document.body.classList.add(...to.split('.'));
    });
};

const language = navigator.language.split(/[-_]/)[0];
if (language !== 'en') require('moment/locale/' + language);
moment.locale(language);

interface StateInterface {
    messages: any;
    localeLoaded: boolean;
}

/**
 * Main APP
 */
class Paanteon extends React.Component<{}, StateInterface> {
    state = {messages: null, localeLoaded: false};

    componentDidMount(): void {
        import('react-intl/locale-data/fr').then(res => {
            addLocaleData(res.default);
            this.setState({
                localeLoaded: true,
            });
        });
        import('./lang/fr').then(res => {
            this.setState({
                messages: res.default,
            });
        });
        if (language !== 'fr')
            import('./lang/' + language).then(res => {
                this.setState({
                    messages: res.default,
                });
            }, function(error) {
                // eslint-disable-next-line no-console
                console.log("Language not supported, fallback to french");
            });
    }

    /**
     * Renders the router, the routes and the static elements
     */
    render() {
        if (!this.state.messages || !this.state.localeLoaded) return null;
        return (
            <IntlProvider locale={language} defaultLocale="fr" messages={this.state.messages}>
                <>
                  <UIRouter plugins={[pushStateLocationPlugin]} states={routes} config={routerConfig}>
                    <>
                      <LoadingBar color="#070f18" background="#37a8db" height={3} onRef={(ref: LoadingBar) => {
                        request.setLoadingInterceptor(ref)
                      }}/>
                      <NotificationContainer/>
                      <UIView/>
                      <CookieBanner />
                      <ScrollToTop/>
                      <div id="modals" />
                    </>
                  </UIRouter>
                </>
            </IntlProvider>);
    }
}

export default withProvideTribe(Paanteon);
