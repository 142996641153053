import React from "react";
import {FormattedMessage} from "react-intl";
import {invalidateStep0, validateStep1} from "../../api/certificationRequests";
import Notification from "../../api/notification";
import {acceptableDocuments} from "../../api/domHelper";
import {pictoCloseBlue} from "../../api/pictos";

type Props = {
    nextStep(event: React.SyntheticEvent): any;
    prevStep(event: React.SyntheticEvent): any;
};

interface StateInterface {
    confirmCC: boolean;
    files: { cniFront?: string, cniBack?: string, passport?: string };
    filesNames: { cniFront?: string, cniBack?: string, passport?: string },
}

class Step1 extends React.Component<Props, StateInterface> {
    public form = React.createRef<HTMLFormElement>();
    private readers: Record<string, FileReader> = {
        cniFront: new FileReader(),
        cniBack: new FileReader(),
        passport: new FileReader(),
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            confirmCC: false,
            files: {},
            filesNames: {},
        }

        this.readers.cniFront.onloadend = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    files: {
                        ...prevState.files,
                        cniFront: this.readers.cniFront.result as string,
                    },
                }
            });
        }

        this.readers.cniBack.onloadend = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    files: {
                        ...prevState.files,
                        cniBack: this.readers.cniBack.result as string,
                    },
                }
            });
        }

        this.readers.passport.onloadend = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    files: {
                        ...prevState.files,
                        passport: this.readers.passport.result as string,
                    },
                }
            });
        }
    }

    get canSubmit(): boolean {
        return (!!this.form.current && this.form.current.checkValidity())
            && ((!!this.state.files.cniBack && !!this.state.files.cniFront) || !!this.state.files.passport);
    }

    uploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && this.readers[e.target.name]) {
            const name = e.target.name;
            const fileName = e.target.files![0].name;

            this.readers[name].readAsDataURL(e.target.files[0]);
            this.setState((prevState) => {
                return {
                    ...prevState,
                    filesNames: {
                        ...prevState.filesNames,
                        [name]: fileName,
                    },
                }
            });
        }
    };

    prevStep = (ev: React.SyntheticEvent) => {
        invalidateStep0().then(() => {
            this.props.prevStep(ev);
        }, () => {
            Notification.error("Erreur impossible de revenir sur la page précédente");
        });
    }

    nextStep = (ev: React.SyntheticEvent) => {
        ev.preventDefault();
        validateStep1(this.state.files).then(() => {
            this.props.nextStep(ev);
        }, () => {
            Notification.error("Erreur impossible d'envoyer vos documents");
        });
    }

    deleteFile = (name: string) => {
        this.setState(prevState => ({
            files: {
                ...prevState.files,
                [name]: undefined,
            },
            filesNames: {
                ...prevState.filesNames,
                [name]: undefined,
            },
        }));
    }

    render() {
        return (
            <div className="uk-container main-content-s">
                <div className="steps">
                    <div className="step current">1</div>
                    <div className="sep"></div>
                    <div className="step">2</div>
                    <div className="sep"></div>
                    <div className="step">3</div>
                </div>
                <h2 className="title-3 h-center text-uppercase margin-large-top"><FormattedMessage id="certification.sendYourFiles"/></h2>
                <form name="authenticityForm" onSubmit={this.nextStep} ref={this.form}>
                    <div>
                        <p className="body text-center">Téléchargez ici une copie ou des photos de votre carte d'identité (recto-verso) ou la double page de votre passeport.</p>
                        <p className="body text-center">Avant de les télécharger, merci de vous assurer de la lisibilité de vos informations.</p>
                    </div>
                    <div>
                        <h3 className="title-6 text-uppercase text-light-blue">
                            <FormattedMessage id="certification.cni"/>
                        </h3>
                        <div className="body-m margin">
                            <div>
                                <label className={"input-btn btn-outlined margin-right" + (this.state.files.cniFront ? " active" : "")} title="Face avant de votre carte d'identité">
                                    <input type="file" name="cniFront"
                                           onChange={this.uploadChange} accept={acceptableDocuments}/>
                                    Ajouter un fichier
                                </label>
                                <FormattedMessage id="certification.uploadFormat"/>
                            </div>
                            {
                                this.state.files.cniFront && <div className="margin-top-s">
                                    { this.state.filesNames.cniFront } <button type="button" className="btn btn-icon btn-close" onClick={() => this.deleteFile("cniFront")}><img className="picto" src={pictoCloseBlue} alt="close"/></button>
                                </div>
                            }
                        </div>
                        <div className="body-m margin">
                            <div>
                                <label className={"input-btn btn-outlined margin-right" + (this.state.files.cniBack ? " active" : "")} title="Face arrière de votre carte d'identité">
                                    <input type="file" name="cniBack"
                                           onChange={this.uploadChange} accept={acceptableDocuments}/>
                                    Ajouter un fichier
                                </label>
                                <FormattedMessage id="certification.uploadFormat"/>
                            </div>
                            {
                                this.state.files.cniBack && <div className="margin-top-s">
                                    { this.state.filesNames.cniBack } <button type="button" className="btn btn-icon btn-close" onClick={() => this.deleteFile("cniBack")}><img className="picto" src={pictoCloseBlue} alt="close"/></button>
                                </div>
                            }
                        </div>
                        <h3 className="title-6 text-uppercase text-light-blue">
                            <FormattedMessage id="certification.passport"/>
                        </h3>
                        <div className="body-m">
                            <div>
                                <label className={"input-btn btn-outlined margin-right" + (this.state.files.passport ? " active" : "")} title="Double page de votre passport">
                                    <input type="file" name="passport"
                                           onChange={this.uploadChange} accept={acceptableDocuments}/>
                                    Ajouter un fichier
                                </label>
                                <FormattedMessage id="certification.uploadFormat"/>
                            </div>
                            {
                                this.state.files.passport && <div className="margin-top-s">
                                    { this.state.filesNames.passport } <button type="button" className="btn btn-icon btn-close" onClick={() => this.deleteFile("passport")}><img className="picto" src={pictoCloseBlue} alt="close"/></button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="margin-large">
                        <div className="margin-large flex space-between">
                            <button onClick={this.prevStep} type="button"
                                    className="btn btn-outlined black btn-big btn-uppercase">
                                Retour
                            </button>
                            <button type="submit" disabled={!this.canSubmit}
                                    className="btn btn-1 btn-big btn-uppercase">
                                Envoyer et passer à l'étape suivante
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Step1;
