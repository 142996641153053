import React, {Component} from 'react';
import Modal from "./Modal";
import auth from "../api/authentication";
import Notification from "../api/notification";

type Props = {
    onModalClose():any
    userId: number
    userEmail: string
    oldUserEmail: string
};

type State = {
    password: string
};

class ChangePasswordModal extends Component<Props, State> {
    readonly state: State = {
        password: "",
    }

    formatUserEmail = (email: string): string => {
        const splittedMail = email.split('@');
        return splittedMail[0][0] + '*'.repeat(splittedMail[0].length - 1) + '@' + splittedMail[1];
    }

    changeMail = () => {
        auth.updateUserById({userId: this.props.userId, email: this.props.userEmail, password: this.state.password}).then(() => {
            Notification.success(`Vous avez modifié votre identifiant. Consultez votre boite email « ${this.formatUserEmail(this.props.oldUserEmail)} » pour valider cette modification. Si cette adresse mail n’existe plus, contactez-nous`);
            this.props.onModalClose();
        }, () => {
            Notification.error("Le mot de passe est erroné")
        });
    }

    updatePassword = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const password = event.currentTarget.value;
        this.setState({password});
    }

    render() {
        return (
            <Modal closeButton onClose={this.props.onModalClose}>
                <div className="pop-up-title">
                    Changement de votre adresse mail pour "{this.props.userEmail}"
                </div>

                <div className="margin">
                    <label>Votre mot de passe actuel</label>
                    <input type="password" className="input block" placeholder="Votre mot de passe" onChange={this.updatePassword}/>
                </div>

                <div className="pop-up-buttons">
                    <button className="btn btn-outlined" onClick={this.props.onModalClose}>Annuler</button>
                    <button className="btn btn-1" onClick={this.changeMail}>Valider</button>
                </div>
            </Modal>
        );
    }
}

export default ChangePasswordModal;
