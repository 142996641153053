import React, {Component} from 'react';
import {pictoCloseBlue} from "../api/pictos";

type Props = {
    children: React.ReactNode
    closeButton?: boolean
    onClose?(): any
}

class Modal extends Component<Props> {
    stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    closeModal = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (this.props.onClose) this.props.onClose();
    }

    render() {
        return (
            <div className="pop-up-background" onClick={this.closeModal}>
                <div className="pop-up" onClick={this.stopPropagation}>
                    {
                        this.props.closeButton && <div><button className="btn btn-icon btn-close" onClick={this.props.onClose}><img className="picto" src={pictoCloseBlue} alt="close"/></button></div>
                    }
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Modal;
