import * as React from "react";
// import JoditEditor from "jodit-react";
import {IJodit, Jodit} from "jodit";
import "jodit/build/jodit.css"

interface OwnProps {
    children: JSX.Element
    onChange?(html: string): any
    value?: string
    placeholder?: string
}

type Props = OwnProps;

// @ts-ignore
const editorConfig: IJodit['options'] = {
    buttons:  [
        'bold',
        'strikethrough',
        'underline',
        'italic',
        'eraser',
        '|',
        'superscript',
        'subscript',
        '|',
        'ul',
        'ol',
        '|',
        'outdent',
        'indent',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'table',
        'link',
        '|',
        'align',
        'undo',
        'redo',
        'fullsize',
        'preview',
    ],
    sizeLG: 0,
    language: 'fr',
    iframe: false,
    // @ts-ignore
    showXPathInStatusbar: false,
    showWordsCounter: false,
    showCharsCounter: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    defaultActionOnPaste: "insert_clear_html",
    defaultActionOnPasteFromWord: "insert_clear_html",
    // TODO: add this when spellcheck works
    // spellcheck: false,
};

export default class RTE extends React.Component<Props> {
    htmlElement: React.RefObject<HTMLTextAreaElement> = React.createRef();
    protected randId = Math.floor(Math.random() * 100000);
    protected editor = null;

    componentDidMount(): void {
        // @ts-ignore
        const conf: IJodit['options'] = {...editorConfig, placeholder: this.props.placeholder ? this.props.placeholder : "Votre message"};
        // @ts-ignore
        this.editor = Jodit.make(this.htmlElement.current, conf);
        if (this.editor !== null) {
            // @ts-ignore
            this.editor.value = this.props.value;
            // @ts-ignore
            this.editor.events.on('blur', value => this.props.onChange && this.props.onChange((value as unknown as React.FocusEvent<HTMLDivElement>).target.innerHTML))
        }

        // TODO: remove Jodit's shit when spellcheck works
        for (const node of document.querySelectorAll('.jodit-wysiwyg[spellcheck=true]')) {
            node.setAttribute('spellcheck', 'false');
        }
    }

    render() {
        return <div style={{color: 'black'}} className="rte">
            {/* <JoditEditor value={this.props.value || ''} ref={''} config={conf} onBlur={(newContent) => this.props.onChange && this.props.onChange((newContent as any as React.FocusEvent<HTMLDivElement>).target.innerHTML)}/>*/}
            <textarea id={"rte-" + this.randId} ref={this.htmlElement} />
        </div>
    }
}
