import request from "./request";
import localStorage from "./localStorage"
import {User} from "./types";

const auth = {
    /**
     *
     * @param {object} user {email, password}
     * @returns {Promise<{isFirstLogin: boolean}>}
     */
    login: async (user: {email: User['email'], password: User['password']}): Promise<{isFirstLogin: boolean, nbTributes:number, user:User, friendsCount: number}> => {
        return new Promise((resolve, reject) => {
            request.post("/auth/login", user).then(result => {
                auth.checkSecurity().then(function() {
                    localStorage.setLocalUser(result.data.user);
                    resolve(result.data);
                }, function(error: any) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    reject(error);
                });
            }, err => {
                // eslint-disable-next-line no-console
                console.log(err);
                reject(err);
            });
        });
    },

    logout: () => {
        return new Promise((resolve, reject) => {
            request.get("/auth/logout").then(function() {
                localStorage.deleteLocalUser();
                resolve();
            }, function() {
                localStorage.deleteLocalUser();
                reject();
            });
        })
    },

    checkSecurity: () => {
        return request.post("/auth/security");
    },

    checkAuthenticated: () => {
        return request.get('/auth/authenticated');
    },

    lostPassword: function(email: string) {
        return request.post('/users/reset-pwd', {
            email: email,
        }, false);
    },

    /**
     * Public method used to reset the user password (via guid).
     * @param {string} guid
     * @param {string} password
     * @returns {object} promise->{void}
     * @description
     *	No authent needed,
     *	200: success,
     *	500: server error.
     */
    resetPassword: function(guid: string, password: string) {
        return request.post('/users/update-pwd', {
            guid: guid,
            newPwd: password,
        }, false);
    },

    /**
     * Public method used to reset the user password.
     * @returns {object} promise->{void}
     * @description
     *    No authent needed,
     *    200: success,
     *  400: invalid data,
     *  409: user already exists,
     *    500: server error.
     * @param user
     */
    registerUser: function(user: User) {
        return request.post('/users', user, false);
    },

    sendRegisterMail: function(user: {email: User['email']}) {
        return request.post('/users/registration-mail', user, false);
    },

    /**
     * Public method used to validate a user registration.
     * @param {string}
     * @returns {object} promise->{void}
     * @returns {object} promise->{void}
     * @description
     *	No authent needed,
     *	200: success,
     *	500: server error.
     */
    validateUserRegistration: function(guid: string) {
        return request.get('/users/validate/' + guid, false);
    },

    getUserById: function(userId: number, light?: boolean) {
        return request.get('/users/' + userId + (light ? '?light=true' : ''), false);
    },

    updateUserById: function(user: Partial<User> & {coverImg?: string, profileImg?: string}) {
        return request.put('/users/' + user.userId, user, false);
    },

    updatePassword(userId: number, pwd: {newPwd: string, oldPwd: string}) {
        return request.put(`/users/${userId}/update-pwd`, pwd);
    },

    deleteAccount(userId: number) {
        return request.delete(`/users/${userId}`);
    },
};

export default auth;
