import React, {PureComponent} from 'react';

interface OwnProps {
    children: JSX.Element[]
}

type Props = OwnProps;

type State = Readonly<{
    opened: number,
}>;

class Toggleable extends PureComponent<Props, State> {
    readonly state: State = {
        opened: -1,
    };
    protected listener = async (ev: MouseEvent) => {
        if (this.state.opened !== -1) {
            if (!this.elements[this.state.opened].contains(ev.target as HTMLElement)) {
                this.setState(prevState => ({
                    ...prevState,
                    opened: -1,
                }));
            }
        }
    };
    protected static body = document.body;
    protected elements: HTMLElement[] = [];


    toggle = (index: number) => {
        this.setState(prevState => {
            return {
                opened: prevState.opened === index ? -1 : index,
            };
        });
    };

    componentDidMount() {
        Toggleable.body.addEventListener('click', this.listener);
    }

    componentWillUnmount() {
        Toggleable.body.removeEventListener('click', this.listener);
    }

    render() {
        return this.props.children.map((child, index) => {
            return React.cloneElement(child, {
                className: child.props.className + (this.state.opened === index ? " opened" : ""),
                onClick: (e: React.SyntheticEvent) => this.toggle(index),
                ref: (node: HTMLElement) => {
                    this.elements[index] = node;
                },
            });
        });
    }
}

export default Toggleable;
