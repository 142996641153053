import React, {Component} from 'react';
import {pictoHeart, pictoHeartBlue, pictoSpeechBubbleBlue, pictoSpeechBubbleFilledBlue} from "../../api/pictos";
import {CommentToSend, Publication, User} from "../../api/types";
import Comments from "../Comments";
import localStorage from "../../api/localStorage";
import ShareModal from "../ShareModal";
import {CommentOpenable} from "./types";

type Props = Readonly<{
    publication: Publication
    onLike(): any
    onShare(): any
    onComment(comment: CommentToSend): any
    onDeleteComment(commentId: number): any
}> & CommentOpenable;

type State = {
    sharePopupOpen: boolean
    commentOpened: boolean
};

class PublicationFooter extends Component<Props, State> {
    private user: User = localStorage.getLocalUser();
    state = {
        sharePopupOpen: false,
        commentOpened: !!this.props.commentOpen,
    };

    openSharePopup = () => {
        this.setState(prevState => ({
            ...prevState,
            sharePopupOpen: true,
        }));
    };

    onShareSent = () => {
        this.setState(prevState => ({
            ...prevState,
            sharePopupOpen: false,
        }));
        this.props.onShare();
    };

    toggleComment = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState(prevState => ({
            ...prevState,
            commentOpened: !prevState.commentOpened,
        }));
    };

    stopPropagation = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    render() {
        return (
            <div className="feed-footer" onClick={this.stopPropagation}>
                <div style={{width: '100%'}}>
                    <span style={{color: "#37a8db"}}>
                        <button className="btn btn-icon" onClick={this.props.onLike}>
                            {
                                this.props.publication.liked ?
                                    <img src={pictoHeart}
                                         className="picto blue"
                                         alt="picto heart"/>
                                    :
                                    <img src={pictoHeartBlue}
                                         className="picto blue"
                                         alt="picto heart"/>
                            }
                            <span className="regular" style={{color: "#37a8db"}}>{this.props.publication.likeCount}</span>
                        </button>
                    </span>
                    <span>
                        <button className="btn btn-icon" onClick={this.toggleComment} style={{color: "#37a8db"}}>
                            <img src={this.props.publication.commented ? pictoSpeechBubbleFilledBlue : pictoSpeechBubbleBlue}
                                 className="picto blue"
                                 alt="picto-speech-bubble-blue"/>
                            <span className="regular">{this.props.publication.Comments ? this.props.publication.Comments.length : -1}</span>
                        </button>
                    </span>
                    <span onClick={this.stopPropagation}>
                        <ShareModal onSent={this.onShareSent} publication={this.props.publication} shareCount={this.props.publication.shareCount} shared={this.props.publication.shared === 1}/>
                    </span>
                </div>

                {
                    this.state.commentOpened && <Comments onDelete={this.props.onDeleteComment} comments={this.props.publication.Comments} selfUser={this.user} onSend={this.props.onComment}/>
                }
            </div>
        );
    }
}

export default PublicationFooter;
