import React, {Component} from 'react';
import Modal from "./Modal";

type Props = {
    children: React.ReactNode
    onCancel(): any
    onConfirm(): any
    cancelMessage?: string
    confirmMessage?: string
}

class ConfirmModal extends Component<Props> {
    render() {
        return (
            <Modal closeButton onClose={this.props.onCancel}>
                <div className="pop-up-title centered">
                    {this.props.children}
                </div>

                <div className="pop-up-buttons centered">
                    <button className="btn btn-outlined" onClick={this.props.onCancel}>{this.props.cancelMessage || 'Annuler'}</button>
                    <button className="btn btn-1" onClick={this.props.onConfirm}>{this.props.confirmMessage || 'Supprimer'}</button>
                </div>
            </Modal>
        );
    }
}

export default ConfirmModal;
