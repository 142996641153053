import React, {Component} from 'react';
import {Link} from "../../api/injectors";
import {Publication} from "../../api/types";
import filterHelper from "../../api/filterHelper";
import PublicationPost from "../PublicationPost";
import {deleteFromArrayAndReturn} from "../../api/helpers";
import Helmet from "../../components/Helmet";

type Props = {
    publications: {data: Publication[] }
}

type State = {
    publications: Publication[]
    search: string
    searchOnIds: boolean
}

class Publications extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            publications: props.publications.data,
            search: '',
            searchOnIds: false,
        }
    }

    get search(): Publication[] {
        return this.state.searchOnIds
            ? this.state.publications.filter(publication => publication.publicationId.toString(10) === this.state.search)
            : filterHelper.deepSearch(this.state.publications, this.state.search);
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    onDelete = (publicationId: number) => {
        this.setState(prevState => ({
            ...prevState,
            publications: deleteFromArrayAndReturn(prevState.publications, prevState.publications.findIndex(p => p.publicationId === publicationId)),
        }));
    }

    toggleSearchOnIds = () => {
        this.setState(prevState => ({
            ...prevState,
            searchOnIds: !prevState.searchOnIds,
        }));
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'Publications | Paanteon'} />
                            <h1 className="page-title">
                                Publications ({this.state.publications.length})
                            </h1>

                            <div>
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>

                                <label>
                                    <input type="checkbox" checked={this.state.searchOnIds} onChange={this.toggleSearchOnIds}/>
                                    Rechercher uniquement sur les IDs
                                </label>

                                <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                            </div>

                            <div className="uk-grid uk-grid-small gallery-grid margin-top">
                                {
                                    this.search.map(publication => {
                                        return <div className="uk-width-1-1">
                                            <span>ID: {publication.publicationId}</span>
                                            <PublicationPost onDelete={this.onDelete} publication={{data: publication}}/>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Publications;
