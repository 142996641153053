import * as React from "react";

/**
 * Generic page component
 */
class Page<P = {}, S = {}> extends React.PureComponent<P, S> {
    /**
     * Scroll the page to the top when routing
     */
    componentDidMount() {
        window.scrollTo(0, 0);
    }
}

export default Page;
