import React, {useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {createCertificationIntent} from "../api/certificationRequests";
import {Stripe, StripeCardElement, StripeCardElementChangeEvent, StripeElements} from '@stripe/stripe-js';
import loader from "../assets/img/loader.gif";

const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#32325d",
            },
        },
        invalid: {
            iconColor: '#FFC7EE',
            color: '#FFC7EE',
        },
    },
};

type Props = {
    onSucceed: () => void,
}

function CheckoutForm({onSucceed}: Props) {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [processing, setProcessing] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const stripe = useStripe() as Stripe;
    const elements = useElements() as StripeElements;

    useEffect(() => {
        createCertificationIntent().then(res => {
            setClientSecret(res.data.clientSecret);
            setProcessing(false);
        })
    }, []);

    const handleChange = (event: StripeCardElementChangeEvent) => {
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    }

    const handleSubmit = async (ev: React.FormEvent) => {
        ev.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement) as StripeCardElement,
            },
        });

        if (payload.error) {
            setError(`Payment failed ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            onSucceed();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="payment-card">
                <div className="card-header">
                    Carte bancaire
                </div>
                <div>
                    <CardElement options={cardStyle} onChange={handleChange} />
                </div>
                {
                    error && <div role={"alert"} className="text-red">
                        {error}
                    </div>
                }
            </div>

            <div className="margin-large">
                <div className="margin-large right">
                    <button disabled={processing || disabled || succeeded}
                            className="btn btn-1 btn-big btn-uppercase">
                        { processing ? <img src={loader} alt={"loader"}/> : 'J\'adhère' }
                    </button>
                </div>
            </div>
        </form>
    )
}

export default CheckoutForm;
