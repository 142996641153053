import React, { ChangeEvent, useCallback, useState } from "react";
import { MetaTribe, Criteria as CriteriaType, Gallery, TagCategory } from "../../api/types";
import Criteria from "../../components/Tribes/Criteria";

const NewTribeForm = (
    {
        metaTribe,
        galleries,
        tagCategories,
        onSubmit,
    }: {
        metaTribe: MetaTribe,
        galleries: {data: Gallery[]},
        tagCategories: {data: TagCategory[]},
        onSubmit: (label: string, criteria: CriteriaType[], minCriteria: number)=>void,
    }):JSX.Element => {
    const [newTribeLabel, setNewTribeLabel] = useState<string>("");
    const [newTribeCriterias, setNewTribeCriterias] = useState<Array<CriteriaType>>([{collectionId: null, tagId: null}])
    const [newMinCriteria, setNewMinCriteria] = useState<number>(1);

    const newCriteriaCollection = useCallback((e: ChangeEvent<HTMLSelectElement>, criteriaIdx: number) => {
        const val = e.target.value;
        const newCriterias = [...newTribeCriterias];
        newCriterias[criteriaIdx].collectionId = val === "-1" ? null : parseInt(val);
        setNewTribeCriterias(newCriterias);
    }, [newTribeCriterias])

    const newCriteriaTag = useCallback((e: ChangeEvent<HTMLSelectElement>, criteriaIdx: number) => {
        const val = e.target.value;
        const newCriterias = [...newTribeCriterias];
        newCriterias[criteriaIdx].tagId = val === "-1" ? null : parseInt(val);
        setNewTribeCriterias(newCriterias);
    }, [newTribeCriterias])

    function resetForm() {
        setNewTribeLabel("");
        setNewTribeCriterias([]);
        setNewMinCriteria(1);
    }

    return (
        <div uk-grid="" className="uk-grid">
            <div className="uk-width-1-1 margin-bottom">
                Ajouter une tribu
            </div>
            <div>
                <label className="padding-h">Nom de la tribu</label>
                <input className="input" onChange={e => setNewTribeLabel(e.target.value)} value={newTribeLabel}/>
            </div>
            <div className="margin-bottom">
                {
                    newTribeCriterias.map((criteria, index) => {
                        return <Criteria
                                    key={index}
                                    criteria={criteria}
                                    galleries={galleries}
                                    index={index}
                                    nbCriteria={newTribeCriterias.length}
                                    newCriteriaCollection={newCriteriaCollection}
                                    newCriteriaTag={newCriteriaTag}
                                    tagCategories={tagCategories}
                                    removeCriteria={(criteriaIndex) =>
                                        setNewTribeCriterias((prev) =>
                                        prev.filter((c, i) =>
                                        i !== criteriaIndex
                                        ))
                                    }
                                />
                    })
                }
                <button
                    className="btn btn-1"
                    onClick={
                        () =>
                        setNewTribeCriterias((prev) =>
                            [...prev, {collectionId: null, tagId: null}]
                        )
                    }
                >
                    Ajouter un critère
                </button>
            </div>
            <div className="flex-colomn">
                <label className="padding-h">Nombre de critères minimum</label>
                <input
                    type="number"
                    className="input"
                    onChange={(event)=>
                        setNewMinCriteria(parseInt(event.target.value))
                    }
                    value={newMinCriteria}
                />
            </div>
            <div>
                <button
                    className="btn btn-1"
                    onClick={()=>{
                        onSubmit(newTribeLabel, newTribeCriterias, newMinCriteria);
                        resetForm();
                    }}
                    disabled={
                        newTribeLabel === "" ||
                        !!newTribeCriterias.find((criteria) =>
                        (criteria.collectionId === null || criteria.collectionId === -1)
                        && (criteria.tagId === null || criteria.tagId === -1)
                        ) || newMinCriteria < 1
                    }
                >
                    Créer
                </button>
            </div>
        </div>
    )
};

export default NewTribeForm;
