import React, {ReactElement} from 'react';
import {Collection, Gallery, Thumbnail, Tribe, UserInfos} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import localStorage from "../api/localStorage";
import {UIView} from "@uirouter/react";
import Page from "./Page";
import {Link} from "../api/injectors";
import Helmet from "../components/Helmet";

interface OwnProps {
    user: UserInfos
    discover: (Thumbnail & {Tributes: []})[]
    popular: (Thumbnail & {Tributes: []})[]
    paanteon?: (Gallery & { userCollections: Collection[] })[]
    userTribes?: {Tribes : Tribe[], userId: number, totalUser: number}
}

type Props = OwnProps;

type State = Readonly<{}>;

const GalleryContext = React.createContext<(Gallery & { userCollections: Collection[] })[]>([]);

type PaanteonUserPageProps = {
    user: UserInfos
    discover: (Thumbnail & { Tributes: [] })[]
    popular: (Thumbnail & { Tributes: [] })[]
    galleries: (Gallery & { userCollections: Collection[] })[]
    isMyPaanteon: boolean
    userTribes?: {Tribes : Tribe[], userId: number, totalUser: number}
}

function PaanteonUserPage(props: PaanteonUserPageProps): ReactElement {
    return <div className="dark-bg">
        <div className="uk-container">
            <Helmet title={props.isMyPaanteon ? 'Mon Paanteon | Paanteon' : `Le Paanteon de ${props.user.firstname} ${props.user.lastname} | Paanteon`} />
            <div className="page-head" style={{backgroundImage: 'url(' + props.user.coverPath + ')'}}>
                {props.isMyPaanteon ?
                    <h1><span className="text-finish">Mon Paanteon</span></h1>
                    :
                    <h1>Le Paanteon <span className="ptn-of">de {props.user.firstname} {props.user.lastname}</span></h1>
                }
            </div>
            <UserSidebar user={props.user} popular={props.popular} discover={props.discover} showLastTributes={true}
                         showCover={false} showButtons={true} showBio={true} collapsible={true}/>

            <div className="main-content">
                <div className="uk-container">
                    <GalleryContext.Provider value={props.galleries}>
                        <UIView/>
                    </GalleryContext.Provider>
                </div>
            </div>
        </div>
    </div>
}

class PaanteonUser extends Page<Props, State> {
    private isMyPaanteon = this.props.user.userId === localStorage.getLocalUser().userId;
    galleries = this.props.paanteon ? this.props.paanteon.map((g) => {
        g.userCollections = g.Collections.filter((c) => {
            return c.Tributes && c.Tributes.length;
        });
        return g;
    }) : [];

    render() {
        if (this.props.paanteon)
            return (
                <PaanteonUserPage user={this.props.user} discover={this.props.discover} popular={this.props.popular}
                                  isMyPaanteon={this.isMyPaanteon} galleries={this.galleries} userTribes={this.props.userTribes}/>
            );
        else // @TODO SIMON handle axios error 409 like in Tribute Details. this is a quick fix
            return <div className="black-bg" style={{minHeight: '65vh'}}>
                <div className="uk-container">
                    {
                        typeof this.props.user === 'object' && <UserSidebar user={this.props.user} showCover showButtons={true} showBio={true} collapsible={true} popular={this.props.popular} discover={this.props.discover}/>
                    }
                    <div className="main-content">
                        <div className="pop-up page">
                            <div className="pop-up-title centered">
                                Le profil de ce membre est privé. L’accès à son paanteon est fermé ou réservé à une communauté restreinte. Vous avez toujours la possibilité de demander ce membre en ami ou bien de le suivre pour voir ses publications.
                            </div>

                            <div className="h-center">
                                <Link to="connected.home" className="btn btn-outlined black">Retour</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    }

    componentDidMount() {
        super.componentDidMount();
        document.body.classList.add(this.isMyPaanteon ? 'my-paanteon' : 'another-paanteon')
    }

    componentWillUnmount() {
        document.body.classList.remove(this.isMyPaanteon ? 'my-paanteon' : 'another-paanteon')
    }
}

export {GalleryContext};
export default PaanteonUser;
