import React, {ReactElement, ReactNode} from 'react';
import Page from "./Page";
import {
    NotificationAnswerComment,
    NotificationBase,
    NotificationCommentPublication,
    NotificationCommentTestimonial,
    NotificationCommentTribute,
    NotificationFollow,
    NotificationFriendship,
    NotificationLikePublication,
    NotificationLikeTestimonial,
    NotificationLikeTribute, NotificationMentionPublication,
    NotificationPaanteon,
    NotificationSharePublication,
    NotificationShareTribute,
    UserInfos,
    UserNotificationSettingValue,
    Tribe,
} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import moment from "moment";
import {
    pictoFriendsFull,
    pictoHeart,
    pictoPaanteonLightBlue,
    pictoSharingFullBlue,
    pictoSpeechBubbleFilledBlue,
} from "../api/pictos";
import {Link} from "../api/injectors";
import {updateUser} from "../components/PublicHeader";
import ReportModal from "../components/ReportModal";
import OptionsMenu from "../components/OptionsMenu";
import shave from "shave";
import {hasDisabledNotifications} from "../api/user";
import {processLinks, processMentions} from "../api/domHelper";
import Helmet from "../components/Helmet";

type Props = {
    selfUser: {data: UserInfos}
    notifications: {data: {unread: number, notifications: NotificationBase[]}}
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = {
}

function NotifBase(props: {date: NotificationBase['date'], profilePic: string, icon: string, children: ReactNode, unread?: boolean}) {
    const date = moment(props.date);
    return <div className={"tile tile-notif" + (props.unread ? ' unread' : '')}>
        <div className="tile-body">
            <img src={props.icon} className="picto picto-notif" alt=""/>
            <div className="notif-body">
                <div className="profile-pic" style={{backgroundImage: `url(${props.profilePic})`}}/>
                <div className="notif-date">
                    {
                        date.isBefore(moment().subtract(1, 'weeks'))
                            ?
                            date.format('LL')
                            :
                            date.fromNow()
                    }
                </div>
                <div className="notif-desc">
                    {props.children}
                </div>
            </div>


        </div>
    </div>
}

function getAnswerCommentRoute(notif: NotificationAnswerComment): {name: string, params: Record<string, any>} {
    switch (notif.object) {
        case "publicationId":
            return {name: "connected.inspiration-feed.publication", params: {publicationId: notif.publicationId as number}}
        case "testimonialId":
            return {name: "connected.in-memoriam.details", params: {testimonialId: notif.testimonialId as number, memoriamId: "inMemoriamId" in notif ? notif.inMemoriamId : null, memoriamUniqueName: notif.uniqueName}}
        case "tributeId":
            return {name: "connected.paanteon.tribute.details", params: {tributeId: notif.tributeId as number, thumbnailUniqueName: notif.uniqueName, username: notif.username}}
    }
}

function Notification(props: {notification: NotificationBase}): ReactElement | null {
    const name = `${props.notification.firstname} ${props.notification.lastname}`;
    const unread = props.notification.unread === 1; // Used to check if notification was viewed

    switch (props.notification.type) {
        case "answerComment": {
            const notif = props.notification as NotificationAnswerComment;
            const route = getAnswerCommentRoute(notif);
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSpeechBubbleFilledBlue}>
                <OptionsMenu>
                    <span className="options-menu">
                        <ul>
                            <li>
                                <ReportModal commentId={notif.commentId}><button
                                    className="btn link-btn">Signaler</button></ReportModal>
                            </li>
                        </ul>
                    </span>
                </OptionsMenu>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a répondu à votre commentaire :
                <Link to={route.name} params={{...route.params, '#': `comment${notif.commentId}`}}>
                    <p className="notif-content">{notif.content}</p>
                </Link>
            </NotifBase>
        }
        case "likeTestimonial": {
            const notif = props.notification as NotificationLikeTestimonial;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoHeart}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a aimé votre témoignage sur le livre d'or de <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: notif.uniqueName, memoriamId: notif.inMemoriamId, '#': `testimonial${notif.testimonialId}`}}>{notif.label}</Link>
            </NotifBase>
        }
        case "commentTestimonial": {
            const notif = props.notification as NotificationCommentTestimonial;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSpeechBubbleFilledBlue}>
                <OptionsMenu>
                    <span className="options-menu">
                        <ul>
                            <li>
                                <ReportModal commentId={notif.commentId}><button
                                    className="btn link-btn">Signaler</button></ReportModal>
                            </li>
                        </ul>
                    </span>
                </OptionsMenu>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a commenté votre témoignage sur le livre d'or de <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: notif.uniqueName, memoriamId: notif.inMemoriamId, '#': `comment${notif.commentId}`}}>{notif.label}</Link> :
                <p className="notif-content">{notif.content}</p>
            </NotifBase>
        }
        case "likePublication": {
            const notif = props.notification as NotificationLikePublication;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoHeart}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a aimé votre <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId}}>publication</Link>
                {
                    !!notif.content && notif.content.length && <> : <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId}}><p className="notif-content">{notif.content}</p></Link></>
                }
            </NotifBase>
        }
        case "sharePublication": {
            const notif = props.notification as NotificationSharePublication;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSharingFullBlue}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a partagé votre <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId}}>publication</Link> :
                <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId}}><p className="notif-content">{notif.content}</p></Link>
            </NotifBase>
        }
        case "commentPublication": {
            const notif = props.notification as NotificationCommentPublication;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSpeechBubbleFilledBlue}>
                <OptionsMenu>
                    <span className="options-menu">
                        <ul>
                            <li>
                                <ReportModal commentId={notif.commentId}><button
                                    className="btn link-btn">Signaler</button></ReportModal>
                            </li>
                        </ul>
                    </span>
                </OptionsMenu>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a commenté votre publication :
                <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId, '#': `comment${notif.commentId}`}}><p className="notif-content">{notif.content}</p></Link>
            </NotifBase>
        }
        case "commentTribute": {
            const notif = props.notification as NotificationCommentTribute;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSpeechBubbleFilledBlue}>
                <OptionsMenu>
                    <span className="options-menu">
                        <ul>
                            <li>
                                <ReportModal commentId={notif.commentId}><button
                                    className="btn link-btn">Signaler</button></ReportModal>
                            </li>
                        </ul>
                    </span>
                </OptionsMenu>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a commenté votre hommage {notif.label}:
                <Link to="connected.paanteon.tribute.details" params={{tributeId: notif.tributeId, thumbnailUniqueName: notif.uniqueName, username: notif.username, '#': `comment${notif.commentId}`}}><p className="notif-content">{notif.content}</p></Link>
            </NotifBase>
        }
        case "shareTribute": {
            const notif = props.notification as NotificationShareTribute;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSharingFullBlue}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a partagé votre hommage <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId}}>{notif.label}</Link> {!!notif.content && ':'}
                {
                    !!notif.content && <p className="notif-content">{notif.content}</p>
                }
            </NotifBase>
        }
        case "likeTribute": {
            const notif = props.notification as NotificationLikeTribute;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoHeart}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> a aimé votre hommage <Link to="connected.paanteon.tribute.details" params={{tributeId: notif.tributeId, thumbnailUniqueName: notif.uniqueName, username: notif.username}}>{notif.label}</Link>
            </NotifBase>
        }
        case "paanteon": {
            const notif = props.notification as NotificationPaanteon;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoPaanteonLightBlue}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> vous a gravé dans sa collection {notif.label}
            </NotifBase>
        }
        case "friendship": {
            const notif = props.notification as NotificationFriendship;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoFriendsFull}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> vous a accepté en ami
            </NotifBase>
        }
        case "follow": {
            const notif = props.notification as NotificationFollow;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoFriendsFull}>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId, username: notif.username}}>{name}</Link></em> vous suit
            </NotifBase>
        }
        case "mentionPublication": {
            const notif = props.notification as NotificationMentionPublication;
            return <NotifBase unread={unread} date={notif.date} profilePic={notif.picturePath} icon={pictoSpeechBubbleFilledBlue}>
                <OptionsMenu>
                    <span className="options-menu">
                        <ul>
                            <li>
                                <ReportModal commentId={notif.publicationId}><button
                                    className="btn link-btn">Signaler</button></ReportModal>
                            </li>
                        </ul>
                    </span>
                </OptionsMenu>
                <em className="ptn-em"><Link to="connected.paanteon.user" params={{userId: notif.userId}}>{name}</Link></em> vous a mentionné dans une publication :
                <Link to="connected.inspiration-feed.publication" params={{publicationId: notif.publicationId}}><p className="notif-content">{processMentions(processLinks([notif.content]), false)}</p></Link>
            </NotifBase>
        }
    }
    return null;
}

class Notifications extends Page<Props, State> {
    readonly state = {};
    componentDidMount() {
        updateUser();
        this.clampContents();
    }

    clampContents(): void {
        shave(".tile.tile-notif .notif-content", 20);
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <UserSidebar user={this.props.selfUser.data} showCover={true} />

                    <div className="main-content">
                        <div className="uk-container">
                            <div className="msg-layout">
                                <Helmet title={`Notifications (${this.props.notifications.data.unread}) | Paanteon`} />
                                <h2 className="msg-header title-1">
                                    Notifications <span className="smaller">({this.props.notifications.data.unread})</span>
                                </h2>

                                <div>
                                    {
                                        this.props.notifications.data.notifications.map((notification) => <Notification notification={notification} key={notification.key}/>)
                                    }

                                    {
                                        hasDisabledNotifications(this.props.selfUser.data.NotificationSettings, UserNotificationSettingValue.NOTIFICATION_PAGE) && <>
                                            Vous n’avez pas activé vos notifications. Pour gérer vos alertes sur le site de Paanteon, rendez-vous sur l’onglet <Link to="connected.parameters.notifications">notifications de vos paramètres</Link>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Notifications;
