import React from "react";
import {SuggestionDataItem} from "react-mentions";

type Props = {
    focused?: boolean
    suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}
};

const MentionUser: React.FC<Props> = function(props) {
    return <div className={'mention-user ' + (props.focused ? 'focus' : '')}>
        <div className="profile-pic" style={{backgroundImage: 'url(' + props.suggestion.picturePath + ')'}}/>
        <div>
            <div>
                {props.suggestion.displayName}
            </div>
            <div style={{color: 'grey'}}>
                @{props.suggestion.display}
            </div>
        </div>
    </div>
}

export default MentionUser;

export const MentionableUsersContext = React.createContext<(SuggestionDataItem & {picturePath?: string, displayName?: string})[]>([]);
