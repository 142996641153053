import React from 'react';
import {Comment} from "../../api/types";
import Page from "../Page";
import Comments from "../../components/Comments";
import {Link} from "../../api/injectors";
import filterHelper from "../../api/filterHelper";
import localStorage from "../../api/localStorage";
import paanteon from "../../api/paanteonRequests";
import Helmet from "../../components/Helmet";

type Props = {
    comments: {data: Comment[] }
}

type State = {
    comments: Comment[]
    search: string,
    searchOnIds: boolean,
}

class CommentsPage extends Page<Props, State> {
    protected user = localStorage.getLocalUser();
    readonly state = {
        comments: this.props.comments.data,
        search: '',
        searchOnIds: false,
    }

    get getSearch(): Comment[] {
        return this.state.searchOnIds
            ? this.state.comments.filter(comment => comment.commentId.toString(10) === this.state.search)
            : filterHelper.deepSearch(this.state.comments, this.state.search);
    }

    deleteComment = (commentId: number) => {
        return paanteon.deleteComment(commentId).then(() => {
            this.setState(prevState => ({
                ...prevState,
                comments: prevState.comments.filter(c => c.commentId !== commentId).map(c => {
                    if (c.Answers) c.Answers = c.Answers.filter(a => a.commentId !== commentId);
                    return c;
                }),
            }));
            return true;
        });
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    toggleSearchOnIds = () => {
        this.setState(prevState => ({
            ...prevState,
            searchOnIds: !prevState.searchOnIds,
        }));
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'Commentaires | Paanteon'} />
                            <h1 className="page-title">
                                Commentaires ({this.state.comments.length})
                            </h1>

                            <div className="margin-bottom">
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>

                                <label>
                                    <input type="checkbox" checked={this.state.searchOnIds} onChange={this.toggleSearchOnIds}/>
                                    Rechercher uniquement sur les IDs
                                </label>

                                <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                            </div>

                            <Comments comments={this.getSearch} onDelete={this.deleteComment} showIds selfUser={this.user}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommentsPage;
