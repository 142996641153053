import React from 'react';
import Page from "../Page";
import {FormattedMessage} from "react-intl";
import {User, UserInfos} from "../../api/types";
import Toggle from "../../components/Toggle";
import moment from "moment";
import authentication from "../../api/authentication";
import Notification from "../../api/notification";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import ConfirmModal from "../../components/ConfirmModal";
import auth from "../../api/authentication";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../../api/injectors";
import localStorage from "../../api/localStorage";
import {updateUser} from "../../components/PublicHeader";
import SelectCountry from '../../components/SelectCountry';
import ChangeEmailModal from "../../components/ChangeEmailModal";
import userRequests from "../../api/userRequests";
import Helmet from "../../components/Helmet";

type Props = {
    user: UserInfos,
    $stateParams: {
        newMailToken?: string,
    },
} & InjectedUIRouterProps

type State = {
    user: User,
    isAccountInfoOpen: boolean
    isAuthInfoOpen: boolean
    isDeleteOpen: boolean
    changePasswordModal: boolean
    deleteAccountModal: boolean
    changeEmailModal: boolean
}

class Account extends Page<Props, State> {
    readonly state: State = {
        user: this.props.user,
        isAccountInfoOpen: false,
        isAuthInfoOpen: !!this.props.$stateParams.newMailToken,
        isDeleteOpen: false,
        changePasswordModal: false,
        deleteAccountModal: false,
        changeEmailModal: false,
    };

    constructor(props: Props) {
        super(props);
        const birthDate = moment(this.state.user.birthdate);
        this.state.user.day = String(birthDate.date());
        this.state.user.month = String(birthDate.month() + 1);
        this.state.user.year = String(birthDate.year());
    }

    handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value,
            },
        }));
    }

    handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        this.setState(state => ({
            user: {
                ...state.user,
                country: value,
            },
        }));
    }

    toggleAccountInfo = () => {
        this.setState(prevState => ({
            ...prevState,
            isAccountInfoOpen: !prevState.isAccountInfoOpen,
        }));
    }

    toggleAuthInfo = () => {
        this.setState(prevState => ({
            ...prevState,
            isAuthInfoOpen: !prevState.isAuthInfoOpen,
        }));
    }

    toggleDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            isDeleteOpen: !prevState.isDeleteOpen,
        }));
    }

    updateUser = (event: React.FormEvent) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                birthdate: moment({
                    year: parseInt(prevState.user.year as string, 10),
                    month: parseInt(prevState.user.month as string, 10) - 1,
                    day: parseInt(prevState.user.day as string, 10),
                }).utc(true).toISOString(),
            },
        }), () => {
            authentication.updateUserById(this.state.user).then((result) => {
                this.props.router.stateService.reload('connected.parameters');
                localStorage.setLocalUser({...localStorage.getLocalUser(), ...this.state.user});
                updateUser();
                Notification.success("Utilisateur mis à jour avec succès");
                if (result.data.certifiedChanges)
                    Notification.success("Vous avez modifié vos informations de compte. Cette modification ne sera prise en compte qu’après validation par nos équipes");
            }, () => {
                Notification.error("Une erreur est survenue lors de la mise à jour des informations du compte");
            });
        });
    }

    setDisplayCity = (val: boolean) => {
        if (this.state.user.displayCountry === false && !val) {
            Notification.warning("Vous ne pouvez pas désactiver l'affichage du pays et l'affichage de la ville en même temps");
        } else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    displayCity: val,
                },
            }));
        }
    }

    setDisplayCountry = (val: boolean) => {
        if (this.state.user.displayCity === false && !val) {
            Notification.warning("Vous ne pouvez pas désactiver l'affichage de la ville et l'affichage du pays en même temps");
        } else {
            this.setState(prevState => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    displayCountry: val,
                },
            }));
        }
    }

    togglePasswordModal = () => {
        this.setState(prevState => ({
            ...prevState,
            changePasswordModal: !prevState.changePasswordModal,
        }));
    }

    toggleDeleteAccountModal = () => {
        this.setState(prevState => ({
            ...prevState,
            deleteAccountModal: !prevState.deleteAccountModal,
        }));
    }

    deleteAccount = () => {
        auth.deleteAccount(this.props.user.userId).then(() => {
            return auth.logout().then(() => {
                this.props.router.stateService.go('home');
                Notification.success("Compte en cours de suppression");
            });
        }, function() {
            Notification.error("Erreur lors de la suppression de votre compte");
        });
    }

    updateUserEmail = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const email = event.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                email,
            },
        }))
    }

    toggleMailValidationPopup = () => {
        this.setState(prevState => ({
            ...prevState,
            changeEmailModal: !prevState.changeEmailModal,
        }));
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.props.$stateParams.newMailToken) {
            userRequests.validateEmailChange(this.props.$stateParams.newMailToken).then(() => {
                this.props.router.stateService.reload("connected.parameters").then(() => {
                    Notification.success("Votre adresse mail a correctement été mise à jour");
                });
            }, (error) => {
                if (error.response.status !== 500) {
                    Notification.error("Erreur lors de la validation de votre nouvelle adresse mail");
                }
            });
        }
    }

    render() {
        return (
            <>
                <Helmet title={'Paramètres - Mon compte | Paanteon'} />
                <div className="tile tile-padding-xl dark-blue">
                    <div className="tile-body" style={{color: 'white'}}>
                        <p>
                            Vous pouvez modifier vos informations de compte, par exemple à la suite d'un déménagement ou
                            d'un changement d'état civil.
                            Toutefois, si votre compte est déjà certifié, vos modifications ne seront prises en compte
                            qu'après validation par nos services.
                        </p>
                    </div>
                </div>
                <div className="tile tile-padding-xl">
                    <div className="tile-body">

                        <div className="collapsible-section-head-container">
                            <button onClick={this.toggleAccountInfo}
                                    className={`collapsible-section-head ${this.state.isAccountInfoOpen ? "opened" : ""}`}>
                                Informations de compte
                            </button>
                        </div>

                        {
                            this.state.isAccountInfoOpen && <div>
                                <p className={"margin-bottom-m grey-label"}>
                                    {this.state.isAccountInfoOpen ? (this.state.user.certificationDate ? 'Compte certifié' : 'Compte non-certifié') : ''} &gt; {!this.state.user.certificationDate && <Link to="connected.certification" className="btn link-btn dark-blue thin">
                                        { this.state.user.certificationStep0 ? 'Je reprends la certification' : 'Je souhaite certifier mon compte' }
                                    </Link>}
                                </p>

                                <form name="personalInfo" onSubmit={this.updateUser}>
                                    <fieldset disabled={this.state.user.certifiedChanges}>
                                        <div>
                                            <label
                                                className="margin-right-l"><FormattedMessage
                                                id="general.user.civility"/>*</label>
                                            <input type="radio" name="gender" id="female"
                                                   value="2"
                                                   checked={this.state.user.gender === "2"}
                                                   onChange={this.handleUserInput}
                                                   className="radio-custom"
                                                   required/>
                                            <label htmlFor="female">Mme</label>
                                            <input type="radio" name="gender" id="male"
                                                   onChange={this.handleUserInput}
                                                   checked={this.state.user.gender === "1"}
                                                   value="1"
                                                   className="radio-custom"/>
                                            <label htmlFor="male">Mr</label>
                                        </div>
                                        <div className="margin">
                                            <label><FormattedMessage id="general.user.lastname"/>*</label>
                                            <input className="input big block dark" type="text"
                                                   value={this.state.user.lastname}
                                                   name="lastname"
                                                   onChange={this.handleUserInput}
                                                   required/>
                                        </div>
                                        <div className="uk-grid uk-grid-small margin">
                                            <div className="uk-width-1-1@m">
                                                <label><FormattedMessage id="general.user.firstname"/>*</label>
                                                <input className="input big block dark" type="text"
                                                       onChange={this.handleUserInput}
                                                       name="firstname"
                                                       value={this.state.user.firstname}
                                                       required/>
                                            </div>
                                            <div className="uk-width-1-2@m uk-grid-margin">
                                                <label>Numéro de téléphone*</label>
                                                <input className="input big block dark" type="tel"
                                                       onChange={this.handleUserInput}
                                                       pattern="\+?[0-9]{8}[0-9]*"
                                                       name="tel"
                                                       value={this.state.user.tel}
                                                       required/>
                                            </div>
                                            <div className="uk-width-1-2@m uk-grid-margin">
                                                <label><FormattedMessage id="general.user.birthdate"/>*</label>
                                                <div className="uk-grid uk-grid-small">
                                                    <div className="uk-width-1-3">
                                                        <input type="number" max="31"
                                                               placeholder="JJ"
                                                               className="input big block dark"
                                                               onChange={this.handleUserInput}
                                                               value={this.state.user.day}
                                                               name="day"
                                                               required/>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <input type="number" max="12"
                                                               placeholder="MM"
                                                               className="input big block dark"
                                                               onChange={this.handleUserInput}
                                                               value={this.state.user.month}
                                                               name="month"
                                                               required/>
                                                    </div>
                                                    <div className="uk-width-1-3">
                                                        <input type="number" max="2019"
                                                               placeholder="AAAA"
                                                               className="input big block dark"
                                                               onChange={this.handleUserInput}
                                                               value={this.state.user.year}
                                                               name="year"
                                                               required/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="margin">
                                            <label><FormattedMessage id="general.user.city"/>*</label>
                                            <input className="input big block dark" type="text"
                                                   onChange={this.handleUserInput}
                                                   value={this.state.user.city}
                                                   name="city"
                                                   required/>
                                        </div>
                                        <div className="right body-m">
                                            Afficher ma ville sur mon profil public <Toggle
                                            checked={this.state.user.displayCity} onChange={this.setDisplayCity}/>
                                        </div>
                                        <div className="margin">
                                            <label><FormattedMessage id="general.user.country"/>*</label>
                                            <SelectCountry country={this.state.user.country} onChange={this.handleCountryChange}>
                                            </SelectCountry>
                                        </div>
                                        <div className="right body-m">
                                            Afficher mon pays sur mon profil public <Toggle
                                            checked={this.state.user.displayCountry} onChange={this.setDisplayCountry}/>
                                        </div>
                                        <div className="margin-large">
                                            <label className="black">
                                                <input type="checkbox" required/> Je certifie sur l'honneur l'exactitude des
                                                informations fournies.
                                            </label>
                                        </div>
                                        <div className="margin right">
                                            <small>* <FormattedMessage id="general.form.requiredFields"/></small>
                                        </div>
                                        <div className="right margin-bottom">
                                            <button type="submit" className="btn btn-1">{this.state.user.certifiedChanges ? "EN ATTENTE" : "SAUVEGARDER"}</button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        }
                    </div>
                </div>

                <div className="tile tile-padding-xl" style={{marginTop: '1px'}}>
                    <div className="tile-body">
                        <div className="collapsible-section-head-container">
                            <button onClick={this.toggleAuthInfo}
                                    className={`collapsible-section-head ${this.state.isAuthInfoOpen ? "opened" : ""}`}>
                                Informations de connexion
                            </button>
                        </div>

                        {
                            this.state.isAuthInfoOpen && <div>
                                <div>
                                    <label>Adresse e-mail*</label>
                                    <input className="input big block dark" type="text"
                                           value={this.state.user.email}
                                           onChange={this.updateUserEmail}
                                           required/>
                                </div>
                                <div className="margin">
                                    <label><FormattedMessage id="general.user.password"/>*</label>
                                    <input className="input big block dark" type="text"
                                           value={'***********'}
                                           required readOnly/>
                                    <div className="margin-top">
                                        <button className="btn link-btn small-link-btn dark-blue" type="button"
                                                onClick={this.togglePasswordModal}>&gt; Je souhaite modifier mon mot de
                                            passe
                                        </button>
                                    </div>

                                </div>
                                <div className="margin right">
                                    <small>* <FormattedMessage id="general.form.requiredFields"/></small>
                                </div>
                                <div className="right margin-bottom">
                                    <button className="btn btn-1" onClick={this.toggleMailValidationPopup} disabled={this.state.user.email === this.props.user.email}>SAUVEGARDER</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className="tile tile-padding-xl" style={{marginTop: '1px'}}>
                    <div className="tile-body">
                        <div className="collapsible-section-head-container">
                            <button onClick={this.toggleDelete}
                                    className={`collapsible-section-head ${this.state.isDeleteOpen ? "opened" : ""}`}>
                                Suppression de compte
                            </button>
                        </div>

                        {
                            this.state.isDeleteOpen && <div>
                                <div className="margin-l margin-bottom">
                                    <p>
                                        Veuillez noter que votre compte sera définitivement supprimé au bout de 30
                                        jours.
                                        A tout moment, pendant cette période, si votre compte a été supprimé
                                        accidentellement ou par erreur, vous pourrez le récupérer simplement en vous
                                        connectant avec vos identifiants.
                                    </p>
                                </div>

                                <div className="flex flex-center space-between margin">
                                    <div>
                                        Je souhaite supprimer mon compte
                                    </div>
                                    <button className="btn btn-1" onClick={this.toggleDeleteAccountModal}>SUPPRIMER</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {
                    this.state.changePasswordModal && <ChangePasswordModal userId={this.props.user.userId} onCancel={this.togglePasswordModal} onConfirm={this.togglePasswordModal}/>
                }

                {
                    this.state.deleteAccountModal && <ConfirmModal onCancel={this.toggleDeleteAccountModal} onConfirm={this.deleteAccount}>
                        Êtes-vous sûre de vouloir supprimer votre compte ?
                        Toutes vos informations seront définitivement supprimées.
                    </ConfirmModal>
                }

                {
                    this.state.changeEmailModal && <ChangeEmailModal userId={this.props.user.userId} oldUserEmail={this.props.user.email} userEmail={this.state.user.email} onModalClose={this.toggleMailValidationPopup}/>
                }
            </>
        );
    }
}

export default injectUIRouter(Account);
