import React, {PureComponent} from 'react';
import {Comment, CommentToSend, Publication, Tribute, User} from "../../api/types";
import {injectUIRouter, Link} from "../../api/injectors";
import PublicationFooter from "./PublicationFooter";
import paanteon from "../../api/paanteonRequests";
import Notification from "../../api/notification";
import {UIRouterReact} from "@uirouter/react";
import moment from "moment";
import localStorage from "../../api/localStorage";
import {CommentOpenable} from "./types";
import ReportModal from "../ReportModal";
import OptionsMenu from "../OptionsMenu";
import ConfirmModal from "../ConfirmModal";
import {pictoCertification} from "../../api/pictos";

type Props = {
    item: Publication & {Tribute: Tribute}
    footer?: boolean
    router: UIRouterReact
    onDelete?(publicationId: number, from: 0 | 1 | 2): void
} & CommentOpenable

type State = {
    item: Publication & {Tribute: Tribute}
    showOptions: boolean
    showDeleteConfirm: boolean
};

class TributePost extends PureComponent<Props, State> {
    protected user: Readonly<User> = localStorage.getLocalUser();
    constructor(props: Props) {
        super(props);

        let tribute = this.props.item.Tribute;
        if (tribute.content) {
            tribute.content = tribute.content.replace(/<style[^>]*>.+<\/style>/gms, "");
            tribute.content = tribute.content.replace(/<\/?[^>]+(>|$)/g, "");
            tribute.content = tribute.content.replace(/&nbsp;/g, " ");
            tribute.content = tribute.content.replace(/&amp;/g, "&");
            if (tribute.content.length > 240) {
                tribute.content = tribute.content.slice(0, 240) + '...';
            }
        }

        this.state = {
            item: {
                ...this.props.item,
                Tribute: tribute,
            },
            showOptions: false,
            showDeleteConfirm: false,
        };
    }

    toggleLike = () => {
        if (this.state.item.liked) {
            paanteon.unlike({publicationId: this.state.item.publicationId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    item: {
                        ...prevState.item,
                        liked: false,
                        likeCount: prevState.item.likeCount - 1,
                    },
                }));
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        } else {
            paanteon.like({publicationId: this.state.item.publicationId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    item: {
                        ...prevState.item,
                        liked: true,
                        likeCount: prevState.item.likeCount + 1,
                    },
                }))
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        }
    }

    onShare = () => {
        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                shareCount: prevState.item.shareCount + 1,
            },
        }));
    }

    sendComment = (comment: CommentToSend) => {
        const comm = {
            ...comment,
            publicationId: this.state.item.publicationId,
        };
        const answerId = comm.answerId;
        if (comm.answerId === -1) delete comm.answerId;
        return paanteon.comment(comm).then((res: {data: Comment}) => {
            this.setState(prevState => {
                if (!res.data.Answers) res.data.Answers = [];
                let nState = {
                    ...prevState,
                    item: {
                        ...prevState.item,
                        commented: 1 as 1,
                    },
                };
                if (answerId === -1) {
                    nState.item.Comments = [...nState.item.Comments, res.data];
                } else {
                    const comm = nState.item.Comments.find(comm => comm.commentId === answerId);
                    if (comm && comm.Answers) comm.Answers = [...comm.Answers, res.data];
                }
                return nState;
            });
            return true;
        }, function() {
            Notification.error("Erreur lors de la création du commentaire");
            return false;
        });
    };

    goToTribute = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        this.props.router.stateService.go('connected.paanteon.tribute.details', {userId: this.state.item.Tribute.User.userId, tributeId: this.state.item.Tribute.tributeId, thumbnailUniqueName: this.state.item.Tribute.Thumbnail.uniqueName, username: this.state.item.Tribute.User.username});
    }

    stopPropagation = (e: React.SyntheticEvent) => {
        e.stopPropagation();
    }

    deleteComment = (commentId: number) => {
        return paanteon.deleteComment(commentId).then(() => {
            this.setState(prevState => ({
                ...prevState,
                item: {
                    ...prevState.item,
                    Comments: prevState.item.Comments.filter(c => c.commentId !== commentId).map(c => {
                        if (c.Answers) c.Answers = c.Answers.filter(a => a.commentId !== commentId);
                        return c;
                    }),
                },
            }));
            return true;
        });
    }

    openConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            showDeleteConfirm: true,
        }));
    }

    closeConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            showDeleteConfirm: false,
        }));
    }

    confirmDelete = () => {
        paanteon.deletePublication(this.props.item.publicationId).then(() => {
            Notification.success("Publication supprimée avec succès");
            this.setState(prevState => ({
                ...prevState,
                showDeleteConfirm: false,
            }));
            if (this.props.onDelete) this.props.onDelete(this.props.item.publicationId, 1);
        }, function() {
            Notification.error("Erreur lors de la suppression de la publication")
        })
    }


    render() {
        return (
            <div className="tile condensed sep clickable" onClick={this.goToTribute}>
                <OptionsMenu>
                        <span className="options-menu">
                            <ul>
                                <li>
                                    <ReportModal publicationId={this.props.item.publicationId}>
                                        <button className="btn link-btn">Signaler</button>
                                    </ReportModal>
                                </li>
                                {
                                    (this.props.item.userId === this.user.userId || this.user.admin) && <li>
                                        <button className="btn link-btn" onClick={this.openConfirmDelete}>Supprimer</button>
                                    </li>
                                }
                            </ul>
                        </span>
                </OptionsMenu>
                <div className="tile-body">
                    <div className="feed-item">
                        <div className="profile-pic"
                             style={{backgroundImage: "url(" + this.state.item.Tribute.User.picturePath + ")"}}/>
                        <div>
                            <div>
                                <p onClick={this.stopPropagation}>
                                    <em className="ptn-em">
                                        <Link to="connected.paanteon.user" params={{userId: this.state.item.Tribute.User.userId, username: this.state.item.Tribute.User.username}}>{this.state.item.Tribute.User.firstname + ' ' + this.state.item.Tribute.User.lastname}</Link>
                                        {
                                            this.state.item.User.certificationDate !== null && <img className="certification" src={pictoCertification} alt="certifié"/>
                                        }
                                    </em>
                                    <small className={"username"}>@{this.state.item.Tribute.User.username}</small>
                                    &nbsp;- <small>{moment(this.state.item.createdAt).format('LL')}</small></p>
                                <p>A gravé "<em className="ptn-em"><Link onClick={this.stopPropagation} to="connected.paanteon.tribute.details"
                                                      params={{
                                                          userId: this.state.item.Tribute.User.userId,
                                                          tributeId: this.state.item.Tribute.tributeId,
                                                          thumbnailUniqueName: this.state.item.Tribute.Thumbnail.uniqueName,
                                                          username: this.state.item.Tribute.User.username,
                                                      }}
                                                      className="cap-first-letter">
                                    {this.state.item.Tribute.Thumbnail.label}</Link></em>" dans sa collection
                                    "<em className="ptn-em"><Link onClick={this.stopPropagation}
                                        to="connected.paanteon.user.galleries.all" params={{
                                        userId: this.state.item.Tribute.User.userId,
                                        '#': 'collection-' + this.state.item.Tribute.Collection.collectionId,
                                    }}>
                                        {this.state.item.Tribute.Collection.label}</Link></em>"</p>
                                <div>
                                    <img className="inspiration-image" src={this.state.item.Tribute.picturePath}
                                         alt=""/>
                                    <strong>{this.state.item.Tribute.title}</strong>
                                    <p dangerouslySetInnerHTML={{__html: this.state.item.Tribute.content}}/>
                                </div>
                            </div>

                            {
                                this.props.footer && <PublicationFooter commentOpen={this.props.commentOpen} onDeleteComment={this.deleteComment} publication={{...this.state.item, shareCount: this.state.item.tributeShareCount || 0, shared: this.state.item.Tribute.shared}} onLike={this.toggleLike} onShare={this.onShare} onComment={this.sendComment}/>
                            }
                        </div>
                    </div>
                </div>

                {
                    this.state.showDeleteConfirm && <ConfirmModal onCancel={this.closeConfirmDelete} onConfirm={this.confirmDelete}>Êtes-vous sûr(e) de vouloir supprimer définitivement cette publication ?</ConfirmModal>
                }
            </div>
        );
    }
}

export default injectUIRouter(TributePost);
