import React from "react";

function Step2() {
    return (
        <div className="uk-container main-content-s">
            <div className="steps">
                <div className="step done">1</div>
                <div className="sep"></div>
                <div className="step current">2</div>
                <div className="sep"></div>
                <div className="step">3</div>
            </div>
            <h2 className="title-3 h-center text-uppercase margin-large-top">
                Nous allons vous appeler !
            </h2>
            <div>
                <div className="margin-bottom-xl">
                    <p className="body text-center margin-bottom">
                        Merci ! nous avons bien reçu vos documents d'identité.
                    </p>
                    <p className="body text-center">
                        Une personne de l'équipe de Paanteon va entrer en contact avec vous dans les 48 h sur votre numéro de téléphone ou en appel vidéo.
                    </p>
                </div>
                <div className="margin-large">
                    <div className="margin-large right">
                        <button type="submit" disabled
                                className="btn btn-1 btn-big btn-uppercase">
                            En attente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step2;
