import React, {PureComponent} from 'react';
import {GalleryContext} from "../../pages/PaanteonUser";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../../api/injectors";
import domHelper from "../../api/domHelper";
import localStorage from "../../api/localStorage";
import {User} from "../../api/types";
import imgPlusCard from "../../assets/img/plus-card.png";

interface OwnProps {
    user: User
    $stateParams: Record<string, any>
}

type Props = OwnProps & InjectedUIRouterProps;

type State = Readonly<{
    addCollectionGalleryId: number | null
    yearlyGalleryCollectionsFilter: number
}>;

class PaanteonAllView extends PureComponent<Props, State> {
    readonly state: State = {
        addCollectionGalleryId: null,
        yearlyGalleryCollectionsFilter: 1,
    };
    private isMyPaanteon = this.props.user.userId === localStorage.getLocalUser().userId;

    toggleAddCollection = (galleryId: number | null) => {
        this.setState(prevState => ({...prevState, addCollectionGalleryId: prevState.addCollectionGalleryId === galleryId ? null : galleryId}));
    };

    setYearlyGalleryCollectionsFilter = (galleryId: number) => {
        this.setState(prevState => ({...prevState, yearlyGalleryCollectionsFilter: galleryId}));
    };

    componentDidMount(): void {
        const {hash} = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const e = document.getElementById(hash.slice(1, hash.length));
                if (e) e.scrollIntoView(true);
            }, 0);
        }
    }

    goToTribute = (evt: React.MouseEvent, collectionId: number, yearly: boolean | undefined, year: string | undefined, tributeId: number, thumbnailUniqueName: string, username: string) => {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.router.stateService.transitionTo(this.props.router.stateService.current, {
            ...this.props.$stateParams,
            '#': 'collection-' + collectionId + (yearly ? 'y' + year : ''),
        }, {
            location: 'replace',
            notify: false,
        }).then(() => {
            this.props.router.stateService.transitionTo('connected.paanteon.tribute.details', {tributeId, thumbnailUniqueName, username});
        })
    }

    render() {
        return <GalleryContext.Consumer>
            {
                (galleries) => (
                    <>
                        <div className="link-tabs">
                            {
                                galleries.map(gallery => {
                                    return <Link className="tab-item" key={gallery.galleryId}
                                                 to="connected.paanteon.user.galleries.all" params={{'#': "gallery-" + gallery.code}} onClick={() => domHelper.scrollToAnchor("gallery-" + gallery.code)}>
                                        {gallery.label}
                                    </Link>
                                })
                            }
                        </div>

                        {
                            galleries.map(gallery => {
                                return <div className="gallery" id={"gallery-" + gallery.code} key={gallery.galleryId}>
                                    <div className="gallery-head">
                                        <h2 className={"gallery-title" + (gallery.userCollections.length ? ' followed' : '')}>
                                            {gallery.label}
                                            <span className={"gallery-subtitle" + (gallery.userCollections.length ? ' followed' : '')}>{ this.isMyPaanteon && gallery.description }</span>
                                        </h2>
                                        {
                                            this.isMyPaanteon ?
                                                <div>
                                                    <span className={"btn link-btn light btn-icon-plus" + (this.state.addCollectionGalleryId === gallery.galleryId ? ' btn-icon-minus' : '')}
                                                    onClick={() => this.toggleAddCollection(gallery.galleryId)}>Ajouter une collection</span>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    {
                                        !gallery.userCollections.length ?
                                            <div className="uk-grid uk-grid-small gallery-grid">
                                                <div className="uk-width-1-3@m uk-width-1-4@l">
                                                    <div ui-sref="" className="card empty expand no-hover-anim">
                                                        <canvas width="250" height="350"/>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }

                                    {
                                        gallery.userCollections.map(collection => {
                                            return <div id={"collection-" + collection.collectionId + (gallery.yearly ? 'y' + gallery.label : '')} key={collection.collectionId}>
                                                <h3 className="collection-title">{collection.label}</h3>
                                                <div className="uk-grid uk-grid-small gallery-grid">

                                                    {
                                                        collection.Tributes ? collection.Tributes.map(tribute => {
                                                                return <div className="uk-width-1-3@m uk-width-1-4@l" key={tribute.Thumbnail.thumbnailId}>
                                                                    <Link to="connected.paanteon.tribute.details" params={{tributeId: tribute.tributeId, thumbnailUniqueName: tribute.Thumbnail.uniqueName, username: this.props.user.username}} className="card blue expand"
                                                                          style={{backgroundImage: 'url(' + tribute.picturePath + ')'}} onClickCapture={(evt: React.MouseEvent) => this.goToTribute(evt, collection.collectionId, gallery.yearly, gallery.label, tribute.tributeId, tribute.Thumbnail.uniqueName, this.props.user.username as string)}>
                                                                        <canvas width="250" height="350"/>
                                                                        <div className="card-footer">
                                                                            <p className="card-name">{tribute.Thumbnail.label}</p>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            })
                                                            : null
                                                    }

                                                    {
                                                        collection.Tributes && collection.Tributes.length < 12 && this.isMyPaanteon ?
                                                            <div className="uk-width-1-3@m uk-width-1-4@l">
                                                                <Link
                                                                    to="connected.paanteon.user.new-tribute"
                                                                    params={{
                                                                        collectionId: collection.collectionId,
                                                                        type: gallery.yearly ? 'yearly' : null,
                                                                        year: gallery.yearly ? gallery.label : null,
                                                                    }}
                                                                    className="card blue expand"
                                                                    style={{backgroundImage: 'url(' + imgPlusCard + ')'}}
                                                                >
                                                                    <canvas width="250" height="350"/>
                                                                </Link>
                                                            </div>
                                                            : null
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }

                                    {
                                        this.state.addCollectionGalleryId === gallery.galleryId ?
                                            <div className="popin-menu block chevron-right">
                                                {
                                                    !!gallery.yearly ?
                                                        <div className="link-tabs margin-bottom">
                                                            {/* TODO: replace display condition for "jardin secret" when existing flag in DB*/}

                                                            {
                                                                galleries.map(galleryItem => {
                                                                    return !galleryItem.yearly && galleryItem.label.toLowerCase() !== 'jardin secret' ?
                                                                        <button className={"tab-item" + (this.state.yearlyGalleryCollectionsFilter === galleryItem.galleryId ? ' active' : '')} key={galleryItem.galleryId}
                                                                                onClick={() => this.setYearlyGalleryCollectionsFilter(galleryItem.galleryId)}>
                                                                            {galleryItem.label}
                                                                        </button>
                                                                        : null
                                                                })
                                                            }
                                                        </div>
                                                        : null
                                                }
                                                <div className="cols-3">
                                                    {
                                                        gallery.Collections.filter(collection => {
                                                            return gallery.yearly ? collection.galleryId === this.state.yearlyGalleryCollectionsFilter : true;
                                                        }).map(collection => {
                                                            return collection.Tributes && collection.Tributes.length ?
                                                                <span className="item disabled" key={collection.collectionId}>{collection.label}</span>
                                                                :
                                                                <Link
                                                                    className={"item" + (collection.Tributes && collection.Tributes.length && !gallery.yearly ? ' disabled' : '')}
                                                                    key={collection.collectionId}
                                                                    to="connected.paanteon.user.new-tribute"
                                                                    params={{
                                                                        collectionId: collection.collectionId,
                                                                        type: gallery.yearly ? 'yearly' : null,
                                                                        year: gallery.yearly ? gallery.label : null,
                                                                    }}>{collection.label} {gallery.yearly}</Link>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                            })
                        }
                    </>
                )
            }
        </GalleryContext.Consumer>
    }
}

export default injectUIRouter(PaanteonAllView);
