import React from "react";
import {User} from "../../api/types";
import {InjectedIntlProps, injectIntl} from "react-intl";
import CertificationCheckoutForm from "../CertificationCheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import config from "../../api/config";

type Props = {
    user: User;
    update(user: User): any;
    nextStep(event?: React.SyntheticEvent): any;
} & InjectedIntlProps;

interface StateInterface {}

const stripePromise = loadStripe(config.stripeAPIKey, {locale: "fr-FR"});

class Step2 extends React.Component<Props, StateInterface> {
    render() {
        return (
            <div className="uk-container main-content-s">
                <div className="steps">
                    <div className="step done">1</div>
                    <div className="sep"></div>
                    <div className="step done">2</div>
                    <div className="sep"></div>
                    <div className="step current">3</div>
                </div>
                <h2 className="title-3 h-center text-uppercase margin-large-top">
                    C'est presque fini
                </h2>
                <form >
                    <div className="margin-bottom-xl">
                        <p className="body text-center margin-bottom">
                            Nous avons pu valider vos informations personnnelles.
                        </p>
                        <p className="body text-center">
                            Il vous reste maintenant à régler votre adhésion de deux euros pour devenir membre à vie du réseau Paanteon
                        </p>
                    </div>
                    <p className="title-3">Montant à régler : 2 euros</p>
                    <Elements stripe={stripePromise}>
                        <CertificationCheckoutForm
                            onSucceed={this.props.nextStep}
                        />
                    </Elements>
                </form>
            </div>
        );
    }
}

export default injectIntl(Step2);
