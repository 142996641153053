import React, { useEffect, useRef } from "react"
import Helmet from "../components/Helmet"
import { UIView } from "@uirouter/react"
import { FormattedMessage } from "react-intl"
import Step1 from "../components/Tutorial/Step1"
import { injectUIRouter } from "../api/injectors"
import Step2 from "../components/Tutorial/Step2"
import tutoSlide7 from "../assets/img/tutorial/tutorial-7.jpg";

const Tutorial = ({...props})=>{
	const ref = useRef<null | HTMLDivElement>(null);
	useEffect(()=>{
		if (props.$stateParams.collectionId)
			ref.current?.scrollIntoView()
	})
	return (
		<div className="main-content no-padding-top">
			<Helmet title="Premier pas | Paanteon" />
			<div className="page-head simple-head">
					<h1>
						<FormattedMessage id="tutorial.firstStep"/><span className="text-finish"></span><UIView/>
					</h1>
			</div>
			<div className="uk-grid flex-center">
				<img className={"uk-width-1-1"} src={tutoSlide7} alt={""} />
			</div>
			<div ref={ref} className="tuto-slide">
				<div className={"tuto-slide-head"}>
					<div className="tuto-slide-title">
						<p className="slide-number">6</p>
						<p className="slide-label">Je commence !</p>
					</div>
					<p className="h-center">Pour entrer dans Paanteon et commencer à interagir avec les autres membres,<br/>gravez votre première inspiration positive !</p>
				</div>
				<div className={"section tuto-slide-body"}>
					{!props.$stateParams.collectionId ?
					<Step1 galleries={props.galleries}/>
					: <Step2
					galleries={props.galleries}
					router={props.router}
					stateParams={props.$stateParams}
					thumbnail={props.thumbnail}
					user={props.user}/>}
				</div>
			</div>
		</div>
	)
}

export default injectUIRouter(Tutorial)
