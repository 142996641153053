import Notification from "../api/notification";
import request from "./request";
import FileSaver from "file-saver";

export function downloadFile(url) {
    request.getResource(url).then(res => {
        const name = /\/([a-z-_0-9.]+)$/.exec(url)[1];
        const blob = new Blob([res.data], {type: res.headers["Content-type"]});
        FileSaver.saveAs(blob, name);
    }, function() {
        Notification.error("Erreur lors du téléchargement du fichier")
    });
}

