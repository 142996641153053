import React from "react";
import { User } from "../../api/types";

const UserCard = ({
    user,
}: {
    user: User,
}) => {

    return (
    <div className="tile user-tile">
        <div className="tile-body flex-colomn space-between align-center">
            <div className="profile-pic"
            style={{backgroundImage: 'url(' + user.picturePath + ')'}}/>
            <div className="margin-top-s">
                <p>{user.firstname}</p>
                <p>{user.lastname}</p>
            </div>
        </div>
    </div>
    )
}

export default UserCard;
