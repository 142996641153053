import request from "./request";
import {CancelablePromise, ContactToSend, UserNotificationSettings} from "./types";
import {AxiosResponse} from "axios";
import {ExternalFriend} from "../components/InviteFriendsModal";

const userRequests = {
    getUserFriends: function(userId: number) {
        return request.get('/users/' + userId + '/friends', false);
    },

    getSuggestedFriends: function(userId: number) {
        return request.get('/users/' + userId + '/suggested-friends', false);
    },

    getUserLastTributes: function<T>(userId: number): CancelablePromise<AxiosResponse<T>> {
        return request.get<T>('/users/' + userId + '/paanteon/tributes/last') as CancelablePromise<AxiosResponse<T>>;
    },

    getUserTributes: function(userId: number) {
        return request.get('/users/' + userId + '/paanteon/tributes');
    },

    sendContact: function(form: ContactToSend) {
        return request.post('/contact', form);
    },

    sendLostPassword: function(email: string) {
        return request.post('/users/lost-pwd', {email});
    },

    resetPassword: function(newPwd: string, guid: string) {
        return request.post('/users/update-pwd', {newPwd, guid});
    },

    getFollows: function(userId: number) {
        return request.get('/users/' + userId + '/follows');
    },

    follow: function(userId: number) {
        return request.post('/users/' + userId + '/follow');
    },

    unfollow: function(userId: number) {
        return request.post('/users/' + userId + '/unfollow');
    },

    updateNotificationSettings: function(userId: number, notificationSettings: UserNotificationSettings) {
        return request.put('/users/' + userId + '/notification-settings', notificationSettings);
    },

    getUsers: function() {
        return request.get('/users');
    },

    validateEmailChange: (uuid: string) => {
        return request.get('/users/email/change/' + uuid);
    },

    blockUser: (userId: number) => {
        return request.post('/users/' + userId + '/block');
    },

    inviteFriends: (friends: ExternalFriend[]) => {
        return request.post('/invite', friends);
    },
    getUserTribes: (userId: number) => {
        return request.get('/users/' + userId + '/tribes');
    },
};

export default userRequests;
