import request from "./request";

export function createCertificationIntent() {
    return request.post('/certification/payment');
}

// User only
export function validateStep0() {
    return request.post('/certification/step/0');
}

export function invalidateStep0() {
    return request.delete('/certification/step/0');
}

export function validateStep1(files: { cniFront?: string, cniBack?: string, passport?: string }) {
    return request.post('/certification/step/1', files);
}

// Admin only
export function requestCertification(userId: number) {
    return request.post('/certification/' + userId);
}

export function validateStep2(userId: number) {
    return request.post('/certification/step/2', {userId});
}
