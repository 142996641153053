import React from 'react';
import PublicHeader from "../components/PublicHeader";
import pictoUserBlue from "../assets/img/pictos/picto-user-blue.png";
import Page from "./Page";
import FormPersonalInfo from "../components/FormPersonalInfo";
import FormCoords from "../components/FormCoords";
import {UIView} from "@uirouter/react";
import {Gender, User} from "../api/types";
import {FormattedMessage, InjectedIntlProps, injectIntl} from 'react-intl';
import {Link} from "../api/injectors";
import authentication from "../api/authentication";
import Notification from "../api/notification";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

class Register extends Page<InjectedIntlProps, { step: number, sentTwice: boolean }> {
    public user: User & { emailConf: string, passwordConf: string };

    constructor(props: InjectedIntlProps) {
        super(props);
        this.state = {
            step: 1,
            sentTwice: false,
        };
        this.user = {
            lastname: '',
            firstname: '',
            gender: Gender.null,
            day: '',
            month: '',
            year: '',
            postalCode: '',
            city: '',
            country: 'France',
            email: '',
            emailConf: '',
            password: '',
            passwordConf: '',
            tel: '',
        };
    }

    nextStep = (e: React.SyntheticEvent) => {
        this.setState(state => ({
            step: state.step + 1,
        }));
    };

    prevStep = () => {
        this.setState(state => ({
            step: state.step - 1,
        }));
    };

    updateUser = (u: any) => {
        this.user = u;
    };

    resendEmail = () => {
        if (!this.state.sentTwice) {
            authentication.sendRegisterMail({email: this.user.email}).then(
                () => {
                    Notification.success(this.props.intl.formatMessage({id: 'register.notification.emailSent'}));
                    this.setState({
                        sentTwice: true,
                    });
                },
                () => {
                    Notification.error(this.props.intl.formatMessage({id: 'register.notification.problemWhenNewEmailSent'}));
                }
            );
        } else {
            Notification.warning(this.props.intl.formatMessage({id: 'register.notification.mailSentTwice'}));
        }
    };

    render() {
        return (
            <>
                <PublicHeader noItems={true} className="shadowed-header"/>
                {
                    this.state.step < 3 &&
                    (<div className="page-head simple-head">
                        <h1>
                            {this.state.step === 1 ? <Helmet title={'Je deviens membre | Paanteon | Étape 1'} /> : <Helmet title={'Je deviens membre | Paanteon | Étape 2'} />}
                            <img src={pictoUserBlue} className="picto small" alt="picto user"/>
                            <FormattedMessage id="register.becomeMember"/><span className="text-finish"></span><UIView/>
                        </h1>
                    </div>)
                }
                <div className={"section" + (this.state.step === 3 ? " section-dark" : "")}>
                    <div className="uk-container">
                        {
                            (() => {
                                if (this.state.step === 1) {
                                    return (
                                        <FormPersonalInfo nextStep={this.nextStep} user={this.user}
                                                          update={this.updateUser}/>
                                    );
                                } else if (this.state.step === 2) {
                                    return (
                                        <FormCoords nextStep={this.nextStep} prevStep={this.prevStep} user={this.user}
                                                    update={this.updateUser}/>
                                    );
                                } else if (this.state.step === 3) {
                                    return (
                                        <div>
                                            <Helmet title={'Je deviens membre | Paanteon | Étape 3'} />
                                            <h2 className="title-1-light"><FormattedMessage
                                                id="general.congratulations"/></h2>
                                            <div className="h-center">
                                                <p className="headline">
                                                    Il vous reste maintenant à finaliser la création de votre compte.<br/>
                                                    Vous avez reçu un email pour valider votre inscription.<br/>
                                                    (Si vous ne le voyez pas, pensez à consulter vos courriers indésirables)
                                                </p>
                                                <p>
                                                    <small>
                                                        <button onClick={this.resendEmail} className="btn link-btn light underlined thin"><FormattedMessage
                                                            id="register.emailNotReceived"/>.</button>
                                                    </small>
                                                </p>
                                                <div className="margin-xl">
                                                    <Link to="home"
                                                          className="btn btn-outlined btn-big light"><FormattedMessage
                                                        id="general.backToHome"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })()
                        }
                    </div>
                </div>

                {
                    this.state.step < 3 &&
                    (<div className="section small-section force-padding section-light-blue ">
                        <div className="flex-center">
                            <p className="margin-right"><FormattedMessage id="register.accountAlreadyCreated"/></p>
                            <Link to="home" className="btn btn-outlined light btn-big"><FormattedMessage
                                id="login.connectYourself"/></Link>
                        </div>
                    </div>)
                }

                <Footer />
            </>
        );
    }
}

export default injectIntl(Register);
