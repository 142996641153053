import React from 'react';
import {ActiveLink} from "../api/injectors";
import localStorage from "../api/localStorage";
import {User} from "../api/types";
import {UIView} from "@uirouter/react";
import Page from "./Page";

class Galleries extends Page<{user: User}, {}> {
    private isMyPaanteon = localStorage.getLocalUser().userId === this.props.user.userId;

    render() {
        return (
            <>
                <div className="uk-grid uk-grid-small margin-bottom">
                    <div className="uk-width-1-3"><ActiveLink className="btn btn-outlined btn-block light" to="connected.paanteon.user.galleries.condensed" class="active">TOUTES LES GALERIES</ActiveLink></div>
                    <div className="uk-width-1-3"><ActiveLink className="btn btn-outlined btn-block light" to="connected.paanteon.user.galleries.collections" class="active">TOUTES LES COLLECTIONS</ActiveLink></div>
                    <div className="uk-width-1-3"><ActiveLink className="btn btn-outlined btn-block light" to="connected.paanteon.user.galleries.all" class="active">TOUT {this.isMyPaanteon ? 'M' : 'S'}ON PAANTEON</ActiveLink></div>
                </div>
                <div>
                    <UIView/>
                </div>
            </>
        );
    }
}

export default Galleries;
