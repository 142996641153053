/**
 * User interface to map data from backend
 * TODO: refactor this type to create a different type between sent and received
 */
export interface User {
    // Personal infos
    userId?: number;
    email: string;
    password?: string;
    firstname?: string;
    lastname?: string;
    gender?: Gender;
    picture?: string;
    city?: string;
    country?: string;
    postalCode?: string;
    tel?: string;
    day?: string;
    month?: string;
    year?: string;
    birthdate?: string;
    cover?: string;
    picturePath?: string;
    coverPath?: string;
    bio?: string;
    admin?: boolean | null;
    lastLoginDate?: string | null;
    createdAt?: string | null;
    displayCity?: boolean;
    displayCountry?: boolean;
    canContact?: boolean
    username?: string
    lastKnownIP?: string;
    suspensionDate?: string | null;
    processDate?: string | null;
    validEmail?: number;
    lastUpdate?: string;
    adminComment?: string;

    // tmp
    tmp_firstname?: string | null;
    tmp_lastname?: string | null;
    tmp_birthdate?: string | null;
    tmp_email?: string | null;
    tmp_tel?: string | null;

    // Social networks
    twitter?: string;
    facebook?: string;
    linkedin?: string;
    instagram?: string;

    // Application infos
    validUid?: string;

    // Joins
    Following?: User[],
    Followed?: User[],
    Friends?: Friendship[],
    FriendsOf?: Friendship[],
    Circles?: Circle[],

    // Created attributes
    friendsRequestCount?: number,
    commonFriends?: number,
    paanteonCount?: number,
    tribeCount?: number
    paanteonThumbnailId?: number
    paanteonThumbnailName?: string
    unreadMessageCount?: number;

    // Certification
    certificationDate?: string | null;
    certifiedChanges?: boolean;
    certificationRequestDate?: string | null;
    certificationStep0?: string | null,
    certificationStep1?: string | null,
    certificationStep2?: string | null,
    certificationPaymentDate?: string | null,
    identityPaperFrontUrl?: string | null,
    identityPaperBackUrl?: string | null,

    // Attribute for affinity
    totalCommonCollection?: number,
    totalCommonThumbnail?: number,
    totalCommonTag?: number,
    totalUser?: number,
    totalUser2?: number,
}

export interface UserPrivacy {
    // Privacy
    privacyPaanteon: number;
    privacyMessage: number;
}

export interface Friendship {
    friendshipId: number,
    friendId: number,
    accepted: boolean,
    User1: User,
    User2: User,
    userId: number,
    createdAt: string,
    friendTributeCount?: number,
}
export interface Circle {
    circleId: number,
    label: string,
    adminId: number
}

/**
 * Gender fixed values
 */
export enum Gender {
    null = "0",
    male = "1",
    female = "2",
}

export interface Featured {
    featuredId: number;
    picture?: string;
    desc?: string;
    thumbnailId?: number;
    Thumbnail?: Thumbnail;
    title?: string;
    count?: number;
    collectionId: number;
}

export interface FeaturedToSend {
    picture: string
    desc: string
    thumbnailId: number
}

export interface Thumbnail {
    thumbnailId: number;
    label: string;
    tributeCount?: number;
    Tributes?: Tribute[];
    Collection: Collection;
    Likes: Like[];
    loveCount: number
    admirationCount: number
    respectCount: number
    wikipedia: string | null
    website: string | null
    thumbnailUserId: number | null
    thumbnailUsername: string | null
    uniqueName: string
    InMemoriam?: InMemoriam
    processed?: boolean
    copyright?: string
    display: boolean
}

export interface ThumbnailToSend {
    label: string
}

export interface Tribute {
    tributeId: number;
    picture?: string;
    picturePath?: string;
    userId?: number;
    User: User;
    Thumbnail: Thumbnail;
    thumbnailId: number | null
    Collection: Collection;
    createdAt: string;
    yearly: number;
    year?: number;
    content: string;
    title: string;
    likeCount: number;
    liked: boolean;
    commentCount: number;
    shareCount: number;
    Comments?: Comment[];
    collectionId: number;
    Recommendations?: Recommendation[];
    shared?: 1 | null
    archivedAt: string | null
}

export interface TributeToSend {
    picture?: string;
    picturePath?: string | ArrayBuffer;
    img?: string | ArrayBuffer;
    thumbnail: string;
    yearly: boolean;
    year?: number;
    content: string;
    title: string;
    draft?: number;
    thumbnailId?: number | null
    collectionId?: number
}

export interface Gallery {
    galleryId: number
    label: string
    yearly?: boolean
    Collections: Collection[]
    code: string
    description: string
}

export interface Collection {
    collectionId: number;
    label: string;
    desc?: string;
    galleryId?: number;
    Tributes?: Tribute[]
}

export interface InMemoriam {
    inMemoriamId: number;
    picture: string;
    firstname: string;
    lastname: string;
    birthYear: number;
    deathYear: string;
    createdAt: string
    testimonialCount?: number
    count?: number
    thumbnailId: number
    Thumbnail: Thumbnail
    hasTribute?: number | null
    country?: string
}

export interface InMemoriamToSend {
    inMemoriamId?: number;
    picture: string
    firstname: string
    lastname: string
    birthYear: number
    deathYear: string
    country: string
}

export interface Publication {
    publicationId: number,
    content: string,
    circleId?: number,
    userId?: number,
    User: User,
    createdAt: string;
    picturePath: string;
    tributeId: number | null;
    Tribute?: Tribute;
    Testimonial?: Testimonial;
    likeCount: number;
    shareCount: number;
    tributeShareCount?: number;
    liked: boolean;
    publicationRefId: number | null;
    PublicationRef: Publication | null;
    type: number;
    Comments: Comment[];
    shared?: 1 | null
    commented?: 1 | null
}

export interface Like {
    likeId?: number
    comment?: string
    level: number
    userId?: number
    publicationId?: number
    shareId?: number
    commentId?: number
    tributeId?: number
    thumbnailId: number
}

export interface Comment {
    commentId: number
    content: string
    createdAt: string
    updatedAt: string
    User: User
    Answers?: Comment[]
    answerId: number | null
    userId: number
}

export interface CommentToSend {
    content?: string
    answerId: number | null
}

export interface Share {
    shareId: number
    comment: string
    createdAt: string
    updateAt: string
    publicationId?: number
    userId: number
    tributeId?: number
}

export interface ShareToSend {
    content: string
    publicationId?: number
    tributeId?: number
}

export class CancelablePromise<T> extends Promise<T> {
    cancel() {}
}

export interface Recommendation {
    recommendationId: number
    title: string
    author: string | null
    type: RecommendationType
    content: string
    picture: string | null
    link: string | null
    createdAt: string
    updatedAt: string
    tributeId: number
    userId: number
    picturePath: string
    Tribute?: Tribute
    User?: User
    recommendCount?: number
    engraveCount?: number
    recommendationThumbnailId?: number
}

export interface RecommendationToSend {
    recommendationId?: number
    title?: string
    author?: string | null
    type?: RecommendationType | string
    content?: string
    picture?: string | null
    link?: string | null
    img?: string
}

export enum RecommendationType {
    ARTICLE = 1,
    DOC = 2,
    MOVIE = 3,
    INTERVIEW = 4,
    BOOK = 5,
    SERIE = 6,
    VIDEO = 7,
}

export type ThumbnailDetails = { tributes: Tribute[], recommendations: [] }

export interface ReportToSend {
    content: string
    reason: string
    tributeId?: number
    publicationId?: number
    commentId?: number
    userId?: number
    recommendationId?: number
    testimonialId?: number
}

export interface ContactToSend {
    email?: string
    message?: string
    subject?: string
    firstname?: string
    lastname?: string
    society?: string
    phone?: string
}

export type UserInfos = User & {
    userId: number,
    Friends: Friendship[],
    FriendsOf: Friendship[],
    Following: User[],
    FollowedBy: User[],
    Circles: Circle[]
    Tributes: Tribute[]
    galleryCount: number
    collectionCount: number
    tributeCount: number
    archiveCount: number
    publicationCount: number
    mutual: number | null
    canContact: boolean,
    NotificationSettings: UserNotificationSettings,

} & UserPrivacy

export interface Testimonial {
    testimonialId: number
    title: string | null
    content: string
    createdAt: string
    updatedAt: string
    inMemoriamId: number
    userId: number
    User: User
    Likes: {likId: number}[]
    Comments: Comment[]
    liked?: 1 | null
    commented?: 1 | null
    InMemoriam?: InMemoriam
}

export interface TestimonialToSend {
    content: string
}

export interface Chat {
    chatId: number
    createdAt: string
    updatedAt: string
    sendId: number
    destId: number
    Sender: User
    Dest: User
    Messages?: Message[]
    unread: number
}

export interface Message {
    messageId: number
    content: string
    createdAt: string
    updatedAt: string
    chatId: number
    userId: number
    picture: string | null
    picturePath: string | null
}

export interface MessageToSend {
    content: string
    destId: number
}

export interface Followings {
    userId: number
    Following: (User & { follow: { createdAt: string, updatedAt: string }, tributeCount: number, commonFriends: number })[]
    FollowedBy: (User & { follow: { createdAt: string, updatedAt: string }, mutual: number | null, tributeCount: number, commonFriends: number })[]
}

export interface SearchResults {
    thumbnails: (Thumbnail & {Tributes: Tribute[]})[]
    users: User[]
    inMemoriams: InMemoriam[]
}

export interface NotificationBase {
    type: string
    date: string
    unread: 0 | 1
    userId: User['userId']
    username: User['username']
    firstname: string
    lastname: string
    picturePath: Required<User>['picturePath']
    key: string
}

export interface NotificationAnswerCommentBase extends NotificationBase {
    // answer content
    content: string
    // answer comment id
    commentId: Comment['commentId']
    object: "publicationId" | "tributeId" | "testimonialId"
    publicationId: number | null
    tributeId: number | null
    uniqueName: string | null
    testimonialId: number | null
}

export interface NotificationAnswerCommentPublication extends NotificationAnswerCommentBase {
    publicationId: number
}

export interface NotificationAnswerCommentTribute extends NotificationAnswerCommentBase {
    tributeId: number
    uniqueName: string
}

export interface NotificationAnswerCommentTestimonial extends NotificationAnswerCommentBase {
    testimonialId: number
    inMemoriamId: number
    uniqueName: Thumbnail['uniqueName']
}

export type NotificationAnswerComment =
    NotificationAnswerCommentPublication
    | NotificationAnswerCommentTribute
    | NotificationAnswerCommentTestimonial

export interface NotificationLikeTestimonial extends NotificationBase {
    testimonialId: Testimonial['testimonialId']
    label: string
    inMemoriamId: InMemoriam['inMemoriamId']
    uniqueName: Thumbnail['uniqueName']
}

export interface NotificationCommentTestimonial extends NotificationBase {
    testimonialId: Testimonial['testimonialId']
    label: string
    content: string
    inMemoriamId: InMemoriam['inMemoriamId']
    uniqueName: Thumbnail['uniqueName']
    commentId: Comment['commentId']
}

export interface NotificationLikePublication extends NotificationBase {
    publicationId: Publication['publicationId']
    content: string
}

export interface NotificationSharePublication extends NotificationBase {
    // shared publication id
    publicationId: Publication['publicationId']
    content: string
}

export interface NotificationCommentPublication extends NotificationBase {
    publicationId: Publication['publicationId']
    // comment content
    content: string
    commentId: Comment['commentId']
}

export interface NotificationCommentTribute extends NotificationBase {
    tributeId: Tribute['tributeId']
    uniqueName: string
    label: string
    content: string
    commentId: Comment['commentId']
}

export interface NotificationShareTribute extends NotificationBase {
    tributeId: Tribute['tributeId']
    uniqueName: string
    label: string
    content: string | null
    publicationId: Publication['publicationId']
}

export interface NotificationLikeTribute extends NotificationBase {
    tributeId: Tribute['tributeId']
    uniqueName: string
    label: string
}

// Someone added you to his paanteon
export interface NotificationPaanteon extends NotificationBase {
    tributeId: Tribute['tributeId']
    uniqueName: string
    label: string
}

export interface NotificationFriendship extends NotificationBase {
    userId: User['userId']
}

export interface NotificationFollow extends NotificationBase {
    userId: User['userId']
}

export interface NotificationMentionPublication extends NotificationBase {
    publicationId: Publication['publicationId']
    content: string
}

export interface NotificationTypes {
    answerComment: NotificationAnswerComment
    likeTestimonial: NotificationLikeTestimonial
    commentTestimonial: NotificationCommentTestimonial
    likePublication: NotificationLikePublication
    sharePublication: NotificationSharePublication
    commentPublication: NotificationCommentPublication
    commentTribute: NotificationCommentTribute
    shareTribute: NotificationShareTribute
    likeTribute: NotificationLikeTribute
    paanteon: NotificationPaanteon
    friendship: NotificationFriendship
    follow: NotificationFollow
}

export interface UserNotificationSettings {
    love: number,
    comment: number,
    share: number,
    friendship: number,
    mention: number,
    tribute: number,
    follower: number,
    global: number,
    userId: number
}

export enum UserNotificationSettingValue {
    NONE = 0,
    NOTIFICATION_PAGE = 1,
    EMAIL = 2,
}

/** Tags section */
export interface TagCategoryBase {
    label: string,
    Tags?: Tag[],
}
export interface TagCategory extends TagCategoryBase {
    tagCategoryId: number,
    order: number,
    Tags: Tag[]
}
export interface TagBase {
    label: string,
    tagCategoryId: number
}
export interface Tag extends TagBase {
    tagId: number,
}

export interface StatisticByMonth {
    year: number
    month: number
    count: number
}

export interface Statistics {
    tributesByMonth: StatisticByMonth[],
    usersByMonth: StatisticByMonth[],
    completedTributesByMonth: StatisticByMonth[],
    totalCompletedTributes: {count: number}
    totalPublications: {count: number}
    totalTributes: {count: number}
    totalShares: {count: number}
    totalLikes: {count: number}
    totalComments: {count: number}
    totalTestimonials: {count: number}
    totalUsers: {count: number}
    sharesByMonth: StatisticByMonth[]
    likesByMonth: StatisticByMonth[]
    commentsByMonth: StatisticByMonth[]
    testimonialsByMonth: StatisticByMonth[]
    publicationsByMonth: StatisticByMonth[]
}

export interface MetaTribeBase {
    label: string,
}

export interface MetaTribe extends MetaTribeBase {
    metaTribeId: number,
    Tribes: Tribe[],
}

export interface TribeBase {
    label: string,
    Criteria: Array<Criteria>,
    metaTribeId: number,
    minCriteria: number,
}

export interface Tribe extends TribeBase {
    tribeId: number,
    Users: Array<User>,
}

export interface Criteria {
    criteriaId?: number,
    collectionId: number|null,
    tagId: number|null,
}
