export enum PrivacyPaanteon {
    ALL_MEMBERS = 8,
    MY_FRIENDS = 1,
    FRIENDS_FRIENDS = 4,
    MY_FOLLOWERS = 2,
    ONLY_ME = 0,
    MY_COMMUNITY = PrivacyPaanteon.MY_FRIENDS | PrivacyPaanteon.FRIENDS_FRIENDS | PrivacyPaanteon.MY_FOLLOWERS,
}

export enum PrivacyMessages {
    ALL_MEMBERS = 8,
    FRIENDS_FRIENDS = 4,
    MY_FOLLOWERS = 2,
    MY_FRIENDS = 1,
    MY_COMMUNITY = PrivacyMessages.FRIENDS_FRIENDS | PrivacyMessages.MY_FOLLOWERS | PrivacyMessages.MY_FRIENDS,
}

export enum PrivacyNotification {
    NONE = 0,
    PANEL = 1,
    EMAIL = 2
}
