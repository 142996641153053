import React, {Component} from 'react';
import Modal from "./Modal";
import {Publication, ShareToSend, Tribute} from "../api/types";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";
import {pictoSharingBlue, pictoSharingFullBlue} from "../api/pictos";

type Props = Readonly<{
    tribute?: Tribute
    publication?: Publication
    onSent?(res: any): any
    shareCount: number
    shared?: boolean
}>;

type State = Readonly<{
    content: string
    open: boolean
}>

class ShareModal extends Component<Props, State> {
    readonly state = {
        content: '',
        open: false,
    }

    setContent = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            content: val,
        }));
    }

    sendShare = () => {
        const share: ShareToSend = {
            content: this.state.content,
        };
        if (this.props.tribute) share.tributeId = this.props.tribute.tributeId;
        if (this.props.publication) share.publicationId = this.props.publication.publicationId;
        paanteon.share(share).then((res) => {
            Notification.success("Partage créé avec succès");
            this.setState(prevState => ({
                ...prevState,
                open: false,
            }));
            if (this.props.onSent) this.props.onSent(res);
        }, function() {
            Notification.error("Erreur lors du partage");
        });
    }

    openModal = () => {
        this.setState(prevState => ({
            ...prevState,
            open: true,
        }))
    }

    closeModal = () => {
        this.setState(prevState => ({
            ...prevState,
            open: false,
        }))
    }

    render() {
        return (
            <>
            <button className="btn btn-icon" onClick={this.openModal}><img src={this.props.shared ? pictoSharingFullBlue : pictoSharingBlue} className="picto blue" alt="picto share"/><span style={{color: "#37a8db"}} className="regular">{this.props.shareCount}</span></button>
                {
                    this.state.open && <Modal closeButton onClose={this.closeModal}>
                        <>
                            <div className="pop-up-title no-padding">Partagez dans votre fil d'inspiration</div>
                            <textarea className={"textarea block"} onChange={this.setContent} value={this.state.content}/>
                            <button className="btn btn-1 margin-top" onClick={this.sendShare}>Partager</button>
                        </>
                    </Modal>
                }
            </>
        );
    }
}

export default ShareModal;
