import request from "./request";
import {
    FeaturedToSend,
    InMemoriamToSend,
    Tag,
    TagCategory,
    Thumbnail,
    ThumbnailToSend,
    TagCategoryBase,
    TagBase,
    Testimonial,
    Tribe,
    MetaTribeBase,
    TribeBase,
} from "./types";

const adminRequests = {
    getAllUsers: function() {
        return request.get('/admin/users');
    },

    getAllTributes: function() {
        return request.get('/admin/tributes');
    },

    getAllPublications: function() {
        return request.get('/admin/publications');
    },

    getAllThumbnails: function() {
        return request.get('/admin/thumbnails');
    },

    updateThumbnail: function(thumbnailId: number, thumbnail: Thumbnail) {
        return request.put('/admin/thumbnails/' + thumbnailId, thumbnail);
    },

    deleteThumbnail(thumbnailId: number) {
        return request.delete('/admin/thumbnails/' + thumbnailId);
    },

    createThumbnail(thumbnail: ThumbnailToSend) {
        return request.post('/admin/thumbnails', thumbnail);
    },

    mergeThumbnails(thumbnails: Thumbnail['thumbnailId'][]) {
        return request.post('/admin/thumbnails/merge', {ids: thumbnails});
    },

    setThumbnailTags(thumbnailId: number, tags: number[]) {
        return request.put(`/admin/thumbnails/${thumbnailId}/tags`, tags);
    },

    createFeatured: function(featured: FeaturedToSend) {
        return request.post('/admin/featured', featured);
    },

    deleteFeatured: function(featuredId: number) {
        return request.delete('/admin/featured/' + featuredId);
    },

    createInMemoriam: function(inMemoriam: InMemoriamToSend) {
        return request.post('/admin/in-memoriam', inMemoriam);
    },

    deleteInMemoriam: function(inMemoriamId: number) {
        return request.delete('/admin/in-memoriam/' + inMemoriamId);
    },

    updateInMemoriam: function(inMemoriamId: number, inMemoriam: InMemoriamToSend) {
        return request.put(`/admin/in-memoriam/${inMemoriamId}`, inMemoriam);
    },

    getAllComments: function() {
        return request.get('/admin/comment');
    },

    getAllTags: function() {
        return request.get('/admin/tags');
    },

    updateTagCategory: function(tagCategoryId: number, tagCategory: TagCategory) {
        return request.put('/admin/tag-categories/' + tagCategoryId, tagCategory);
    },

    deleteTagCategory(tagCategoryId: number) {
        return request.delete('/admin/tag-categories/' + tagCategoryId);
    },

    createTagCategory(tagCategory: TagCategoryBase) {
        return request.post('/admin/tag-categories', tagCategory);
    },

    updateTag: function(tagId: number, tag: Tag) {
        return request.put('/admin/tags/' + tagId, tag);
    },

    deleteTag(tagId: number) {
        return request.delete('/admin/tags/' + tagId);
    },

    createTag(tag: TagBase) {
        return request.post('/admin/tag-categories/' + tag.tagCategoryId + '/tags', tag);
    },

    certifyUser(userId: number) {
        return request.post('/admin/users/' + userId + '/certify');
    },

    deleteUser(userId: number) {
        return request.delete('/admin/users/' + userId);
    },

    suspendUser(userId: number) {
        return request.post('/admin/users/' + userId + '/suspend');
    },

    unsuspendUser(userId: number) {
        return request.post('/admin/users/' + userId + '/unsuspend');
    },

    processUserStart(userId: number) {
        return request.post('/admin/users/' + userId + '/process/start');
    },

    processUserStop(userId: number) {
        return request.post('/admin/users/' + userId + '/process/end');
    },

    validateChanges(userId: number) {
        return request.post('/admin/users/' + userId + '/validate-changes', {validate: true})
    },

    refuseChanges(userId: number) {
        return request.post('/admin/users/' + userId + '/validate-changes', {validate: false})
    },

    getStatistics() {
        return request.get('/admin/stats');
    },

    processThumbnail(thumbnailId: number) {
        return request.post('/admin/thumbnails/' + thumbnailId + '/process');
    },

    getTestimonials() {
        return request.get('/admin/testimonials');
    },

    deleteTestimonial(testimonialId: Testimonial["testimonialId"]) {
        return request.delete('/admin/testimonials/' + testimonialId);
    },

    updateHomeData(homeData: {copyright?: string, background?: string}) {
        return request.post('/admin/public-home', homeData);
    },

    getAllGalleries() {
        return request.get('/admin/galleries');
    },

    getAllTribes() {
        return request.get('/admin/tribes');
    },

    createTribe(tribe: TribeBase) {
        return request.post('/admin/meta-tribes/' + tribe.metaTribeId + '/tribes', tribe);
    },

    updateTribe(tribeId: number, tribe: Tribe) {
        return request.put('/admin/tribes/' + tribeId, tribe);
    },

    deleteTribe(tribeId: number) {
        return request.delete('/admin/tribes/' + tribeId);
    },

    createMetaTribe(metaTribe: MetaTribeBase) {
        return request.post('/admin/meta-tribes', metaTribe);
    },

    deleteMetaTribe(metaTribeId: number) {
        return request.delete('/admin/meta-tribes/' + metaTribeId);
    },

    updateMetaTribe(metaTribeId: number, metaTribe: MetaTribeBase) {
        return request.put('/admin/meta-tribes/' + metaTribeId, metaTribe);
    },
};

export default adminRequests;
