import {ReactStateDeclaration, Transition, UIView} from "@uirouter/react";
import localStorage from "./localStorage";
import Home from "../pages/admin/Home";
import Tributes from "../pages/admin/Tributes";
import adminRequests from "./adminRequests";
import Publications from "../pages/admin/Publications";
import Thumbnails from "../pages/admin/Thumbnails";
import FeaturedImages from "../pages/admin/Featured";
import images from "./images";
import paanteon from "./paanteonRequests";
import InMemoriam from "../pages/admin/InMemoriam";
import CommentsPage from "../pages/admin/CommentsPage";
import UsersPage from "../pages/admin/UsersPage";
import Tags from "../pages/admin/Tags";
import StatisticsPage from "../pages/admin/StatisticsPage";
import TestimonialsPage from "../pages/admin/TestimonialsPage";
import HomeManagement from "../pages/admin/HomeManagement";
import paanteonRequests from "./paanteonRequests";
import Tribes from "../pages/admin/Tribes";

const adminRoutes: ReactStateDeclaration[] = [];

adminRoutes.push({
    name: 'connected.admin',
    url: '/admin',
    resolve: {
        isAdmin: [Transition, function(trans: Transition) {
            if (!localStorage.getLocalUser().admin) {
                trans.abort();
                trans.router.stateService.go('connected.home');
            }
            return true;
        }],
    },
    redirectTo: 'connected.admin.home',
    component: UIView,
});

adminRoutes.push({
    name: 'connected.admin.home',
    url: '/home',
    component: Home,
});

adminRoutes.push({
    name: 'connected.admin.tributes',
    url: '/tributes',
    component: Tributes,
    resolve: {
        tributes: function() {
            return adminRequests.getAllTributes();
        },
    },
});

adminRoutes.push({
    name: 'connected.admin.publications',
    url: '/publications',
    component: Publications,
    resolve: {
        publications: function() {
            return adminRequests.getAllPublications();
        },
    },
});

adminRoutes.push({
    name: 'connected.admin.thumbnails',
    url: '/thumbnails',
    component: Thumbnails,
    resolve: {
        thumbnails: function() {
            return adminRequests.getAllThumbnails();
        },
        tagCategories: [function() {
            return adminRequests.getAllTags();
        }],
    },
});

adminRoutes.push({
    name: 'connected.admin.featured',
    url: '/featured',
    component: FeaturedImages,
    resolve: {
        featured: function() {
            return images.getFeatured();
        },
    },
});

adminRoutes.push({
    name: 'connected.admin.in-memoriam',
    url: '/in-memoriam',
    component: InMemoriam,
    resolve: {
        memoriam: [function() {
            return paanteon.getInMemoriam();
        }],
    },
});

adminRoutes.push({
    name: 'connected.admin.comments',
    url: '/comment',
    component: CommentsPage,
    resolve: {
        comments: [function() {
            return adminRequests.getAllComments();
        }],
    },
});

adminRoutes.push({
    name: 'connected.admin.users',
    url: '/user',
    component: UsersPage,
    resolve: {
        users: [function() {
            return adminRequests.getAllUsers();
        }],
    },
});

adminRoutes.push({
    name: 'connected.admin.tags',
    url: '/tag',
    component: Tags,
    resolve: {
        tagCategories: [function() {
            return adminRequests.getAllTags();
        }],
    },
});

adminRoutes.push({
    name: 'connected.admin.statistics',
    url: '/statistics',
    component: StatisticsPage,
    resolve: {
        statistics: function() {
            return adminRequests.getStatistics();
        },
    },
})

adminRoutes.push({
    name: 'connected.admin.testimonials',
    url: '/testimonials',
    component: TestimonialsPage,
    resolve: {
        testimonials: function() {
            return adminRequests.getTestimonials();
        },
    },
});

adminRoutes.push({
    name: 'connected.admin.homeManagement',
    url: '/home-management',
    component: HomeManagement,
    resolve: {
        publicHomeData: function() {
            return paanteonRequests.getPublicHomeData();
        },
    },
})

adminRoutes.push({
    name: 'connected.admin.tribes',
    url: '/tribes',
    component: Tribes,
    resolve: {
        metaTribes: function() {
            return adminRequests.getAllTribes();
        },
        galleries: function() {
            return adminRequests.getAllGalleries();
        },
        tagCategories: function() {
            return adminRequests.getAllTags();
        },
    },
})

export default adminRoutes;
