import React from 'react';
import logoPaanteon from '../../assets/img/logo-paanteon-dark-bg.svg';
import {InjectedUIRouterProps, injectUIRouter, Link} from "../../api/injectors";
import localStorage from "../../api/localStorage";

const Footer: React.FC<InjectedUIRouterProps> = ({router}) => {
    const isLogged = localStorage.isSetLocalUser();

    return (
        <footer className="footer">
            <img alt="logo" src={logoPaanteon} className="logo"/>
            <div className="liveinspired h-center">
                <span>#live</span><span>inspired</span>
            </div>
            <nav>
                <ul>
                    {/* <li><Link to="home">Home Publique</Link></li>
                    <li><Link to="connected.home">Home Connectée</Link></li>
                    <li><Link to="connected.paanteon">Paanteon</Link></li>
                    <li><Link to="ui-kit">UI-kit</Link></li>*/}
                    {/*
                        Here we needed to use base links due to a bug in @uirouter/react
                        TODO: update when fixed and change this
                    */}
                    {isLogged && <li><Link to={"connected.presentation"}>Présentation</Link></li>}
                    <li><a href={router.stateService.href("manifest")} target={"_blank"}>Manifesto</a></li>
                    <li><a href={router.stateService.href("terms-and-condition-of-use")} target={"_blank"}>Conditions générales d'utilisation</a></li>
                    <li><a href={router.stateService.href("privacy-policy")} target={"_blank"}>Politique de confidentialité</a></li>
                    <li><a href={router.stateService.href("community-code")} target={"_blank"}>Charte pour la communauté</a></li>
                    <li><Link to="contact">Nous contacter</Link></li>
                    {/* <li ng-if="!userIsLogged"><a to="login">Login</a></li>
                        <li ng-if="userIsLogged">
                            {{user.firstname}} {{user.lastname}}
                            <a ng-click="logout()">Logout</a>
                            <a to="updatePassword">Change password</a>
                        </li>*/}
                </ul>
            </nav>
            <p className="copyright">
                &copy; 2021 Paanteon - Tous droits réservés
            </p>
        </footer>
    );
}

export default injectUIRouter(Footer);
