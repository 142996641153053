import {FunctionComponent, useEffect} from "react";
import Helmet from "../components/Helmet";
import * as React from "react";
import tutoSlide7 from "../assets/img/tutorial/tutorial-7.jpg";

const TutorialTribute: FunctionComponent = () => {
    useEffect(() => {
        /**
         * Scroll the page to the top on component mount
         */
        window.scrollTo(0, 0);
    }, []);

        return (
            <>
                <div className="dark-bg">
                    <div className="uk-width-1-1 padding-top text-center">
                        <Helmet title={`Comment graver | Paanteon`} />

                        <div className="main-content no-padding-top">
                            <div>
                                <div className="uk-grid flex-center">
                                    <img className={"uk-width-1-1"} src={tutoSlide7} alt={""} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default TutorialTribute;
