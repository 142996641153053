import React from 'react';
import {Collection, Gallery, Thumbnail, Tribute, TributeToSend, UserInfos} from "../api/types";
import Page from "./Page";
import filterHelper from "../api/filterHelper";
import {StateObject, StateDeclaration, UIViewResolves} from "@uirouter/react";
import Toggleable from "../components/Toggleable";
import {pictoCamera, pictoLink, pictoSave, pictoWikipedia} from "../api/pictos";
import RTE from "../elements/RTE";
import Notification from "../api/notification";
import paanteon from "../api/paanteonRequests";
import {InjectedUIRouterProps, injectUIRouter} from "../api/injectors";
import Autosuggest from 'react-autosuggest';
import ConfirmModal from "../components/ConfirmModal";
import {acceptableImages} from "../api/domHelper";

interface OwnProps {
    user: UserInfos
    galleries: (Gallery & { userCollections: Collection[] })[]
    discover: Thumbnail[]
    popular: Thumbnail[]
    thumbnail?: { data: Thumbnail }
}

type Props = OwnProps & UIViewResolves & InjectedUIRouterProps;

type State = Readonly<{
    // galleries: (Gallery & { userCollections: Collection[] })[]
    selectedGallery?: Gallery
    yearlyGalleryCollectionsFilter: number
    selectedCollection: number,
    selectedCollectionLabel: string,
    tribute: TributeToSend
    picUploadLabel: string
    activeTributeEditing: boolean
    searchedTributes: (Thumbnail & {Tribute: Tribute})[]
    modified: boolean
    openConfirmModal: boolean
    lastSavedTransition: StateDeclaration | null
}>;

const getThumbnailSuggestionValue = (thumbnail: Thumbnail) => thumbnail.label;
// stateChangeStart

const renderThumbnailSuggestion = (suggestion: Thumbnail) => <div>{suggestion.label}</div>

const shouldRenderThumbnailSuggestions = (value: string) => {
    if (value.trim)
    return value.trim().length > 2;
    else return true;
}

class NewTribute extends Page<Props, State> {
    readonly state: State = {
        yearlyGalleryCollectionsFilter: 1,
        selectedCollection: 1,
        selectedCollectionLabel: "",
        tribute: {
            thumbnail: this.props.thumbnail ? this.props.thumbnail.data.label : this.props.$stateParams.name ? decodeURIComponent(this.props.$stateParams.name) : "",
            yearly: false,
            content: "",
            title: "",
        },
        picUploadLabel: "Téléversez une photo depuis votre ordinateur.\nOu glissez-déposez ici.",
        activeTributeEditing: false,
        searchedTributes: [],
        modified: false,
        openConfirmModal: false,
        lastSavedTransition: null,
    };
    private yearly: boolean = false;
    private saved: boolean = false;

    setCollectionsFilter = (collectionId: number) => {
        this.setState(prevState => ({...prevState, yearlyGalleryCollectionsFilter: collectionId}));
    };

    activateTributeEditing = () => {
        this.setState(prevState => ({...prevState, activeTributeEditing: true}));
    };

    setContent = (html: string) => {
        this.setState(prevState => {
            let newSate = {...prevState, modified: true};
            newSate.tribute = {
                ...prevState.tribute,
                content: html,
            };
            return newSate;
        });
    };

    setThumbnail = (event: React.FormEvent<HTMLInputElement>, {newValue}: {newValue: string}) => {
        const val = newValue;
        this.setState(prevState => {
            let newSate = {...prevState};
            newSate.tribute = {
                ...prevState.tribute,
                thumbnail: val,
            };
            return newSate;
        });
    };

    searchTributes = (payload: {value: string, reason: "input-changed" | "input-focused" | "escape-pressed" | "suggestions-revealed" | "suggestion-selected"}) => {
        paanteon.searchTributes(payload.value).then((tributesData: { data: (Thumbnail & {Tribute: Tribute})[] }) => {
            this.setState(prevState => ({
                ...prevState,
                searchedTributes: tributesData.data,
            }));
        }, () => {});
    }

    clearThumbnailSuggestions = () => {
        this.setState(prevState => ({
            ...prevState,
            searchedTributes: [],
        }))
    }

    setTitle = (event: React.FormEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => {
            let newSate = {...prevState, modified: true};
            newSate.tribute = {
                ...prevState.tribute,
                title: val,
            };
            return newSate;
        });
    };

    saveTribute = (draft?: boolean) => {
        // handle yearly virtual gallery
        if (!this.state.selectedGallery) {
            Notification.warning("Veuillez d’abord choisir une galerie Humanité / Planète / Culture / Lifestyle, puis une collection à l’intérieur de celle-ci");
            return;
        }
        if (!this.state.tribute.picturePath) {
            Notification.warning("Veuillez d'abord téléverser une image");
            return;
        }
        if (!this.state.tribute.thumbnail) {
            Notification.warning('Formulaire incomplet! Veuillez renseigner le nom et une photo.');
            return;
        }
        if (this.state.tribute.thumbnail.length > 100) {
            Notification.warning('Le nom de votre inspiration est trop long');
            return;
        }
        if (draft && this.state.tribute.draft !== 0) { // no draft mode if tribute is already published
            this.setState(prevState => {
                let newSate = {...prevState};
                newSate.tribute = {
                    ...prevState.tribute,
                    draft: 0, // was 1 previously, remove draft mode
                };
                return newSate;
            });
        } else {
            this.setState(prevState => {
                let newSate = {...prevState};
                newSate.tribute = {
                    ...prevState.tribute,
                    draft: 0,
                };
                return newSate;
            });
        }
        let tributeToSend = {...this.state.tribute, thumbnail: this.state.tribute.thumbnail.trim()};
        if (this.state.selectedGallery && this.state.selectedGallery.yearly) {
            tributeToSend.yearly = true;
            tributeToSend.year = Number(this.state.selectedGallery.label);
        }
        let request = paanteon.createTribute(this.state.selectedCollection, tributeToSend);
        request.then((result) => {
            Notification.success('Hommage enregistré');
            this.saved = true;
            if (result.data.tributeId) this.props.router.stateService.go('connected.paanteon.tribute.details', {tributeId: result.data.tributeId, thumbnailUniqueName: result.data.Thumbnail.uniqueName, username: this.props.user.username}, {reload: true});
        }, function(error) {
            if (error.response && error.response.status === 400) {
                Notification.warning('Formulaire incomplet! Veuillez renseigner le nom et une photo.');
            } else if (error.response && error.response.status === 412) {
                Notification.warning('Vous disposez du maximum de 12 vignettes dans votre collection!');
            } else if (error.response && error.response.status === 409) {
                Notification.error(`${tributeToSend.thumbnail} est déjà gravé dans votre Paanteon`);
            } else {
                Notification.error('Erreur lors de la sauvegarde de l\'hommage');
            }
        });
    };

    componentDidMount(): void {
        super.componentDidMount();
        this.yearly = this.props.$stateParams.type === "yearly";

        /* this.setState(prevState => ({
            ...prevState,
            galleries: this.props.paanteon.data.map((g) => {
                g.userCollections = g.Collections.filter((c) => {
                    return c.Thumbnails && c.Thumbnails.length;
                });
                return g;
            })
        }));*/

        this.selectGallery(this.props.$stateParams.collectionId ? Number(this.props.$stateParams.collectionId) : -1, Number(this.props.$stateParams.year) || null);

        const picInput = document.getElementById('tribute-pic') as HTMLInputElement;
        if (picInput) {
            // handling tribute's picture upload
            var reader = new FileReader();
            picInput.addEventListener( 'change', ( e: Event ) => {
                // @ts-ignore
                if (e.currentTarget && (e.currentTarget as HTMLInputElement).files !== null && (e.currentTarget as HTMLInputElement).files.length > 0) {
                    // @ts-ignore
                    const name = (e.currentTarget as HTMLInputElement).files[0].name;
                    this.setState(prevState => ({...prevState, picUploadLabel: name}));
                    // @ts-ignore
                    reader.readAsDataURL((e.currentTarget as HTMLInputElement).files[0]);
                }
            });
            reader.onloadend = () => {
                this.setState(prevState => {
                    let nState = {...prevState};
                    nState.tribute = {
                        ...prevState.tribute,
                        img: reader.result ? reader.result : '',
                        picturePath: reader.result ? reader.result : '',
                    };
                    return nState;
                });
            }

            const uploadBox = document.getElementById('upload-box');
            if (uploadBox) {
                uploadBox.ondragover = uploadBox.ondragenter = evt => {
                    evt.stopPropagation();
                    evt.preventDefault();
                }

                const dropReader = new FileReader();
                uploadBox.ondrop = event => {
                    event.stopPropagation();
                    event.preventDefault();
                    if (event.dataTransfer) {
                        // picInput.files = event.dataTransfer.files;

                        // @ts-ignore
                        const name = event.dataTransfer.files[0].name;
                        if (event.dataTransfer.files[0].type.length && acceptableImages.includes(event.dataTransfer.files[0].type)) {
                            this.setState(prevState => ({...prevState, picUploadLabel: name}));
                            // @ts-ignore
                            reader.readAsDataURL(event.dataTransfer.files[0]);
                        } else {
                            Notification.error("Format non supporté");
                        }
                    }
                }
                dropReader.onloadend = () => {
                    this.setState(prevState => {
                        let nState = {...prevState};
                        nState.tribute = {
                            ...prevState.tribute,
                            img: reader.result ? reader.result : '',
                            picturePath: reader.result ? reader.result : '',
                        };
                        return nState;
                    });
                }
            }
        }

        this.props.router.transitionService.onStart({
            from: (state?: StateObject) => {
                return state?.name === 'connected.paanteon.user.new-tribute' && this.state.modified && !this.state.openConfirmModal && !this.saved;
            },
        }, (transition) => {
            this.setState(prevState => ({
                ...prevState,
                openConfirmModal: true,
                lastSavedTransition: {...transition.to(), params: transition.params()},
            }));
            return false;
        })
    }

    selectGallery(collectionId: number, year: number | null) {
        for (let index = 0; index < this.props.galleries.length; index++) {
            const gallery = this.props.galleries[index];
            if (gallery.yearly && year && Number(gallery.label) === year) { // select year virtual gallery instead of db gallery
                this.setState({
                    selectedGallery: gallery,
                });
            } else if (!gallery.yearly) { // normal selection we look for the gallery containing the collection
                const collection = gallery.Collections.filter((el) => {
                    return el.collectionId === collectionId;
                })[0];
                if (collection) {
                    this.setState({
                        selectedGallery: gallery,
                        yearlyGalleryCollectionsFilter: collection.galleryId ? collection.galleryId : 1,
                        selectedCollection: collection.collectionId,
                        selectedCollectionLabel: collection.label,
                    });
                }
            }
        }
    }

    showEngraveError = () => {
        Notification.error("Veuillez graver d'abord votre inspiration")
    }

    closeConfirmModal = () => {
        this.setState(prevState => ({
            ...prevState,
            openConfirmModal: false,
        }));
    }

    goToLastPage = () => {
        // @ts-ignore
        this.props.router.stateService.go(this.state.lastSavedTransition.name, this.state.lastSavedTransition.params);
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">

                            <div className="link-tabs" style={{position: "relative"}}>
                                <Toggleable>
                                    {
                                        this.props.galleries.sort(filterHelper.orderByField<Gallery, 'galleryId'>('galleryId')).map(gallery => {
                                            return (
                                                <div className="tab-item toggle-popin-menu" key={gallery.galleryId}>
                                                    <span className={"toggle-button" + (this.state.selectedGallery && this.state.selectedGallery.galleryId === gallery.galleryId ? ' active' : '')}>{gallery.label}</span>
                                                    <div className="popin-menu block">
                                                        {
                                                            !!gallery.yearly ?
                                                                <div className="link-tabs margin-bottom">
                                                                    {/* TODO: replace display condition for "jardin secret" when existing flag in DB
                                                                + REMOVE CODE DUPLICATION ON THIS COMPONENT!! :) (paanteon.all, paanteon.collection, and here...)*/}

                                                                    {
                                                                        this.props.galleries.sort(filterHelper.orderByField<Gallery, 'galleryId'>('galleryId')).map(galleryItem => {
                                                                            return !galleryItem.yearly && galleryItem.label.toLowerCase() !== 'jardin secret' ?
                                                                                <button
                                                                                    className={"tab-item" + (this.state.yearlyGalleryCollectionsFilter === galleryItem.galleryId && this.state.selectedGallery && this.state.selectedGallery.yearly === gallery.yearly ? ' active' : '')}
                                                                                    key={galleryItem.galleryId}
                                                                                    onClick={(e: React.SyntheticEvent) => {
 this.setCollectionsFilter(galleryItem.galleryId); e.stopPropagation()
}}>
                                                                                    {galleryItem.label}
                                                                                </button>
                                                                                : null
                                                                        })
                                                                    }
                                                                </div>
                                                                : null
                                                        }

                                                        <div className="cols-3">
                                                            {
                                                                gallery.Collections.filter((col) => {
                                                                    return gallery.yearly ? col.galleryId === this.state.yearlyGalleryCollectionsFilter : true;
                                                                }).map(collection => {
                                                                    return <button
                                                                        className={"item" + (this.state.selectedCollection === collection.collectionId && this.state.selectedGallery?.yearly && this.state.selectedGallery.label === gallery.label ? ' active' : '')}
                                                                        key={collection.collectionId}
                                                                        onClick={() => this.selectGallery(collection.collectionId, Number(gallery.label) || null)}>{collection.label}</button>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Toggleable>
                            </div>


                            <div className="margin-large-top">
                                <Autosuggest
                                    inputProps={{
                                        value: this.state.tribute.thumbnail,
                                        onChange: this.setThumbnail,
                                        placeholder: "Entrer le nom de votre inspiration",
                                    }}
                                    suggestions={this.state.searchedTributes}
                                    getSuggestionValue={getThumbnailSuggestionValue}
                                    onSuggestionsFetchRequested={this.searchTributes}
                                    onSuggestionsClearRequested={this.clearThumbnailSuggestions}
                                    renderSuggestion={renderThumbnailSuggestion}
                                    shouldRenderSuggestions={shouldRenderThumbnailSuggestions}
                                    theme={{
                                        container: 'react-autosuggest__container',
                                        containerOpen: 'react-autosuggest__container--open',
                                        input: "input big no-border block",
                                        inputOpen: 'react-autosuggest__input--open',
                                        inputFocused: 'react-autosuggest__input--focused',
                                        suggestionsContainer: 'react-autosuggest__suggestions-container',
                                        suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
                                        suggestionsList: 'react-autosuggest__suggestions-list',
                                        suggestion: 'react-autosuggest__suggestion',
                                        suggestionFirst: 'react-autosuggest__suggestion--first',
                                        suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
                                        sectionContainer: 'react-autosuggest__section-container',
                                        sectionContainerFirst: 'react-autosuggest__section-container--first',
                                        sectionTitle: 'react-autosuggest__section-title',
                                    }}
                                />

                                <h3 className="collection-title margin h-center">{this.state.selectedCollectionLabel}</h3>
                            </div>

                            <div>
                                <div id={"upload-box"} className={"upload-box" + (!!this.state.tribute.picturePath ? ' preview' : '')} style={{backgroundImage: 'url(' + this.state.tribute.picturePath + ')'}}>
                                    <input type="file" name="tribute-pic" id="tribute-pic" accept={acceptableImages}/>
                                    <label htmlFor="tribute-pic">
                                        <img src={pictoCamera} alt="picto camera"/>
                                        <br/>
                                        <span style={{whiteSpace: 'pre-wrap'}}>{this.state.tribute.picturePath ? null : this.state.picUploadLabel}</span>
                                    </label>
                                </div>

                                <div className="margin-large h-center">
                                    <button onClick={() => this.saveTribute()} className="btn btn-1">JE GRAVE</button>
                                </div>
                            </div>

                            <div className={"tile margin-large tribute-writing-bloc" + (!this.state.activeTributeEditing ? ' collapsed' : '')}>
                                <div className="tile-head">
                                    <div className="uk-grid uk-grid-small">
                                        <div className="uk-width-expand">
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a href="">&gt; Voir son paanteon</a><br/>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a href="">&gt; Voir le livre d'or</a>
                                        </div>
                                        <div>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a href=""><img src={pictoWikipedia} alt="picto wikipedia" className="picto"/> Page Wikipedia</a><br/>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a href=""><img src={pictoLink} alt="picto link" className="picto"/> Site officiel</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="tile-body">
                                    <div className="right">
                                        <button className="btn link-btn blue" onClick={() => this.saveTribute(true)}><img src={pictoSave} className="picto" alt="picto save"/>Sauvegarder</button>
                                    </div>
                                    <input type="text" maxLength={80} onChange={this.setTitle} value={this.state.tribute.title} className="input block margin-top" placeholder={this.state.activeTributeEditing ? 'Ajouter un titre' : 'Je rédige un hommage'} onClick={this.activateTributeEditing}/>

                                    <div className="margin-top">
                                        <RTE placeholder="Rédiger un hommage" onChange={this.setContent}><div className="rte margin tribute"/></RTE>
                                    </div>
                                </div>
                            </div>

                            <div className="margin-large h-center">
                                <button className="btn btn-1" onClick={() => this.saveTribute()}>JE GRAVE</button>
                            </div>

                            <div>
                                <div className={"tile dark-blue switchable margin-large "}>
                                    <div className="tile-head">
                                        <h3 className="tile-title">Mes recommandations pour aller plus loin</h3>
                                    </div>
                                    <div className="tile-body h-center">
                                        <button className="plus-card" onClick={this.showEngraveError}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.openConfirmModal && <ConfirmModal cancelMessage="Retour" confirmMessage="Quitter" onCancel={this.closeConfirmModal} onConfirm={this.goToLastPage}>
                        Êtes-vous sûr(e) de vouloir quitter cette page ?
                    </ConfirmModal>
                }
            </div>
        );
    }
}

export default injectUIRouter(NewTribute);
