import React from 'react';
import ReactDOM from 'react-dom';
import pictoPaanteon from '../../assets/img/pictos/picto-paanteon-light-blue.svg';
import $ from "jquery";
import images from "../../api/images";
import {Thumbnail} from "../../api/types";
import {Link} from "../../api/injectors";
import localStorage from "../../api/localStorage";

const slickSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    variableWidth: true,
    centerMode: true,
    rows: 0,
    autoplay: true,
    autoplaySpeed: 4000,
};

class ThumbsCarousel extends React.Component<{ thumbs?: Thumbnail[] }, { thumbs: Thumbnail[] }> {
    private isConnected: boolean = localStorage.isSetLocalUser();
    constructor(props: { thumbs?: Thumbnail[] }) {
        super(props);
        this.state = {
            thumbs: props.thumbs ? props.thumbs : [],
        }
    }

    componentDidMount() {
        if (!this.state.thumbs.length)
            images.getPopular().then(res => {
                this.setState({
                    thumbs: res.data,
                });
                $(ReactDOM.findDOMNode(this) as HTMLDivElement).slick(slickSettings);
            }, err => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
        else
            $(ReactDOM.findDOMNode(this) as HTMLDivElement).slick(slickSettings);
    }

    render() {
        return (
            <div className="carousel thumbs-carousel">
                {
                    this.state.thumbs.map(thumb => {
                        return thumb.Tributes && thumb.Tributes[0].Collection && (this.isConnected ? (
                                    <Link key={thumb.thumbnailId}
                                          className="card"
                                          style={{backgroundImage: 'url(' + thumb.Tributes[0].picturePath + ')'}}
                                          to="connected.paanteon.thumbnail.tributes"
                                          params={{thumbnailId: thumb.thumbnailId, thumbnailUniqueName: thumb.uniqueName}}>
                                        <div className="chip">{thumb.tributeCount}
                                            <img className="picto" src={pictoPaanteon} alt="picto-paanteon"/>
                                        </div>
                                        <div className="card-footer">
                                            <p className="card-name">{thumb.label}</p>
                                            <p className="card-title">{thumb.Tributes[0].Collection.label}</p>
                                        </div>
                                    </Link>
                                ) :
                                <div key={thumb.thumbnailId}
                                     className="card"
                                     style={{backgroundImage: 'url(' + thumb.Tributes[0].picturePath + ')'}}>
                                    <div className="chip">{thumb.tributeCount}
                                        <img className="picto" src={pictoPaanteon} alt="picto-paanteon"/>
                                    </div>
                                    <div className="card-footer">
                                        <p className="card-name">{thumb.label}</p>
                                        <p className="card-title">{thumb.Tributes[0].Collection.label}</p>
                                    </div>
                                </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default ThumbsCarousel;
