import React, { ChangeEvent } from 'react';
import adminRequests from '../../api/adminRequests';
import { Link } from "../../api/injectors";
import { Tag, TagCategory } from "../../api/types";
import Page from "../Page";
import Notification from "../../api/notification";
import ConfirmModal from "../../components/ConfirmModal";
import {deleteFromArrayAndReturn} from "../../api/helpers";
import Helmet from "../../components/Helmet";

type Props = {
    tagCategories: {data: TagCategory[] }
}

type State = {
    newCategoryName: string,
    tagCategories: TagCategory[],
    tagNewNames: Map<number, string>,
    categoryToDelete: TagCategory | null,
    openedTagCategoryId: number | null,
}

class Tags extends Page<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newCategoryName: "",
            tagCategories: props.tagCategories.data,
            tagNewNames: new Map(props.tagCategories.data.map(cat => [cat.tagCategoryId, ""])),
            categoryToDelete: null,
            openedTagCategoryId: null,
        };
    }

    setOpenedTCId = (tcId: number | null) => {
        if (this.state.openedTagCategoryId === tcId) tcId = null;
        this.setState(prevState => ({
            ...prevState,
            openedTagCategoryId: tcId,
        }));
    }

    createTagCategory = () => {
        adminRequests.createTagCategory({label: this.state.newCategoryName})
            .then((response) => {
                const category: TagCategory = {...response.data, Tags: response.data.Tags || []};

                this.setState(oldState => {
                    const newCategoriesNewNames = new Map(oldState.tagNewNames);
                    newCategoriesNewNames.set(category.tagCategoryId, "");

                    return {
                        ...oldState,
                        newCategoryName: "",
                        tagCategories: [...oldState.tagCategories, category],
                        tagNewNames: newCategoriesNewNames,
                    };
                });

                Notification.success("La catégorie a été créée avec succès");
            }, () => Notification.error("Échec lors de la création de la catégorie"));
    };
    deleteCategory = (tagCategory: TagCategory) => {
        adminRequests.deleteTagCategory(tagCategory.tagCategoryId)
            .then(() => {
                this.setState(oldState => ({
                    ...oldState,
                    tagCategories: oldState.tagCategories.filter(cat => cat.tagCategoryId !== tagCategory.tagCategoryId),
                    categoryToDelete: null,
                }));

                Notification.success("La catégorie a été supprimée avec succès");
            }, () => Notification.error("Échec lors de la suppression de la catégorie"));
    }
    updateTagCategory(tagCategory: TagCategory): void {
        adminRequests.updateTagCategory(tagCategory.tagCategoryId, tagCategory)
            .then(() => {
                Notification.success("La catégorie a été mise à jour avec succès");
            }, () => Notification.error("Échec lors de la suppression de la catégorie"));
    }
    updateTagCategoryLabel = (text: string, tagCategoryId: number) => {
        this.setState(oldState => {
            const newTagCategories = [...oldState.tagCategories];
            const tagCategoryIdx = oldState.tagCategories.findIndex(cat => cat.tagCategoryId === tagCategoryId);
            newTagCategories[tagCategoryIdx] = {
                ...newTagCategories[tagCategoryIdx],
                label: text,
            };
            return {
                ...oldState,
                tagCategories: newTagCategories,
            };
        });
    }
    updateTagCategoryOrder = (order: string, tagCategoryId: number) => {
        this.setState(oldState => {
            const newTagCategories = [...oldState.tagCategories];
            const tagCategoryIdx = oldState.tagCategories.findIndex(cat => cat.tagCategoryId === tagCategoryId);
            newTagCategories[tagCategoryIdx] = {
                ...newTagCategories[tagCategoryIdx],
                order: parseInt(order),
            };
            return {
                ...oldState,
                tagCategories: newTagCategories,
            };
        });
    }
    updateNewTagCategoryLabel = (event: ChangeEvent<HTMLInputElement>) => {
        const newCategoryName = event.target.value;
        this.setState({newCategoryName});
    }
    createTag = (tagCategoryId: number) => {
        adminRequests.createTag({
            label: this.state.tagNewNames.get(tagCategoryId)!,
            tagCategoryId,
        }).then(resp => {

            this.setState(oldState => {
                const newTagCategories = [...oldState.tagCategories];
                const tagCategoryIdx = oldState.tagCategories.findIndex(cat => cat.tagCategoryId === tagCategoryId);
                const newTagNewNames = new Map(oldState.tagNewNames);

                newTagCategories[tagCategoryIdx] = {
                    ...newTagCategories[tagCategoryIdx],
                    Tags: [...newTagCategories[tagCategoryIdx].Tags, resp.data],
                };
                newTagNewNames.set(tagCategoryId, "");

                return {
                    ...oldState,
                    tagCategories: newTagCategories,
                    tagNewNames: newTagNewNames,
                };
            });

            Notification.success("Le tag a été créé avec succès");
        }, () => Notification.error("Échec lors de la création du tag"));
    };
    deleteTag = (tag: Tag) => {
        adminRequests.deleteTag(tag.tagId)
            .then(() => {
                this.setState(oldState => {
                    const newTagCategories = [...oldState.tagCategories];
                    const editedTagCategory = newTagCategories[oldState.tagCategories.findIndex(cat => cat.tagCategoryId === tag.tagCategoryId)];

                    editedTagCategory.Tags = deleteFromArrayAndReturn(editedTagCategory.Tags, editedTagCategory.Tags.findIndex(t => t.tagId === tag.tagId));

                    return {
                        ...oldState,
                        tagCategories: newTagCategories,
                    };
                });

                Notification.success("Le tag a été supprimé avec succès");
            }, () => Notification.error("Échec lors de la suppression du tag"));
    }
    updateTag(tag: Tag): void {
        adminRequests.updateTag(tag.tagId, tag)
            .then(() => {
                Notification.success("Le tag a été mis à jour avec succès")
            }, () => Notification.error("Échec lors de la mise à jour du tag"));
    }
    updateTagLabel = (text: string, tag: Tag) => {
        this.setState(oldState => {
            const newTagCategories = [...oldState.tagCategories];
            const tagCategoryIdx = oldState.tagCategories.findIndex(cat => cat.tagCategoryId === tag.tagCategoryId);
            const newTags = [...newTagCategories[tagCategoryIdx].Tags];
            const editedTagIdx = newTagCategories[tagCategoryIdx].Tags.findIndex(t => t.tagId === tag.tagId);

            newTags[editedTagIdx] = {
                ...newTags[editedTagIdx],
                label: text,
            };

            newTagCategories[tagCategoryIdx] = {
                ...newTagCategories[tagCategoryIdx],
                Tags: newTags,
            };

            return {
                ...oldState,
                tagCategories: newTagCategories,
            };
        });
    }
    updateNewTagLabel = (text: string, tagCategoryId: number) => {
        this.setState(oldState => {
            const newTagNewNames = new Map(oldState.tagNewNames);

            newTagNewNames.set(tagCategoryId, text);
            return {
                ...oldState,
                tagNewNames: newTagNewNames,
            };
        });
    }

    openDeleteCategoryModal = (tagCategory: TagCategory) => {
        this.setState({categoryToDelete: tagCategory});
    }

    closeDeleteCategoryModal = () => {
        this.setState({categoryToDelete: null});
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'Gestion des tags | Paanteon'} />
                            <h1 className="page-title">
                                Gestion des tags
                            </h1>

                            <div className="margin-bottom">
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>
                            </div>

                            <div>
                                <h4>Ajouter une catégorie</h4>
                                <input className="input" size={50} placeholder="Nom de la catégorie" onChange={this.updateNewTagCategoryLabel} value={this.state.newCategoryName}/>
                                <button className="btn btn-1" onClick={this.createTagCategory}>Créer</button>
                            </div>

                            <div className="margin-top">
                                <h2>Liste des catégories</h2>
                                {
                                    this.state.tagCategories.map((tagCategory: TagCategory) => {
                                        return <div className="margin-bottom" key={tagCategory.tagCategoryId}>
                                            <div className="uk-grid" data-uk-grid="">

                                                <div style={{width: "6em"}}>
                                                <button
                                                        onClick={() => this.setOpenedTCId(tagCategory.tagCategoryId)}
                                                        className={`collapsible-tags-head ${this.state.openedTagCategoryId === tagCategory.tagCategoryId ? "opened" : ""}`}>
                                                </button>
                                                    ID: {tagCategory.tagCategoryId}
                                                </div>
                                                <div className="uk-width-expand">
                                                    <input className="input block" value={tagCategory.label} onChange={(event) => this.updateTagCategoryLabel(event.target.value, tagCategory.tagCategoryId)}/>
                                                </div>
                                                <div>
                                                    <input type="number" placeholder="ordre" className="input block" value={tagCategory.order} onChange={(event) => this.updateTagCategoryOrder(event.target.value, tagCategory.tagCategoryId)}/>
                                                </div>
                                                <div>
                                                    <button className="btn btn-1" onClick={() => this.updateTagCategory(tagCategory)}>Sauvegarder</button>
                                                    <button className="btn btn-1" onClick={() => this.openDeleteCategoryModal(tagCategory)}>Supprimer</button>
                                                </div>
                                            </div>
                                            { this.state.openedTagCategoryId === tagCategory.tagCategoryId &&
                                                <div>
                                                    <h4>Tags</h4>
                                                    <div className="margin-bottom">
                                                        Nouveau tag:&nbsp;
                                                        <input className="input" size={50} placeholder="Nom du tag" onChange={(event) => this.updateNewTagLabel(event.target.value, tagCategory.tagCategoryId)} value={this.state.tagNewNames.get(tagCategory.tagCategoryId) || ""}/>
                                                        <button className="btn btn-1" onClick={() => this.createTag(tagCategory.tagCategoryId)}>Créer</button>
                                                    </div>
                                                    {
                                                        tagCategory.Tags.map((tag: Tag) => {
                                                            return <div className="uk-grid uk-grid-small" key={tag.tagId} data-uk-grid="">
                                                                <div style={{width: "4em"}}/>
                                                                <div style={{width: "4em"}}>ID: {tag.tagId}</div>
                                                                <div className="uk-width-expand">
                                                                    <input className="input block" value={tag.label} onChange={(event) => this.updateTagLabel(event.target.value, tag)}/>
                                                                </div>
                                                                <div>
                                                                    <button className="btn btn-1" onClick={() => this.updateTag(tag)}>Sauvegarder</button>
                                                                    <button className="btn btn-1" onClick={() => this.deleteTag(tag)}>Supprimer</button>
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                            <hr/>
                                        </div>
                                    })
                                }
                            </div>

                            {
                                this.state.categoryToDelete !== null && <ConfirmModal onCancel={this.closeDeleteCategoryModal} onConfirm={() => this.deleteCategory(this.state.categoryToDelete!)}>
                                    Voulez-vous vraiment supprimer la catégorie "{this.state.categoryToDelete.label}" ?
                                </ConfirmModal>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tags;
