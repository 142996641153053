import React, { useMemo } from "react";
import UserSidebar from "../components/UserSidebar";
import Helmet from "../components/Helmet";
import { Tribe as TribeType } from "../api/types";
import UserTribe from "../components/Tribes/UserTribe";
import { useTribe } from "../components/Tribes/tribeProvider";

const Tribe = ({userTribes, ...props}: {userTribes: {Tribes : TribeType[], userId: number, totalUser: number}, [x:string]:any}) => {

    const { openTribeId, setOpenTribeId } = useTribe();

    const sortedTribeMembers = useMemo(()=>userTribes.Tribes.sort((a, b) => {
        return b.Users.length - a.Users.length
    }), [userTribes.Tribes])

    return (
        <>
            <Helmet title={"Tribus | Paanteon"} />
            <div className="dark-bg">
                <div className="uk-container">
                    <UserSidebar user={props.user} popular={props.popular} showCover={true}/>
                    <div className="main-content">
                        <div className="uk-container">
                            <h1 className="page-title">Mes Tribus</h1>
                            <div className="margin-top-xl">
                                {userTribes.Tribes.length === 0 && props.user.Tributes.length >= 10 ? (
                                    <div className="padding-top">
                                        <div className="tribe-card no-tribe">
                                            <div className="tribe-card-head">
                                                <p className="tribe-name">Vous pourrez bientôt voir vos tribus, continuez de construire votre paanteon.</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : props.user.Tributes.length < 10 ?
                                (
                                    <div className="padding-top">
                                        <div className="tribe-card no-tribe">
                                            <div className="tribe-card-head">
                                                <p className="tribe-name">Vous pourrez bientôt voir vos tribus, continuez de construire votre paanteon.</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : sortedTribeMembers.map(
                                        (tribe: TribeType) => (
                                            <UserTribe
                                                handleClick={(tribeId: number)=>{
                                                    if (openTribeId === tribeId) {
                                                        setOpenTribeId(null);
                                                    } else {
                                                        setOpenTribeId(tribeId);
                                                    }
                                                }}
                                                tribe={tribe}
                                                userId={userTribes.userId}
                                                connectedUserScore={userTribes.totalUser}
                                                key={tribe.tribeId}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tribe;
