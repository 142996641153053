import React from 'react';
import {Tribute, UserInfos, Tribe} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import moment, {Moment} from "moment";
import {Link} from "../api/injectors";
import Page from "./Page";
import Helmet from "../components/Helmet";

interface ArchivedTribute extends Tribute {
    archivedAt: string
}

type Props = {
    archives: {data: ArchivedTribute[]}
    user: UserInfos
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = {
    dates: {date: Moment, tributes: ArchivedTribute[]}[]
}

class Archives extends Page<Props, State> {
    constructor(props: Props) {
        super(props);

        const dates: {date: Moment, tributes: ArchivedTribute[]}[] = [];
        if (props.archives.data.length) {
            dates.push({
                date: moment(props.archives.data[0].createdAt),
                tributes: [props.archives.data[0]],
            });
            for (let i = 1; i < props.archives.data.length; i++) {
                const d = moment(props.archives.data[i].createdAt);
                if (d.isSame(dates[dates.length - 1].date, 'day')) {
                    dates[dates.length - 1].tributes.push(props.archives.data[i]);
                } else {
                    dates.push({
                        date: d,
                        tributes: [props.archives.data[i]],
                    });
                }
            }
        }

        this.state = {dates};
    }


    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <Helmet title={'Mes archives | Paanteon'} />
                    <div className="page-head" style={{backgroundImage: 'url(' + this.props.user.coverPath + ')'}}>
                        <h1>Mes archives</h1>
                    </div>
                    <UserSidebar user={this.props.user} showButtons />

                    <div className="main-content">
                        {
                            this.state.dates.map(date =>
                                <div key={date.date.valueOf()}>
                                    <div className="collection-subtitle">
                                        {date.date.format('LL').toUpperCase()}
                                    </div>

                                    <div className="uk-grid uk-grid-small gallery-grid">
                                        {
                                            date.tributes.map(tribute =>
                                                <div className="uk-width-1-3@m uk-width-1-4@l" key={tribute.tributeId}>
                                                    <Link to="connected.paanteon.tribute.details" params={{tributeId: tribute.tributeId, thumbnailUniqueName: tribute.Thumbnail.uniqueName, username: this.props.user.username}} className="card expand"
                                                          style={{backgroundImage: 'url(' + tribute.picturePath + ')'}}>
                                                        <canvas width="250" height="350"/>
                                                        <div className="card-footer">
                                                            <p className="card-name">{tribute.Thumbnail.label}</p>
                                                            <p className="card-title">{tribute.Collection.label}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }

                        {
                            this.state.dates.length === 0 && <span>Aucun hommage archivé</span>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Archives;
