import {FunctionComponent, useEffect} from "react";
import Helmet from "../components/Helmet";
import * as React from "react";
import tutoSlide1 from "../assets/img/tutorial/tutorial-1.jpg";
import tutoSlide2 from "../assets/img/tutorial/tutorial-2.jpg";
import tutoSlide3 from "../assets/img/tutorial/tutorial-3.jpg";
import tutoSlide4 from "../assets/img/tutorial/tutorial-4.jpg";
import tutoSlide5 from "../assets/img/tutorial/tutorial-5.jpg";
import tutoSlide6 from "../assets/img/tutorial/tutorial-6.jpg";
import tutoSlide8 from "../assets/img/tutorial/tutorial-8.jpg";
import {Link} from "../api/injectors";
import PublicHeader from "../components/PublicHeader";
import { UserInfos } from "../api/types";

type Props = {
    user?: UserInfos
}
const Presentation: FunctionComponent<Props> = (props) => {
    useEffect(() => {
        /**
         * Scroll the page to the top on component mount
         */
        window.scrollTo(0, 0);
    }, []);

        return (
            <>
                {
                    !props.user &&
                        <PublicHeader/>
                }
                <div className="dark-bg">
                    <div className="uk-width-1-1 padding-top text-center">
                        <Helmet title={`Présentation | Paanteon`} />
                        <h1 className="page-title">Présentation</h1>

                        <div className="main-content no-padding-top">
                            <div>
                                <div className="uk-grid flex-center">
                                    <img className={"uk-width-1-1"} src={tutoSlide1} alt={""} />
                                    <img className={"uk-width-1-1"} src={tutoSlide2} alt={""} />
                                </div>
                                <div className="uk-grid flex-center">
                                    <img className={"uk-width-1-1"} src={tutoSlide3} alt={""} />
                                    <img className={"uk-width-1-1"} src={tutoSlide4} alt={""} />
                                    <img className={"uk-width-1-1"} src={tutoSlide5} alt={""} />
                                    <img className={"uk-width-1-1"} src={tutoSlide6} alt={""} />
                                    <Link to={"connected.home"}>
                                        <img className={"uk-width-1-1"} src={tutoSlide8} alt={""} />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default Presentation;
