import React, {Component} from 'react';
import filterHelper from "../api/filterHelper";
import {Collection, Gallery, Tribute, TributeToSend, User, UserInfos} from "../api/types";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../api/injectors";
import localStorage from "../api/localStorage";
import {pictoCamera, pictoLink, pictoSave, pictoWikipedia} from "../api/pictos";
import Notification from "../api/notification";
import paanteon from "../api/paanteonRequests";
import RTE from "../elements/RTE";
import Recommendations from "../components/Recommendations";
import {StateObject, StateDeclaration} from "@uirouter/react";
import ConfirmModal from "../components/ConfirmModal";
import {acceptableImages} from "../api/domHelper";

interface OwnProps {
    user: UserInfos
    galleries: (Gallery & { userCollections: Collection[] })[]
    tribute: { data: Tribute }
    userTributes: {tributeId: number, archivedAt: string, uniqueName: string}[]
}

type Props = OwnProps & InjectedUIRouterProps;

type State = Readonly<{
    selectedGallery?: Gallery
    tribute: TributeToSend & {tributeId: number}
    picUploadLabel: string
    activeTributeEditing: boolean
    modified: boolean
    openConfirmModal: boolean
    lastSavedTransition: StateDeclaration | null
}>;

class EditTribute extends Component<Props, State> {
    readonly state: State = {
        tribute: {
            ...this.props.tribute.data,
            yearly: this.props.tribute.data.yearly === 1,
            tributeId: this.props.tribute.data.tributeId || -1,
            thumbnail: this.props.tribute.data.Thumbnail.label,
        },
        picUploadLabel: "J'ajoute une photo",
        activeTributeEditing: (this.props.tribute.data.title.length + this.props.tribute.data.content.length) > 0,
        modified: false,
        openConfirmModal: false,
        lastSavedTransition: null,
    };
    private isMyPaanteon = this.props.user.userId === localStorage.getLocalUser().userId;
    private readonly nextTribute?: {tributeId: number, thumbnailUniqueName: string};
    private readonly prevTribute?: {tributeId: number, thumbnailUniqueName: string};
    private user: User = localStorage.getLocalUser();
    private saved: boolean = false;
    private tributeTitleInput = React.createRef<HTMLInputElement>();

    activateTributeEditing = () => {
        this.setState(prevState => ({...prevState, activeTributeEditing: true}));
    };

    setContent = (html: string) => {
        const val = html.replace(/<style[^>]*>(.|\s)*<\/style>/gim, '');
        this.setState(prevState => {
            let newSate = {...prevState};
            if (prevState.tribute.content !== val && val !== '<br>') newSate.modified = true;
            newSate.tribute = {
                ...prevState.tribute,
                content: val,
            };
            return newSate;
        });
    };

    setTitle = (event: React.FormEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => {
            let newSate = {...prevState, modified: true};
            newSate.tribute = {
                ...prevState.tribute,
                title: val,
            };
            return newSate;
        });
    };

    saveTribute = (draft?: boolean) => {
        // handle yearly virtual gallery
        if (this.state.selectedGallery && this.state.selectedGallery.yearly) {
            this.setState(prevState => {
                let newSate = {...prevState};
                newSate.tribute = {
                    ...prevState.tribute,
                    yearly: true,
                };
                return newSate;
            });
        }
        this.setState(prevState => {
            let newSate = {...prevState};
            newSate.tribute = {
                ...prevState.tribute,
                draft: 0, // was 1 previously, remove draft mode
            };
            return newSate;
        });
        let request = paanteon.updateTribute(this.state.tribute);
        request.then((result) => {
            Notification.success('Hommage enregistré');
            this.saved = true;
            this.props.router.stateService.go("connected.paanteon.tribute.details", {tributeId: this.state.tribute.tributeId, thumbnailUniqueName: this.props.tribute.data.Thumbnail.uniqueName, username: this.props.user.username}, {reload: true, inherit: false, notify: true});
        }, function(error) {
            if (error.response && error.response.status === 400) {
                Notification.warning('Formulaire incomplet! Veuillez renseigner le nom et une photo.');
            } else if (error.response && error.response.status === 412) {
                Notification.warning('Vous disposez du maximum de 12 vignettes dans votre collection!');
            } else {
                Notification.error('Erreur lors de la sauvegarde de l\'hommage');
            }
        });
    };

    constructor(props: Props) {
        super(props);

        const tributes = props.tribute.data.archivedAt ?
            props.userTributes.filter(t => !!t.archivedAt)
            :
            props.userTributes.filter(t => !t.archivedAt);
        let pos: number;
        for (pos = 0; pos < tributes.length; pos++) {
            if (tributes[pos].tributeId === this.props.tribute.data.tributeId) {
                break;
            }
        }
        if (tributes[pos + 1]) {
            this.nextTribute = {tributeId: tributes[pos + 1].tributeId, thumbnailUniqueName: tributes[pos + 1].uniqueName};
        }
        if (tributes[pos - 1]) {
            this.prevTribute = {tributeId: tributes[pos - 1].tributeId, thumbnailUniqueName: tributes[pos - 1].uniqueName};
        }
    }

    componentDidMount(): void {
        for (let index = 0; index < this.props.galleries.length; index++) {
            const gallery = this.props.galleries[index];
            if (gallery.yearly && this.props.tribute.data.yearly) { // select year virtual gallery instead of db gallery
                this.setState({
                    selectedGallery: gallery,
                });
            } else if (!this.props.tribute.data.yearly) { // normal selection we look for the gallery containing the collection
                const collection = gallery.Collections.filter((el) => {
                    return el.collectionId === this.props.tribute.data.collectionId
                })[0];
                if (collection) {
                    this.setState({
                        selectedGallery: gallery,
                    });
                    break;
                }
            }
        }

        const picInput = document.getElementById('tribute-pic') as HTMLInputElement;
        if (picInput) {
            // handling tribute's picture upload
            var reader = new FileReader();
            picInput.addEventListener( 'change', ( e: Event ) => {
                // @ts-ignore
                if (e.currentTarget && (e.currentTarget as HTMLInputElement).files !== null && (e.currentTarget as HTMLInputElement).files.length > 0) {
                    // @ts-ignore
                    const name = (e.currentTarget as HTMLInputElement).files[0].name;
                    this.setState(prevState => ({...prevState, picUploadLabel: name}));
                    // @ts-ignore
                    reader.readAsDataURL((e.currentTarget as HTMLInputElement).files[0]);
                }
            });
            reader.onloadend = () => {
                this.setState(prevState => {
                    let nState = {...prevState};
                    nState.tribute = {
                        ...prevState.tribute,
                        img: reader.result ? reader.result : '',
                        picturePath: reader.result ? reader.result : '',
                    };
                    return nState;
                });
            }

            const uploadBox = document.getElementById('upload-box');
            if (uploadBox) {
                uploadBox.ondragover = uploadBox.ondragenter = evt => {
                    evt.stopPropagation();
                    evt.preventDefault();
                }

                const dropReader = new FileReader();
                uploadBox.ondrop = event => {
                    event.stopPropagation();
                    event.preventDefault();
                    if (event.dataTransfer) {
                        // picInput.files = event.dataTransfer.files;

                        // @ts-ignore
                        const name = event.dataTransfer.files[0].name;
                        if (event.dataTransfer.files[0].type.length && acceptableImages.includes(event.dataTransfer.files[0].type)) {
                            this.setState(prevState => ({...prevState, picUploadLabel: name}));
                            // @ts-ignore
                            reader.readAsDataURL(event.dataTransfer.files[0]);
                        } else {
                            Notification.error("Format non supporté");
                        }
                    }
                }
                dropReader.onloadend = () => {
                    this.setState(prevState => {
                        let nState = {...prevState};
                        nState.tribute = {
                            ...prevState.tribute,
                            img: reader.result ? reader.result : '',
                            picturePath: reader.result ? reader.result : '',
                        };
                        return nState;
                    });
                }
            }
        }

        this.props.router.transitionService.onStart({
            from: (state?: StateObject) => {
                return state?.name === 'connected.paanteon.tribute.details.edit' && this.state.modified && !this.state.openConfirmModal && !this.saved;
            },
        }, (transition) => {
            this.setState(prevState => {
                return {
                    ...prevState,
                    openConfirmModal: true,
                    lastSavedTransition: {...transition.to(), params: transition.params()},
                };
            });
            return false;
        })

        this.tributeTitleInput.current?.click();
        this.tributeTitleInput.current?.focus();
    }

    closeConfirmModal = () => {
        this.setState(prevState => ({
            ...prevState,
            openConfirmModal: false,
        }));
    }

    goToLastPage = () => {
        // @ts-ignore
        this.props.router.stateService.go(this.state.lastSavedTransition.name, this.state.lastSavedTransition.params);
    }

    render() {
        const links = this.props.galleries.sort(filterHelper.orderByField<Gallery, 'galleryId'>('galleryId')).map((gallery: Gallery) => {
            return <Link to="connected.paanteon.user.galleries.all" params={{'#': "gallery-" + gallery.code, userId: this.props.user.userId}} className={"tab-item" + (this.state.selectedGallery && this.state.selectedGallery.galleryId === gallery.galleryId ? ' active' : '')} key={gallery.galleryId}>{gallery.label}</Link>
        });
        return (
            <>
                <div className="link-tabs">
                    {links}
                </div>

                <div className="margin-large">
                    <h2 className="title-1 title-with-nav no-margin-bottom">
                        {
                            this.prevTribute ?
                                <Link className="prev arrow" to="connected.paanteon.tribute.details" params={this.prevTribute}/>
                                : null
                        }
                        <span className="cap-first-letter">{this.props.tribute.data.Thumbnail.label}</span>
                        {
                            this.nextTribute ?
                                <Link className="next arrow" to="connected.paanteon.tribute.details" params={this.nextTribute}/>
                                : null
                        }
                    </h2>
                    <h3 className="collection-title no-margin h-center">{this.props.tribute.data.Collection.label}</h3>
                </div>

                <div>
                    <div id="upload-box" className={"upload-box" + (!!this.state.tribute.picturePath ? ' preview' : '')} style={{backgroundImage: 'url(' + this.state.tribute.picturePath + ')'}}>
                        <input type="file" name="tribute-pic" id="tribute-pic" accept={acceptableImages}/>
                        <label htmlFor="tribute-pic">
                            <img src={pictoCamera} alt="picto camera"/>
                            <br/>
                            <span>{this.state.tribute.picturePath ? null : this.state.picUploadLabel}</span>
                        </label>
                    </div>

                    <div className="margin-large h-center">
                        <button onClick={() => this.saveTribute()} className="btn btn-1">JE GRAVE</button>
                    </div>
                </div>

                <div className={"tile margin-large tribute-writing-bloc" + (!this.state.activeTributeEditing ? ' collapsed' : '')}>
                    <div className="tile-head">
                        <div className="uk-grid uk-grid-small">
                            <div className="uk-width-expand">
                                {
                                    this.props.tribute.data.Thumbnail.thumbnailUserId && <><Link to="connected.paanteon.user" params={{userId: this.props.tribute.data.Thumbnail.thumbnailUserId, username: this.props.tribute.data.Thumbnail.thumbnailUsername}}>&gt; Voir son paanteon</Link><br/></>
                                }
                                {
                                    this.props.tribute.data.Thumbnail.InMemoriam?.inMemoriamId && <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: this.props.tribute.data.Thumbnail.uniqueName, memoriamId: this.props.tribute.data.Thumbnail.InMemoriam.inMemoriamId}}>&gt; Voir le livre d'or</Link>
                                }
                            </div>
                            <div>
                                {
                                    this.props.tribute.data.Thumbnail.wikipedia && <><a href={this.props.tribute.data.Thumbnail.wikipedia} target="_blank" rel="noopener noreferrer"><img src={pictoWikipedia} className="picto" alt="picto wikipedia"/> Wikipédia</a><br/></>
                                }
                                {
                                    this.props.tribute.data.Thumbnail.website && <a href={this.props.tribute.data.Thumbnail.website} target="_blank" rel="noopener noreferrer"><img src={pictoLink} className="picto" alt="picto website"/> Site officiel</a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="tile-body">
                        <div className="right">
                            <button className="btn link-btn blue" onClick={() => this.saveTribute(true)}><img src={pictoSave} className="picto" alt="picto save"/>Sauvegarder</button>
                        </div>
                        <input maxLength={80} ref={this.tributeTitleInput} type="text" onChange={this.setTitle} value={this.state.tribute.title} className="input block margin-top" placeholder={this.state.activeTributeEditing ? 'Ajouter un titre' : 'Je rédige un hommage'} onClick={this.activateTributeEditing} />

                        <div className="margin-top">
                            <RTE placeholder="Rédiger un hommage" onChange={this.setContent} value={this.state.tribute.content}><div className="rte margin tribute"/></RTE>
                        </div>
                    </div>
                </div>

                {
                    this.props.tribute.data.Recommendations && <Recommendations editable recommendations={this.props.tribute.data.Recommendations} tributeId={this.props.tribute.data.tributeId as number}/>
                }

                {
                    this.state.openConfirmModal && <ConfirmModal cancelMessage="Retour" confirmMessage="Quitter" onCancel={this.closeConfirmModal} onConfirm={this.goToLastPage}>
                        Êtes-vous sûr(e) de vouloir quitter cette page ?
                    </ConfirmModal>
                }
            </>
        );
    }
}

export default injectUIRouter(EditTribute);
