import React from 'react';
import Page from "./Page";
import {UserInfos, Tribe} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import {UIView} from "@uirouter/react";
import {ActiveLink} from "../api/injectors";
import Helmet from "../components/Helmet";

type Props = {
    user: UserInfos
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

class Parameters extends Page<Props> {
    render() {
        return (
            <>
                <div className="dark-bg">
                    <div className="uk-container">
                        <UserSidebar user={this.props.user} showCover={true}/>

                        <div className="main-content">
                            <div className="uk-container">
                                <Helmet title={'Paramètres | Paanteon'} />
                                <h1 className="page-title">
                                    Paramètres
                                </h1>

                                <div className="flex margin-bottom-m" style={{justifyContent: 'space-between'}}>
                                    <div style={{width: '30%'}}>
                                        <ActiveLink
                                            className="btn btn-outlined btn-block light"
                                            to="connected.parameters.account"
                                            class="active" exact>
                                            COMPTE
                                        </ActiveLink>
                                    </div>
                                    <div style={{width: '30%'}}>
                                        <ActiveLink
                                            className="btn btn-outlined btn-block light"
                                            to="connected.parameters.privacy"
                                            class="active" exact>
                                            CONFIDENTIALITÉ
                                        </ActiveLink>
                                    </div>
                                    <div style={{width: '30%'}}>
                                        <ActiveLink
                                            className="btn btn-outlined btn-block light"
                                            to="connected.parameters.notifications"
                                            class="active" exact>
                                            NOTIFICATIONS
                                        </ActiveLink>
                                    </div>
                                </div>

                                <UIView />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Parameters;
