import request from "./request";
import {Thumbnail} from "./types";

const images = {
    popular: [],

    getFeatured: () => {
        return request.get("/paanteon/featured");
    },

    getPopular: function(): Promise<{ data: Thumbnail[] }> {
        return this.popular.length ? Promise.resolve({data: this.popular}) : request.get("/paanteon/popular");
    },
};

export default images;
