import React, { ComponentType, createContext, useCallback, useState, useContext, ReactNode } from "react"
export interface TribeAPI {
    openTribeId: number | null;

    setOpenTribeId: (_openTribeId: number | null)=> void;
}

export const TribeContext = createContext<TribeAPI | null>(null);

export const TribeProvider = ({children}: {children: ReactNode}) => {
    const [openTribeId, _setOpenTribeId] = useState<TribeAPI['openTribeId']>(null);

    const setOpenTribeId: TribeAPI['setOpenTribeId'] = useCallback((_openTribeId: number | null) => {
        _setOpenTribeId(_openTribeId);
    }, [])
    return (
        <TribeContext.Provider value={{
            openTribeId,
            setOpenTribeId,
        }}>
            {children}
        </TribeContext.Provider>
    )
}

export function withProvideTribe<P extends Record<string, unknown>>(
    WrappedComponent: ComponentType<P>,
): ComponentType<P> {
    const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideTribe(props: P) {
    return (
      <TribeProvider>
        <WrappedComponent {...props} />
      </TribeProvider>
    );
  }

  WithProvideTribe.displayName = `withProvideTribe(${displayName})`;

  return WithProvideTribe;
}

export function useTribe(): TribeAPI {
  return useContext(TribeContext) as TribeAPI;
}
