import React from 'react';
import Page from "./Page";
import PublicHeader from "../components/PublicHeader";
import {Link} from "../api/injectors";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

type Props = {}

type State = {}

class TermsAndConditionsOfUse extends Page<Props, State> {
    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <div className="dark-bg">

                    <div className="uk-container main-content">
                        <Helmet title={'Conditions générales d’Utilisation de Paanteon | Paanteon'} />
                        <h1 className="page-title">
                            Conditions générales d’Utilisation de Paanteon
                        </h1>
                    </div>

                    <div className="section">
                        <div className="uk-container main-content-s body">
                            <p className="margin-bottom-xl">Date de dernière mise à jour :  1er juin 2021</p>

                            <p className="margin-bottom">Les présentes conditions générales d’utilisation (« <strong>CGU</strong> ») règlementent votre accès et votre utilisation du Site Internet Paanteon, fourni par la société Love Unlimited. Nous vous prions de lire attentivement ces CGU.</p>
                            <p className="margin-bottom-xl">En accédant et/ou en utilisant la Plateforme Paanteon, vous acceptez de vous conformer à ces CGU, à notre <Link to="privacy-policy">Politique de Confidentialité</Link> ainsi qu’à notre <Link to="community-code">Charte pour la communauté</Link>.</p>

                            <h2 className="title-2">Définitions</h2>
                            <p className="margin-bottom">« <strong>Compte Personnel</strong> » : désigne l’espace personnel d’un Utilisateur personne physique accessible à partir de ses identifiant et mot de passe.</p>
                            <p className="margin-bottom">« <strong>Compte Professionnel</strong> » : désigne l’espace personnel d’un Utilisateur personne morale, société, organisation ou tout autre entité accessible à partir de ses identifiant et mot de passe.</p>
                            <p className="margin-bottom">« <strong>Plateforme Paanteon</strong> » ou « <strong>Plateforme</strong> » : désigne le service proposé à tout Utilisateur sur le Site Internet Paanteon leur permettant de regrouper dans un panthéon personnel virtuel leurs inspirations et influences majeures, puis de se regrouper par affinités et de partager autour de leurs centres d’intérêt communs.</p>
                            <p className="margin-bottom">« <strong>Site Internet</strong> » : désigne le site internet Paanteon, disponible à partir du lien URL <a href="https://www.paanteon.com">www.paanteon.com</a>, permettant aux Utilisateurs d’accéder à la Plateforme Paanteon.</p>
                            <p className="margin-bottom-xl">« <strong>Utilisateur</strong> » : désigne tout utilisateur, personne physique de la Plateforme Paanteon y compris tout simple visiteur du Site Internet.</p>

                            <h3 className="title-3">1. La Plateforme Paanteon</h3>
                            <p className="margin-bottom">La Plateforme Paanteon est un réseau social inspirationnel qui vous permet de regrouper dans votre panthéon personnel en ligne (« Mon Paanteon ») votre univers d’inspirations positives, puis de vous regrouper par affinités électives avec d’autres membres au sein de « tribus », et de partager avec eux autour de vos préférences et goûts en commun.</p>
                            <p className="margin-bottom-xl">Pour vous garantir l’accès à la Plateforme Paanteon et vous offrir une meilleure expérience sur la Plateforme, nous devons être en mesure de vous identifier et de connaître vos centres d’intérêts pour vous présenter des tribus qui vous ressemblent.</p>

                            <h3 className="title-3">2. Acceptation</h3>
                            <p className="margin-bottom-xl">Tout Utilisateur déclare en accédant et en utilisant la Plateforme Paanteon, depuis le Site internet, avoir pris connaissance des présentes CGU et déclare les accepter expressément sans réserve et/ou modification de quelque nature que ce soit. Les présentes sont donc pleinement opposables aux Utilisateurs.</p>

                            <h3 className="title-3">3. Règles générales</h3>
                            <p className="margin-bottom">Tout Utilisateur déclare être informé qu’il devra, pour accéder au Site Internet disposer d’un accès à Internet souscrit auprès du fournisseur de son choix, dont le coût est à sa charge, et reconnaît que la fiabilité des transmissions est aléatoire en raison, notamment, du caractère hétérogène des infrastructures et réseaux sur lesquelles elles circulent et que, en particulier, des pannes ou saturations peuvent intervenir.</p>
                            <p className="margin-bottom">Il appartient à l’Utilisateur de prendre toute mesure qu’il jugera appropriée pour assurer la sécurité de son équipement et de ses propres données, logiciels ou autres, notamment contre la contamination par tout virus et/ou de tentative d’intrusion dont il pourrait être victime.</p>
                            <p className="margin-bottom">Tout équipement connecté au Site Internet est et reste sous l’entière responsabilité de l’Utilisateur, la responsabilité de Love Unlimited ne pourra pas être recherchée pour tout dommage direct ou indirect qui pourrait subvenir du fait de la connexion à la Plateforme.</p>
                            <p className="margin-bottom-xl">L’Utilisateur s’engage, le cas échéant, à respecter et à maintenir la confidentialité des identifiants de connexion à son Compte Personnel et reconnaît expressément que toute connexion à son Compte Personnel, ainsi que toute transmission de données depuis son Compte Personnel sera réputée avoir été effectuée par l’Utilisateur. Toute perte, détournement ou utilisation des Identifiants de connexion et leurs éventuelles conséquences relèvent de la seule et entière responsabilité de l’Utilisateur.</p>

                            <h3 className="title-3">4. Qui peut utiliser la Plateforme Paanteon ?</h3>
                            <p className="margin-bottom">Vous devez avoir au moins 15 ans et créer un Compte Utilisateur pour accéder à la Plateforme Paanteon. Tout Utilisateur déclare expressément être une personne physique en s’inscrivant sur la Plateforme.</p>
                            <p className="margin-bottom">Lorsque vous créez votre compte, vous devez utiliser les nom et prénom que vous utilisez au quotidien, les identités fantaisistes pourront être rejetées. Vous devez également fournir des informations exactes à propos de vous et créer un seul compte personnel.</p>
                            <p className="margin-bottom">L’identité de tous les membres est certifiée par la Plateforme Paanteon afin de garantir l’authenticité du réseau.</p>
                            <p className="margin-bottom">La certification d’identité peut intervenir à tout moment et au plus tard dans un délai d’un an à compter de l’inscription de l’Utilisateur sur la Plateforme.</p>
                            <p className="margin-bottom">Si vous utilisez un pseudonyme, une certification d’identité pourra être requise, avant même ce délai. L’absence de certification entraine la suppression du compte et la perte du Contenu Utilisateur.</p>
                            <p className="margin-bottom">La certification s’effectue depuis les paramètres du Compte Utilisateur à l’aide de l’onglet « Informations de compte », puis en cliquant sur « Je souhaite certifier mon compte ». Un droit d’adhésion à vie d’un montant de deux euros sera demandé à l’issue du processus de certification de votre identité. Vos données bancaires ne sont pas conservées par Love Unlimited.</p>
                            <p className="margin-bottom-xl">Tout Utilisateur ayant vu son Compte Utilisateur précédemment supprimé ne pourra pas se réinscrire sur la Plateforme.</p>

                            <h3 className="title-3">5. Publication de contenu</h3>
                            <h4 className="title-5">a) Le contenu utilisateur</h4>
                            <p className="margin-bottom">La Plateforme Paanteon vous permet de publier du contenu varié, y compris des photographies et des commentaires afin de créer votre propre univers et alimenter votre fil d’actualité et la Plateforme. Tout élément que vous publiez ou divulguez sur la Plateforme est appelé « Contenu Utilisateur ». Vous êtes responsable du Contenu Utilisateur que vous publiez sur la Plateforme</p>
                            <p className="margin-bottom">Nous nous réservons le droit de supprimer le Contenu Utilisateur, ou de changer la manière dont il est utilisé sur la Plateforme, pour quelque raison que ce soit. Cette règle concerne notamment le Contenu Utilisateur qui enfreindrait les règles et principes définis dans la Charte pour la communauté.</p>
                            <p className="margin-bottom">Lorsque vous publiez des contenus sur la Plateforme Paanteon, en ce compris des visuels, des commentaires, des opinions, vous acceptez que Love Unlimited et d’autres Utilisateurs puissent les partager auprès des utilisateurs de la Plateforme, sauf refus exprès de votre part formulé dans les paramétrages de confidentialité de la Plateforme.</p>
                            <p className="margin-bottom">Afin de garantir le respect des valeurs de Paanteon nous nous réservons le droit de modérer les commentaires, idées ou réactions publiées sur la Plateforme qui contreviendraient aux présentes CGU ou à la Charte pour la communauté.</p>
                            <p className="margin-bottom">Vous ne pouvez pas utiliser la Plateforme Paanteon pour faire ou partager quoi que ce soit :</p>
                            <ul>
                                <li>qui enfreigne les présentes conditions générales, la <Link to="community-code">Charte pour la communauté</Link> et la règlementation applicable à votre utilisation de la Plateforme Paanteon ;</li>
                                <li>qui est illégal, trompeur, discriminant ou frauduleux ;</li>
                                <li>qui enfreint ou viole des droits de tiers, y compris des droits de propriété intellectuelle.</li>
                            </ul>
                            <p className="margin-bottom-xl">Toute publication de ce type sera supprimée et sera susceptible d’entrainer la suspension temporaire ou définitive de votre Compte Utilisateur.</p>

                            <h4 className="title-5">b) La conservation du Contenu Utilisateur</h4>
                            <p className="margin-bottom">Le Contenu Utilisateur est conservé par Love Unlimited pendant toute la durée de vie de votre Compte Utilisateur. Après avoir supprimé votre compte ou si vous supprimez du Contenu Utilisateur de la Plateforme, celui-ci peut perdurer dans nos systèmes :</p>
                            <ul>
                                <li>Lorsque la suppression immédiate est impossible ;</li>
                                <li>Votre contenu utilisateur a été réutilisé par des tiers et ceux-ci ne l’ont pas supprimé ;</li>
                                <li>Lorsque la suppression immédiate du Contenu Utilisateur empêcherait ou limiterait l’identification ou une enquête relative à des activités illégales ou des violations des présentes CGU ou de la Charte pour la communauté, ou le respect d’une demande d’une autorité judiciaire ou administrative.</li>
                            </ul>
                            <p className="margin-bottom-xl">Dans ces hypothèses, le contenu ne sera pas conservé plus longtemps qu’il n’est nécessaire aux fins pour lesquelles il a été conservé (la durée exacte variant au cas par cas).</p>

                            <h4 className="title-5">c) Contenus sponsorisés</h4>
                            <p className="margin-bottom-xl">Certains contenus que nous vous présentons sont sponsorisés par des annonceurs. Ces contenus sont toujours identifiés comme tels.</p>

                            <h4 className="title-5">d) Signaler du contenu abusif ou indésirable</h4>
                            <p className="margin-bottom">Pour signaler un contenu illicite ou indésirable sur la Plateforme, veuillez utiliser le lien « Signaler » à proximité du contenu en question.</p>
                            <p className="margin-bottom-xl">Les signalements abusifs et répétés sont susceptibles d’entrainer la suspension ou la suppression du compte de l’Utilisateur à l’origine du ou des signalement(s).</p>

                            <h3 className="title-3">6. Responsabilité et obligations de Paanteon</h3>
                            <p className="margin-bottom">Love Unlimited, en qualité d’hébergeur, ne saurait en aucun cas être tenu responsable des contenus publiés par les Utilisateurs sur la Plateforme Paanteon et ne donne aucune garantie, expresse ou implicite à cet égard.</p>
                            <p className="margin-bottom">Love Unlimited s'engage à mettre en œuvre tous les moyens nécessaires afin d'assurer au mieux l’accès à la Plateforme Paanteon aux Utilisateurs.</p>
                            <p className="margin-bottom">Toutefois, Paanteon décline toute responsabilité en cas de :</p>
                            <ul>
                                <li>Interruptions, pannes, modifications et/ou dysfonctionnement de la Plateforme Paanteon.</li>
                                <li>Impossibilité momentanée d'accès à la Plateforme en raison de problèmes techniques et ce quelles qu'en soient l'origine et la provenance.</li>
                                <li>Perte de données ou d’informations stockées par la Plateforme.</li>
                                <li>Dommages directs ou indirects causés à l'Utilisateur, quelle qu'en soit la nature, résultant des contenus publiés sur le Site Internet, et/ou de l'accès, de la gestion, de l'utilisation, de l'exploitation, du dysfonctionnement et/ou de l'interruption de la Plateforme Paanteon.</li>
                                <li>Utilisation anormale et/ou une exploitation illicite de la Plateforme Paanteon par tout Utilisateur.</li>
                                <li>Attaque ou piratage informatique, privation, suppression ou interdiction, temporaire ou définitive, et pour quelque cause que ce soit, de l’accès au réseau Internet.</li>
                            </ul>
                            <p className="margin-bottom">La responsabilité de Love Unlimited ne pourra être engagée que pour les dommages directs subis par l’Utilisateur, résultant d’un manquement à ses propres obligations contractuelles telles que définies aux présentes.</p>
                            <p className="margin-bottom">L’Utilisateur renonce donc à demander réparation à Love Unlimited à quelque titre que ce soit, de dommages indirects tels que le manque à gagner, la perte de chance, le préjudice commercial ou financier, l’augmentation de frais généraux ou les pertes trouvant leur origine ou étant la conséquence de l’exécution des présentes. Tout Utilisateur est alors seul responsable des dommages causés aux tiers et des conséquences des réclamations ou actions qui pourraient en découler.</p>
                            <p className="margin-bottom-xl">L'Utilisateur renonce également à exercer tout recours contre Love Unlimited dans le cas de poursuites diligentées par un tiers à son encontre du fait de l'utilisation et/ou de l'exploitation illicite de la Plateforme Paanteon, ou en cas de perte par un Utilisateur de son mot de passe ou en cas d'usurpation de son identité.</p>

                            <h3 className="title-3">7. Responsabilité et obligation des Utilisateurs</h3>
                            <p className="margin-bottom">Tout Utilisateur déclare et garantit en utilisant la Plateforme Paanteon qu’il ne contrevient à aucune règlementation en vigueur et qu’il ne porte atteinte à aucun droit de tiers. En particulier, les Utilisateurs s’engagent à respecter la propriété intellectuelle des tiers et s’interdisent de tenir tout propos insultant ou dégradant à l’égard des tiers.</p>
                            <p className="margin-bottom">L’Utilisateur s’engage en particulier à ce que son Compte Personnel ne comporte aucune information obligatoire fausse et/ou mensongère et aucune information portant atteinte aux droits d’un tiers.</p>
                            <p className="margin-bottom-xl">L’Utilisateur s’engage à ne pas procéder à l’extraction par transfert permanent ou temporaire de la totalité ou d’une partie qualitativement ou quantitativement substantielle ou à l’extraction répétée et systématique de partie qualitativement ou quantitativement non substantielle du contenu d’une ou plusieurs bases de données accessibles sur le Site Internet ou sur un autre support, par tout moyen et sous toute forme que ce soit, en ce compris à des fins d’utilisation ou de consultation par un média et/ou un procédé non autorisé par Love Unlimited.</p>

                            <h3 className="title-3">8. Propriété intellectuelle</h3>
                            <p className="margin-bottom">La marque « Paanteon » ainsi que l’ensemble des marques figuratives ou non et plus généralement toutes les autres marques, illustrations, images et logotypes figurant sur le site, déposées ou non, sont la propriété exclusive de Love Unlimited ou de ses partenaires lui ayant concédé une licence d’utilisation.</p>
                            <p className="margin-bottom">Toute reproduction totale ou partielle, modification ou utilisation de ces marques, illustrations, images et logotypes, pour quelque motif et sur quelque support que ce soit, sans accord préalable de Love Unlimited est strictement interdite.</p>
                            <p className="margin-bottom-xl">Si vous utilisez du contenu couvert par des droits de propriété intellectuelle détenus par Love Unlimited expressément rendus disponibles (images, dessins, vidéos ou des sons), Love Unlimited conserve tous les droits relatifs à ce contenu.</p>

                            <h3 className="title-3">9. Protection des données personnelles, cookies et géolocalisation</h3>
                            <p className="margin-bottom">Love Unlimited collecte et traite les données personnelles des Utilisateurs notamment les données recueillies dans le cadre de la création du Compte Personnel.</p>
                            <p className="margin-bottom">Pour savoir comment Paanteon collecte et traite les données personnelles des Utilisateurs, consultez la Politique de Confidentialité de la Plateforme Paanteon.</p>
                            <p className="margin-bottom-xl">Dans le cadre de votre utilisation de la Plateforme Paanteon, de votre navigation sur le Site Internet, des cookies sont susceptibles d’être déposés sur votre terminal dans les conditions exposées dans la Politique de Confidentialité de la Plateforme Paanteon.</p>

                            <h3 className="title-3">10. Suspension de compte, limitation d’accès et résiliation</h3>
                            <p className="margin-bottom">Vous avez la possibilité de mettre fin à votre relation contractuelle avec Paanteon à tout moment, sans frais et sans motif. Pour cela, il vous suffit de demander la suppression de votre Compte Utilisateur depuis les paramètres de votre compte.</p>
                            <p className="margin-bottom">Le Compte Utilisateur sera définitivement supprimé dans un délai de 100 jours. Les droits d’adhésion ne seront pas remboursés. Si vous souhaitez recommencer à utiliser la Plateforme, vous serez dans l’obligation de vous inscrire dans les mêmes conditions que lors de votre première inscription. Vous ne pourrez pas réactiver votre ancien compte et devrez à nouveau certifier votre identité et vous acquitter du droit d’adhésion.</p>
                            <p className="margin-bottom">Nous pouvons résilier ou suspendre immédiatement vos droits d’accès ou d’utilisation de la Plateforme Paanteon pour quelque raison que ce soit et notamment en cas de violation des présentes CGU ou de la Charte pour la communauté.</p>
                            <p className="margin-bottom">Love Unlimited peut supprimer tout avis, annonce, message, contenu publié par les Utilisateurs, pour un motif valable tel que (i) la violation de votre part des présentes CGU, notamment vos engagements en tant qu’Utilisateur, ou (ii) la violation de la Charte pour la communauté, ou si (iii) Love Unlimited a des raisons sérieuses de croire que ceci est nécessaire pour protéger la sécurité et l’intégrité de la Plateforme, celles des autres utilisateurs ou de tiers ou à des fins de prévention des fraudes ou d’enquêtes, ou enfin pour préserver la convivialité de la Plateforme.</p>
                            <p className="margin-bottom-xl">Lorsque cela est nécessaire, vous serez notifié de la mise en œuvre d’une telle mesure afin de vous permettre de donner des explications à Love Unlimited. Love Unlimited décidera, à sa seule discrétion, de lever les mesures mises en œuvre ou non. En cas de suspension permanente, aucune demande de remboursement ne sera acceptée pour les frais d’adhésion.</p>

                            <h3 className="title-3">11. Lien, sites et services de tiers</h3>
                            <p className="margin-bottom">La Plateforme peut contenir des liens vers des sites web, des contenus, des services en ce compris d’annonceurs, qui n’appartiennent pas ou ne sont pas contrôlés par Love Unlimited. Love Unlimited ne saurait être tenu pour responsable directement ou indirectement du contenu de ces sites web, contenus, services.</p>
                            <p className="margin-bottom-xl">Lorsque vous accédez à un site web, un service ou un contenu externe depuis la Plateforme Paanteon, vous le faites à vos propres risques et vous acceptez que la responsabilité de Love Unlimited ne soit pas engagée si vous accédez à ou utilisez ces sites web, services ou contenus de tiers.</p>

                            <h3 className="title-3">12. Limite de responsabilité</h3>
                            <p className="margin-bottom">Love Unlimited ne saurait être tenu responsable en cas de dommage qui ne résulterait pas de sa propre violation des présentes CGU et/ou tout autre acte de sa part et/ou d’évènements en dehors de son contrôle raisonnable.</p>
                            <p className="margin-bottom">Love Unlimited décline toute garantie de qualité marchande, d'adéquation à un usage particulier et de non-contrefaçon. Love Unlimited n’assume aucune responsabilité pour le contenu utilisateur qu’un tiers ou vous-même publiez ou envoyez via la Plateforme.</p>
                            <p className="margin-bottom-xl">Nous accordons une grande importance aux contenus publiés et partagés sur la Plateforme. Si vous constatez des contenus inappropriés sur la Plateforme Paanteon, veuillez-nous le signaler en cliquant sur « Signaler ».</p>

                            <h3 className="title-3">13. Indemnisation</h3>
                            <p className="margin-bottom">Vous consentez à défendre et indemniser Love Unlimited, ses filiales et/ou leurs fournisseurs respectifs et tous leurs cadres dirigeants, directeurs, employés et agents pour toutes réclamations, d'action, revendications,  demandes des recouvrement, pertes, dommages, amendes, sanctions pénales et/ou tout autre coût ou dépense de toute sorte et/ou de toute nature, y compris, sans toutefois s'y limiter, des frais légaux et comptables raisonnables supportés par Love Unlimited, intentés par des tiers en conséquence de :</p>
                            <p className="margin-left">(a) votre violation des présentes CGU ;</p>
                            <p className="margin-left">(b) votre violation de toute loi ou de droits de tiers ; et/ou</p>
                            <p className="margin-left margin-bottom">(c) votre utilisation de la Plateforme Paanteon par le biais du Site Internet.</p>
                            <p className="margin-bottom-xl">L'utilisation de la Plateforme Paanteon est soumise à la condition que vous garantissiez à Love Unlimited que vous n'utiliserez pas le Site Internet et/ou la Plateforme à des fins illégales ou interdites par les présentes CGU.</p>

                            <h3 className="title-3">14. Réclamations et signalement</h3>
                            <p className="margin-bottom-xl">Toute réclamation ou signalement de contenu jugé illicite devra être formulé directement sur le contenu litigieux en cliquant sur « Signaler » ou par le biais du formulaire de contact accessible à partir de l’onglet « Nous contacter ».</p>

                            <h3 className="title-3">15. Mise à jour des conditions générales d’utilisation</h3>
                            <p className="margin-bottom">Love Unlimited est susceptible de mettre à jour les présentes CGU de temps à autre afin de refléter les évolutions de la Plateforme Paanteon et de ses modalités d’utilisation</p>
                            <p className="margin-bottom-xl">Vous serez informé de toute nouvelle mise à jour de ces CGU et votre acceptation de la version à jour sera requise.</p>

                            <h3 className="title-3">16. Intégralité</h3>
                            <p className="margin-bottom">Les présentes CGU, la <Link to="privacy-policy">Politique de confidentialité</Link> ainsi que la <Link to="community-code">Charte pour la communauté</Link>, tout amendement et tout accord supplémentaire que vous pourriez passer avec Love Unlimited constitueront l’intégralité de l’accord entre vous et Love Unlimited eu égard à la Plateforme Paanteon.</p>
                            <p className="margin-bottom-xl">Si une quelconque disposition des présentes CGU est réputée invalide ou inapplicable, pour quelque raison que ce soit, la disposition en question sera réputée non écrite, sans remettre en cause la validité des autres dispositions qui continueront de s’appliquer entre les Utilisateurs et Love Unlimited.</p>

                            <h3 className="title-3">17. Loi applicable</h3>
                            <p className="margin-bottom">Les présentes CGU sont soumises au droit français.</p>
                            <p className="margin-bottom">En cas de litige ou de réclamation et aux fins de trouver un accord amiable, l’Utilisateur adressera ses griefs à Love Unlimited par courrier recommandé avec accusé de réception ou par voie électronique à l’adresse : Love Unlimited/Service Paanteon - 1 rue de la République - 69001 Lyon France.</p>
                            <p className="margin-bottom-xl">A défaut d’un accord amiable, tout litige ou réclamation pourra être porté devant la juridiction compétente.</p>

                            <h3 className="title-3">18. Qui sommes-nous ?</h3>
                            <p className="margin-bottom">La plateforme Paanteon.com est la propriété de Love Unlimited.</p>
                            <p className="margin-bottom">Love Unlimited est une société par actions simplifiée, au capital social de 2.000 €, immatriculée au RCS de Lyon sous le numéro 831 560 222, et dont le siège social est situé au : 1 rue de la République - 69001 Lyon (N° de TVA intracommunautaire : FR 498 315 602 22). Elle est hébergée par la société OVH, 2 rue Kellerman - 59100 Roubaix (France).</p>
                            <p className="margin-bottom-xl">Crédits photos slider : Adobe Stock, AFP ou autres si précisé.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default TermsAndConditionsOfUse;
