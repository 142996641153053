import React, {Component} from 'react';
import PublicHeader from "../components/PublicHeader";
import {UIView} from "@uirouter/react";
import {User} from "../api/types";
import {MentionableUsersContext} from "../components/MentionUser";
import Footer from "../components/Footer";

type Props = {
    mentionUsers: {data: User[]}
}

class Connected extends Component<Props> {
    private mentionUsers = this.props.mentionUsers.data.map(u => ({id: u.userId as number, display: u.username as string, displayName: `${u.firstname} ${u.lastname}`, picturePath: u.picturePath}));

    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <MentionableUsersContext.Provider value={this.mentionUsers}>
                    <UIView/>
                </MentionableUsersContext.Provider>

                <Footer />
            </>
        );
    }
}

export default Connected;
