import React from 'react';
import {Gender, User, UserInfos} from "../api/types";
import Page from "./Page";
import {
    pictoCamera,
    pictoCollapse,
    pictoFacebook,
    pictoLinkedin,
    pictoTwitter,
    pictoWebsite,
    pictoPencilBlue,
    pictoCertification,
} from "../api/pictos";
import paanteonProcess from "../assets/img/paanteon-process.png";
import {Link} from "../api/injectors";
import auth from "../api/authentication";
import Notification from "../api/notification";
import {acceptableImages} from "../api/domHelper";
import shave from "shave";
import Helmet from "../components/Helmet";
import config from "../api/config";
import { Tribe } from "../api/types"

interface OwnProps {
    user: { data: UserInfos}
    userTribes: {Tribes : Tribe[], userId: number, totalUser: number}
}

type Props = OwnProps;

type State = Readonly<{
    user: User & {coverImg?: string, profileImg?: string}
    editMode: boolean
    sidebarCollapsed: boolean
}>;

class Profile extends Page<Props, State> {
    readonly state: State = {
        user: this.props.user.data,
        editMode: false,
        sidebarCollapsed: false,
    };

    handleUserInput = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        this.setState(state => ({
            user: {
                ...state.user,
                [name]: value,
            },
        }));
    };

    saveUser = () => {
        auth.updateUserById(this.state.user).then((result) => {
            this.setState(prevState => ({...prevState, user: result.data, editMode: false}));
            Notification.success('Profil mis à jour');
        }, function(error) {
            Notification.error('Erreur lors de la mis à jour');
            // eslint-disable-next-line no-console
            console.error(error);
        });
    };

    openEditMode = () => {
        this.setState(prevState => ({
            ...prevState,
            editMode: true,
        }));
    }

    componentDidMount(): void {
        super.componentDidMount();

        const coverPicUpload = document.getElementById('cover-pic-upload');
        if (coverPicUpload) {
            // handling tribute's picture upload
            const reader = new FileReader();
            coverPicUpload.addEventListener( 'change', ( e: Event ) => {
                // @ts-ignore
                if (e.currentTarget && (e.currentTarget as HTMLInputElement).files !== null && (e.currentTarget as HTMLInputElement).files.length > 0) {
                    // @ts-ignore
                    reader.readAsDataURL((e.currentTarget as HTMLInputElement).files[0]);
                }
            });
            reader.onloadend = () => {
                auth.updateUserById({
                    ...this.state.user,
                    coverImg: reader.result ? reader.result as string : '',
                }).then((res: {data: User}) => {
                    this.setState(prevState => ({
                        ...this.state,
                        user: {
                            ...this.state.user,
                            ...res.data,
                        },
                    }));
                }, () => {
                    Notification.error("Une erreur est survenue lors de l'envoi de l'image");
                });
            }
        }

        const profilePicUpload = document.getElementById('profile-pic-upload');
        if (profilePicUpload) {
            // handling tribute's picture upload
            const reader = new FileReader();
            profilePicUpload.addEventListener( 'change', ( e: Event ) => {
                // @ts-ignore
                if (e.currentTarget && (e.currentTarget as HTMLInputElement).files !== null && (e.currentTarget as HTMLInputElement).files.length > 0) {
                    // @ts-ignore
                    reader.readAsDataURL((e.currentTarget as HTMLInputElement).files[0]);
                }
            });
            reader.onloadend = () => {
                auth.updateUserById({
                    ...this.state.user,
                    profileImg: reader.result ? reader.result as string : '',
                }).then((res: {data: User}) => {
                    this.setState(prevState => ({
                        ...this.state,
                        user: {
                            ...this.state.user,
                            ...res.data,
                        },
                    }));
                }, () => {
                    Notification.error("Une erreur est survenue lors de l'envoi de l'image");
                });
            }
        }

        shave(".card .card-footer .card-name", 50, {spaces: false});
    }

    toggleSidebar = () => {
        this.setState( prevState => ({
            ...prevState,
            sidebarCollapsed: !prevState.sidebarCollapsed,
        }))
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <Helmet title={'Mon Profil | Paanteon'} />
                    <div className="page-head" style={{backgroundImage: 'url(' + this.state.user.coverPath + ')'}}>
                        <input type="file" name="cover-pic-upload" id="cover-pic-upload" accept={acceptableImages}/>
                        <label className="upload" htmlFor="cover-pic-upload">
                            <img src={pictoCamera} alt="picto camera"/>
                        </label>
                        <h1><span className="text-finish">Mon Profil</span></h1>
                    </div>
                    <div className={`sidebar sidebar-ptn sidebar-collapsible ${this.state.sidebarCollapsed ? "collapsed" : ""}`}>
                        <div className="sidebar-block sidebar-profile">
                            <div className="profile-pic" style={{backgroundImage: 'url(' + (this.state.user.picturePath ? this.state.user.picturePath : ((this.state.user.gender === Gender.male || !this.state.user.gender) ? config.backendStatic + '/public/users/male.jpg' : config.backendStatic + '/public/users/woman.jpg')) + ')'}}>
                                <input type="file" name="profile-pic-upload" id="profile-pic-upload" accept={acceptableImages}/>
                                <label className="upload" htmlFor="profile-pic-upload">
                                    <img src={pictoCamera} alt="picto camera"/>
                                </label>
                            </div>
                            <button className="btn ghost-link collapse-btn" onClick={this.toggleSidebar}>
                                <img src={pictoCollapse} className="picto" alt="picto collapse"/>
                            </button>
                            <p className="name">
                                {this.state.user.firstname} {this.state.user.lastname}
                                {
                                    this.state.user.certificationDate !== null && <img src={pictoCertification} alt="certifié"/>
                                }
                            </p>
                            <p className="username">
                                @{this.state.user.username}
                            </p>
                            <p className="city">
                                {this.state.user.displayCity && this.state.user.city}
                                {this.state.user.displayCity && this.state.user.displayCountry && ', '}
                                {this.state.user.displayCountry && this.state.user.country}
                            </p>
                            <div className="sidebar-sep"/>

                            {
                                this.state.editMode ?
                                    <textarea className="textarea block no-resize" name="bio"
                                              onChange={this.handleUserInput} value={this.state.user.bio || ''}
                                              maxLength={650} rows={10} placeholder="j'ajoute une description"/>
                                    :
                                    <div>{this.state.user.bio}</div>
                            }

                            <div className="sidebar-sep"/>
                            <div className="socials">
                                {
                                    this.state.editMode ?
                                        <>
                                            <div className="flex">
                                                <img src={pictoLinkedin} alt="picto LinkedIn"/><input className="input line-input block" type="url" name="linkedin" onChange={this.handleUserInput} value={this.state.user.linkedin || ''} placeholder="https://"/>
                                            </div>
                                            <div className="flex">
                                                <img src={pictoFacebook} alt="picto Facebook"/><input className="input line-input block" type="url" name="facebook" onChange={this.handleUserInput} value={this.state.user.facebook || ''} placeholder="https://"/>
                                            </div>
                                            <div className="flex">
                                                <img src={pictoTwitter} alt="picto Twitter"/><input className="input line-input block" type="url" name="twitter" onChange={this.handleUserInput} value={this.state.user.twitter || ''} placeholder="https://"/>
                                            </div>
                                            <div className="flex">
                                                <img src={pictoWebsite} alt="picto Twitter"/><input className="input line-input block" type="url" name="instagram" onChange={this.handleUserInput} value={this.state.user.instagram || ''} placeholder="https://"/>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {this.state.user.linkedin && <a target="_blank" rel="noopener noreferrer"
                                                href={this.state.user.linkedin.startsWith('http') ? this.state.user.linkedin : 'https://' + this.state.user.linkedin}>
                                                    <img src={pictoLinkedin}
                                                                                     alt="picto LinkedIn"/></a>}
                                            {this.state.user.facebook && <a target="_blank" rel="noopener noreferrer"
                                                href={this.state.user.facebook.startsWith('http') ? this.state.user.facebook : 'https://' + this.state.user.facebook}><img src={pictoFacebook}
                                                                                     alt="picto Facebook"/></a>}
                                            {this.state.user.twitter && <a target="_blank" rel="noopener noreferrer"
                                                href={this.state.user.twitter.startsWith('http') ? this.state.user.twitter : 'https://' + this.state.user.twitter}><img src={pictoTwitter}
                                                                                    alt="picto Twitter"/></a>}
                                            {this.state.user.instagram && <a target="_blank" rel="noopener noreferrer"
                                                href={this.state.user.instagram.startsWith('http') ? this.state.user.instagram : 'https://' + this.state.user.instagram}><img src={pictoWebsite}
                                                                                      alt="picto Website"/></a>}
                                        </>
                                }
                            </div>

                            {
                                this.state.editMode ?
                                    <div className="margin-top-l h-center">
                                        <button className="btn btn-1" onClick={this.saveUser}>JE SAUVEGARDE</button>
                                    </div>
                                    :
                                    <div style={{ marginTop: "142px"}}>
                                        <img className="picto" src={pictoPencilBlue} alt="pencil (edit) icon"/>
                                        <button className="btn link-btn blue" onClick={this.openEditMode}>Editer mon profil</button>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="main-content" style={{paddingBottom: "20px"}}>

                        {
                            this.props.user.data.Tributes.length > 0 ?
                                <div className="tile my-ptn-tile">
                                    <p className="tile-title">Mon paanteon</p>
                                    <p className="tile-subtitle">Vos derniers hommages</p>

                                    <div className="tile-body">
                                        <div className="thumbs-grid">
                                            {
                                                this.props.user.data.Tributes.slice(0, 4).map(tribute =>
                                                    <Link to="connected.paanteon.tribute.details" params={{tributeId: tribute.tributeId, username: this.props.user.data.username, thumbnailUniqueName: tribute.Thumbnail.uniqueName}} className="card blue expand"
                                                            style={{backgroundImage: 'url(' + tribute.picturePath + ')'}} key={tribute.tributeId}>
                                                        <canvas width="250" height="350"/>
                                                        <div className="card-footer">
                                                            <p className="card-name">{tribute.Thumbnail.label}</p>
                                                        </div>
                                                    </Link>
                                                )
                                            }
                                        </div>

                                        <p className="h-center margin">
                                            Galeries ({this.props.user.data.galleryCount}) - Collections ({this.props.user.data.collectionCount}) - Hommages ({this.props.user.data.tributeCount})
                                        </p>

                                        <div className="tile-footer">
                                            <Link to="connected.paanteon" className="btn btn-1 btn-big">MON PAANTEON</Link>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="paanteon-process">
                                    <h2 className="title">MON PAANTEON</h2>
                                    <p className="subtitle">Pour interagir, commencez par <strong>créer votre paanteon personnel.</strong></p>
                                    <img src={paanteonProcess} alt="créez votre Paanteon"/>
                                    <div className="h-center">
                                        <Link to="connected.paanteon.user.galleries.condensed" className="btn btn-1 btn-big">JE COMMENCE</Link>
                                    </div>
                                </div>
                        }
                        <div className="profile-grid">
                            <div className="profile-grid-first">
                                <div className="sidebar-block secondary flex space-between">
                                    <div>
                                        <div className="block-title">AMIS</div>
                                        {
                                            this.props.user.data.Friends.length + this.props.user.data.FriendsOf.length > 0 ?
                                                <p>({this.props.user.data.Friends.length + this.props.user.data.FriendsOf.length})</p>
                                                :
                                                <p>Ajoutez vos amis <br/>&nbsp;</p>
                                        }
                                        <Link to="connected.friends" className="btn btn-outlined">VOIR</Link>
                                    </div>
                                    <div>
                                        <div className="block-title">ABONNEMENTS</div>
                                        {
                                            this.props.user.data.Following.length > 0 ?
                                                <p>({this.props.user.data.Following.length})</p>
                                                :
                                                <p>Ceux que je suis</p>
                                        }
                                        <Link to="connected.follow.following" className="btn btn-outlined">VOIR</Link>
                                    </div>
                                    <div>
                                        <div className="block-title">ABONNÉS</div>
                                        {
                                            this.props.user.data.FollowedBy.length > 0 ?
                                                <p>({this.props.user.data.FollowedBy.length})</p>
                                                :
                                                <p>Ceux qui me suivent</p>
                                        }
                                        <Link to="connected.follow.followers" className="btn btn-outlined">VOIR</Link>
                                    </div>
                                </div>
                                <div className="flex space-between" style={{marginTop: "20px"}}>
                                    <div style={{flexGrow: 1}}>
                                        <div className="sidebar-block secondary" style={{marginRight: "10px"}}>
                                            <div className="block-title">MES TRIBUS</div>
                                            {
                                                this.props.userTribes.Tribes.length > 0 ?
                                                    <p>{"Vous appartenez à"}<br />{this.props.userTribes.Tribes.length + " tribu(s)"}</p>
                                                :
                                                    <p>{"Vous n'avez pas encore"}<br />{"rejoint de tribus."}</p>
                                            }
                                            <Link to="connected.tribes" className="btn btn-outlined">VOIR</Link>
                                        </div>
                                    </div>
                                    <div style={{flexGrow: 1}}>
                                        <div className="sidebar-block secondary" style={{marginLeft: "10px"}}>
                                            <div className="block-title">MES CERCLES</div>
                                            <p>Rejoignez des cercles de<br />discussion.</p>
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a href="" className="btn btn-outlined">VOIR</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: "300px", marginBottom: "40px"}}>
                                <div className="sidebar-block secondary">
                                    <div className="block-title">MES PUBLICATIONS</div>
                                    {
                                        this.props.user.data.publicationCount > 0 ?
                                            <p>({this.props.user.data.publicationCount})</p>
                                            :
                                            <p>Vous n'avez pas encore de publication.</p>
                                    }
                                    <Link to="connected.inspiration-feed.my-publications" className="btn btn-outlined">VOIR</Link>
                                </div>
                                <div className="sidebar-block secondary">
                                    <div className="block-title">MES ARCHIVES</div>
                                        <p>
                                            {
                                                this.props.user.data.archiveCount > 0 ?
                                                    <span>({this.props.user.data.archiveCount})</span>
                                                    :
                                                    <span>Votre espace d'archives est vide.</span>
                                            }
                                        </p>

                                    <Link to="connected.paanteon.archives" className="btn btn-outlined">VOIR</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Profile;
