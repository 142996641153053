import React from 'react';
import Page from "../Page";
import {Link} from "../../api/injectors";
import {Statistics} from "../../api/types";
import moment from "moment";
import Helmet from "../../components/Helmet";

type Props = {
    statistics: {data: Statistics}
}

type State = {
    year: number
    month: number
}

const CURRENT = new Date();
const CURRENT_YEAR = CURRENT.getFullYear();
const CURRENT_MONTH = CURRENT.getMonth();

const months = Array(12).fill(undefined).map((_, index) => {
    return new Date(CURRENT_YEAR, index)
});

const MIN_YEAR = 2019;
const years = new Array(CURRENT_YEAR - MIN_YEAR + 1).fill(0).map((_val, i) => i + MIN_YEAR);

class StatisticsPage extends Page<Props, State> {
    readonly state: State = {
        month: CURRENT_MONTH + 1,
        year: CURRENT_YEAR,
    }
    private formattedMonths = months.map(date => {
        return moment(date).format('MMMM');
    });

    setYear = (e: React.SyntheticEvent<HTMLSelectElement>) => {
        this.setState({year: parseInt(e.currentTarget.value, 10)});
    }

    setMonth = (e: React.SyntheticEvent<HTMLSelectElement>) => {
        const val = parseInt(e.currentTarget.value, 10);
        this.setState({month: val});
    }

    render() {
        const filteredTributesCount = this.props.statistics.data.tributesByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredCompletedTributesCount = this.props.statistics.data.completedTributesByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredUsersCount = this.props.statistics.data.usersByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredSharesCount = this.props.statistics.data.sharesByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredLikesCount = this.props.statistics.data.likesByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredCommentsCount = this.props.statistics.data.commentsByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredTestimonialsCount = this.props.statistics.data.testimonialsByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);
        const filteredPublicationsCount = this.props.statistics.data.publicationsByMonth.find(stat => stat.year === this.state.year && stat.month === this.state.month);

        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'Statistiques | Paanteon'} />
                            <h1 className="page-title">
                                Statistiques
                            </h1>

                            <div className={"margin-bottom"}>
                                <Link to="^">&lt; Retour</Link>
                            </div>

                            <h2>Fréquentation</h2>
                            <p>Nombre de membres: {this.props.statistics.data.totalUsers.count}</p>

                            <h2>Engagement</h2>
                            <p>Nombre total de vignettes gravées: {this.props.statistics.data.totalTributes.count}</p>
                            <p>Nombre total d'hommages remplies (hors titre): {this.props.statistics.data.totalCompletedTributes.count}</p>
                            <p>Nombre total de publications (hors posts automatiques): {this.props.statistics.data.totalPublications.count}</p>
                            <p>Nombre total de partages: {this.props.statistics.data.totalShares.count}</p>
                            <p>Nombre total de likes: {this.props.statistics.data.totalLikes.count}</p>
                            <p>Nombre total de commentaires: {this.props.statistics.data.totalComments.count}</p>
                            <p>Nombre total de témoignages livres d'or: {this.props.statistics.data.totalTestimonials.count}</p>
                            <br/>
                            <hr/>
                            <div className="margin-top">
                                <select onChange={this.setMonth} value={this.state.month} className="margin-bottom">
                                    {
                                        this.formattedMonths.map((month, index) => <option value={index + 1} key={month}>
                                            {month}
                                        </option>)
                                    }
                                </select>
                                <select onChange={this.setYear} value={this.state.year}>
                                    {
                                        years.map(year => <option value={year} key={year}>
                                            {year}
                                        </option>)
                                    }
                                </select>

                                <h2>Fréquentation</h2>
                                <p>Nombre de membres: {filteredUsersCount?.count || 0}</p>

                                <h2>Engagement</h2>
                                <div>
                                    Nombre de vignettes gravées: {filteredTributesCount?.count || 0}
                                </div>
                                <div>
                                    Nombre d'hommages remplies (hors titre): {filteredCompletedTributesCount?.count || 0}
                                </div>
                                <div>
                                    Nombre de publications (hors posts automatiques): {filteredPublicationsCount?.count || 0}
                                </div>
                                <div>
                                    Nombre de partages: {filteredSharesCount?.count || 0}
                                </div>
                                <div>
                                    Nombre de likes: {filteredLikesCount?.count || 0}
                                </div>
                                <div>
                                    Nombre de commentaires: {filteredCommentsCount?.count || 0}
                                </div>
                                <div>
                                    Nombre de témoignages livres d'or: {filteredTestimonialsCount?.count || 0}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StatisticsPage;
