import * as React from "react";
import Page from "./Page";
import FeaturedCarousel from "../components/FeaturedCarousel";
import {ActiveLink, Link} from "../api/injectors";
import {InMemoriam, Thumbnail} from "../api/types";
import ThumbsCarousel from "../components/ThumbsCarousel";
import moment from "moment";
import Helmet from "../components/Helmet";

class ConnectedHome extends Page<{ new: Thumbnail[], memoriam: InMemoriam[] }, {}> {
    render() {
        let memoriam = this.props.memoriam;
        memoriam.sort((a, b) => moment(b.deathYear).diff(moment(a.deathYear)));
        return (
            <>
                <Helmet title={"Paanteon | Le réseau social inspirationnel"} />
                <FeaturedCarousel/>
                <div className="section section-dark with-gradient">
                    <div className="uk-container">
                        <div className="uk-grid uk-grid-small">
                            <div className="uk-width-1-4"></div>
                            <div className="uk-width-1-4"><ActiveLink className="btn btn-outlined btn-block light"
                                                                      to="connected.home" class="active">À LA
                                UNE</ActiveLink></div>
                            <div className="uk-width-1-4"><ActiveLink className="btn btn-outlined btn-block light"
                                                                      to="connected.inspiration-feed"
                                                                      class="active">FIL D'INSPIRATION</ActiveLink>
                            </div>
                        </div>
                        <div className="section-title">
                            Les + gravés<br/>aujourd'hui sur Paanteon
                        </div>
                    </div>
                    <ThumbsCarousel/>
                    <div className="h-center">
                        <Link to="connected.paanteon" className="btn btn-1 btn-big">MON PAANTEON</Link>
                    </div>
                </div>
                <div className="section section-dark">
                    <div className="uk-container">
                        <div className="section-title">
                            Ils font leur<br/>entrée dans Paanteon
                        </div>
                    </div>
                    <ThumbsCarousel thumbs={this.props.new}/>
                </div>
                <div className="section section-dark">
                    <div className="uk-container">
                        <div className="section-title">
                            In memoriam
                        </div>
                        <p className="section-subtitle">Pour rendre hommage à ceux qui nous ont quittés</p>
                        <div className="uk-grid">
                            {
                                memoriam && memoriam.slice(0, 9).map((item: InMemoriam) => {
                                    return (
                                        <div className="uk-width-1-3@s uk-width-1-2" key={item.inMemoriamId}>
                                            <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: item.Thumbnail.uniqueName, memoriamId: item.inMemoriamId}} className="framed-pic-card"
                                                  style={{backgroundImage: 'url(' + item.picture + ')'}}>
                                                <div className="card-footer">
                                                    <p className="card-title">{item.firstname} {item.lastname}</p>
                                                    <p className="card-subtitle">({item.birthYear}-{moment(item.deathYear).format('yyyy')})</p>
                                                </div>
                                                <canvas className="card-ratio" width="16" height="9"></canvas>
                                            </Link>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className="h-center margin-large">
                            <Link to="connected.in-memoriam" className="btn btn-1 btn-big">VOIR TOUS LES LIVRES D'OR</Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ConnectedHome;
