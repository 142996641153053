import React, {PureComponent} from 'react';
import {Comment, CommentToSend, Publication, User} from "../../api/types";
import PublicationFooter from "./PublicationFooter";
import paanteon from "../../api/paanteonRequests";
import Notification from "../../api/notification";
import {injectUIRouter, Link} from "../../api/injectors";
import {UIRouterReact} from "@uirouter/react";
import moment from "moment";
import ReportModal from "../ReportModal";
import localStorage from "../../api/localStorage";
import ConfirmModal from "../ConfirmModal";
import OptionsMenu from "../OptionsMenu";
import {stopPropagation} from "../../api/helpers";
import {processLinks, processMentions} from "../../api/domHelper";
import {CommentOpenable} from "./types";
import {pictoCertification} from "../../api/pictos";

type Props = {
    item: Publication
    footer?: boolean
    router: UIRouterReact
    onDelete?(publicationId: number, type: 0 | 1 | 2): any
} & CommentOpenable

type State = {
    item: Publication
    showDeleteConfirm: boolean
};

class Post extends PureComponent<Props, State> {
    protected user: Readonly<User> = localStorage.getLocalUser();
    constructor(props: Props) {
        super(props);
        let item = this.props.item;
        if (item.content) {
            item.content = item.content.replace(/<\/?[^>]+(>|$)/g, "");
            item.content = item.content.replace(/&nbsp;/g, " ");
            item.content = item.content.replace(/&amp;/g, "&");
        }

        this.state = {
            item,
            showDeleteConfirm: false,
        };
    }

    toggleLike = () => {
        if (this.state.item.liked) {
            paanteon.unlike({publicationId: this.state.item.publicationId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    item: {
                        ...prevState.item,
                        liked: false,
                        likeCount: prevState.item.likeCount - 1,
                    },
                }));
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        } else {
            paanteon.like({publicationId: this.state.item.publicationId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    item: {
                        ...prevState.item,
                        liked: true,
                        likeCount: prevState.item.likeCount + 1,
                    },
                }))
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        }
    }

    onShare = () => {
        this.setState(prevState => ({
            ...prevState,
            item: {
                ...prevState.item,
                shareCount: prevState.item.shareCount + 1,
            },
        }));
    }

    sendComment = (comment: CommentToSend) => {
        const comm = {
            ...comment,
            publicationId: this.state.item.publicationId,
        };
        const answerId = comm.answerId;
        if (comm.answerId === -1) delete comm.answerId;
        return paanteon.comment(comm).then((res: {data: Comment}) => {
            this.setState(prevState => {
                if (!res.data.Answers) res.data.Answers = [];
                let nState = {
                    ...prevState,
                    item: {
                        ...prevState.item,
                        commented: 1 as 1,
                    },
                };
                if (answerId === -1) {
                    nState.item.Comments = [...nState.item.Comments, res.data];
                } else {
                    const comm = nState.item.Comments.find(comm => comm.commentId === answerId);
                    if (comm && comm.Answers) comm.Answers = [...comm.Answers, res.data];
                }
                return nState;
            });
            return true;
        }, function() {
            Notification.error("Erreur lors de la création du commentaire");
            return false;
        });
    };

    deleteComment = (commentId: number) => {
        return paanteon.deleteComment(commentId).then(() => {
            this.setState(prevState => ({
                ...prevState,
                item: {
                    ...prevState.item,
                    Comments: prevState.item.Comments.filter(c => c.commentId !== commentId).map(c => {
                        if (c.Answers) c.Answers = c.Answers.filter(a => a.commentId !== commentId);
                        return c;
                    }),
                },
            }));
            return true;
        });
    }

    goToPublication = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        this.props.router.stateService.go('connected.inspiration-feed.publication', {publicationId: this.state.item.publicationId});
    }

    openConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            showDeleteConfirm: true,
        }));
    }

    closeConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            showDeleteConfirm: false,
        }));
    }

    confirmDelete = () => {
        paanteon.deletePublication(this.props.item.publicationId).then(() => {
            Notification.success("Publication supprimée avec succès");
            this.setState(prevState => ({
                ...prevState,
                showDeleteConfirm: false,
            }));
            if (this.props.onDelete) this.props.onDelete(this.props.item.publicationId, 0);
        }, function() {
            Notification.error("Erreur lors de la suppression de la publication")
        })
    }

    render() {
        return (
            /* <Link to="connected.inspiration-feed.publication" params={{publicationId: this.state.item.publicationId}} className="sep">*/
            <>
                <div className="tile condensed sep clickable" onClick={this.goToPublication}>
                    <OptionsMenu>
                        <span className="options-menu">
                            <ul>
                                <li>
                                    <ReportModal publicationId={this.props.item.publicationId}>
                                        <button className="btn link-btn">Signaler</button>
                                    </ReportModal>
                                </li>
                                {
                                    (this.props.item.userId === this.user.userId || this.user.admin) && <li>
                                        <button className="btn link-btn" onClick={this.openConfirmDelete}>Supprimer</button>
                                    </li>
                                }
                            </ul>
                        </span>
                    </OptionsMenu>
                    <div className="tile-body">
                        <div className="feed-item">
                            <div className="profile-pic" style={{backgroundImage: "url(" + this.state.item.User.picturePath + ')'}}/>
                            <div>
                                <p onClick={stopPropagation}>
                                    <em className="ptn-em">
                                        <Link to="connected.paanteon.user" params={{userId: this.state.item.userId, username: this.state.item.User.username}}>{this.state.item.User.firstname + ' ' + this.state.item.User.lastname}</Link>
                                        {
                                            this.state.item.User.certificationDate !== null && <img className="certification" src={pictoCertification} alt="certifié"/>
                                        }
                                    </em>
                                    <small className={"username"}>@{this.state.item.User.username}</small>
                                    &nbsp;- <small>{moment(this.state.item.createdAt).format('LL')}</small>
                                </p>
                                <p onClick={e => e.stopPropagation()}>{processMentions(processLinks([this.state.item.content]))}</p>
                                <img className="inspiration-image"
                                     src={this.state.item.picturePath} alt=""/>

                                {
                                    this.props.footer && <PublicationFooter commentOpen={this.props.commentOpen} onDeleteComment={this.deleteComment} publication={this.state.item} onLike={this.toggleLike} onShare={this.onShare} onComment={this.sendComment}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {
                    this.state.showDeleteConfirm && <ConfirmModal onCancel={this.closeConfirmDelete} onConfirm={this.confirmDelete}>Êtes-vous sûr(e) de vouloir supprimer définitivement cette publication ?</ConfirmModal>
                }
            </>
            /* </Link>*/
        );
    }
}

export default injectUIRouter(Post);
