import React, {PureComponent} from 'react';
import {CancelablePromise, Circle, Friendship, Thumbnail, Tribute, User} from "../../api/types";
import {Link} from "../../api/injectors";
import pictoCollapse from "../../assets/img/pictos/picto-collapse.png";
import pictoLinkedin from "../../assets/img/pictos/picto-linkedin.svg";
import pictoFacebook from "../../assets/img/pictos/picto-facebook.svg";
import pictoTwitter from "../../assets/img/pictos/picto-twitter.svg";
import pictoInstagram from "../../assets/img/pictos/picto-website.svg";
import localStorage from "../../api/localStorage";
import pictoCirclesBlue from "../../assets/img/pictos/picto-circles-blue.svg";
import imgMolecularCook from "../../assets/img/cuisine-moleculaire.jpg";
import imgTechnology from "../../assets/img/technology.jpg";
import userRequests from "../../api/userRequests";
import friendsRequests from "../../api/friendsRequests";
import Notification from "../../api/notification";
import {AxiosResponse} from "axios";
import shave from "shave";
import {pictoCertification} from "../../api/pictos";
import ConfirmModal from "../ConfirmModal";
import {updateUser} from "../PublicHeader";
import RoundedProgressBar from '../ProgressBar/RoundedProgressBar';

interface OwnProps {
    user: User & { userId: number, Friends: Friendship[], FriendsOf: Friendship[], Following: User[], FollowedBy: User[], Circles: Circle[], mutual: number | null, Tributes: Tribute[] }
    discover?: (Thumbnail & {Tributes: Tribute[]})[]
    popular?: (Thumbnail & {Tributes: Tribute[]})[]
    showCover?: boolean //
    showBio?: boolean
    showButtons?: boolean
    showLastTributes?: boolean
    showCirclesSuggestions?: boolean
    collapsible?: boolean
    children?: React.ReactNode
    // userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type Props = OwnProps;

type State = Readonly<{
    lastTributes?: Tribute[]
    friendship: Friendship | null
    mutual: boolean
    sidebarCollapsed: boolean
    selectedBlockUserId: number | null
}>;

class UserSidebar extends PureComponent<Props, State> {
    readonly state: State = {
        friendship: null,
        mutual: !!this.props.user.mutual,
        sidebarCollapsed: false,
        selectedBlockUserId: null,
    };
    private isMyself = localStorage.getLocalUser().userId === this.props.user.userId;
    private friendsCount: number = 0;
    private promiseUserLastTribute: CancelablePromise<AxiosResponse<Tribute[]>> | null = null;
    private isFriend: boolean = localStorage.getLocalUser().userId === this.props.user || this.props.user.Friends.find(f => f.accepted && f.friendId === localStorage.getLocalUser().userId) !== undefined || this.props.user.FriendsOf.find(f => f.accepted && f.userId === localStorage.getLocalUser().userId) !== undefined;

    static defaultProps = {
        showButtons: true,
        showBio: true,
    }

    askAsFriend = (userId: number) => {
        friendsRequests.friendsRequest(userId).then((result) => {
            Notification.success('Invitation envoyée!');
            let friendship = result.data;
            delete friendship.friendId;
            this.setState(prevState => ({...prevState, friendship}));
        }, function(error) {
            Notification.error('Problème lors de la demande');
        });
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        this.clampCards();
    }

    componentDidMount(): void {
        if (this.props.showLastTributes) {
            this.promiseUserLastTribute = userRequests.getUserLastTributes<Tribute[]>(this.props.user.userId);
            this.promiseUserLastTribute.then((result) => {
                    this.setState(prevState => ({...prevState, lastTributes: result.data}), () => {
                        this.clampCards();
                    });
                }
            );
        }

        if (this.props.user.Friends) {
            let friendship = this.props.user.Friends.filter(function(el: Friendship) {
                return el.friendId === localStorage.getLocalUser().userId;
            }).concat(this.props.user.FriendsOf.filter(function(el) {
                return el.userId === localStorage.getLocalUser().userId;
            }))[0];
            this.setState(prevState => ({...prevState, friendship}));
        }
    }

    clampCards(): void {
        shave(".card .card-footer .card-name", 50, {spaces: false});
    }

    componentWillUnmount(): void {
        if (this.promiseUserLastTribute) this.promiseUserLastTribute.cancel();
    }

    follow = () => {
        userRequests.follow(this.props.user.userId).then(() => {
            Notification.success("Abonnement réalisé");
            this.setState(prevState => ({
                ...prevState,
                mutual: true,
            }));
        }, function() {
            Notification.error("Erreur lors de l'abonnement")
        });
    }

    unfollow = () => {
        userRequests.unfollow(this.props.user.userId).then(() => {
            Notification.success("Désabonnement pris en compte avec succès")
            this.setState(prevState => ({
                ...prevState,
                mutual: false,
            }));
        }, function() {
            Notification.error("Erreur lors du désabonnement")
        });
    }

    toggle = () => {
        this.setState( prevState => ({
            ...prevState,
            sidebarCollapsed: !prevState.sidebarCollapsed,
        }))
    }

    openBlockUserPopup = (userId: number) => {
        this.setState(prevState => ({
            ...prevState,
            selectedBlockUserId: userId,
        }));
    }

    closeBlockUserPopup = () => {
        this.setState(prevState => ({
            ...prevState,
            selectedBlockUserId: null,
        }));
    }

    blockUser = () => {
        userRequests.blockUser(this.state.selectedBlockUserId as number).then(() => {
            Notification.success('Utilisateur bloqué');
            updateUser();
            this.closeBlockUserPopup();
        }, function(err) {
            Notification.error('Erreur lors du blocage');
        });
    }

    // DEPRECATED
    // calculateAffinity = (tribe:Tribe|undefined) => {
    //     if (tribe === undefined) return 0;

    //     const userToCheckAffinity = tribe?.Users.find((user) => user.userId === this.props.user.userId);
    //     if (userToCheckAffinity === undefined) return 0;

    //     const sumCommonData = userToCheckAffinity.totalCommonCollection!
    //     + userToCheckAffinity.totalCommonTag!
    //     + userToCheckAffinity.totalCommonThumbnail!

    //     const scoreAffinityConnectedUser = sumCommonData / this.props.userTribes?.totalUser!;
    //     const scoreAffinityUser = sumCommonData / tribe?.Users.find((user) => user.userId === this.props.user.userId)?.totalUser!;

    //     return parseFloat((((scoreAffinityConnectedUser + scoreAffinityUser) / 2) * 100).toFixed(0));
    // }

    calculateUserAffinity = (user:User) => {

        const sumCommonData = user.totalCommonCollection!
        + user.totalCommonTag!
        + user.totalCommonThumbnail!

        const scoreAffinityConnectedUser = sumCommonData / user.totalUser!;
        const scoreAffinityUser = sumCommonData / user.totalUser2!;

        return parseFloat((((scoreAffinityConnectedUser + scoreAffinityUser) / 2) * 100).toFixed(0));
    }

    render() {
        this.friendsCount = this.props.user.Friends.filter(function(el) {
            return el.accepted;
        }).length + this.props.user.FriendsOf.filter(function(el) {
            return el.accepted;
        }).length;

        return <div className={`sidebar sidebar-ptn ${this.props.collapsible ? "sidebar-collapsible" : ""}  ${this.state.sidebarCollapsed ? "collapsed" : ""}`}>
            {
                this.props.showCover ?
                    <div className="sidebar-head"
                         style={{backgroundImage: 'url(' + this.props.user.coverPath + ')'}}/>
                    : null
            }

            <div className="sidebar-block sidebar-profile">
                <div className="profile-pic"
                     style={{backgroundImage: 'url(' + this.props.user.picturePath + ')'}}/>
                {
                    this.props.collapsible ? <button className="btn ghost-link collapse-btn" onClick={this.toggle}>
                    <img src={pictoCollapse} className="picto" alt="picto collapse"/>
                </button>
                        : null
                }

                <p className="name">
                    {this.props.user.firstname} {this.props.user.lastname}
                    {
                        this.props.user.certificationDate !== null && <img src={pictoCertification} alt="certifié"/>
                    }
                </p>
                <p className="username">
                    @{this.props.user.username}
                </p>
                <p className="city">
                    {this.props.user.displayCity && this.props.user.city}
                    {
                        this.props.user.displayCity && this.props.user.displayCountry
                        && this.props.user.country && this.props.user.country.length > 0
                        && this.props.user.city && this.props.user.city.length > 0
                        && ', '
                    }
                    {this.props.user.displayCountry && this.props.user.country}
                </p>
                <div className="sidebar-sep"/>
                {
                    this.props.showBio && (this.props.user.bio || this.props.user.linkedin || this.props.user.facebook || this.props.user.twitter || this.props.user.instagram) ?
                        <>
                            <p className="desc">{this.props.user.bio}</p>
                            <div className="socials">
                                {this.props.user.linkedin ? <a href={this.props.user.linkedin.startsWith('http') ? this.props.user.linkedin : 'https://' + this.props.user.linkedin} target="_blank" rel="noopener noreferrer"><img src={pictoLinkedin} alt="picto Linkedin"/></a> : null}
                                {this.props.user.facebook ? <a href={this.props.user.facebook.startsWith('http') ? this.props.user.facebook : 'https://' + this.props.user.facebook} target="_blank" rel="noopener noreferrer"><img src={pictoFacebook} alt="picto Facebook"/></a> : null}
                                {this.props.user.twitter ? <a href={this.props.user.twitter.startsWith('http') ? this.props.user.twitter : 'https://' + this.props.user.twitter} target="_blank" rel="noopener noreferrer"><img src={pictoTwitter} alt="picto Twitter"/></a> : null}
                                {this.props.user.instagram ? <a href={this.props.user.instagram.startsWith('http') ? this.props.user.instagram : 'https://' + this.props.user.instagram} target="_blank" rel="noopener noreferrer"><img src={pictoInstagram} alt="picto Instagram"/></a> : null}
                            </div>
                            <div className="sidebar-sep"/>
                        </>
                        : null
                }
                <div className='flex align-center space-between'>
                    <div className="stats">
                        <p>
                        { this.isMyself ?
                            <Link to="connected.friends"><strong>{this.friendsCount}</strong> amis</Link>
                            :
                            <><strong>{this.friendsCount}</strong> amis</>
                        }
                        </p>
                        <p>
                        { this.isMyself ?
                            <Link to="connected.follow.following"><strong>{this.props.user.Following.length}</strong> abonnements</Link>
                            :
                            <><strong>{this.props.user.Following.length}</strong> abonnements</>
                        }
                        </p>
                        <p>
                        { this.isMyself ?
                            <Link to="connected.follow.followers"><strong>{this.props.user.FollowedBy.length}</strong> abonnés</Link>
                            :
                            <><strong>{this.props.user.FollowedBy.length}</strong> abonnés</>
                        }
                        </p>
                        <p>
                        { this.props.user.paanteonCount !== undefined && this.props.user.paanteonCount > 0 &&
                            <Link to="connected.paanteon.thumbnail.tributes" params={{thumbnailId: this.props.user.paanteonThumbnailId, thumbnailUniqueName: this.props.user.paanteonThumbnailName}}><strong>{this.props.user.paanteonCount}</strong> paanteons</Link>
                        }
                        </p>
                        <p>{ this.isMyself && this.props.user.Tributes.length > 10 ?
                            <Link to="connected.tribes"><strong>{this.props.user.tribeCount}</strong> tribu(s)</Link>
                            :
                            <><strong>{this.props.user.tribeCount}</strong> tribu(s)</>
                        }</p>
                        <p><strong>{this.props.user.Circles.length}</strong> cercles</p>
                    </div>
                    {
                        this.props.user.userId !== localStorage.getLocalUser().userId &&
                        <RoundedProgressBar size="medium" percentage={this.calculateUserAffinity(this.props.user)} />
                    }
                </div>

                {
                    this.props.showButtons && this.isMyself ?
                        <div>
                            <Link to="connected.inspiration-feed.my-publications" className="btn btn-outlined btn-block">MES PUBLICATIONS</Link>
                            <Link to="connected.paanteon.archives" className="btn btn-outlined btn-block margin">MES ARCHIVES</Link>
                            <Link to="connected.paanteon" className="btn btn-1 btn-block">MON PAANTEON</Link>
                        </div>
                        : null
                }

                {
                    this.props.showButtons && !this.isMyself ?
                        <div>
                            {!this.state.friendship ? <button className="btn btn-outlined btn-block margin" onClick={() => this.askAsFriend(this.props.user.userId)}>DEMANDER EN AMI</button> : null}
                            {this.state.friendship && !this.state.friendship.accepted && this.state.friendship.userId ?
                                <button className="btn btn-outlined btn-block" disabled={this.state.friendship != null && this.state.friendship.accepted == null}>
                                    DEMANDE EN ATTENTE
                                </button> : null}
                            {this.state.friendship && this.state.friendship.accepted === null && this.state.friendship.friendId ? <Link to="connected.friends" className="btn btn-outlined btn-block">VOIR LA DEMANDE</Link> : null}
                            {this.props.user.canContact && <Link to="connected.messages" params={{userId: this.props.user.userId}} className="btn btn-outlined btn-block margin">ENVOYER UN MESSAGE</Link>}
                            {
                                !this.isFriend && !this.state.mutual && <button className="btn btn-outlined btn-block margin" onClick={this.follow}>SUIVRE</button>
                            }
                            {
                                this.state.mutual && <button className="btn btn-outlined btn-block margin" onClick={this.unfollow}>NE PLUS SUIVRE</button>
                            }
                            <Link to="connected.paanteon.user.galleries.condensed" params={{userId: this.props.user.userId, username: this.props.user.username}} className="btn btn-1 btn-block margin">SON PAANTEON</Link>
                            <button className="btn link-btn blue" onClick={() => this.openBlockUserPopup(this.props.user.userId)}>
                                Bloquer ce membre
                            </button>
                        </div>
                        : null
                }
            </div>

            {
                this.props.showLastTributes && this.state.lastTributes ?
                    <div className="sidebar-latest-tributes sidebar-block">
                        <p className="block-title">{this.isMyself ? 'M' : 'S'}es derniers hommages</p> {/* SES / MES derniers hommages */}
                        <div className="uk-grid uk-grid-small">
                            {
                                this.state.lastTributes.map(card => {
                                    return <div className="uk-width-1-2 uk-grid-margin" key={card.tributeId} title={card.Thumbnail.label}>
                                        <Link to="connected.paanteon.tribute.details" params={{tributeId: card.tributeId, thumbnailUniqueName: card.Thumbnail.uniqueName, username: this.props.user.username}} className="card expand small blue no-hover-anim" style={{backgroundImage: 'url(' + card.picturePath + ')'}}>
                                            <canvas width="250" height="350"/>
                                            <div className="card-footer">
                                                <p className="card-name">{card.Thumbnail.label}</p>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    : null
            }

            {
                this.props.popular ?
                    <div className="sidebar-most-popular sidebar-block">
                        <p className="block-title">Les plus populaires<br/>du moment</p>
                        <div className="uk-grid uk-grid-small">
                            {
                                this.props.popular.slice(0, 4).map((card: Thumbnail & {Tributes: Tribute[]}) => {
                                    return (
                                        <div className="uk-width-1-2 uk-grid-margin" key={card.thumbnailId}>
                                            <Link to="connected.paanteon.thumbnail.tributes" params={{
                                                thumbnailId: card.thumbnailId,
                                                thumbnailUniqueName: card.uniqueName,
                                            }}
                                                  className="card expand small blue no-hover-anim"
                                                  style={{backgroundImage: 'url(' + card.Tributes[0].picturePath + ')'}}>
                                                <canvas width="250" height="350"/>
                                                <div className="card-footer">
                                                    <p className="card-name">{card.label}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : null
            }

            {
                this.props.discover ?
                    <div className="sidebar-discover sidebar-block">
                        <p className="block-title">Vous pourriez découvrir aussi</p>
                        <div className="uk-grid uk-grid-small">
                            {
                                this.props.discover.length && this.props.discover.slice(0, 4).map((card: Thumbnail & {Tributes: Tribute[]}) => {
                                    return (
                                        <div className="uk-width-1-2 uk-grid-margin" key={card.thumbnailId}>
                                            <Link to="connected.paanteon.thumbnail.tributes" params={{
                                                thumbnailId: card.thumbnailId,
                                                thumbnailUniqueName: card.uniqueName,
                                            }}
                                                  className="card expand small blue no-hover-anim"
                                                  style={{backgroundImage: 'url(' + card.Tributes[0].picturePath + ')'}}>
                                                <canvas width="250" height="350"/>
                                                <div className="card-footer">
                                                    <p className="card-name">{card.label}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    : null
            }

            {
                this.props.showCirclesSuggestions ?
                    <div className="sidebar-circles-suggestion sidebar-block">
                        <p className="block-title">Suggestions de cercles</p>
                        <div className="uk-grid uk-grid-small">
                            <div className="uk-width-1-2 uk-grid-margin">
                                <Link to="" className="round-thumbnail">
                                    <div className="pic" style={{backgroundImage: 'url(' + imgMolecularCook + ')'}}>
                                        <canvas width="100" height="100"/>
                                    </div>
                                    <p>Amateurs de cuisine moléculaire</p>
                                    <span className="chip chip-white">875
                                        <img src={pictoCirclesBlue} alt="picto circles blue" className="picto"/>
                                    </span>
                                </Link>
                            </div>
                            <div className="uk-width-1-2 uk-grid-margin">
                                <Link to="" className="round-thumbnail">
                                    <div className="pic" style={{backgroundImage: 'url(' + imgTechnology + ')'}}>
                                        <canvas width="100" height="100"/>
                                    </div>
                                    <p>Adeptes de nouvelles technologies</p>
                                    <span className="chip chip-white">3,7 K
                                        <img src={pictoCirclesBlue} alt="picto circles blue" className="picto"/>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    : null
            }

            {
                this.state.selectedBlockUserId &&
                <ConfirmModal confirmMessage="Bloquer" onCancel={this.closeBlockUserPopup} onConfirm={this.blockUser}>
                    Voulez-vous vraiment bloquer cet utilisateur ?
                </ConfirmModal>
            }

            {this.props.children}
        </div>
    }
}

export default UserSidebar;
