import React, {FormEvent, FunctionComponent, useCallback, useMemo, useRef, useState} from "react";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../../api/injectors";
import {InjectedIntlProps, injectIntl} from "react-intl";
import Helmet from "../../components/Helmet";
import {acceptableImages} from "../../api/domHelper";
import adminRequests from "../../api/adminRequests";
import {AxiosResponse} from "axios";
import Notification from "../../api/notification";

interface Props {
  publicHomeData?: AxiosResponse<{
    copyright: string,
    background: string,
  }>,
}

const HomeManagement: FunctionComponent<Props & InjectedUIRouterProps & InjectedIntlProps> = ({publicHomeData}) => {
  let [copyright, setCopyright] = useState(publicHomeData?.data.copyright || "");
  let [backgroundUrl, setBackgroundUrl] = useState(publicHomeData?.data.background || undefined);
  const backgroundReader = useRef<FileReader | undefined>();

  const [homeBackground, setHomeBackground] = useState<string | null>(null);
  const [backgroundCopyright, setBackgroundCopyright] = useState(copyright);

  const cannotSubmit = useMemo(
    () => copyright === backgroundCopyright && homeBackground === null,
    [backgroundCopyright, copyright, homeBackground],
  );

  const readBackground = useCallback((file: File) => {
    backgroundReader.current = new FileReader();

    backgroundReader.current.onloadend = () => {
      const homeBackgroundRes = (backgroundReader.current as FileReader).result;
      setHomeBackground(homeBackgroundRes as string);
    };

    backgroundReader.current.readAsDataURL(file);
  }, []);

  const updateHome = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    adminRequests.updateHomeData({background: homeBackground || undefined, copyright: backgroundCopyright}).then((resp) => {
      Notification.success("La page d'accueil a été mise à jour avec succès");
      setCopyright(resp.data.copyright);
      setBackgroundUrl(resp.data.background);
      setHomeBackground(null);
    }, () => {
      Notification.error("Une erreur est survenue lors de la mise à jour de la page d'accueil");
    });
  }, [backgroundCopyright, homeBackground]);

  const uploadHomeBackground = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files)
      readBackground(e.target.files[0]);
  }, [readBackground]);

  return (
    <div className="dark-bg">
      <div className="uk-container">
        <div className="main-content">
          <div className="uk-container">
            <Helmet title={'Home non connecté | Paanteon'} />
            <h1 className="page-title">
              Home non connecté
            </h1>

            <div className="margin-bottom">
              <Link to="^">&lt; Retour</Link>
            </div>

            <div className={"margin-bottom"}>
              <form onSubmit={updateHome}>
                <div className={"margin-bottom"}>
                  {(homeBackground || backgroundUrl) && <div>
                    <div className={"picture-preview margin-bottom"}>
                      <img src={homeBackground || backgroundUrl} alt={""} style={{ width: '100%' }} />
                      { backgroundCopyright && <div className="img-copyright">
                        { backgroundCopyright }
                      </div>}
                    </div>
                  </div>}

                  <label className={"input-btn btn-outlined margin-right btn light" + (homeBackground ? " active" : "")} title="Photo de fond de la page d'accueil">
                    <input
                      type="file"
                      name="homeBackground"
                      onChange={uploadHomeBackground}
                      accept={acceptableImages}
                    />
                    Nouveau fond de la page d'accueil
                  </label>

                  <input type="text"
                         placeholder="Copyright photo"
                         className="input big light"
                         onChange={(e) => setBackgroundCopyright(e.target.value)}
                         value={backgroundCopyright}
                         name="backgroundCopyright" />
                </div>

                <div>
                  <button type={"submit"} className={"btn btn-1"} disabled={cannotSubmit}>
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectUIRouter(injectIntl(HomeManagement));
