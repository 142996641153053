const filterHelper = {
    fieldSearch: <P, K extends keyof P>(objects: P[], fields: K[], expression: string): P[] => {
        if (expression.length === 0) return objects;
        let filtered: P[] = [];
        let words: string[] = expression.split(' ');

        let foundWordInObject: boolean;
        let foundAllWordsInObject: boolean;
        for (let i = 0; i < objects.length; i++) {
            foundAllWordsInObject = true;
            for (let j = 0; j < words.length; j++) {
                foundWordInObject = false;
                for (let k = 0; k < fields.length; k++) {
                    if (typeof objects[i][fields[k]] === "string") {
                        if ((objects[i][fields[k]] as unknown as string).toLowerCase() === words[j].toLowerCase()) {
                            foundWordInObject = true;
                            break;
                        }
                    }
                }

                if (!foundWordInObject) {
                    foundAllWordsInObject = false;
                    break;
                }
            }

            if (foundAllWordsInObject) filtered.push(objects[i]);
        }

        return filtered;
    },

    deepSearch: <P>(objects: P[], expression: string): P[] => {
        if (expression.length === 0) return objects;
        let filtered: P[] = [];
        let words: string[] = expression.split(' ');
        for (let i = 0; i < words.length; i++) {
            words[i] = words[i].toLowerCase();
        }

        let stringify: string;
        let foundAllWordsInObject: boolean;
        for (let i = 0; i < objects.length; i++) {
            stringify = JSON.stringify(objects[i]).toLowerCase();
            foundAllWordsInObject = true;
            for (let j = 0; j < words.length; j++) {
                if (stringify.indexOf(words[j]) === -1) {
                    foundAllWordsInObject = false;
                    break;
                }
            }

            if (foundAllWordsInObject) filtered.push(objects[i]);
        }

        return filtered;
    },

    orderByField: <P, K extends keyof P>(field: K, reverse?: boolean) => {
        return reverse ? function(a: P, b: P) {
            return a[field] > b[field] ? -1 : 1;
        } : function(a: P, b: P) {
            return a[field] > b[field] ? 1 : -1;
        };
    },
};

export default filterHelper;
