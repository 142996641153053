import React, {Component} from 'react';
import {Link} from "../../api/injectors";
import moment from "moment";
import {Comment, CommentToSend, User} from "../../api/types";
import ReportModal from "../ReportModal";
import ConfirmModal from "../ConfirmModal";
import OptionsMenu from "../OptionsMenu";
import {Mention, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem} from "react-mentions";
import MentionUser, {MentionableUsersContext} from "../MentionUser";
import {processMentions} from "../../api/domHelper";

type Props = {
    comments: Comment[]
    onSend?(comment: CommentToSend): Promise<boolean>
    onDelete(commentId: number): Promise<any>
    selfUser?: User
    showIds?: boolean
};

type State = {
    newComments: {[key: number]: CommentToSend}
    maxComments: number
    toggledAnswers: {[key: number]: number} // Show or not answers for comment
    toggledComments: {[key: number]: boolean} // Show or not full comment
    commentConfirmId: number | null
};

class Comments extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const {hash} = window.location;
        let commentAnchor = null;
        if (hash !== '' && hash.slice(1).startsWith('comment')) {
            const id = parseInt(hash.slice(8), 10);
            commentAnchor = props.comments.find(c => c.commentId || !!c.Answers?.find(a => a.commentId === id));
        }

        this.state = {
            newComments: {},
            maxComments: 12,
            toggledAnswers: commentAnchor ? {[commentAnchor.commentId]: 1} : {},
            toggledComments: {},
            commentConfirmId: null,
        };
    }

    sendComment = (answerId: number) => {
        return (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (event.key === "Enter") {
                event.preventDefault();

                if (this.props.onSend && this.state.newComments[answerId]?.content?.length)
                    this.props.onSend(this.state.newComments[answerId]).then((res: boolean) => {
                        if (res) {
                            this.setState(prevState => ({
                                ...prevState,
                                newComments: {
                                    ...prevState.newComments,
                                    [answerId]: {
                                        answerId,
                                        content: '',
                                    },
                                },
                            }));
                        }
                    });
            }
        }
    }

    increaseComments = () => {
        this.setState(prevState => ({
            ...prevState,
            maxComments: prevState.maxComments + 12,
        }));
    };

    toggleAnswers = (commentId: number, up: number) => {
        return () => {
            this.setState(prevState => ({
                ...prevState,
                toggledAnswers: {
                    ...prevState.toggledAnswers,
                    [commentId]: prevState.toggledAnswers[commentId] === up ? 0 : up,
                },
            }));
        };
    };

    setComment: (answerId: number) => OnChangeHandlerFunc = (answerId) => {
        return (event) => {
            const val = event.target.value;
            this.setState(prevState => {
                let nState = {...prevState};
                if (!nState.newComments[answerId]) {
                    nState.newComments = {
                        ...prevState.newComments,
                        [answerId]: {
                            content: val,
                            answerId,
                        },
                    }
                } else {
                    nState.newComments[answerId] = {
                        ...nState.newComments[answerId],
                        content: val,
                    }
                }
                return nState;
            });
        };
    };

    toggleComment = (commentId: number) => {
        return () => {
            this.setState(prevState => ({
                ...prevState,
                toggledComments: {
                    ...prevState.toggledComments,
                    [commentId]: !prevState.toggledComments[commentId],
                },
            }))
        };
    };

    openCommentConfirm = (commentId: number) => {
        this.setState(prevState => ({
            ...prevState,
            commentConfirmId: commentId,
        }));
    }

    closeCommentConfirm = () => {
        this.setState(prevState => ({
            ...prevState,
            commentConfirmId: null,
        }));
    }

    confirmCommentDelete = () => {
        this.props.onDelete(this.state.commentConfirmId as number).then(() => {
            this.setState(prevState => ({
                ...prevState,
                commentConfirmId: null,
            }));
        })
    }

    stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
    }

    render() {
        return (
            <div className="tile" style={{width: '100%'}} onClick={this.stopPropagation}>
                <div className="tile-body">
                    {
                        this.props.onSend && this.props.selfUser && <div className="feed-item write-comment let-overflow">
                            <div className="profile-pic" style={{backgroundImage: 'url(' + this.props.selfUser.picturePath + ')'}}/>
                            <p>
                                <MentionableUsersContext.Consumer>
                                    {
                                        (mentionableUsers) => <MentionsInput id="first-textarea" value={this.state.newComments[-1] ? this.state.newComments[-1].content : ''}
                                                                             onChange={this.setComment(-1)}
                                                                             placeholder={"Votre commentaire"}
                                                                             onKeyPress={this.sendComment(-1)}
                                                                             className={"textarea textarea-with-mentions no-resize"}>
                                            <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionableUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                     renderSuggestion={((suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>)}/>
                                        </MentionsInput>
                                    }
                                </MentionableUsersContext.Consumer>
                            </p>
                        </div>
                    }

                    {
                        this.props.comments.slice(0, this.state.maxComments).map(comment => {
                            return <div className="feed-item let-overflow" key={comment.commentId} id={`comment${comment.commentId}`}>
                                <div className="profile-pic" style={{backgroundImage: 'url(' + comment.User.picturePath + ')'}}/>
                                <div>
                                    <p>
                                        <em className="ptn-em"><Link to="connected.paanteon.user.galleries.condensed"
                                                  params={{userId: comment.User.userId, username: comment.User.username}}>{comment.User.firstname} {comment.User.lastname}</Link></em>
                                        <small>&nbsp;-&nbsp;{moment(comment.createdAt).format('LL')}</small>
                                        {
                                            this.props.showIds && <small>&nbsp;-&nbsp;ID: {comment.commentId}</small>
                                        }
                                    </p>
                                    <OptionsMenu>
                                        <span className="options-menu">
                                            <ul>
                                                <li>
                                                    <ReportModal commentId={comment.commentId}><button className="btn link-btn">Signaler</button></ReportModal>
                                                </li>
                                                {
                                                    (comment.userId === this.props.selfUser?.userId || this.props.selfUser?.admin) && <li><button className="btn link-btn" onClick={() => this.openCommentConfirm(comment.commentId)}>Supprimer</button></li>
                                                }
                                            </ul>
                                        </span>
                                    </OptionsMenu>
                                    <p className='break-word'>{comment.content.length > 300 && !this.state.toggledComments[comment.commentId] ? <>{processMentions([comment.content.slice(0, 300)])}... <button className="btn link-btn" onClick={this.toggleComment(comment.commentId)}>[lire la suite]</button></> : processMentions([comment.content])}</p>
                                    <div className="feed-footer">
                                        {/* <small><img src={pictoHeartEmpty} alt="picto heart" className="picto"/>10 TODO (comment.likes ??) : comment.likes</small>*/}
                                        {
                                            this.props.onSend && this.props.selfUser && <small className="pointer" onClick={this.toggleAnswers(comment.commentId, 2)}>Répondre</small>
                                        }
                                        {
                                            comment.Answers && comment.Answers.length ?
                                                <small className="pointer" onClick={this.toggleAnswers(comment.commentId, 1)}>{this.state.toggledAnswers[comment.commentId] ? 'Masquer' : 'Voir'} les {comment.Answers.length} réponses</small>
                                                : null
                                        }
                                    </div>

                                    {
                                        this.state.toggledAnswers[comment.commentId] ?
                                            <div ng-show="comment.showAnswers">
                                                {
                                                    this.state.toggledAnswers[comment.commentId] === 2 && this.props.selfUser && this.props.onSend ?
                                                        <div className="feed-item let-overflow">
                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + this.props.selfUser.picturePath + ')'}}/>
                                                            <MentionableUsersContext.Consumer>
                                                                {
                                                                    (mentionableUsers) => <MentionsInput value={this.state.newComments[comment.commentId] ? this.state.newComments[comment.commentId].content : ''}
                                                                                                         onChange={this.setComment(comment.commentId)}
                                                                                                         placeholder={"Répondre..."}
                                                                                                         onKeyPress={this.sendComment(comment.commentId)}
                                                                                                         className={"textarea textarea-with-mentions no-resize"}>
                                                                        <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionableUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                                                 renderSuggestion={((suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>)}/>
                                                                    </MentionsInput>
                                                                }
                                                            </MentionableUsersContext.Consumer>
                                                        </div>
                                                        : null
                                                }


                                                {
                                                    comment.Answers && comment.Answers.map((answer) => {
                                                        return <div className="feed-item let-overflow" key={answer.commentId} id={`comment${answer.commentId}`}>
                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + answer.User.picturePath + ')'}}/>
                                                            <div>
                                                                <p>
                                                                    <em className="ptn-em"><Link to="connected.paanteon.user.galleries.condensed"
                                                                             params={{userId: answer.userId, username: answer.User.username}}>{answer.User.firstname} {answer.User.lastname}</Link></em><small> - {moment(answer.createdAt).format('LL')}</small>
                                                                    {
                                                                        this.props.showIds && <small>&nbsp;-&nbsp;ID: {answer.commentId}</small>
                                                                    }
                                                                </p>

                                                                <OptionsMenu>
                                                                    <span className="options-menu">
                                                                        <ul>
                                                                            <li>
                                                                                <ReportModal commentId={answer.commentId}><button className="btn link-btn">Signaler</button></ReportModal>
                                                                            </li>
                                                                            {
                                                                                (answer.userId === this.props.selfUser?.userId || this.props.selfUser?.admin) && <li><button className="btn link-btn" onClick={() => this.openCommentConfirm(answer.commentId)}>Supprimer</button></li>
                                                                            }
                                                                        </ul>
                                                                    </span>
                                                                </OptionsMenu>
                                                                <p className="break-word">{answer.content.length > 300 && !this.state.toggledComments[answer.commentId] ? <>{processMentions([answer.content.slice(0, 300)])}... <button className="btn link-btn" onClick={this.toggleComment(answer.commentId)}>[lire la suite]</button></> : processMentions([answer.content])}</p>
                                                            </div>
                                                        </div>
                                                    })
                                                }

                                                {
                                                    this.state.toggledAnswers[comment.commentId] === 1 && this.props.selfUser && this.props.onSend ?
                                                        <div className="feed-item let-overflow">
                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + this.props.selfUser.picturePath + ')'}}/>
                                                            <MentionableUsersContext.Consumer>
                                                                {
                                                                    (mentionableUsers) => <MentionsInput value={this.state.newComments[comment.commentId] ? this.state.newComments[comment.commentId].content : ''}
                                                                                                         onChange={this.setComment(comment.commentId)}
                                                                                                         placeholder={"Répondre..."}
                                                                                                         onKeyPress={this.sendComment(comment.commentId)}
                                                                                                         className={"textarea textarea-with-mentions no-resize"}>
                                                                        <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionableUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                                                 renderSuggestion={((suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>)}/>
                                                                    </MentionsInput>
                                                                }
                                                            </MentionableUsersContext.Consumer>
                                                        </div>
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }
                                </div>
                            </div>
                        })
                    }

                    {
                        this.props.comments && this.state.maxComments < this.props.comments.length ?
                            <div className="h-center margin">
                                <button className="btn btn-outlined light-blue" onClick={this.increaseComments}>VOIR PLUS DE COMMENTAIRES</button>
                            </div>
                            : null
                    }
                </div>

                {
                    this.state.commentConfirmId && <ConfirmModal onCancel={this.closeCommentConfirm} onConfirm={this.confirmCommentDelete}>Êtes-vous sûr(e) de vouloir supprimer définitivement ce commentaire ?</ConfirmModal>
                }
            </div>
        );
    }
}

export default Comments;
