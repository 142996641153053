import React, {PureComponent} from 'react';
import {Like, Thumbnail} from "../api/types";
import pictoLove from "../assets/img/pictos/picto-love.svg";
import pictoAdmiration from "../assets/img/pictos/picto-admiration.svg";
import pictoRespect from "../assets/img/pictos/picto-respect.svg";
import paanteon from "../api/paanteonRequests";

interface OwnProps {
    thumbnail: Thumbnail
    static?: boolean
}

type Props = OwnProps;

type State = Readonly<{
    like: Like
    loveCount: number
    admirationCount: number
    respectCount: number
}>;

class SlideSwitch extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            like: this.props.thumbnail.Likes[0] ? this.props.thumbnail.Likes[0] : {level: -1, thumbnailId: this.props.thumbnail.thumbnailId},
            loveCount: this.props.thumbnail.loveCount,
            admirationCount: this.props.thumbnail.admirationCount,
            respectCount: this.props.thumbnail.respectCount,
        }
    }

    switchTo = (index: number) => {
        if (!this.props.static || !this.props.static) {
            var oldValue = this.state.like.level;
            // we add like or update if exists

            this.setState(prevState => ({
                ...prevState,
                like: {
                    ...prevState.like,
                    level: index,
                },
            }), () => {
                paanteon.like(this.state.like).then(() => {
                    // we update counters for each picto (+1 to new level, -1 to old level)
                    this.setState(prevState => {
                        let nState = {...prevState};
                        switch (index) {
                            case 1: nState.loveCount += 1; break;
                            case 2: nState.admirationCount += 1; break;
                            case 3: nState.respectCount += 1; break;
                        }
                        switch (oldValue) {
                            case 1: nState.loveCount -= 1; break;
                            case 2: nState.admirationCount -= 1; break;
                            case 3: nState.respectCount -= 1; break;
                        }
                        return nState;
                    });
                }, () => {
                    this.setState(prevState => ({
                        ...prevState,
                        like: {
                            ...prevState.like,
                            level: oldValue,
                        },
                    }));
                });
            });
        }
    }

    render() {
        return (
            <div className={"slide-switch" + (this.props.static ? ' static' : '')} ng-class="{'static': static}">
                <div className={"switch-cursor" + (this.state.like.level > -1 ? " pos-" + this.state.like.level : '')}/>
                <div className="switch-container">
                    <div className={"switch-option" + (this.state.like.level === 1 ? ' active' : '')} onClick={() => this.switchTo(1)}>
                        <img className="icon" src={pictoLove} alt="picto love"/>
                        <div className="switch-caption">
                            <span className="main">Love</span>
                            <span className="secondary">({this.state.loveCount})</span>
                        </div>
                    </div>
                    <div className={"switch-option" + (this.state.like.level === 2 ? ' active' : '')} onClick={() => this.switchTo(2)}>
                        <img className="icon" src={pictoAdmiration} alt="picto admire"/>
                        <div className="switch-caption">
                            <span className="main">Admiration</span>
                            <span className="secondary">({this.state.admirationCount})</span>
                        </div>
                    </div>
                    <div className={"switch-option" + (this.state.like.level === 3 ? ' active' : '')} onClick={() => this.switchTo(3)}>
                        <img className="icon" src={pictoRespect} alt="picto respect"/>
                        <div className="switch-caption">
                            <span className="main">Respect</span>
                            <span className="secondary">({this.state.respectCount})</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SlideSwitch;
