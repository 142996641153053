import React from 'react';
import Page from "./Page";
import PublicHeader from "../components/PublicHeader";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

type Props = {}

type State = {}

class Manifest extends Page<Props, State> {
    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <div className="dark-bg">

                    <div className="uk-container main-content">
                        <Helmet title={'Manifesto | Paanteon'} />
                        <h1 className="page-title">
                            Manifesto
                        </h1>
                    </div>

                    <div className="section">
                        <div className="uk-container main-content-s body spaced-body">

                            <p>Chez les Grecs et les Romains, le panthéon désignait le temple de « tous les dieux », du grec πᾶν (pãn) et θεοσ (theos).</p>
                            <p>Si, de l’Antiquité à nos jours, cette institution sacrée a perduré dans l’esprit de l’humanité, puis s’est sécularisée, c’est qu’elle répond à un besoin profond chez l’homme : aimer et admirer.</p>
                            <p>Les nations et les républiques, soucieuses de personnifier des idéaux, ont honoré ainsi les hommes et femmes illustres de leur histoire nationale. S’ils ont été choisis, c’est le plus souvent en raison de leurs grandes réussites dans leur domaine et parce qu’ils incarnent des valeurs riches d’enseignement pour tous.</p>
                            <p>Entrer au Panthéon, c’est encore aujourd’hui recevoir l’hommage ultime, le témoignage d’une admiration nationale et l’assurance de demeurer gravé dans la mémoire collective.</p>
                            <h2 className="title-2">Faire vivre une symbolique collective</h2>
                            <p>Si les panthéons religieux ou républicains sont dépositaires d’une mémoire mythique ou historique, ils sont largement aujourd’hui des lieux désuets et ignorés, illustrant l’affaissement d’une forme de civilisation de l’admiration. Il est vrai que les citoyens sont rarement consultés pour savoir qui devrait y être accueilli.</p>
                            <p>A l’heure d’internet et des réseaux sociaux, comment continuer à célébrer nos grandes icônes civilisationnelles qui nous servent de repères, de boussoles, bref ces « dieux modernes » ?</p>
                            <p>Comment continuer de faire vivre une symbolique collective, de transmettre des récits permettant de tisser du lien social, de raconter des histoires inspirantes et de faire que ces récits soient partagés ?</p>
                            <p>Paanteon est né de ce constat. Nous portons tous en nous un panthéon idéal dans lequel nous mettrions sans hésiter des personnalités, mortes ou vivantes, réputées ou inconnues, que nous admirons personnellement, soit parce qu’elles ont eu une grande importance dans l’histoire, soit parce qu’elles nous servent de modèle au quotidien.</p>
                            <p>Ne voudrions-nous pas aussi y mettre tout ce qui nous relie aux autres et au monde ? L’émerveillement que l’on peut ressentir devant la beauté de la nature, les émotions que suscitent en nous des œuvres culturelles et artistiques, sans parler de la richesse des expériences humaines et de ce qui enchante notre vie au quotidien ?</p>
                            <h2 className="title-2">Une connaissance de soi, un élan vers les autres</h2>
                            <p>Paanteon a été créé pour cela : vous permettre de créer votre panthéon personnel en ligne (« Mon Paanteon ») afin d’y graver votre univers d’inspirations positives, puis de vous regrouper par affinités électives avec d’autres membres au sein de « tribus » et de partager avec eux autour de préférences et goûts en commun.</p>
                            <p>Construire votre panthéon personnel, c’est rassembler dans ce « temple virtuel » vos inspirations et influences majeures. Celles qui tissent le fil de votre vie, créent du sens, font de vous ce que vous êtes et rendent chacun de nous unique.</p>
                            <p>Il s’agit d’une démarche double. Elle implique tout d’abord un effort d’introspection pour réfléchir à ce qui est réellement important. Comme dans la fameuse devise : « Connais-toi toi-même » des Grecs anciens qui proposaient de découvrir en soi les sources de l’épanouissement, de l’accomplissement personnel et d’une certaine sagesse.</p>
                            <p>Dès lors, cette connaissance devient un formidable élan pour mieux se relier aux autres et au monde.</p>
                            <p>C’est pourquoi Paanteon est aussi un réseau social, le premier dédié à l’inspiration, un lieu vivant de l’imaginaire individuel et collectif, un lieu d’inspiration et d’échanges autour de passions et de goûts en commun.</p>
                            <h2 className="title-2">Pour un web plus lent, réflexif et nutritif</h2>
                            <p>Les réseaux sociaux ne peuvent pas être qu’un déversoir de négativité, un miroir déformant d’exaspérations sans fin et de passions mauvaises, un puits sans fond de la superficialité instantanée, de l’exhibitionnisme permanent et de la médiocrité générale.</p>
                            <p>Avec Paanteon, nous voulons proposer un réseau social fondé sur un web plus positif, plus authentique, moins frénétique, plus lent, plus réflexif, plus nutritif, associé à un usage plus modéré et non addictif.</p>
                            <h2 className="title-2">Retrouver un esprit de bienveillance</h2>
                            <p>Nous voulons aussi retrouver l’esprit de communautés bienveillantes, un esprit centré sur une quête de connaissance et une aspiration à l’accomplissement personnel et à l’élévation de soi.</p>
                            <p>Nous croyons en des valeurs de liberté, de fraternité et de tolérance. La liberté d’expression doit prévaloir, toujours dans le respect de la diversité des goûts, des préférences et des opinions des uns et des autres. Si la discussion et la critique sont toujours possibles, les échanges se doivent de rester polis, courtois et constructifs (cf. notre Charte pour la communauté).</p>
                            <h2 className="title-2">Pour un web plus authentique</h2>
                            <p>L’anonymat est un poison qui autorise aujourd’hui toutes les dérives et tous les abus. C’est pourquoi il n’a pas sa place sur Paanteon.</p>
                            <p>Une procédure de certification assure donc que l’identité de chaque membre est bien réelle et aide à prévenir les abus.</p>
                            <p>Nous appliquons une discipline stricte de gestion des contenus. Les contenus inappropriés ou illicites ne seront pas tolérés. Nous vous encourageons à nous les signaler.</p>
                            <p>Les membres qui enfreignent les règles pourront être suspendus temporairement ou exclus.</p>
                            <h2 className="title-2">Pour un web respectueux de votre vie privée</h2>
                            <p>Sur Paanteon, nous préservons votre vie privée. Ce que vous faites au quotidien, les endroits où vous vous rendez, ce que vous mangez, achetez, les sites internet que vous visitez, ne nous regarde pas.</p>
                            <p>Hormis les informations strictement nécessaires pour devenir membre et utiliser la plateforme, nous nous engageons à ne faire aucun traçage ni aucune capture de vos données personnelles. Tout simplement parce que nous n’en avons pas besoin.</p>
                            <p>Vous restez propriétaire de vos données. Si vous nous accordez une licence pour publier tout le contenu que vous êtes susceptible de générer sur Paanteon, vous en restez maître et pouvez à tout moment décider de le supprimer et de le récupérer.</p>
                            <p>Nous vous donnons aussi de larges possibilités de paramétrages d’usage et de confidentialité qui vous permettront de garder la maîtrise de votre visibilité, de votre temps passé et de vos notifications.</p>
                            {/* <h2 className="title-2">Un modèle raisonné, la possibilité de choisir</h2>
                            <p>Paanteon repose sur un modèle publicitaire raisonné, de type informationnel, et donc non intrusif car proche de vos goûts et de vos préférences.</p>
                            <p>Nous vous offrons aussi une formule d’abonnement avec la possibilité de surfer en toute sérénité pour tous ceux qui souhaiteraient ne voir aucune publicité.</p> */}
                            <h2 className="title-2">Une contribution au monde en retour</h2>
                            <p>Enfin, nous nous engageons à consacrer une partie de nos bénéfices futurs à une contribution en retour au monde sous la forme de soutien financier à des hommes et femmes, à des causes et à des entreprises qui font du bien à la planète et à tous les êtres qui la peuplent.</p>

                            <div className="text-center text-uppercase title-6 margin-l">
                                <strong className="text-light-blue">BIENVENUE SUR PAANTEON</strong><br />
                                LE RÉSEAU DES GENS CURIEUX DES AUTRES ET DU MONDE.<br />
                                LÀ OÙ NAISSENT DE FORMIDABLES HISTOIRES INSPIRANTES.<br />
                                <strong className="text-light-blue">MERCI D’ENRICHIR NOTRE COMMUNAUTÉ !</strong><br />
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default Manifest;
