import React from 'react';
import UserSidebar from "../components/UserSidebar";
import {InMemoriam as InMemoriamType, UserInfos, Tribe} from "../api/types";
import {InjectedUIRouterProps, injectUIRouter, Link} from "../api/injectors";
import Toggleable from "../components/Toggleable";
import {UIView} from "@uirouter/react";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";
import {stopPropagation} from "../api/helpers";
import moment from "moment";
import Page from "./Page";
import Helmet from "../components/Helmet";

const alphabet = (new Array(26)).fill(0).map((n, index) => String.fromCharCode(index + 65));
const yearsSince2000 = (new Array((new Date().getFullYear()) - 2000 + 1)).fill(0).map((n, index) => 2000 + index);

type Props = {
    memoriam: InMemoriamType[]
    user: UserInfos
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
} & InjectedUIRouterProps

type State = {
    proposition: string
    selectedOrder: string | null
    selectedFilterYear: number | null
    selectedFilterLetterInLastname: string | null
    modifiedOrder: boolean
}

class InMemoriam extends Page<Props, State> {
    readonly state = {
        proposition: '',
        selectedFilterYear: null,
        selectedFilterLetterInLastname: null,
        selectedOrder: 'youngest',
        modifiedOrder: false,
    }
    private removeTransitionHook: Function | null = null;

    setProposition = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            proposition: val,
        }));
    }

    sendProposition = () => {
        if (this.state.proposition.length) {
            paanteon.suggestInMemoriam({proposition: this.state.proposition}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    proposition: '',
                }));

                Notification.success("Livre d'or suggéré avec succès");
            }, function() {
                Notification.error("Erreur lors de l'envoi de la suggestion")
            });
        }
    }

    setSelectedYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const y = Number(e.currentTarget.value as unknown);
        this.setState(prevState => ({
            ...prevState,
            selectedFilterYear: y === -1 ? null : y,
        }));
    }

    setSelectedYearToNull = () => {
        this.setState(prevState => ({
            ...prevState,
            selectedFilterYear: null,
        }));
    }

    setSelectedLetter = (letter: string | null) => {
        return () => {
            this.setState(prevState => ({
                ...prevState,
                selectedFilterLetterInLastname: letter,
            }));
        }
    }

    setOrder = (order: string | null) => {
        return () => {
            this.setState(prevState => ({
                ...prevState,
                selectedOrder: order,
                modifiedOrder: true,
            }));
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.removeTransitionHook = this.props.router.transitionService.onSuccess({to: 'connected.in-memoriam'}, () => {
            window.scrollTo(0, 0);
        });
    }

    componentWillUnmount() {
        if (this.removeTransitionHook) this.removeTransitionHook();
    }

    render() {
        let memoriam = this.props.memoriam;
        if (this.state.selectedFilterYear) memoriam = memoriam.filter(memo => moment(memo.deathYear).year() === this.state.selectedFilterYear);
        if (this.state.selectedFilterLetterInLastname) {
            // @ts-ignore
            memoriam = memoriam.filter(memo => memo.lastname.startsWith(this.state.selectedFilterLetterInLastname));
        }
        if (this.state.selectedOrder) {
            if (this.state.selectedOrder === 'youngest') memoriam.sort((a, b) => moment(b.deathYear).diff(moment(a.deathYear)));
            if (this.state.selectedOrder === 'popular') {
                // @ts-ignore
                memoriam.sort((a, b) => b.testimonialCount - a.testimonialCount)
            }
        }

        return (
            <UIView>
                <div>
                    <div className="dark-bg">
                        <div className="uk-container">
                            <UserSidebar user={this.props.user} showCover={true}>
                            <div className="sidebar-suggest-golden-book sidebar-block fixed-ratio night-blue">
                                <div className="block-content">
                                    <div>
                                        <p className="block-title">Suggérer un livre d'or</p>
                                        <input placeholder="J'entre un nom" className="input block" maxLength={50} onChange={this.setProposition} value={this.state.proposition}/>
                                        <button className="btn btn-outlined light block" onClick={this.sendProposition}>Envoyer</button>
                                    </div>
                                </div>
                                <canvas className="block-ratio" width="1" height="1"/>
                            </div>
                            </UserSidebar>

                            <div className="main-content">
                                <div className="uk-container">
                                    <Helmet title={'In Memoriam | Paanteon'} />
                                    <h1 className="page-title">In Memoriam</h1>
                                    <h2 className="page-subtitle">Pour rendre hommage à ceux qui nous ont quittés</h2>

                                    <div className="link-tabs" style={{position: "relative"}}>
                                        <Toggleable>
                                            <div key={0} className="tab-item toggle-popin-menu">
                                                <span className="toggle-button active margin-right">Filtrer par</span>
                                                {
                                                    this.state.selectedFilterLetterInLastname && <span className="active-filter-chip" onClick={stopPropagation} style={{position: 'relative'}}>
                                                        {this.state.selectedFilterLetterInLastname}
                                                        <button className="btn btn-icon btn-icon-close" onClick={this.setSelectedLetter(null)}/>
                                                    </span>
                                                }
                                                {
                                                    this.state.selectedFilterYear && <span className="active-filter-chip" style={{position: 'relative'}} onClick={stopPropagation}>{this.state.selectedFilterYear}<button onClick={this.setSelectedYearToNull} className="btn btn-icon btn-icon-close"/></span>
                                                }
                                                {
                                                    this.state.selectedOrder && this.state.modifiedOrder && <span className="active-filter-chip" style={{position: 'relative'}} onClick={stopPropagation}>
                                                        {
                                                            this.state.selectedOrder === 'youngest' ?
                                                                'Les plus récents'
                                                                :
                                                                'Les plus populaires'
                                                        }

                                                        <button className="btn btn-icon btn-icon-close" onClick={this.setOrder(null)}/>
                                                    </span>
                                                }

                                                <div className="popin-menu block" onClick={stopPropagation}>
                                                    <div className="buttons-bar">
                                                        <button className={"btn btn-block light" + (this.state.selectedOrder === 'youngest' ? ' btn-1' : ' btn-outlined')} onClick={this.setOrder('youngest')}>Les plus récents</button>
                                                        <select className="btn btn-block btn-outlined light" onChange={this.setSelectedYear} value={this.state.selectedFilterYear === null ? -1 : this.state.selectedFilterYear as unknown as number}>
                                                            <option value={-1}>Année</option>
                                                            {
                                                                yearsSince2000.map(year => <option key={year} value={year}>{year}</option>)
                                                            }
                                                        </select>
                                                        {/* <button className="btn btn-block btn-outlined light">France</button>*/}
                                                        <button className={"btn btn-block light" + (this.state.selectedOrder === 'popular' ? ' btn-1' : ' btn-outlined')} onClick={this.setOrder('popular')}>Les plus populaires</button>
                                                    </div>
                                                    <p className="menu-section-title">Ordre alphabétique :</p>
                                                    <div className="alphabet-buttons-grid">
                                                        {
                                                            alphabet.map(letter => <button className={"btn letter-btn btn-outlined" + (letter !== this.state.selectedFilterLetterInLastname ? ' light' : ' light-blue')} disabled={this.state.selectedFilterLetterInLastname === letter} onClick={this.setSelectedLetter(letter)} key={letter}>{letter}</button>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <React.Fragment key={1}/>
                                        </Toggleable>
                                    </div>
                                    <div className="uk-grid section section-condensed section-dark">
                                        {
                                            memoriam.map((item: InMemoriamType) => {
                                                return (
                                                    <div className="uk-width-1-3@s uk-width-1-2" key={item.inMemoriamId}>
                                                        <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: item.Thumbnail.uniqueName, memoriamId: item.inMemoriamId}} className="framed-pic-card"
                                                              style={{backgroundImage: 'url(' + item.picture + ')'}}>
                                                            <div className="card-footer">
                                                                <p className="card-title">{item.firstname} {item.lastname}</p>
                                                                <p className="card-subtitle">({item.birthYear}-{moment(item.deathYear).format('yyyy')})</p>
                                                            </div>
                                                            <canvas className="card-ratio" width="16" height="9"></canvas>
                                                        </Link>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </UIView>
        );
    }
}

export default injectUIRouter(InMemoriam);
