import React, {Component} from 'react';
import {Recommendation, RecommendationToSend} from "../../api/types";
import paanteon from "../../api/paanteonRequests";
import RecommendationTile from "./RecommendationTile";
import $ from "jquery";
import Notification from "../../api/notification";
import {deleteFromArrayAndReturn} from "../../api/helpers";
import ConfirmModal from "../ConfirmModal";
import {acceptableImages} from "../../api/domHelper";

type Props = Readonly<{
    editable?: boolean
    recommendations: Recommendation[]
    tributeId: number
}>

type State = {
    editMode: boolean
    recommendations: Recommendation[]
    newRecommendation: RecommendationToSend
    fullView: boolean
    selectedRecommendationIdToDelete: number
}

export function getCategoryFromId(typeId: number) {
    switch (typeId) {
        case 1:
            return "Article";
        case 2:
            return "Documentaire";
        case 3:
            return "Film";
        case 4:
            return "Interview"
        case 5:
            return "Livre";
        case 6:
            return "Série";
        case 7:
            return "Vidéo";
        case 8:
            return "Oeuvre";
        case 9:
            return "Lieu";
        case 10:
            return "Site web";
        case 11:
            return "Album";
    }
}

const slickSettings = {
    dots: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 3,
};

/* const slickSettings = {
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};*/

class Recommendations extends Component<Props, State> {
    private reader: FileReader = new FileReader();
    protected slickInstance:JQuery<HTMLElement> | null = null;
    state = {
        editMode: false,
        recommendations: this.props.recommendations,
        newRecommendation: {
            recommendationId: undefined,
            title: undefined,
            author: undefined,
            type: undefined,
            content: undefined,
            link: undefined,
            img: undefined,
        },
        fullView: false,
        selectedRecommendationIdToDelete: -1,
    }
    sliderDiv: React.RefObject<HTMLDivElement>;

    constructor(props: Props) {
        super(props);
        this.sliderDiv = React.createRef<HTMLDivElement>();

        this.reader.onloadend = () => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    newRecommendation: {
                        ...prevState.newRecommendation,
                        img: this.reader.result as string,
                    },
                }
            });
        }
    }

    toggleEditMode = () => {
        this.setState(prevState => ({
            ...prevState,
            editMode: !prevState.editMode,
            fullView: false,
            newRecommendation: {},
        }), () => {
            if (!this.state.editMode) this.slickInstance = $(this.sliderDiv.current as HTMLDivElement).slick(slickSettings);
        });
    };

    handleNewRecommendationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const name = e.target.name;
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            newRecommendation: {
                ...prevState.newRecommendation,
                [name]: val,
            },
        }));
    };

    saveRecommendation = (e: React.FormEvent) => {
        e.preventDefault();
        const newRec = {...this.state.newRecommendation};
        if (!newRec.img) {
            Notification.warning("Veuillez d'abord téléverser une image");
            return;
        }

        // @ts-ignore
        if (newRec.img.startsWith('http')) delete newRec.img;

        if (!newRec.recommendationId) {
            paanteon.createRecommendation(this.props.tributeId, newRec).then((res: {data: Recommendation}) => {
                this.setState(prevState => ({
                    ...prevState,
                    recommendations: [...prevState.recommendations, res.data],
                    editMode: false,
                    newRecommendation: {},
                    fullView: false,
                }), () => {
                    this.slickInstance = $(this.sliderDiv.current as HTMLDivElement).slick(slickSettings);
                });
            }, function() {
                Notification.error("Erreur lors de la création de la recommandation")
            });
        } else {
            // @ts-ignore
            paanteon.updateRecommendation(this.props.tributeId, newRec.recommendationId, newRec).then((res: {data: Recommendation}) => {
                this.setState(prevState => {
                    let nState = {
                        ...prevState,
                        recommendations: prevState.recommendations.slice(0),
                        editMode: false,
                        newRecommendation: {},
                        fullView: false,
                    };
                    for (let i = 0; i < nState.recommendations.length; i++) {
                        if (nState.recommendations[i].recommendationId === newRec.recommendationId) {
                            nState.recommendations[i] = res.data;
                            break;
                        }
                    }
                    return nState;
                }, () => {
                    this.slickInstance = $(this.sliderDiv.current as HTMLDivElement).slick(slickSettings);
                });
            }, function() {
                Notification.error("Erreur lors de la mise à jour de la recommandation")
            })
        }
    };

    editRecommendation = (reco: Recommendation) => {
        this.setState(prevState => ({
            ...prevState,
            newRecommendation: {
                ...reco,
                img: reco.picturePath,
            },
            editMode: true,
        }))
    };

    recommendationPicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) this.reader.readAsDataURL(e.target.files[0]);
    };

    toggleFullView = (reco: Recommendation, event: React.MouseEvent<HTMLButtonElement>) => {
        const slickIndex = event.currentTarget.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.getAttribute('data-slick-index');
        this.setState(prevState => ({
            ...prevState,
            fullView: !prevState.fullView,
        }), () => {
            this.slickInstance?.slick('slickSetOption', {slidesToShow: this.state.fullView ? 1 : 3, slidesToScroll: this.state.fullView ? 1 : 3}, true);
            if (this.state.fullView && this.slickInstance) {
                this.slickInstance.slick('slickGoTo', slickIndex, true);
            }
        });
    }

    closeConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            selectedRecommendationIdToDelete: -1,
        }));
    }

    openConfirmDelete = (recommendationId: number) => {
        this.setState(prevState => ({
            ...prevState,
            selectedRecommendationIdToDelete: recommendationId,
        }));
    }

    confirmDelete = () => {
        paanteon.deleteRecommendation(this.props.tributeId, this.state.selectedRecommendationIdToDelete).then(() => {
            Notification.success("Hommage supprimé avec succès");
            // if (this.props.onDelete) this.props.onDelete();
            $(this.sliderDiv.current as HTMLDivElement).slick('unslick');
            this.setState(prevState => ({
                ...prevState,
                recommendations: deleteFromArrayAndReturn(prevState.recommendations, prevState.recommendations.findIndex(rec => rec.recommendationId === prevState.selectedRecommendationIdToDelete)),
                fullView: false,
                selectedRecommendationIdToDelete: -1,
            }), () => {
                this.slickInstance = $(this.sliderDiv.current as HTMLDivElement).slick(slickSettings);
            });
        }, function() {
            Notification.error("Erreur lors de la suppression de l'hommage")
        });
    }

    componentDidMount() {
        this.slickInstance = $(this.sliderDiv.current as HTMLDivElement).slick(slickSettings);
    }

    render() {
        return (
            <div>
                <div className={ "tile dark-blue switchable margin-large " + (this.state.editMode ? 'edit-mode' : '') }>
                    <div className="tile-head">
                        <h3 className="tile-title">Mes recommandations pour aller plus loin</h3>
                    </div>
                    <div className="tile-body">
                        {
                            !this.state.editMode ?
                                <div>
                                    <div>
                                        <div className="block-content-slider" ref={this.sliderDiv}>
                                            {
                                                this.state.recommendations.map((reco: Recommendation) => (
                                                    <div className="slide-container" key={reco.recommendationId}>
                                                        <div>
                                                            <RecommendationTile fixedSize={!this.state.fullView}
                                                                                key={reco.recommendationId}
                                                                                editRecommendation={this.props.editable ? this.editRecommendation : undefined}
                                                                                toggleFullView={this.toggleFullView}
                                                                                recommendation={reco}
                                                                                fullView={this.state.fullView}
                                                                                openConfirmDelete={() => this.openConfirmDelete(reco.recommendationId)}/>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div>
                                                <div className="cta">
                                                    {
                                                        this.props.editable && <button className="plus-card" onClick={this.toggleEditMode}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <form onSubmit={this.saveRecommendation}>
                                    <div className="uk-grid" uk-grid="">
                                        <div className="uk-width-1-2@m">
                                            <input className="input big block" type="text" placeholder="Titre de l'oeuvre" name="title" value={this.state.newRecommendation.title || ''} onChange={this.handleNewRecommendationChange} required/>
                                            <input className="input big block margin" type="text" placeholder="Auteur/réalisateur" name="author" value={this.state.newRecommendation.author || ''} onChange={this.handleNewRecommendationChange} required/>
                                            <select required className="select big block" onChange={this.handleNewRecommendationChange} name="type" value={this.state.newRecommendation.type || ''}>
                                                <option className="placeholder-option" value="">Sélectionnez le type</option>
                                                <option value={1}>Article</option>
                                                <option value={2}>Documentaire</option>
                                                <option value={3}>Film</option>
                                                <option value={4}>Interview</option>
                                                <option value={5}>Livre</option>
                                                <option value={6}>Série</option>
                                                <option value={7}>Vidéo</option>
                                                <option value={8}>Oeuvre</option>
                                                <option value={9}>Lieu</option>
                                                <option value={10}>Site web</option>\
                                                <option value={11}>Album</option>
                                            </select>
                                        </div>
                                        <div className="uk-width-1-2@m">
                                            <div className="flex space-between">
                                                <div className="title-5">
                                                    <div>Téléverser une nouvelle photo</div>
                                                    <br/>
                                                    <div>Ou</div>
                                                    <br/>
                                                    <div>Ajouter un lien</div>
                                                </div>
                                                <div className={"light-upload-box" + (!!this.state.newRecommendation.img ? ' preview' : '')} style={{backgroundImage: 'url(' + this.state.newRecommendation.img + ')'}}>
                                                    <input type="file" name="recommandation-pic" id="recommandation-pic" onChange={this.recommendationPicChange} accept={acceptableImages}/>
                                                    <label htmlFor="recommandation-pic">
                                                        <div className="plus-card small"></div>
                                                    </label>
                                                </div>
                                            </div>
                                            <input className="input big block" style={{ marginTop: '29px' }} placeholder="http://" name="link" onChange={this.handleNewRecommendationChange} value={this.state.newRecommendation.link || ''}/>
                                        </div>

                                        <div className="uk-width-1-1 margin">
                                            <textarea className="textarea big block bigger-font" placeholder="1300 caractères max" maxLength={1300} name="content" onChange={this.handleNewRecommendationChange} value={this.state.newRecommendation.content || ''}/>
                                        </div>

                                        <div className="uk-width-1-1 right">
                                            <button className="btn btn-outlined" type="button" onClick={this.toggleEditMode}>Annuler</button>
                                            <button className="btn btn-1">Sauvegarder</button>
                                        </div>
                                    </div>
                                </form>
                        }
                    </div>
                </div>

                {
                    this.state.selectedRecommendationIdToDelete !== -1 && <ConfirmModal onCancel={this.closeConfirmDelete} onConfirm={this.confirmDelete}>Êtes-vous sûr(e) de vouloir supprimer définitivement cette recommandation de votre hommage ?</ConfirmModal>
                }
            </div>
        );
    }
}

export default Recommendations;
