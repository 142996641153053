import React from 'react';
import Page from "../Page";
import {Link} from "../../api/injectors";
import Helmet from "../../components/Helmet";

class Home extends Page<any, any> {
    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={'Administration | Paanteon'} />
                            <h1 className="page-title">
                                Administration
                            </h1>

                            <div className="uk-grid uk-grid-margin">
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Hommages</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="connected.admin.tributes">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Publications</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.publications">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Vignettes</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.thumbnails">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Featured</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.featured">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Livre d'or</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.in-memoriam">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Commentaires</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.comments">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Utilisateurs</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.users">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Tags</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.tags">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Statistiques</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.statistics">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Témoignages</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.testimonials">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Home non connecté</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.homeManagement">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-width-1-3 margin-bottom">
                                    <div className="tile">
                                        <div className="tile-head">
                                            <p className="tile-title">Tribus</p>
                                        </div>
                                        <div className="tile-body">
                                            <Link className="btn link-btn" to="^.tribes">Voir</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
