import React from 'react';
import UserSidebar from "../components/UserSidebar";
import {ContactToSend, UserInfos, Tribe} from "../api/types";
import PublicHeader from "../components/PublicHeader";
import {Link} from "../api/injectors";
import localStorage from "../api/localStorage";
import userRequests from "../api/userRequests";
import Notification from "../api/notification";
import Page from "./Page";
import Helmet from "../components/Helmet";
import Footer from '../components/Footer';

type Props = {
    user?: UserInfos
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = {
    contactForm: ContactToSend
}

class Contact extends Page<Props, State> {
    readonly state: State = {
        contactForm: {
            email: localStorage.isSetLocalUser() ? localStorage.getLocalUser().email : '',
            message: '',
            subject: '',
        },
    }

    saveFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const name = e.target.name;
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            contactForm: {
                ...prevState.contactForm,
                [name]: val,
            },
        }));
    }

    sendContactForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        userRequests.sendContact(this.state.contactForm).then(() => {
            Notification.success("Message envoyé avec succès");
            this.setState(prevState => ({
                ...prevState,
                contactForm: {
                    ...prevState.contactForm,
                    message: '',
                    subject: '',
                },
            }))
        }, () => {
            Notification.error("Une erreur est survenue lors de l'envoi")
        });
    }

    render() {
        return (
            <>
                {
                    !this.props.user &&
                        <PublicHeader/>
                }
                <div className="dark-bg">
                    <div className="uk-container">
                        {
                            this.props.user &&
                            <UserSidebar user={this.props.user} showCover={true}/>
                        }

                        <div className="main-content">
                            <div className="uk-container">
                                <Helmet title={'Un problème ? Une question ? Contactez-nous ! | Paanteon'} />
                                <h1 className="page-title"><span className="thin">Un problème ? Une question ?</span><br/>Contactez-nous !</h1>
                                <div className="tile">
                                    <form onSubmit={this.sendContactForm}>
                                        <div className="tile-body">
                                            {
                                                !this.props.user &&
                                                    <div className="uk-grid">
                                                        <div className="uk-width-1-2@s">
                                                            <input className="input block margin-bottom" type="text" placeholder="Nom" required name="lastname" onChange={this.saveFormChange} value={this.state.contactForm.lastname}/>
                                                        </div>
                                                        <div className="uk-width-1-2@s">
                                                            <input className="input block margin-bottom" type="text" placeholder="Prénom" required name="firstname" onChange={this.saveFormChange} value={this.state.contactForm.firstname}/>
                                                        </div>
                                                        <div className="uk-width">
                                                            <input className="input block margin-bottom" type="email" placeholder="E-mail" required name="email" onChange={this.saveFormChange} value={this.state.contactForm.email}/>
                                                        </div>
                                                        <div className="uk-width-1-2">
                                                            <input className="input block margin-bottom" type="tel" pattern="\+?[0-9]{8}[0-9]*" placeholder="Tél" required name="phone" onChange={this.saveFormChange} value={this.state.contactForm.phone}/>
                                                        </div>
                                                        <div className="uk-width-1-2">
                                                            <input className="input block margin-bottom" type="text" placeholder="Société" maxLength={16} required name="society" onChange={this.saveFormChange} value={this.state.contactForm.society}/>
                                                        </div>
                                                    </div>
                                            }

                                            {
                                                this.props.user && <select required className="select big dark block margin-top margin-bottom" style={{fontWeight: 600}} onChange={this.saveFormChange} name="subject" value={this.state.contactForm.subject}>
                                                    <option value="" className="placeholder-option">Sujet de votre demande</option>
                                                    <option>J'ai un problème technique</option>
                                                    <option>Je souhaite faire une suggestion</option>
                                                    <option>Mon compte a été piraté ou usurpé</option>
                                                    <option>J'ai une question sur la confidentialité</option>
                                                    <option>Je souhaite désactiver le compte d'un utilisateur décédé</option>
                                                </select>
                                            }
                                            <textarea required className="textarea big block no-resize" placeholder="Votre message" maxLength={1300} onChange={this.saveFormChange} name="message" value={this.state.contactForm.message}/>
                                            <p className="margin-bottom" style={{color: 'grey', textAlign: 'right'}}>1300 caractères max</p>

                                            {
                                                this.props.user &&
                                                <p style={{color: 'black'}} className="h-center margin-bottom">Votre nom d'utilisateur sera automatiquement inclus dans le message</p>
                                            }

                                            <div className="h-center">
                                                <Link to="home" className="btn btn-outlined black">Annuler</Link>
                                                <button className="btn btn-1">Envoyer</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !this.props.user && (<Footer />)
                }
            </>
        );
    }
}

export default Contact;
