import React, {Component} from 'react';
import PublicHeader from "../components/PublicHeader";
import userRequests from "../api/userRequests";
import Notification from "../api/notification";
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

type State = {
    email: string
}

class LostPassword extends Component<{}, State> {
    readonly state = {
        email: '',
    };

    setEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            email: val,
        }));
    }

    sendLostPassword = (e: React.FormEvent) => {
        e.preventDefault();
        userRequests.sendLostPassword(this.state.email).then(() => {
            Notification.success("Demande envoyée avec succès. Vérifiez vos e-mails");
        }, () => {
            Notification.error("Une erreur est surevenue lors de l'envoi, veuillez réessayer plus tard");
        });
    }

    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <div className="dark-bg" style={{minHeight: '65vh'}}>
                    <div className="uk-container h-center">
                        <Helmet title={'Vous avez oublié votre mot de passe ? | Paanteon'} />
                        <h1 className="page-title">Vous avez oublié votre mot de passe ?</h1>
                        <p>Entrez l'adresse e-mail qui vous sert d'identifiant et validez.</p>
                        <p>Vous allez recevoir un e-mail pour réinitialiser votre mot de passe</p>

                        <div className="main-content">
                            <form onSubmit={this.sendLostPassword}>
                                <input className="input" style={{width: '400px'}} type="email" placeholder="Votre adresse e-mail" onChange={this.setEmail} value={this.state.email}/>
                                <br/>
                                <button className="btn btn-1 margin-top" style={{width: '220px'}}>Envoyer</button>
                            </form>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        );
    }
}

export default LostPassword;
