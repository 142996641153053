import React, {Component} from 'react';
import Modal from "./Modal";
import formatValidation from "../api/formatValidation";
import {FormattedMessage} from "react-intl";
import auth from "../api/authentication";
import Notification from "../api/notification";

type Props = {
    onCancel?():any
    onConfirm?():any
    userId: number
};

type State = {
    oldPwd: string
    newPwd: string
    newPwdConfirm: string
};

class ChangePasswordModal extends Component<Props, State> {
    readonly state: State = {
        oldPwd: '',
        newPwd: '',
        newPwdConfirm: '',
    }

    setOldPwd = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            oldPwd: val,
        }));
    }

    setNewPwd = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            newPwd: val,
        }));
    }

    setNewPwdConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            newPwdConfirm: val,
        }));
    }

    changePassword = () => {
        auth.updatePassword(this.props.userId, {newPwd: this.state.newPwd, oldPwd: this.state.oldPwd}).then(() => {
            Notification.success("Mot de passe modifié avec succès");
            this.props.onConfirm?.();
        }, function(err) {
            if (err.response && err.response.status === 412) {
                Notification.error("L'ancien mot de passe est erroné");
            } else {
                Notification.error("Erreur lors de la mise à jour du mot de passe")
            }
        });
    }

    render() {
        return (
            <Modal closeButton onClose={this.props.onCancel}>
                <div className="pop-up-title">
                    Changement de mot de passe
                </div>

                <div className="margin">
                    <label>Ancien mot de passe</label>
                    <input type="password" className="input block" placeholder="Votre mot de passe" onChange={this.setOldPwd}/>
                </div>

                <div className="margin">
                    <label>Nouveau mot de pase</label>
                    <input type="password" className="input block" placeholder="Nouveau mot de passe" onChange={this.setNewPwd}/>
                    {
                        !formatValidation.validPasswordSize(this.state.newPwd) &&
                        <span><FormattedMessage id='formCoords.passwordTooShort'/></span>
                    }
                </div>

                <div className="margin margin-bottom-m">
                    <label>Confirmation du nouveau mot de passe</label>
                    <input type="password" className="input block" placeholder="Confirmation du nouveau mot de passe" onChange={this.setNewPwdConfirm}/>
                    {
                        !formatValidation.contentMatch(this.state.newPwd, this.state.newPwdConfirm) &&
                        <span><FormattedMessage id="formCoords.passwordsDontMatch"/></span>
                    }
                </div>

                <div className="pop-up-buttons">
                    <button className="btn btn-outlined" onClick={this.props.onCancel}>Annuler</button>
                    <button className="btn btn-1" onClick={this.changePassword} disabled={!formatValidation.validPasswordSize(this.state.newPwd) || !formatValidation.contentMatch(this.state.newPwd, this.state.newPwdConfirm)}>Confirmer</button>
                </div>
            </Modal>
        );
    }
}

export default ChangePasswordModal;
