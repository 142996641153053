import React from "react";
import formatValidation from "../../api/formatValidation";
import {User} from "../../api/types";
import {FormattedHTMLMessage, FormattedMessage, InjectedIntlProps, injectIntl} from "react-intl";
import moment from "moment";
import authentication from "../../api/authentication";
import Notification from "../../api/notification";
import { InjectedUIRouterProps, injectUIRouter } from "../../api/injectors";

type Props = {
    user: User;
    update(user: User): any;
    nextStep(event: React.SyntheticEvent): any;
    prevStep(event: React.SyntheticEvent): any;
} & InjectedIntlProps & InjectedUIRouterProps;

interface StateInterface {
    user: User & { emailConf?: string | undefined, passwordConf?: string | undefined };
    confirmCGU: boolean;
    confirmAge: boolean;
    exactFields: boolean;
}

class FormCoords extends React.Component<Props, StateInterface> {
    public form = React.createRef<HTMLFormElement>();
    // list of tmp domain mail that we do not allow on paanteon
    private forbiddenDomains = ['slmails.com', '@yopmail.com', '@yopmail.fr', '@yopmail.net', '@cool.fr.nf', '@jetable.fr.nf', '@courriel.fr.nf', '@moncourrier.fr.nf', '@monemail.fr.nf', '@monmail.fr.nf', '@speedmail.ze.cx', '@hide.biz.st', '@mymail.infos.st', '@sharklasers.com', '@grr.la', '@guerrillamail.biz', '@guerrilamail.com', '@guerrilamail.de', '@guerrilamail.net', '@guerrilamail.org', '@guerrilamailblock.com', '@spam4.me', 'e3b.org'];

    constructor(props: Props) {
        super(props);
        this.state = {
            user: props.user,
            confirmCGU: false,
            confirmAge: false,
            exactFields: false,
        }
    }

    handleUserInput = (e: React.FormEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        if (name === 'confirmAge' || name === 'confirmCGU' || name === 'exactFields') {
            const value = e.currentTarget.checked;
            this.setState(prevState => ({
                ...prevState,
                [name]: value,
            }));
        } else {
            const value = e.currentTarget.value;
            this.setState(state => ({
                ...state,
                user: {
                    ...state.user,
                    [name]: value,
                },
            }));
        }
    }

    componentDidUpdate(prevProps: Props, prevState: StateInterface, snapshot: any) {
        this.props.update(this.state.user);
    }

    createUser = (e: React.SyntheticEvent) => {
        e.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                birthdate: moment({
                    year: parseInt(prevState.user.year as string, 10),
                    month: parseInt(prevState.user.month as string, 10) - 1,
                    day: parseInt(prevState.user.day as string, 10),
                }).utc(true).toISOString(),
            },
        }), () => {
            if (this.forbiddenDomains.indexOf(this.state.user.email.slice(this.state.user.email.indexOf('@'))) >= 0) {
                Notification.error(this.props.intl.formatMessage({id: 'formConfirmCGU.notification.mailNotAuthorized'}));
            } else {
                authentication.registerUser(this.state.user).then(() => {
                    this.props.nextStep(e);
                }, error => {
                    if (error && error.response && error.response.status && error.response.status === 400) {
                        Notification.error(this.props.intl.formatMessage({id: 'formConfirmCGU.notification.fieldsMissing'}));
                    } else if (error && error.response && error.response.status && error.response.status === 409) {
                        Notification.error(this.props.intl.formatMessage({id: 'formConfirmCGU.notification.userAlreadyExists'}));
                    }
                });
            }
        });
    };

    get canSubmit(): boolean {
        return (!!this.form.current && this.form.current.checkValidity())
            && formatValidation.contentMatch(this.state.user.email, this.state.user.emailConf)
            && formatValidation.validPasswordSize(this.state.user.password)
            && formatValidation.contentMatch(this.state.user.password, this.state.user.passwordConf);
    }

    render() {
        const hrefCGU = this.props.router ? this.props.router.stateService.href('terms-and-condition-of-use') : null;
        const hrefConfidentialPolitic = this.props.router ? this.props.router.stateService.href('privacy-policy') : null;
        const hrefCookiePolitic = this.props.router ? this.props.router.stateService.href('community-code') : null;

        return (
            <div>
                <div className="steps">
                    <div className="step done">&#10004;</div>
                    <div className="sep"></div>
                    <div className="step current">2</div>
                </div>
                <h2 className="title-3 h-center margin-xl"><FormattedMessage id="formCoords.coords"/></h2>
                <form name="coordsForm" onSubmit={this.createUser} ref={this.form}>
                    <div className="uk-grid uk-grid-large">
                        <div className="uk-width-1-1">
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.phone"/>*</label><br/>
                                <input className="input big block dark" type="tel"
                                       name="tel"
                                       onChange={this.handleUserInput}
                                       maxLength={16}
                                       pattern="\+?[0-9]{8}[0-9]*"
                                       value={this.state.user.tel}
                                       autoFocus
                                       required/>
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.email"/>*</label><br/>
                                <input className="input big block dark" type="email"
                                       name="email"
                                       onChange={this.handleUserInput}
                                       value={this.state.user.email} required/>
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.emailConf"/>*</label><br/>
                                <input className="input big block dark" type="email"
                                       name="emailConf"
                                       onChange={this.handleUserInput}
                                       value={this.state.user.emailConf} required/>
                                {
                                    !formatValidation.contentMatch(this.state.user.email, this.state.user.emailConf) &&
                                    (<span><FormattedMessage id="formCoords.emailsDontMatch"/></span>)
                                }
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.password"/>*</label><br/>
                                <input className="input big block dark" type="password"
                                       name="password"
                                       onChange={this.handleUserInput}
                                       value={this.state.user.password}
                                       required/>
                                {
                                    !formatValidation.validPasswordSize(this.state.user.password) &&
                                    <span><FormattedMessage id='formCoords.passwordTooShort'/></span>
                                }
                            </div>
                            <div className="margin">
                                <label htmlFor=""><FormattedMessage id="general.user.passwordConf"/>*</label><br/>
                                <input className="input big block dark" type="password"
                                       name="passwordConf"
                                       onChange={this.handleUserInput}
                                       value={this.state.user.passwordConf}
                                       required/>
                                {
                                    !formatValidation.contentMatch(this.state.user.password, this.state.user.passwordConf) &&
                                    <span><FormattedMessage id="formCoords.passwordsDontMatch"/></span>
                                }
                            </div>
                            <div className="margin right">
                                <small>* <FormattedMessage id="general.form.requiredFields"/></small>
                            </div>

                            <div className="margin">
                                <label className="blue large checkbox-custom">
                                    <input type="checkbox" name="exactFields" onChange={this.handleUserInput} required /><div className="checkbox black"></div>
                                    <FormattedMessage id="general.form.exactFields"/>
                                </label>
                            </div>

                            <div className="margin">
                                <label className="blue large checkbox-custom">
                                    <input className="checkbox" type="checkbox" name="confirmCGU"
                                        onChange={this.handleUserInput} defaultChecked={this.state.confirmCGU} required /><div className="checkbox black"></div>
                                    <FormattedHTMLMessage id="formConfirmCGU.contracts" values={{
                                        cgu: hrefCGU,
                                        confidentialPolitic: hrefConfidentialPolitic,
                                        cookiePolitic: hrefCookiePolitic,
                                    }}/>
                                </label>
                            </div>
                            <div className="margin">
                                <label className="blue large checkbox-custom">
                                    <input className="checkbox" type="checkbox" name="confirmAge"
                                        onChange={this.handleUserInput} defaultChecked={this.state.confirmAge} required /><div className="checkbox black"></div>
                                    <FormattedMessage id="formConfirmCGU.ageConfirm"/>
                                </label>
                            </div>
                            <div className="margin">
                                {/* documentation on https://developers.google.com/recaptcha/docs/display */}
                                <script src="https://www.google.com/recaptcha/api.js" async
                                        defer/>
                                <div className="g-recaptcha" data-sitekey="your_site_key"></div>
                            </div>
                            <div className="margin-large flex space-between">
                                <button onClick={this.props.prevStep} type="button"
                                        className="btn btn-outlined black btn-big"><FormattedMessage
                                    id="general.form.prevStep"/></button>
                                <button type="submit" disabled={!this.canSubmit}
                                        className="btn btn-1 btn-big">
                                    <FormattedMessage id="general.form.valid" />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default injectIntl(injectUIRouter(FormCoords));
