import React, { FunctionComponent } from 'react';

interface OwnProps {
    count?: number
}

type Props = OwnProps;

const CounterChip: FunctionComponent<Props> = (props) => {
    return <span className="counter-chip" style={props.count && props.count > 0 ? {} : {display: "none"}}>{props.count}</span>;
};

export default CounterChip;
