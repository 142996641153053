import config from "./config";

const formatValidation = {
    /**
     * Public method used to test if a value is undefined.
     * @param {string} value
     * @returns {boolean}
     */
    undefinedValue: function(value: any) {
        return typeof value === 'undefined';
    },

    /**
     * Public method used to test an email format validity.
     * @param {string} email
     * @returns {boolean}
     */
    validEmail: function(email: string) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return !email || re.test(email);
    },

    /**
     * Public method used to test if the email has a forbidden domain
     * @param {string} email
     * @returns {boolean}
     */
    getEmailHasForbiddenDomain: function(email: string) {
        return config.forbiddenDomains.indexOf(email.slice(email.indexOf('@'))) >= 0;
    },

    /**
     * Public method used to test two content match.
     * @param {string} content
     * @param {string} contentConfirmation
     * @returns {boolean}
     */
    contentMatch: function(content: string | undefined | null, contentConfirmation: string | undefined | null) {
        return (!content || !contentConfirmation) || (content === contentConfirmation);
    },

    /**
     * Public method used to test a password size.
     * @param {string} password
     * @returns {boolean}
     */
    validPasswordSize: function(password: string | undefined) {
        return !password || (password.length >= config.minimalPasswordSize);
    },

};

export default formatValidation;
