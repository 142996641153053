import React, {Component} from 'react';
import {Link} from "../../api/injectors";
import {Tribute} from "../../api/types";
import filterHelper from "../../api/filterHelper";
import Helmet from "../../components/Helmet";

type Props = {
    tributes: {data: Tribute[] }
}

type State = {
    tributes: Tribute[]
    search: string
}

class Tributes extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tributes: props.tributes.data,
            search: '',
        }
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    render() {
        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <div className="main-content">
                        <div className="uk-container">
                            <Helmet title={`Hommages (${this.state.tributes.length}) | Paanteon`} />
                            <h1 className="page-title">
                                Hommages ({this.state.tributes.length})
                            </h1>

                            <div>
                                <div className="margin-bottom">
                                    <Link to="^">&lt; Retour</Link>
                                </div>
                                <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                            </div>

                            <div className="uk-grid uk-grid-small gallery-grid margin-top">
                                {
                                    filterHelper.deepSearch(this.state.tributes, this.state.search).map(tribute => {
                                        return <div className="uk-width-1-3@m uk-width-1-4@l" key={tribute.tributeId}>
                                            <Link to="connected.paanteon.tribute.details" params={{tributeId: tribute.tributeId, thumbnailUniqueName: tribute.Thumbnail.uniqueName, username: tribute.User.username}} className="card"
                                                  style={{backgroundImage: 'url(' + tribute.picturePath + ')'}}>
                                                <canvas width="250" height="350"/>
                                                <div className="card-footer">
                                                    <p className="card-name">{tribute.Thumbnail.label} ({tribute.tributeId})</p>
                                                    <p className="card-title">{tribute.Collection.label}</p>
                                                    <p className="card-title">{tribute.User.firstname} {tribute.User.lastname}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Tributes;
