import React, {Component} from 'react';
import ReactDOM from "react-dom";
import Modal from "./Modal";
import {deleteFromArrayAndReturn} from "../api/helpers";
import paanteon from "../api/paanteonRequests";
import Notification from "../api/notification";

interface OwnProps {
    children: React.ReactElement<HTMLButtonElement>
    publicationId?: number
    tributeId?: number
    commentId?: number
    userId?: number
    recommendationId?: number
    testimonialId?: number
}

interface PublicationProps extends OwnProps {
    publicationId: number
}

interface TributeProps extends OwnProps {
    tributeId: number
}

interface CommentProps extends OwnProps {
    commentId: number
}

interface UserProps extends OwnProps {
    userId?: number
}

interface RecommendationProps extends OwnProps {
    recommendationId: number
}

interface TestimonialProps extends OwnProps {
    testimonialId: number
}

type Props = PublicationProps | TributeProps | CommentProps | UserProps | RecommendationProps | TestimonialProps

type State = {
    open: boolean
    checkedTypes: string[]
    details: string
}

const SignalTypes = [
    'Propos haineux ou injurieux',
    'Propriété intellectuelle',
    'Divulgation de fausses informations',
    'Exploitation sexuelle',
    'Incitation à la violence / violences',
    'Autres',
];

class ReportModal extends Component<Props, State> {
    state = {
        open: false,
        checkedTypes: [],
        details: "",
    }

    openModal = () => {
        this.setState(prevState => ({
            ...prevState,
            open: true,
        }))
    }

    closeModal = () => {
        this.setState(prevState => ({
            ...prevState,
            open: false,
        }));
    }

    toggleType = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        if (e.currentTarget.checked)
            this.setState(prevState => ({
                ...prevState,
                checkedTypes: [...prevState.checkedTypes, val],
            }));
        else {

            this.setState(prevState => ({
                ...prevState,
                checkedTypes: deleteFromArrayAndReturn(prevState.checkedTypes, prevState.checkedTypes.indexOf(val)),
            }));
        }
    }

    setDetails = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const val = e.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            details: val,
        }));
    }

    sendReport = () => {
        paanteon.sendReport({
            content: this.state.details,
            reason: this.state.checkedTypes.join(', '),
            publicationId: this.props.publicationId,
            tributeId: this.props.tributeId,
            commentId: this.props.commentId,
            userId: this.props.userId,
            recommendationId: this.props.recommendationId,
            testimonialId: this.props.testimonialId,
        }).then(() => {
            Notification.success("Signalement envoyé avec succès");
            this.setState(prevState => ({
                ...prevState,
                open: false,
            }));
        });
    }

    render() {
        return (
            <>
                <this.props.children.type {...this.props.children.props} onClick={this.openModal}/>
                {
                    this.state.open && ReactDOM.createPortal(<Modal closeButton onClose={this.closeModal}>
                            <div className="pop-up-title">
                                Signalement: quel est le problème ?
                            </div>

                            <div data-uk-grid="" className="uk-grid uk-child-width-1-2@s">
                                {
                                    SignalTypes.map(type => (
                                        <div key={type}>
                                            <div>
                                                <label className="checkbox-custom">
                                                    <input type="checkbox" onChange={this.toggleType} value={type}/> {type}
                                                    <div className="checkbox"></div>
                                                </label>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="margin-top-l">
                                <label className="label-strong">Détaillez votre demande :</label><br/>
                                <textarea className="textarea block medium no-resize" onChange={this.setDetails}/>
                            </div>

                            <div className="pop-up-buttons">
                                <button onClick={this.closeModal} className="btn btn-outlined">Annuler</button>
                                <button className="btn btn-1" onClick={this.sendReport}
                                        disabled={this.state.details.length === 0}>Envoyer
                                </button>
                            </div>
                        </Modal>,
                        document.getElementById('modals') as Element,
                    )
                }
            </>
        );
    }
}

export default ReportModal;
