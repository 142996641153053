import React from 'react';
import {Recommendation, User} from "../../api/types";
import {getCategoryFromId} from "./index";
import {pictoEdit} from "../../api/pictos";
import ReportModal from "../ReportModal";
import OptionsMenu from "../OptionsMenu";
import localStorage from "../../api/localStorage";

type Props = {
    recommendation: Recommendation
    editRecommendation?(reco: Recommendation): any
    fixedSize: boolean
    fullView?: boolean
    toggleFullView(reco: Recommendation, event: React.MouseEvent<HTMLButtonElement>): any
    onDelete?(): any
    openConfirmDelete?(): any
}

type State = {
    showConfirmDelete: boolean
}

class RecommendationTile extends React.Component<Props, State> {
    protected user: User = localStorage.getLocalUser();
    readonly state: State = {
        showConfirmDelete: false,
    }

    render() {
        return (
            <>
                <div
                    className={'content-card' + (this.props.fixedSize ? ' fixed-size' : '') + (this.props.fullView ? ' block-display' : '')}>
                    {
                        this.props.fullView &&
                            <>
                                <OptionsMenu>
                                    <span className="options-menu">
                                        <ul>
                                            {
                                                this.props.recommendation.userId !== this.user.userId && <li>
                                                    <ReportModal recommendationId={this.props.recommendation.recommendationId}><button
                                                        className="btn link-btn">Signaler</button></ReportModal>
                                                </li>
                                            }

                                            {
                                                this.props.recommendation.userId === this.user.userId &&
                                                <>
                                                    <li>
                                                        <button className="btn link-btn" onClick={this.props.openConfirmDelete}>Supprimer</button>
                                                    </li>
                                                </>
                                            }

                                            {
                                                (this.user.admin && this.user.userId !== this.props.recommendation.userId) &&
                                                <>
                                                    <li>
                                                        <button className="btn link-btn" onClick={this.props.openConfirmDelete}>Supprimer</button>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </span>
                                </OptionsMenu>
                                <button className="btn btn-icon-close"
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.props.toggleFullView(this.props.recommendation, event)}/>
                                {
                                    this.props.editRecommendation !== undefined && <input type="image" alt="picto edit" className="btn picto action" src={pictoEdit}
                                                                                          onClick={() => this.props.editRecommendation && this.props.editRecommendation(this.props.recommendation)}/>
                                }
                            </>
                    }
                    {
                        this.props.fullView &&
                        <div className="card-head">
                            <img className="thumbnail" src={this.props.recommendation.picturePath} alt=""/>
                            <h3 className="card-title">{this.props.recommendation.title}</h3>
                            <p className="category">{getCategoryFromId(this.props.recommendation.type)}</p>
                            <p className="author">{this.props.recommendation.author}</p>
                            <div>
                                {
                                    this.props.recommendation.link !== null && <a target="_blank" href={this.props.recommendation.link as unknown as string} rel="noopener noreferrer" className="btn btn-outlined btn-block">Voir plus</a>
                                }
                            </div>
                        </div>
                    }
                    <div className="card-body">
                        {
                            this.props.fullView ?
                                <p className="content">
                                    {this.props.recommendation.content}
                                </p>
                                :
                                <>
                                    <div className="thumbnail">
                                        <img src={this.props.recommendation.picturePath} alt=""/>
                                    </div>
                                    <div className="card-title">
                                        {this.props.recommendation.title}
                                    </div>

                                    <p className="category">{getCategoryFromId(this.props.recommendation.type)}</p>
                                    <p className="author">De {this.props.recommendation.author}</p>
                                    <p className="text">{this.props.recommendation.content}</p>
                                </>
                        }
                    </div>
                    {
                        !this.props.fullView &&
                        <div className="card-footer">
                            <button className="btn btn-icon-plus"
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => this.props.toggleFullView(this.props.recommendation, event)}/>
                        </div>
                    }
                </div>
            </>
        );
    }
}

export default RecommendationTile;
