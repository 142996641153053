import React, {PureComponent} from 'react';

type Props = {
    onChange?(value: boolean):any
    checked?: boolean
    disabled?: boolean
}

class Toggle extends PureComponent<Props> {
    toggle = () => {
        this.props.onChange?.(!this.props.checked);
    }

    render() {
        return (
            <button onClick={this.toggle} type="button" className={`btn-switch ${this.props.checked ? "switch-on" : "switch-off"}`} disabled={this.props.disabled} />
        );
    }
}

export default Toggle;
