import React, {Component} from 'react';

class ScrollToTop extends Component<{}, {show: boolean}> {
    private bodyElement: (HTMLElement | null) = null;

    constructor(props: {}) {
        super(props);
        this.state = {
            show: false,
        }
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        this.bodyElement = document.getElementById('root');
        if (this.bodyElement) {
            const show = window.ResizeObserver ? this.bodyElement.scrollHeight > window.innerHeight : false;
            this.setState(prevState => ({
                ...prevState,
                show,
            }));

            if (window.ResizeObserver) {
                const observer = new ResizeObserver(() => {
                    if (this.bodyElement) {
                        const show = this.bodyElement.scrollHeight > window.innerHeight;
                        this.setState(prevState => ({
                            ...prevState,
                            show,
                        }));
                    }
                });
                observer.observe(this.bodyElement);
            }
        }
    }

    render() {
        if (this.state.show)
            return (
                <button className={"scroll-to-top-container"} onClick={this.scrollToTop}>
                    <span className={"scroll-to-top"}/>
                </button>
            );
        else return null;
    }
}

export default ScrollToTop;
