import React, {Component} from 'react';
import PublicHeader from "../components/PublicHeader";
import userRequests from "../api/userRequests";
import Notification from "../api/notification";
import {StateParams} from '@uirouter/react';
import Helmet from "../components/Helmet";
import Footer from "../components/Footer";

type Props = {
    $stateParams: StateParams
}

type State = {
    pwd: string
    pwdConfirm: string
    emailSent: boolean
}

class ResetPassword extends Component<Props, State> {
    readonly state = {
        pwd: '',
        pwdConfirm: '',
        emailSent: false,
    };

    setPwd = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            pwd: val,
        }));
    }

    setPwdConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            pwdConfirm: val,
        }));
    }

    sendResetPassword = (e: React.FormEvent) => {
        e.preventDefault();
        if (this.state.pwd !== this.state.pwdConfirm) {
            Notification.error("Les mots de passe ne correspondent pas");
        } else {
            userRequests.resetPassword(this.state.pwd, this.props.$stateParams.guid).then(() => {
                Notification.success("Mot de passe réinitialisé avec succès");
                this.setState(prevState => ({
                    ...prevState,
                    emailSent: true,
                }));
            }, () => {
                Notification.error("Une erreur est surevenue lors de l'envoi, veuillez réessayer plus tard");
            });
        }
    }

    render() {
        return (
            <>
                <PublicHeader className="shadowed-header"/>
                <div className="dark-bg" style={{minHeight: '65vh'}}>
                    <div className="uk-container h-center">
                        <Helmet title={'Mot de passe oublié | Paanteon'} />
                        <h1 className="page-title">Mot de passe oublié</h1>

                        <div className="main-content">
                            {
                                this.state.emailSent ?
                                    <div>
                                        <p>Votre mot de passe a été modifié avec succès</p>
                                    </div>
                                    :
                                    <div>
                                        <p>Entrez votre nouveau mot de passe et validez</p>

                                        <form onSubmit={this.sendResetPassword}>
                                            <input className="input big margin-top" placeholder="Votre mot de passe"
                                                   onChange={this.setPwd} value={this.state.pwd} type="password" style={{width: '400px'}}/>
                                            <br/>
                                            <input className="input big margin-top"
                                                   placeholder="Confirmez votre mot de passe"
                                                   onChange={this.setPwdConfirm} value={this.state.pwdConfirm}
                                                   type="password" style={{width: '400px'}}/>
                                            <br/>
                                            <button className="btn btn-1 margin-top" style={{width: '220px'}}>Envoyer
                                            </button>
                                        </form>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default ResetPassword;
