import React from 'react';
import authentication from "../../api/authentication";
import auth from "../../api/authentication";
import localStorage from "../../api/localStorage";

import logo from "img/logo-paanteon-dark-bg.svg";
import pictoPaanteon from "pictos/picto-paanteon.svg";
import pictoInspirationFeedWhite from "pictos/picto-inspiration-feed-white.svg";
import pictoFriendsWhite from "pictos/picto-friends.svg";
import pictoFriendsBlack from "pictos/picto-friends-black.svg";
import pictoCirclesWhite from "pictos/picto-circles-white.svg";
import pictoCirclesBlack from "pictos/picto-circles-black.svg";
import pictoTribesWhite from "pictos/picto-tribes.svg";
import pictoTribesBlack from "pictos/picto-tribes-black.svg";
import pictoMessageWhite from "pictos/picto-message-white.svg";
import pictoNotifs from "pictos/picto-notifs.svg";
import pictoMagnify from "pictos/picto-magnify-white.svg";
import pictoCloseBlue from "pictos/picto-close-blue.svg";
import pictoCloseBlack from "pictos/picto-close-black.svg";
import pictoBurgerMenu from "pictos/picto-burger-menu.svg";
import pictoCandleBlack from "pictos/picto-candle-black.svg";
import {SearchResults, Tribute, User} from "../../api/types";
import {FormattedMessage, InjectedIntlProps, injectIntl} from 'react-intl';
import {ActiveLink, InjectedUIRouterProps, injectUIRouter, Link} from "../../api/injectors";
import CounterChip from "../../elements/CounterChip";
import Notification from "../../api/notification";
import {AxiosError} from "axios";
import {pictoCandle} from "../../api/pictos";
import paanteon from "../../api/paanteonRequests";
import loaderImg from "../../assets/img/loader.gif";
import shave from "shave";
import {RawParams, StateOrName} from '@uirouter/react';
import InviteFriendsModal from "../InviteFriendsModal";
import { hasOneYearAccount } from '../../api/user';

let STATIC_INSTANCE: PublicHeader;

export function updateUser() {
    STATIC_INSTANCE.updateUser();
}

type Props = { className?: string, noItems?: boolean } & InjectedIntlProps & InjectedUIRouterProps

type State = {
    loading: boolean,
    user?: User & {Tributes : Tribute[]},
    userLogin: {email: string, password: string},
    isProfileMenuOpen: boolean
    displaySearch: boolean
    search: string
    suggestedSearchResults: SearchResults
    searchTimeout: number | null
    displayedSearchCategories: Record<string, boolean>
    notificationsCount: number | null
    showInviteFriendsModal: boolean
};

function groupBy<T>(list: T[], max: number): T[][] {
    return list.reduce<T[][]>((acc, val, idx) => {
        const i = Math.floor(idx / max);
        if (idx % max === 0) {
            acc.push([val]);
        } else {
            acc[i].push(val);
        }
        return acc;
    }, []);
}

function displaySearchGroup(group: JSX.Element[], idx: number): JSX.Element {
    return <div data-uk-grid="" className="uk-grid uk-child-width-1-3 uk-grid-small" key={idx}>
        <div>
            <div data-uk-grid="" className="uk-grid uk-child-width-1-3 uk-grid-small">
                {group.slice(0, 3).map(el => <div key={el.key}>{el}</div>)}
            </div>
        </div>

        <div>
            <div data-uk-grid="" className="uk-grid uk-child-width-1-3 uk-grid-small">
                {group.slice(3, 6).map(el => <div key={el.key}>{el}</div>)}
            </div>
        </div>

        <div>
            <div data-uk-grid="" className="uk-grid uk-child-width-1-3 uk-grid-small">
                {group.slice(6, 9).map(el => <div key={el.key}>{el}</div>)}
            </div>
        </div>
    </div>
}

function displayInMemoSearchGroup(group: JSX.Element[], idx: number): JSX.Element {
    return <div data-uk-grid="" className="uk-grid uk-child-width-1-6 uk-grid-small" key={idx}>
        {group.map(el => <div key={el.key}>{el}</div>)}
    </div>
}

class PublicHeader extends React.Component<Props, State> {
    removeTransitionHook: Function | null = null;
    profileTransitionHooks: Function[] = [];

    constructor(props: { className?: string, noItems?: boolean } & InjectedIntlProps & InjectedUIRouterProps) {
        super(props);
        this.state = {
            loading: false,
            userLogin: {
                email: '',
                password: '',
            },
            isProfileMenuOpen: false,
            displaySearch: false,
            search: '',
            suggestedSearchResults: {
                thumbnails: [],
                users: [],
                inMemoriams: [],
            },
            searchTimeout: null,
            displayedSearchCategories: {},
            notificationsCount: null,
            showInviteFriendsModal: !!props.router.stateService.params.inviteFriends,
        };
        STATIC_INSTANCE = this;

        this.profileTransitionHooks.push(this.props.router.transitionService.onSuccess({}, () => {
           this.setState(prevState => ({
               ...prevState,
               isProfileMenuOpen: false,
           }));
        }));
    }

    updateUser() {
        if (localStorage.isSetLocalUser()) {
            // this.setState({loading: true});
            auth.getUserById(localStorage.getLocalUser().userId).then(res => {
                this.setState({user: res.data});
            }, () => {});

            paanteon.getNotifications().then(res => {
                this.setState(prevState => ({
                    ...prevState,
                    notificationsCount: res.data.unread,
                }));
            }, () => {});
        }
    }

    updatePwd = (event: React.FormEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            userLogin: {
                ...prevState.userLogin,
                password: val,
            },
        }));
    };
    updateUserEmail = (event: React.FormEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            userLogin: {
                ...prevState.userLogin,
                email: val,
            },
        }));
    };

    connect = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        authentication.login(this.state.userLogin).then((resp) => {
            this.props.router.stateService.go(
                this.state.user?.Tributes.length! === 0 ? 'connected.tutorial' : 'connected.home',
                !resp.isFirstLogin && this.state.user?.Tributes.length! > 0 && resp.friendsCount <= 3 ? {inviteFriends: true} : {}
            );
        }, (err: AxiosError) => {
            if (err.response) {
                if (err.response.status === 404) {
                    Notification.error("Ce couple d'identifiants n'est pas valide");
                } else if (err.response.status === 410) {
                    Notification.error("Compte suspendu, merci de contacter le support pour plus d'informations");
                } else if (err.response.status === 412) {
                    Notification.error("L'adresse mail de ce compte n'a pas été validée");
                }
            }
        });
    };

    logout = () => {
        authentication.logout().then(() => {
            this.props.router.stateService.go('home');
        });
    };

    toggleProfileMenu = () => {
        this.setState(prevState => {
            if (prevState.isProfileMenuOpen) {
                document.body.classList.remove("no-scroll-xs");
            } else {
                document.body.classList.add("no-scroll-xs");
            }

            return {
                ...prevState,
                isProfileMenuOpen: !prevState.isProfileMenuOpen,
            };
        });
    }

    toggleSearch = () => {
        this.setState(prevState => {
            let newState = {
                ...prevState,
                displaySearch: !prevState.displaySearch,
            }
            if (!newState.displaySearch) newState.search = '';
            return newState;
        }, () => {
            if (this.state.displaySearch) {
                document.getElementById("header-search")?.focus();
            }
        });
    }

    updateSearch = (e: React.FormEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
        if (val.trim().length >= 2) {
            if (this.state.searchTimeout !== null) clearTimeout(this.state.searchTimeout);
            this.setState(prevState => ({
                ...prevState,
                searchTimeout: window.setTimeout(() => {
                    paanteon.search(val).then(res => {
                        this.setState(prevState => ({
                            ...prevState,
                            suggestedSearchResults: res.data,
                            searchTimeout: null,
                        }));
                    }, () => {
                        Notification.error("Erreur lors de la recherche")
                        this.setState(prevState => ({
                            ...prevState,
                            searchTimeout: null,
                        }));
                    })
                }, 250),
            }));
        }
    }

    toggleDisplayedSearchCategory = (cat: string):void => {
        this.setState(prevState => ({
            ...prevState,
            displayedSearchCategories: {
                ...prevState.displayedSearchCategories,
                [cat]: !prevState.displayedSearchCategories[cat],
            },
        }));
    }

    setShowInviteFriendsModal = (show: boolean): void => {
        this.setState({showInviteFriendsModal: show})
    }

    componentDidMount(): void {
        if (localStorage.isSetLocalUser()) {
            this.setState({loading: true});
            Promise.all([
                auth.getUserById(localStorage.getLocalUser().userId).then(res => {
                    this.setState({user: res.data});
                }),
                paanteon.getNotifications().then(res => {
                    this.setState(prevState => ({
                        ...prevState,
                        notificationsCount: res.data.unread,
                    }));
                }),
            ]).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    loading: false,
                }));
            })
        }

        this.removeTransitionHook = this.props.router.transitionService.onStart({
            from: 'connected.**',
        }, (transition) => {
            this.setState(prevState => ({
                ...prevState,
                displaySearch: false,
                search: '',
            }));
            return true;
        })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        shave(".search-results .card .card-footer .card-name", 50, {spaces: false});
    }

    componentWillUnmount() {
        if (this.removeTransitionHook) this.removeTransitionHook();
        this.profileTransitionHooks.forEach(f => f());
    }

    isInState(state: StateOrName, params?: RawParams) {
        return this.props.router.stateService.includes(state);
    }

    render() {
        if (!this.state.loading) {
            const emailPlaceholder = this.props.intl.formatMessage({id: "general.user.email"});
            const passwordPlaceholder = this.props.intl.formatMessage({id: "general.user.password"});

            const isMenuActive = this.isInState('connected.profile') || this.isInState('connected.parameters') || this.isInState('connected.help') || this.isInState('connected.admin');

            const searchedThumbnails = this.state.suggestedSearchResults.thumbnails.map(thumbnail =>
                <Link to="connected.paanteon.thumbnail.tributes"
                      params={{thumbnailId: thumbnail.thumbnailId, thumbnailUniqueName: thumbnail.uniqueName}}
                      className="card blue expand"
                      style={{backgroundImage: 'url(' + thumbnail.Tributes[0].picturePath + ')'}}
                      key={thumbnail.thumbnailId}>
                    <canvas width="250" height="350"/>
                    <div className="card-footer">
                        <p className="card-name">{thumbnail.label}</p>
                    </div>
                </Link>)

            const searchedUsers = this.state.suggestedSearchResults.users.map(user => <Link to="connected.paanteon.user" className="user-result" params={{userId: user.userId, username: user.username}} key={user.userId}>
                <canvas width="1" height="1" className="profile-pic" style={{backgroundImage: "url(" + user.picturePath + ')'}}/>
                     <div className="user-name">{user.firstname} {user.lastname}</div>
            </Link>)

            const searchedInMemoriams = this.state.suggestedSearchResults.inMemoriams.map(inMemo => <Link
                to="connected.in-memoriam.details" params={{memoriamUniqueName: inMemo.Thumbnail.uniqueName, memoriamId: inMemo.inMemoriamId}} className="framed-pic-card"
                style={{backgroundImage: 'url(' + inMemo.picture + ')'}} key={inMemo.inMemoriamId}>
                <div className="card-footer">
                    <p className="card-title">{inMemo.firstname} {inMemo.lastname}</p>
                </div>
                <canvas className="card-ratio" width="16" height="9"/>
            </Link>)

            return (<>
                <div className={this.props.className ? this.props.className + " header" : "header"}>
                    <div className="uk-container">
                        <div className="navbar">
                            <div className="navbar-left">
                                {/* <UISref to="home" className="navbar-item">
                                <a><img className="logo" src={logo} alt="logo Paanteon"/></a>
                            </UISref>*/}
                                <Link to="home" className="navbar-item"><img className="logo" src={logo}
                                                                             alt="logo Paanteon"/></Link>
                            </div>
                            {
                                !this.state.user && <div className="navbar-center">
                                    <form onSubmit={this.connect}>
                                        <input className="input no-border" type="email" onChange={this.updateUserEmail}
                                               placeholder={emailPlaceholder}/>
                                        <input className="input no-border" type="password" onChange={this.updatePwd}
                                               placeholder={passwordPlaceholder}/>
                                        <button className="btn btn-1" disabled={!this.state.userLogin.password || this.state.userLogin.password.length === 0 || this.state.userLogin.email.length === 0}><FormattedMessage
                                            id="login.connect"/></button>
                                    </form>
                                    <Link to="lost-password" className="btn link-btn small-link-btn" style={{marginLeft: '10px'}}><FormattedMessage
                                        id="login.passwordForgotten"/></Link>
                                </div>
                            }
                            {
                                this.state.user && this.state.user.Tributes.length > 0 && (!hasOneYearAccount(this.state.user) || this.state.user.certificationDate) && (<div className="navbar-center">
                                    <ActiveLink to="connected.paanteon" className="nav-paanteon picto-nav picto-nav-bigger-1" class="active" title="Mon paanteon">
                                        <img src={pictoPaanteon} alt=""/>
                                    </ActiveLink>
                                    <ActiveLink to="connected.inspiration-feed" className="nav-inspiration-feed picto-nav picto-nav-bigger-1" class="active" title="Fil d'inspiration">
                                        <img src={pictoInspirationFeedWhite} alt=""/>
                                    </ActiveLink>
                                    <ActiveLink to="connected.friends" className="nav-friends picto-nav uk-visible@m" class="active" title="Mes amis">
                                        <img src={pictoFriendsWhite} alt=""/>
                                        <CounterChip count={this.state.user.friendsRequestCount}/>
                                    </ActiveLink>
                                    <ActiveLink to="connected.in-memoriam" className="nav-in-memoriam picto-nav uk-visible@m" class="active" title="In Memoriam">
                                        <img src={pictoCandle} alt=""/>
                                    </ActiveLink>
                                    {/* <ActiveLink to="x" className="picto-nav" class="active">
                                        <img src={pictoCirclesWhite} alt=""/>
                                    </ActiveLink>*/}
                                    <button title="Cercles de discussion" className="nav-search-button picto-nav btn btn-icon" onClick={() => Notification.success("Cercles de discussion bientôt disponibles")}>
                                        <img src={pictoCirclesWhite} alt=""/>
                                    </button>
                                    <ActiveLink to="connected.tribes" title="Tribus" className="nav-search-button picto-nav btn btn-icon" class="active">
                                        <img src={pictoTribesWhite} alt=""/>
                                    </ActiveLink>
                                    <ActiveLink to="connected.messages" className="nav-messages picto-nav picto-nav-smaller" class="active" title="Messagerie">
                                        <img src={pictoMessageWhite} alt=""/>
                                        <CounterChip count={this.state.user.unreadMessageCount}/>
                                    </ActiveLink>
                                    <ActiveLink to="connected.notifications" className="nav-notifications picto-nav" class="active" title="Notifications">
                                        <img src={pictoNotifs} alt=""/>
                                        <CounterChip count={this.state.notificationsCount as number}/>
                                    </ActiveLink>
                                    <button className="nav-search-button picto-nav btn btn-icon" onClick={this.toggleSearch}>
                                        <img src={pictoMagnify} alt=""/>
                                    </button>

                                    {
                                        this.state.displaySearch && <div className="global-search">
                                            <input id="header-search" className="input" onChange={this.updateSearch} value={this.state.search}/>
                                            {
                                                this.state.searchTimeout !== null && <img className="search-loader" src={loaderImg} alt="loading"/>
                                            }
                                            <button className="btn btn-icon btn-close" onClick={this.toggleSearch}><img className="picto" src={pictoCloseBlue} alt="close"/></button>
                                        </div>
                                    }
                                </div>
                            )}

                            {
                                !this.props.noItems ?
                                    <div className="navbar-right">
                                        {this.state.user && <div>
                                            <button onClick={this.toggleProfileMenu} className={"btn btn-icon profile-nav-item" + (isMenuActive ? ' active' : '')}>
                                                <div className="profile-pic uk-visible@m"
                                                     style={{backgroundImage: "url('" + this.state.user.picturePath + "')"}}/>
                                                <img className={"uk-hidden@m menu"} src={pictoBurgerMenu} alt={"Menu"} />
                                            </button>

                                            <div className={`drop-menu top-right${this.state.isProfileMenuOpen ? "" : " closed"}`}>
                                                <div className={"drop-inner"}>
                                                    <button onClick={this.toggleProfileMenu} className={"btn btn-icon uk-hidden@m btn-close"}>
                                                        <img className="picto" src={pictoCloseBlack} alt="close"/>
                                                    </button>
                                                    <div className="drop-title">
                                                        <div className="drop-picto profile-pic uk-hidden@m"
                                                             style={{backgroundImage: "url('" + this.state.user.picturePath + "')"}}/>
                                                        <span>{this.state.user.firstname} {this.state.user.lastname}</span>
                                                    </div>
                                                    {this.state.user && this.state.user.Tributes.length > 0 && (!hasOneYearAccount(this.state.user) || this.state.user.certificationDate) && <div className="drop-body">
                                                        <div className={"drop-body-section"}>
                                                            <ul>
                                                                <li><Link className="btn link-btn" to="connected.profile">Mon profil</Link></li>
                                                                {
                                                                    !this.state.user.certificationDate &&
                                                                    <li><Link className="btn link-btn" to="connected.certification">Je certifie mon compte</Link></li>
                                                                }
                                                                <li><Link className="btn link-btn" to="connected.parameters.account">Paramètres</Link></li>
                                                                <li><Link className="btn link-btn" to="connected.help">Aide</Link></li>
                                                                {this.state.user.Tributes.length < 3 &&
                                                                    <li><Link className="btn link-btn" to="connected.presentation">Présentation</Link></li>
                                                                }
                                                                <li><button className={"btn link-btn"} onClick={() => this.setShowInviteFriendsModal(true)}>Je parraine mes amis</button></li>

                                                                {
                                                                    this.state.user.admin &&
                                                                    <>
                                                                        <li><Link className="btn link-btn" to="connected.admin">Administration</Link></li>
                                                                    </>
                                                                }
                                                            </ul>
                                                        </div>

                                                        <div className={"drop-title uk-hidden@m"}>
                                                            <Link className="btn link-btn" to="connected.friends">
                                                                <img className={"drop-picto"} src={pictoFriendsBlack} alt="" />
                                                                <span className={"drop-text"}>Mes amis</span>
                                                            </Link>
                                                        </div>

                                                        <div className={"drop-title uk-hidden@m"}>
                                                            <Link className="btn link-btn" to="connected.in-memoriam">
                                                                <img className={"drop-picto"} src={pictoCandleBlack} alt="" />
                                                                <span className={"drop-text"}>In memoriam</span>
                                                            </Link>
                                                        </div>

                                                        <div className={"drop-title uk-hidden@m"}>
                                                            <Link className="btn link-btn" to="connected.tribes">
                                                                <img className={"drop-picto"} src={pictoTribesBlack} alt="" />
                                                                <span className={"drop-text"}>Mes tribus</span>
                                                            </Link>
                                                        </div>

                                                        <div className={"drop-title uk-hidden@m"}>
                                                            <Link className="btn link-btn" to="y">
                                                                <img className={"drop-picto"} src={pictoCirclesBlack} alt="" />
                                                                <span className={"drop-text"}>Mes cercles</span>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className="drop-footer">
                                                        <button className="link light" onClick={this.logout}>
                                                            &gt;&nbsp;<FormattedMessage id="login.logout"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                        {
                                            !this.state.user && <Link to="register" className="btn btn-outlined light">
                                                <FormattedMessage id="login.signup"/>
                                            </Link>
                                        }
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>

                {
                    this.state.displaySearch && this.state.search.trim().length >= 2 && (this.state.searchTimeout === null || (this.state.suggestedSearchResults.thumbnails.length > 0 && this.state.suggestedSearchResults.users.length > 0 && this.state.suggestedSearchResults.inMemoriams.length > 0)) &&
                    <div className="search-results">
                        <div className="uk-container">
                            {
                                this.state.searchTimeout === null && (this.state.suggestedSearchResults.thumbnails.length === 0 && this.state.suggestedSearchResults.users.length === 0 && this.state.suggestedSearchResults.inMemoriams.length === 0) && <p className="no-result">
                                    Aucun résultat
                                </p>
                            }
                            {
                                this.state.suggestedSearchResults.thumbnails.length > 0 && <div className="results-section">
                                    <h3 className="category-title">Hommages</h3>
                                    {
                                        groupBy((this.state.displayedSearchCategories["thumbnails"] ? searchedThumbnails : searchedThumbnails.slice(0, 9)), 9).map(displaySearchGroup)
                                    }
                                    {
                                        searchedThumbnails.length > 9 &&
                                        <div className="section-footer">
                                            <button
                                                className={`btn btn-toggle ${this.state.displayedSearchCategories["thumbnails"] ? "toggled" : ""}`}
                                                onClick={() => this.toggleDisplayedSearchCategory("thumbnails")}>
                                                voir {this.state.displayedSearchCategories["thumbnails"] ? 'moins' : 'plus'}
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                            {
                                this.state.suggestedSearchResults.users.length > 0 && <div className="results-section">
                                    <h3 className="category-title">Membres</h3>
                                    {
                                        groupBy((this.state.displayedSearchCategories["users"] ? searchedUsers : searchedUsers.slice(0, 9)), 9).map(displaySearchGroup)
                                    }
                                    {
                                        searchedUsers.length > 9 &&
                                        <div className="section-footer">
                                            <button
                                                className={`btn btn-toggle ${this.state.displayedSearchCategories["users"] ? "toggled" : ""}`}
                                                onClick={() => this.toggleDisplayedSearchCategory("users")}>
                                                voir {this.state.displayedSearchCategories["users"] ? 'moins' : 'plus'}
                                            </button>
                                        </div>
                                    }
                                </div>
                            }

                            {
                                this.state.suggestedSearchResults.inMemoriams.length > 0 && <div className="results-section">
                                    <h3 className="category-title">Livres d'or</h3>
                                    {
                                        groupBy((this.state.displayedSearchCategories["memoriams"] ? searchedInMemoriams : searchedInMemoriams.slice(0, 6)), 6).map(displayInMemoSearchGroup)
                                    }
                                    {
                                        searchedInMemoriams.length > 9 &&
                                        <div className="section-footer">
                                            <button
                                                className={`btn btn-toggle ${this.state.displayedSearchCategories["memoriams"] ? "toggled" : ""}`}
                                                onClick={() => this.toggleDisplayedSearchCategory("memoriams")}>
                                                voir {this.state.displayedSearchCategories["memoriams"] ? 'moins' : 'plus'}
                                            </button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }

                {this.state.showInviteFriendsModal && <InviteFriendsModal onClose={() => this.setShowInviteFriendsModal(false)} /> }
            </>);
        }
        return <></>;
    }
}

export default injectUIRouter(injectIntl(PublicHeader));
