import React from "react";
import {Link} from "../../api/injectors";

function Step4() {
    return (
        <div className="uk-container main-content-s">
            <h2 className="title-3 h-center text-uppercase margin-large-top">
                Félicitations !
            </h2>
            <div>
                <div className="margin-bottom-xl">
                    <p className="body text-center margin-bottom">
                        Votre paiement a bien été pris en compte.
                    </p>
                    <p className="body text-center">
                        Votre adhésion est donc validée et votre compte Paanteon est maintenant certifié !
                    </p>
                </div>
                <div className="margin-large">
                    <div className="margin-large right">
                        <Link to="connected.home" options={{reload: true}} className="btn btn-1 btn-big btn-uppercase">
                            Retour à la page d'accueil
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Step4;
