import React from 'react';
import {Comment, CommentToSend, InMemoriam, Testimonial, TestimonialToSend, User, UserInfos, Tribe} from "../api/types";
import UserSidebar from "../components/UserSidebar";
import {Link} from "../api/injectors";
import pictoEngraveWhite from "../assets/img/pictos/picto-engrave-white.svg";
import Toggleable from "../components/Toggleable";
import {pictoHeart, pictoHeartBlue, pictoPaanteonLightBlue, pictoSpeechBubbleFilledBlue} from "../api/pictos";
import pictoSpeechBubbleBlue from "../assets/img/pictos/picto-speech-bubble-blue.svg";
import Comments from "../components/Comments";
import localStorage from "../api/localStorage";
import paanteon from "../api/paanteonRequests";
import {deleteFromArrayAndReturn, stopPropagation} from "../api/helpers";
import Notification from "../api/notification";
import moment from "moment";
import Page from "./Page";
import ReportModal from "../components/ReportModal";
import OptionsMenu from "../components/OptionsMenu";
import ConfirmModal from "../components/ConfirmModal";
import {processLinks, processMentions} from "../api/domHelper";
import {Mention, MentionsInput, OnChangeHandlerFunc, SuggestionDataItem} from "react-mentions";
import MentionUser, {MentionableUsersContext} from "../components/MentionUser";
import Helmet from "../components/Helmet";

type Props = {
    inMemo: {data: InMemoriam & { Testimonials: Testimonial[] } }
    user: UserInfos
    memoriam: InMemoriam[]
    userTribes?: {Tribes: Tribe[], userId: number, totalUser: number}
}

type State = {
    testimonials: Testimonial[]
    toggledComments: {[key: number]: boolean}
    likedTestimonials: {[key: number]: boolean}
    likeCountTestimonials: {[key: number]: number}
    newTestimonial: TestimonialToSend
    selectedOrder: string | null
    modifiedOrder: boolean
    selectedTestimonialIdToDelete: number
}

class InMemoriamDetails extends Page<Props, State> {
    private user: User = localStorage.getLocalUser();
    readonly state: State = {
        testimonials: this.props.inMemo.data.Testimonials,
        toggledComments: {},
        likedTestimonials: Object.fromEntries(this.props.inMemo.data.Testimonials.map(t => [t.testimonialId, Boolean(t.liked)])),
        likeCountTestimonials: Object.fromEntries(this.props.inMemo.data.Testimonials.map(t => [t.testimonialId, t.Likes.length])),
        newTestimonial: {
            content: '',
        },
        selectedOrder: 'youngest',
        modifiedOrder: false,
        selectedTestimonialIdToDelete: -1,
    }

    constructor(props: Props) {
        super(props);

        const {hash} = window.location;
        let testimonial = null;
        if (hash !== '' && hash.slice(1).startsWith('comment')) {
            const id = parseInt(hash.slice(8), 10);
            testimonial = props.inMemo.data.Testimonials.find(t => !!t.Comments.find(c => c.commentId === id));
            if (testimonial) this.state.toggledComments[testimonial.testimonialId] = true;
        }
    }

    toggleComment = (testimonialId: number) => {
        this.setState(prevState => ({
            ...prevState,
            toggledComments: {
                ...prevState.toggledComments,
                [testimonialId]: !prevState.toggledComments[testimonialId],
            },
        }));
    }

    deleteComment = (testimonialId: number, commentId: number) => {
        return paanteon.deleteComment(commentId).then(() => {
            this.setState(prevState => {
                let nState = {...prevState};

                for (let i = 0; i < prevState.testimonials.length; i++) {
                    if (prevState.testimonials[i].testimonialId === testimonialId) {
                        for (let j = 0; j < prevState.testimonials[i].Comments.length; j++) {
                            if (prevState.testimonials[i].Comments[j].commentId === commentId) {
                                nState.testimonials = [...prevState.testimonials];
                                nState.testimonials[i] = {
                                    ...prevState.testimonials[i],
                                    Comments: deleteFromArrayAndReturn(prevState.testimonials[i].Comments, j),
                                }

                                return nState;
                            } else if (prevState.testimonials[i].Comments[j].Answers) {
                                // @ts-ignore
                                for (let k = 0; k < prevState.testimonials[i].Comments[j].Answers.length; k++) {
                                    // @ts-ignore
                                    if (prevState.testimonials[i].Comments[j].Answers[k].commentId === commentId) {
                                        nState.testimonials = [...prevState.testimonials];
                                        nState.testimonials[i] = {
                                            ...prevState.testimonials[i],
                                            Comments: [...prevState.testimonials[i].Comments],
                                        }
                                        nState.testimonials[i].Comments[j] = {
                                            ...prevState.testimonials[i].Comments[j],
                                            // @ts-ignore
                                            Answers: deleteFromArrayAndReturn(prevState.testimonials[i].Comments[j].Answers, k),
                                        }

                                        return nState;
                                    }
                                }
                            }
                        }

                        break;
                    }
                }

                return nState;
            });
            return true;
        });
    }

    sendComment = (testimonialId: number, comment: CommentToSend) => {
        const comm = {
            ...comment,
            testimonialId: testimonialId,
        };
        const answerId = comm.answerId;
        if (comm.answerId === -1) delete comm.answerId;
        return paanteon.comment(comm).then((res: {data: Comment}) => {
            this.setState(prevState => {
                if (!res.data.Answers) res.data.Answers = [];
                let nState = {...prevState};
                if (answerId === -1) {
                    for (let i = 0; i < nState.testimonials.length; i++) {
                        if (nState.testimonials[i].testimonialId === testimonialId) {
                            nState.testimonials = [...nState.testimonials];
                            nState.testimonials[i] = {
                                ...nState.testimonials[i],
                                Comments: [...nState.testimonials[i].Comments, res.data],
                            }
                            break;
                        }
                    }
                } else {
                    for (let i = 0; i < nState.testimonials.length; i++) {
                        const comm = nState.testimonials[i].Comments.find(comm => comm.commentId === answerId);
                        if (comm && comm.Answers) comm.Answers = [...comm.Answers, res.data];
                    }
                }
                return nState;
            });
            return true;
        }, function() {
            Notification.error("Erreur lors de la création du commentaire");
            return false;
        });
    }

    toggleLike = (testimonialId: number) => {
        if (this.state.likedTestimonials[testimonialId]) {
            paanteon.unlike({testimonialId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    likedTestimonials: {
                        ...prevState.likedTestimonials,
                        [testimonialId]: false,
                    },
                    likeCountTestimonials: {
                        ...prevState.likeCountTestimonials,
                        [testimonialId]: prevState.likeCountTestimonials[testimonialId] - 1,
                    },
                }));
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        } else {
            paanteon.like({testimonialId}).then(() => {
                this.setState(prevState => ({
                    ...prevState,
                    likedTestimonials: {
                        ...prevState.likedTestimonials,
                        [testimonialId]: true,
                    },
                    likeCountTestimonials: {
                        ...prevState.likeCountTestimonials,
                        [testimonialId]: prevState.likeCountTestimonials[testimonialId] + 1,
                    },
                }));
            }, () => {
                Notification.error("Une erreur est survenue, veuillez réessayer plus tard");
            });
        }
    }

    postTestimonial = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();

            paanteon.createTestimonial(this.props.inMemo.data.inMemoriamId, this.state.newTestimonial).then(res => {
                if (!res.data.Comments) res.data.Comments = [];
                this.setState(prevState => ({
                    ...prevState,
                    testimonials: [...prevState.testimonials, res.data],
                    newTestimonial: {
                        content: '',
                    },
                    likeCountTestimonials: {
                        [res.data.testimonialId]: 0,
                    },
                }));
            }, () => {
                Notification.error("Erreur lors de l'envoi du témoignage")
            });
        }
    }

    setNewTestimonialContent: OnChangeHandlerFunc = (e) => {
        const val = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            newTestimonial: {
                ...prevState.newTestimonial,
                content: val,
            },
        }));
    }

    setOrder = (order: string | null) => {
        this.setState(prevState => ({
            ...prevState,
            selectedOrder: order,
            modifiedOrder: true,
        }));
    }

    openConfirmDelete = (testimonialId: number) => {
        this.setState(prevState => ({
            ...prevState,
            selectedTestimonialIdToDelete: testimonialId,
        }));
    }

    closeConfirmDelete = () => {
        this.setState(prevState => ({
            ...prevState,
            selectedTestimonialIdToDelete: -1,
        }));
    }

    confirmDelete = () => {
        paanteon.deleteTestimonial(this.props.inMemo.data.inMemoriamId, this.state.selectedTestimonialIdToDelete).then(() => {
            this.setState(prevState => ({
                ...prevState,
                selectedTestimonialIdToDelete: -1,
                testimonials: prevState.testimonials.filter(t => t.testimonialId !== prevState.selectedTestimonialIdToDelete),
            }));
        }, () => {
            Notification.error("Une erreur est survenue lors de la suppression du témoignage");
        });
    }

    componentDidMount() {
        super.componentDidMount();
        const {hash} = window.location;
        if (hash !== '') {
            setTimeout(() => {
                const e = document.getElementById(hash.slice(1, hash.length));
                if (e) e.scrollIntoView(true);
            }, 0);
        }
    }

    render() {
        let testimonials = [...this.state.testimonials];

        if (this.state.selectedOrder) {
            if (this.state.selectedOrder === 'youngest') testimonials.sort((a, b) => moment(b.createdAt).diff(a.createdAt));
            if (this.state.selectedOrder === 'inspirational') testimonials.sort((a, b) => this.state.likeCountTestimonials[b.testimonialId] - this.state.likeCountTestimonials[a.testimonialId]);
        }

        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <Helmet title={`In Memoriam - ${this.props.inMemo.data.firstname} ${this.props.inMemo.data.lastname} | Paanteon`} />
                    <div className="page-head img-w-copyright" style={{backgroundImage: 'url(' + this.props.inMemo.data.picture + ')', height: '540px'}}>
                        <div className="caption">
                            <div className="caption-title">In Memoriam</div>
                            <div className="caption-content">
                                {this.props.inMemo.data.firstname + ' ' + this.props.inMemo.data.lastname}
                            </div>
                            <p className="body-s smaller">({this.props.inMemo.data.birthYear}-{moment(this.props.inMemo.data.deathYear).format('yyyy')})</p>
                            <div className="caption-footer">
                                {
                                    this.props.inMemo.data.count && this.props.inMemo.data.count > 0 ?
                                        <Link className="chip ghost-link" to="connected.paanteon.thumbnail.tributes" params={{thumbnailId: this.props.inMemo.data.thumbnailId, thumbnailUniqueName: this.props.inMemo.data.Thumbnail.uniqueName}}>
                                            {this.props.inMemo.data.count}
                                            <img className="picto" alt="picto-paanteon" src={pictoPaanteonLightBlue}/>
                                        </Link>
                                        :
                                        <span className="chip">
                                            {this.props.inMemo.data.count}
                                            <img className="picto" alt="picto-paanteon" src={pictoPaanteonLightBlue}/>
                                        </span>
                                }
                                {
                                    this.props.inMemo.data.hasTribute === null &&
                                    <Link to="connected.paanteon.user.new-tribute"
                                          params={{thumbnailId: this.props.inMemo.data.thumbnailId, username: this.props.user.username}}><img
                                        className="picto" alt="picto-engrave-white"
                                        src={pictoEngraveWhite}/></Link>
                                }
                            </div>
                        </div>
                        { this.props.inMemo.data.Thumbnail.copyright && <div className="img-copyright">
                            { this.props.inMemo.data.Thumbnail.copyright }
                        </div> }
                    </div>

                    <UserSidebar user={this.props.user} showCover>
                        <div className="sidebar-block night-blue">
                            <p className="block-title">Autres livres d'or</p>
                            <div className="margin-large-top">
                            {
                                this.props.memoriam.filter(item => { // remove item currently seen
                                    return item.inMemoriamId !== this.props.inMemo.data.inMemoriamId
                                }).map((a) => ({sort: Math.random(), value: a})) // randomize
                                .sort((a, b) => a.sort - b.sort)
                                .map((a) => a.value)
.slice(0, 3)
.map(item => (
                                    <div key={item.inMemoriamId}>
                                        <Link to="connected.in-memoriam.details" params={{memoriamUniqueName: item.Thumbnail.uniqueName, memoriamId: item.inMemoriamId}} className="framed-pic-card"
                                                style={{backgroundImage: 'url(' + item.picture + ')'}}>
                                            <div className="card-footer">
                                                <p className="card-title">{item.firstname} {item.lastname}</p>
                                                <p className="card-subtitle">({item.birthYear}-{moment(item.deathYear).format('yyyy')})</p>
                                            </div>
                                            <canvas className="card-ratio" width="16" height="9"></canvas>
                                        </Link>
                                    </div>
                                ))
                            }
                            </div>
                            <Link to="connected.in-memoriam" className="btn btn-outlined light btn-block margin">Voir tout</Link>
                        </div>
                    </UserSidebar>

                    <div className="main-content">
                        <div className="uk-container">
                            <div className="tile margin-large-bottom">
                                <div className="tile-body">
                                    <div className="feed-item write-comment let-overflow">
                                        <div className="profile-pic"
                                             style={{backgroundImage: "url(" + this.props.user.picturePath + ")"}}/>
                                        <div className="comment-form">
                                            <MentionableUsersContext.Consumer>
                                                {
                                                    (mentionUsers) => <MentionsInput value={this.state.newTestimonial.content}
                                                                                     onChange={this.setNewTestimonialContent}
                                                                                     onKeyPress={this.postTestimonial}
                                                                                     placeholder={"Laissez un témoignage"}
                                                                                     className={"textarea textarea-with-mentions block no-resize"}>
                                                        <Mention trigger={new RegExp("(?:^|\\s)(@([^@]+))")} data={mentionUsers} displayTransform={(id, display) => `@${display}`} markup={'@[__display__](__id__)'} appendSpaceOnAdd
                                                                 renderSuggestion={(suggestion: SuggestionDataItem & {picturePath?: string, displayName?: string}, search, highlightedDisplay, index, focused) => <MentionUser suggestion={suggestion} focused={focused}/>}/>
                                                    </MentionsInput>
                                                }
                                            </MentionableUsersContext.Consumer>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="link-tabs" style={{position: "relative"}}>
                                <Toggleable>
                                    <div key={0} className="tab-item toggle-popin-menu">
                                        <span className="toggle-button active margin-right">Filtrer par</span>

                                        {
                                            this.state.selectedOrder && this.state.modifiedOrder && <span className="active-filter-chip" style={{position: 'relative'}} onClick={stopPropagation}>
                                                {
                                                    this.state.selectedOrder === 'youngest' ?
                                                        'Les plus récents'
                                                        :
                                                        'Les plus inspirants'
                                                }
                                                <button className="btn btn-icon btn-icon-close" onClick={() => this.setOrder(null)}/>
                                            </span>
                                        }

                                        <div className="popin-menu block" onClick={stopPropagation}>
                                            <div className="buttons-bar">
                                                <button className={"btn btn-block light" + (this.state.selectedOrder === 'youngest' ? ' btn-1' : ' btn-outlined')} onClick={() => this.setOrder('youngest')}>Les plus récents</button>
                                                <button className={"btn btn-block light" + (this.state.selectedOrder === 'inspirational' ? ' btn-1' : ' btn-outlined')} onClick={() => this.setOrder('inspirational')}>Les plus inspirants</button>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment key={1}/>
                                </Toggleable>
                            </div>

                            <div className="feed-list margin-top">
                                {
                                    testimonials.map(testimonial => (
                                        <React.Fragment key={testimonial.testimonialId}>
                                            <div className="feed-item" id={`testimonial${testimonial.testimonialId}`}>
                                                <div className="profile-pic" style={{backgroundImage: 'url(' + testimonial.User.picturePath + ')'}}/>
                                                <div>
                                                    <OptionsMenu>
                                                        <span className="options-menu">
                                                            <ul>
                                                                {
                                                                    testimonial.userId !== this.user.userId && <li>
                                                                        <ReportModal testimonialId={testimonial.testimonialId}><button
                                                                            className="btn link-btn">Signaler</button></ReportModal>
                                                                    </li>
                                                                }

                                                                {
                                                                    testimonial.userId === this.user.userId &&
                                                                    <>
                                                                        <li>
                                                                            <button className="btn link-btn" onClick={() => this.openConfirmDelete(testimonial.testimonialId)}>Supprimer</button>
                                                                        </li>
                                                                    </>
                                                                }

                                                                {
                                                                    (this.user.admin && this.user.userId !== testimonial.userId) &&
                                                                    <>
                                                                        <li>
                                                                            <button className="btn link-btn" onClick={() => this.openConfirmDelete(testimonial.testimonialId)}>Supprimer</button>
                                                                        </li>
                                                                    </>
                                                                }
                                                            </ul>
                                                        </span>
                                                    </OptionsMenu>
                                                    <p>
                                                        <em className="ptn-em"><Link to="connected.paanteon.user.galleries.condensed"
                                                                  params={{userId: testimonial.userId, username: testimonial.User.username}}>{testimonial.User.firstname} {testimonial.User.lastname}</Link></em>
                                                        <small>&nbsp;-&nbsp;{moment(testimonial.createdAt).format('LL')}</small>
                                                    </p>
                                                    <h3 className="item-title">{testimonial.title}</h3>
                                                    <div>{processMentions(processLinks([testimonial.content]))}</div>
                                                    <div className="feed-footer">
                                                            <span style={{color: "#37a8db"}}>
                                                                <button className="btn btn-icon" onClick={() => this.toggleLike(testimonial.testimonialId)} style={{color: "#37a8db"}}>
                                                                    {
                                                                        this.state.likedTestimonials[testimonial.testimonialId] ?
                                                                            <img src={pictoHeart} className="picto blue"
                                                                                 alt="picto heart"/>
                                                                            :
                                                                            <img src={pictoHeartBlue} className="picto blue"
                                                                                 alt="picto heart"/>
                                                                    }
                                                                    <span className="regular">{this.state.likeCountTestimonials[testimonial.testimonialId]}</span>
                                                                </button>
                                                            </span>
                                                        <span style={{color: "#37a8db"}}>
                                                            <button className="btn btn-icon" onClick={() => this.toggleComment(testimonial.testimonialId)} style={{color: "#37a8db"}}>
                                                                {
                                                                    testimonial.commented ?
                                                                        <img src={pictoSpeechBubbleFilledBlue}
                                                                             className="picto blue"
                                                                             alt="picto-speech-bubble-blue"/>
                                                                        :
                                                                        <img src={pictoSpeechBubbleBlue}
                                                                             className="picto blue"
                                                                             alt="picto-speech-bubble-blue"/>
                                                                }
                                                                <span className="regular">{testimonial.Comments.length}</span>
                                                            </button>
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>

                                            {
                                                this.state.toggledComments[testimonial.testimonialId] &&
                                                <Comments comments={testimonial.Comments} onDelete={(commentId: number) => this.deleteComment(testimonial.testimonialId, commentId)} selfUser={this.user} onSend={comment => this.sendComment(testimonial.testimonialId, comment)}/>
                                            }
                                        </React.Fragment>
                                    ))
                                }

                                {
                                    this.state.selectedTestimonialIdToDelete !== -1 && <ConfirmModal onCancel={this.closeConfirmDelete} onConfirm={this.confirmDelete}>Êtes-vous sûr(e) de vouloir supprimer définitivement votre témoignage ?</ConfirmModal>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InMemoriamDetails;
