import * as React from 'react';
import {FunctionComponent, useCallback, useEffect, useState} from "react";

const CookieBanner: FunctionComponent = () => {
    const [isDisplayed, setIsDisplayed] = useState(false);

    const onClose = useCallback(() => {
        setIsDisplayed(false);
        localStorage.setItem("hasClosedCookieBanner", "true");
    }, []);

    useEffect(() => {
        setIsDisplayed(localStorage.getItem("hasClosedCookieBanner") === null);
    }, []);

    return isDisplayed ? (
        <div className={"cookie-banner uk-grid"}>
            <div className={"uk-width-expand"}>En utilisant les services de Paanteon, vous acceptez notre politique de confidentialité respectueuse de votre vie privée : aucun traçage des membres, aucun cookie publicitaire ou émanant de tierces parties. Nous n’utilisons qu’un cookie fonctionnel de connexion à votre compte et un autre pour la mesure anonymisée de fréquentation.</div>
            <button className={"uk-width-auto btn btn-outlined light"} onClick={onClose}>Fermer</button>
        </div>
    ) : null;
}

export default CookieBanner;
