import * as React from "react";
import Page from "./Page";
import {updateUser} from "../components/PublicHeader";
import {Friendship, User} from "../api/types";
import moment from "moment";
import OptionsMenu from "../components/OptionsMenu";
import localStorage from "../api/localStorage";
import {Link} from "../api/injectors";
import stringHelper from "../api/stringHelper";
import pictoMagnifyWhite from "../assets/img/pictos/picto-magnify-white.svg";
import Toggleable from "../components/Toggleable";
import filterHelper from "../api/filterHelper";
import SimpleLink from "../elements/SimpleLink";
import friendsRequests from "../api/friendsRequests";
import Notification from "../api/notification";
import userRequests from "../api/userRequests";
import ConfirmModal from "../components/ConfirmModal";
import ReportModal from "../components/ReportModal";
import {stopPropagation} from "../api/helpers";
import {pictoCertification} from "../api/pictos";
import Helmet from "../components/Helmet";

type Props = {
    suggestedFriends: User[]
    userFriends: Friendship[]
}

type State = {
    friendships: {
        friends: (Friendship & { Friend: User })[],
        requests: (Friendship & { Friend?: User })[],
        incoming: (Friendship & { Friend?: User })[],
    }
    friendSearch: string
    suggestedFriends: User[]
    friendsRequestLimit: number
    selectedDeleteFriendId: number | null
    selectedBlockFriendshipId: number | null
    selectedOrder: string | null
}

class Friends extends Page<Props, State> {
    handleFriendSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.setState(prevState => ({...prevState, friendSearch: value}));
    };

    askAsFriend = (userId: number) => {
        friendsRequests.friendsRequest(userId).then((result) => {
            Notification.success('Invitation envoyée!');
            userRequests.getSuggestedFriends(localStorage.getLocalUser().userId).then((res) => {
                this.setState(prevState => ({...prevState, suggestedFriends: res.data}));
            }, function(err) {
                // eslint-disable-next-line no-console
                console.log(err);
            });
            userRequests.getUserFriends(localStorage.getLocalUser().userId).then(res => {
                this.setState(prevState => ({...prevState, friendships: this.loadFriendships(res.data)}));
            }, err => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
        }, function(error) {
            Notification.error('Problème lors de la demande');
        });
    };

    cancelRequest = (friendshipId: number) => {
        friendsRequests.deleteFriendship(friendshipId).then((result) => {
            this.setState(prevState => {
                let nState = {...prevState};
                nState.friendships = {
                    ...nState.friendships,
                    requests: this.state.friendships.requests.filter(function(friendship) {
                        return friendship.friendshipId !== friendshipId;
                    }),
                }
                return nState;
            });
            Notification.success('Demande annulée!');
        }, function(err) {
            Notification.error('Erreur lors de la validation');
        });
    };

    deleteFriendship = (friendshipId: number) => {
        friendsRequests.deleteFriendship(friendshipId).then((result) => {
            this.setState(prevState => {
                let nState = {...prevState};
                nState.friendships = {
                    ...nState.friendships,
                    friends: this.state.friendships.friends.filter(function(friendship) {
                        return friendship.friendshipId !== friendshipId;
                    }),
                }
                return nState;
            });
            Notification.success('Suppression réussie');
        }, function(err) {
            Notification.error('Erreur lors de la suppression');
        });
    };

    acceptIncomingRequest = (friendshipId: number) => {
        friendsRequests.acceptFriendship(friendshipId).then((result) => {
            this.setState(prevState => {
                let nState = {...prevState};
                nState.friendships = {
                    ...nState.friendships,
                    incoming: this.state.friendships.incoming.filter(function(friendship) {
                        return friendship.friendshipId !== friendshipId ||
                            (
                                (friendship.userId === localStorage.getLocalUser().userId ? friendship.Friend = friendship.User2 : friendship.Friend = friendship.User1)
                                && nState.friendships.friends.unshift(friendship as Friendship & { Friend: User })
                                && false
                            );
                    }),
                }
                return nState;
            });
            updateUser();
            Notification.success('Vous êtes amis!');
        }, function(err) {
            Notification.error('Erreur lors de la validation');
        });
    };

    ignoreIncomingRequest = (friendshipId: number) => {
        friendsRequests.refuseFriendship(friendshipId).then((result) => {
            this.setState(prevState => {
                let nState = {...prevState};
                nState.friendships = {
                    ...nState.friendships,
                    incoming: this.state.friendships.incoming.filter(function(friendship) {
                        return friendship.friendshipId !== friendshipId;
                    }),
                }
                return nState;
            });
            updateUser();
            Notification.success('Demande ignorée!');
        }, function(err) {
            Notification.error('Erreur lors de la validation');
        });
    };

    blockUser = () => {
        friendsRequests.blockFriendship(this.state.selectedBlockFriendshipId as number).then(() => {
            Notification.success('Utilisateur bloqué');
            const fid = this.state.selectedBlockFriendshipId as number;
            this.setState(prevState => ({
                ...prevState,
                selectedBlockFriendshipId: null,
            }));
            this.setState(prevState => {
                let nState = {...prevState};
                nState.friendships = {
                    ...nState.friendships,
                    incoming: this.state.friendships.incoming.filter(function(friendship) {
                        return friendship.friendshipId !== fid;
                    }),
                }
                return nState;
            });
            updateUser();
        }, function(err) {
            Notification.error('Erreur lors du blocage');
        });
    };

    incrementFriendsRequestLimit = (inc: number) => {
        this.setState(prevState => ({...prevState, friendsRequestLimit: prevState.friendsRequestLimit + inc}));
    };

    openDeleteFriendModal = (friendId: number) => {
        this.setState(prevState => ({
            ...prevState,
            selectedDeleteFriendId: friendId,
        }));
    }

    closeDeleteFriendModal = () => {
        this.setState(prevState => ({
            ...prevState,
            selectedDeleteFriendId: null,
        }));
    }

    setSelectedOrder = (order: string | null) => {
        this.setState(prevState => ({
            ...prevState,
            selectedOrder: order,
        }));
    }

    constructor(props: Props) {
        super(props);

        this.state = {
            friendships: this.loadFriendships(props.userFriends),
            friendSearch: '',
            suggestedFriends: this.props.suggestedFriends,
            friendsRequestLimit: 3,
            selectedDeleteFriendId: null,
            selectedBlockFriendshipId: null,
            selectedOrder: null,
        };
    }

    openBlockFriendshipPopup = (friendshipId: number) => {
        this.setState(prevState => ({
            ...prevState,
            selectedBlockFriendshipId: friendshipId,
        }));
    }

    closeBlockFriendshipPopup = () => {
        this.setState(prevState => ({
            ...prevState,
            selectedBlockFriendshipId: null,
        }));
    }

    loadFriendships(userFriends: Props['userFriends']) {
        const friendships: State["friendships"] = {
            friends: [],
            requests: [],
            incoming: [],
        };

        userFriends.forEach((f: (Friendship & { Friend?: User })) => {
            let friendship = f;
            if (friendship.accepted) { // friends
                if (friendship.userId === localStorage.getLocalUser().userId) {
                    friendship.Friend = friendship.User2;
                } else {
                    friendship.Friend = friendship.User1;
                }
                friendships.friends.push(friendship as Friendship & { Friend: User });
            } else if (friendship.userId === localStorage.getLocalUser().userId) { // friendship request the user made
                friendships.requests.push(friendship);
            } else { // incoming friends request from other users
                friendships.incoming.push(friendship);
            }
        });

        return friendships;
    }

    getOrder = (value: string) => {
        if (value === 'youngest') return "Les plus récents";
        if (value === 'inspirational') return "Les plus inspirants";
        return "";
    }

    render() {
        const filteredFriends = filterHelper.deepSearch(this.state.friendships.friends.map(friendship => ({
            createdAt: friendship.createdAt,
            friendshipId: friendship.friendshipId,
            Friend: {
                picturePath: friendship.Friend.picturePath,
                userId: friendship.Friend.userId,
                firstname: friendship.Friend.firstname,
                lastname: friendship.Friend.lastname,
                commonFriends: friendship.Friend.commonFriends,
                certification: friendship.Friend.certificationDate !== null,
                username: friendship.Friend.username,
                bio: friendship.Friend.bio,
            },
            friendTributeCount: friendship.friendTributeCount as number,
        })), this.state.friendSearch);

        if (this.state.selectedOrder === 'youngest') filteredFriends.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
        if (this.state.selectedOrder === 'inspirational') filteredFriends.sort((a, b) => b.friendTributeCount - a.friendTributeCount);

        return (
            <>
                <div className="dark-bg">
                    <div className="uk-container">
                        <Helmet title={`Mes amis (${this.state.friendships.friends.length}) | Paanteon`} />
                        <h1 className="page-title">Mes amis <span className="thin">({this.state.friendships.friends.length})</span></h1>
                        <div className="sidebar">
                            {
                                this.state.friendships.requests.length ? <div className="sidebar-friendship-requests sidebar-block dark-blue">
                                        <p className="block-title">Mes demandes en attente ({this.state.friendships.requests.length})</p>
                                        <div className="uk-grid uk-grid-small margin-top">
                                            <div className="uk-width-1-1">
                                                {
                                                    this.state.friendships.requests.slice(0, this.state.friendsRequestLimit).map(item => {
                                                        return <div className="tile sep" key={item.friendshipId}>
                                                            <div className="tile-body compact">
                                                                <div className="feed-item small">
                                                                    <div className="profile-pic" style={{backgroundImage: 'url(' + item.User2.picturePath + ')'}}/>
                                                                    <div style={{flex: 1}}>
                                                                        <p className="body-m">
                                                                            <strong>{item.User2.firstname && stringHelper.capitalizeFirstLetter(item.User2.firstname)} {item.User2.lastname && stringHelper.capitalizeFirstLetter(item.User2.lastname.toUpperCase())}</strong>
                                                                        </p>
                                                                        <p className="body-s">Envoyée le {moment(item.createdAt).format("L")}</p>
                                                                        <p className="right margin-top-s">
                                                                            <button className="btn link-btn blue" onClick={() => this.cancelRequest(item.friendshipId)}>Annuler l'invitation</button>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {
                                            this.state.friendships.requests.length > this.state.friendsRequestLimit ?
                                                <button className="btn btn-block btn-outlined light margin-top" onClick={() => this.incrementFriendsRequestLimit(3)}>Voir plus</button>
                                                : null
                                        }
                                    </div>
                                    : null
                            }
                            <div className="sidebar-friends-suggestion sidebar-block dark-blue">
                                <p className="block-title">Suggestion d'amis</p>
                                <div className="uk-grid uk-grid-small margin-top">
                                    <div className="uk-width-1-1">
                                        {
                                            !this.state.suggestedFriends.length && <div className="tile user-tile sep">
                                                <div className="tile-body">
                                                    <p className="user-name">Aucune suggestion disponible</p>
                                                </div>
                                            </div>
                                        }

                                        {
                                            this.state.suggestedFriends.slice(0, 3).map((user: User) => {
                                                return <div className="tile user-tile sep" key={user.userId}>
                                                    <div className="tile-body">
                                                        <div className="profile-pic" style={{backgroundImage: 'url(' + user.picturePath + ')'}}/>
                                                        <p className="user-name"><Link className="btn link-btn black" to="connected.paanteon.user.galleries.condensed" params={{userId: user.userId, username: user.username}}>{user.firstname && stringHelper.capitalizeFirstLetter(user.firstname)} {user.lastname && stringHelper.capitalizeFirstLetter(user.lastname.toUpperCase())}</Link></p>
                                                        {user.bio && <p className="bio margin-s">{user.bio}</p>}
                                                        <button className="btn btn-outlined" onClick={() => user.userId && this.askAsFriend(user.userId)}>INVITER</button>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-content no-padding-top">
                            <div className="uk-container">
                                <div className="tile light-blue">
                                    {
                                        this.state.friendships.incoming.length ? <div className="tile-body">
                                                <div className="tile-title">Invitations en attente ({this.state.friendships.incoming.length})</div>
                                                <div className="uk-grid uk-grid-small margin-top">
                                                    <div className="uk-width-1-1">
                                                        {
                                                            this.state.friendships.incoming.map(item => {
                                                                return <div className="tile sep" key={item.friendshipId}>
                                                                    <OptionsMenu>
                                                                <span className="options-menu">
                                                                    <ul>
                                                                        <li>
                                                                            <ReportModal userId={item.User1.userId}>
                                                                                <SimpleLink>Signaler comme indésirable</SimpleLink>
                                                                            </ReportModal>
                                                                        </li>
                                                                        <li><SimpleLink onClick={() => this.openBlockFriendshipPopup(item.friendshipId)}>Bloquer</SimpleLink></li>
                                                                    </ul>
                                                                </span>
                                                                    </OptionsMenu>
                                                                    <div className="tile-body compact">
                                                                        <div className="pending-invitation">
                                                                            <div className="profile-pic" style={{backgroundImage: 'url(' + item.User1.picturePath + ')'}}/>
                                                                            <div className="user-info">
                                                                                <p className="body-m"><strong>{item.User1.firstname} {item.User1.lastname}</strong></p>
                                                                                <Link className="btn link-btn blue" to="connected.paanteon.user.galleries.collections" params={{userId: item.User1.userId, username: item.User1.username}}>Voir son profil</Link>
                                                                                <p className="bio margin-s">{item.User1.bio}</p>
                                                                            </div>
                                                                            <button className="btn btn-outlined" onClick={() => this.ignoreIncomingRequest(item.friendshipId)}>IGNORER</button>
                                                                            <button className="btn btn-1" onClick={() => this.acceptIncomingRequest(item.friendshipId)}>ACCEPTER</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="friends-filter-and-search margin-top flex flex-baseline" style={{position: "relative"}}>
                                    <Toggleable>
                                        <div className="toggle-popin-menu" key={1}>
                                            <span className="toggle-button active margin-right">FILTRER PAR</span>

                                            {
                                                this.state.selectedOrder &&
                                                    <span className="active-filter-chip" style={{position: 'relative'}} onClick={stopPropagation}>
                                                        {this.getOrder(this.state.selectedOrder)}

                                                        <button className="btn btn-icon btn-icon-close" onClick={() => this.setSelectedOrder(null)}/>
                                                    </span>
                                            }

                                            <div className="popin-menu block chevron-left">
                                                <div className="uk-grid uk-grid-small" onClick={stopPropagation}>
                                                    <div className="uk-width-1-3@m uk-grid-margin">
                                                        <button disabled className="btn btn-block btn-outlined light">Les plus proches de chez moi</button>
                                                    </div>

                                                    <div className="uk-width-1-3@m uk-grid-margin">
                                                        <button className={"btn btn-block light" + (this.state.selectedOrder === 'youngest' ? ' btn-1' : ' btn-outlined')} onClick={() => this.setSelectedOrder('youngest')}>Les plus récents</button>
                                                    </div>

                                                    <div className="uk-width-1-3@m uk-grid-margin">
                                                        <button className={"btn btn-block light" + (this.state.selectedOrder === 'inspirational' ? ' btn-1' : ' btn-outlined')} onClick={() => this.setSelectedOrder('inspirational')}>Les plus inspirants</button>
                                                    </div>

                                                    <div className="uk-width-1-3@m uk-grid-margin">
                                                        <button disabled className="btn btn-block btn-outlined light">Taux d'affinité</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <React.Fragment key={2}/>
                                    </Toggleable>

                                    <div className="right" style={{flex: 1}}>
                                        <span className="input-with-icon">
                                            <input className="input line-input transparent wide" type="text" onChange={this.handleFriendSearch} placeholder="Rechercher"/>
                                            <img className="icon" src={pictoMagnifyWhite} alt="picto magnify white"/>
                                        </span>
                                    </div>
                                </div>
                                <div className="tile margin">
                                    <div className="tile-body" style={{paddingTop: "15px", paddingBottom: "30px"}}>
                                        <div className="uk-grid uk-grid-small">
                                            {
                                                (!this.state.friendships.friends.length || !filteredFriends.length) && <div className="uk-width-1-1 uk-grid-margin">
                                                    <div className="tile-body compact">
                                                        <div className="feed-item no-margin" style={{alignItems: "center"}}>
                                                            {
                                                                !this.state.friendships.friends.length && <p className="body-m">Vous n'avez pas encore d'ami</p>
                                                            }

                                                            {
                                                                this.state.friendships.friends.length && !filteredFriends.length ? <p className="body-m">Aucun résultat pour cette recherche</p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {
                                                filteredFriends.map(item => {
                                                    return <div className="uk-width-1-2 uk-grid-margin" key={item.friendshipId}>
                                                        <div className="tile small">
                                                            <OptionsMenu>
                                                                <span className="options-menu">
                                                                    <ul>
                                                                        <li><SimpleLink onClick={() => this.openDeleteFriendModal(item.friendshipId)}>Supprimer</SimpleLink></li>
                                                                    </ul>
                                                                </span>
                                                            </OptionsMenu>
                                                            <div className="tile-body compact">
                                                                <div className="feed-item no-margin" style={{alignItems: "center"}}>
                                                                    <div className="profile-pic" style={{backgroundImage: 'url(' + item.Friend.picturePath + ')'}}/>
                                                                    <div>
                                                                        <p className="body-m">
                                                                            <strong>
                                                                                <Link className="btn link-btn black" to="connected.paanteon.user.galleries.condensed" params={{userId: item.Friend.userId, username: item.Friend.username}}>{item.Friend.firstname} {item.Friend.lastname}</Link>
                                                                                {
                                                                                    item.Friend.certification && <img className="certification" src={pictoCertification} alt="certifié"/>
                                                                                }
                                                                            </strong>
                                                                        </p>
                                                                        <p className="body-s">{item.Friend.commonFriends && item.Friend.commonFriends} ami{item.Friend.commonFriends && item.Friend.commonFriends > 1 ? 's' : null} en
                                                                            commun</p>
                                                                        <p className="bio">{item.Friend.bio}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="right margin-top-s">
                                                                    <Link to="connected.messages" params={{userId: item.Friend.userId}} className="btn btn-outlined">ENVOYER UN MESSAGE</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }

                                            {
                                                this.state.selectedDeleteFriendId &&
                                                    <ConfirmModal onCancel={this.closeDeleteFriendModal} onConfirm={() => {
                                                        this.deleteFriendship(this.state.selectedDeleteFriendId as number);
                                                        this.closeDeleteFriendModal();
                                                    }}>
                                                        Voulez-vous vraiment supprimer cet ami ?
                                                    </ConfirmModal>
                                            }

                                            {
                                                this.state.selectedBlockFriendshipId &&
                                                    <ConfirmModal confirmMessage="Bloquer" onCancel={this.closeBlockFriendshipPopup} onConfirm={this.blockUser}>
                                                        Voulez-vous vraiment bloquer cet utilisateur ?
                                                    </ConfirmModal>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Friends;
