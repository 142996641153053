import React, { useEffect } from "react";

const RoundedProgressBar = (
    {
        percentage,
        size,
        className,
    } : {
        percentage: number,
        size: "small" | "medium" | "large",
        className?: string,
    }
) => {
    const [value, setValue] = React.useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (value < percentage) {
                setValue((prev)=> prev + 1);
            }
        }, 2000 / percentage);
        return () => clearInterval(interval);
    }, [percentage, value]);

    const style = { "--value": value, "--percentage": percentage } as React.CSSProperties;
    const alignBottom = { marginTop: "auto" };
    return <div style={alignBottom}><div className={`rounded-progress-bar ${size} ${className}`} aria-valuenow={value} aria-valuemin={0} aria-valuemax={100} style={style}>
        <div className="rounded-progress-bar__value" />
    </div><div className="sidebar-profile stats"><p>affinité</p></div></div>;
};

export default RoundedProgressBar;
