import React from 'react';
import {UserInfos} from "../../api/types";
import Page from "../Page";
import {Link} from "../../api/injectors";
import filterHelper from "../../api/filterHelper";
import localStorage from "../../api/localStorage";
import moment from 'moment';
import {hasValidEmail} from "../../api/user";
import auth from "../../api/authentication";
import Notification from "../../api/notification";
import adminRequests from "../../api/adminRequests";
import ConfirmModal from "../../components/ConfirmModal";
import Modal from "../../components/Modal";
import {requestCertification, validateStep2} from "../../api/certificationRequests";
import {downloadFile} from "../../api/resources";
import Helmet from "../../components/Helmet";


type Props = {
    users: {data: UserInfos[] }
}

type IndexableWithString = {
    [index: string]: string,
}

type State = {
    users: UserInfos[],
    search: string,
    selectedUsersIndexes: number[],
    userToDelete: UserInfos | null,
    userHistory: UserInfos | null,
    searchOnIds: boolean,
}

class UsersPage extends Page<Props, State> {
    protected user = localStorage.getLocalUser();
    readonly state: State = {
        users: this.props.users.data,
        search: '',
        selectedUsersIndexes: [],
        userToDelete: null,
        userHistory: null,
        searchOnIds: false,
    };

    get getSearch(): UserInfos[] {
        return this.state.searchOnIds
            ? this.state.users.filter(user => user.userId.toString(10) === this.state.search)
            : filterHelper.deepSearch(this.state.users, this.state.search);
    }

    updateSearch = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const val = event.currentTarget.value;
        this.setState(prevState => ({
            ...prevState,
            search: val,
        }));
    }

    toggleSearchOnIds = () => {
        this.setState(prevState => ({
            ...prevState,
            searchOnIds: !prevState.searchOnIds,
        }));
    }

    updateUserComment = (comment: string, user: UserInfos) => {
        this.setState(prevState => {
            const userIndex = prevState.users.indexOf(user);
            const usersCopy = [...prevState.users];

            usersCopy[userIndex] = {
                ...usersCopy[userIndex],
                adminComment: comment,
            };

            return {
                ...prevState,
                users: usersCopy,
            };
        });
    }

    saveUserComment = (user: UserInfos) => {
        auth.updateUserById(user).then(() => {
            Notification.success("Commentaire utilisateur enregistré avec succès");
        }, () => {
            Notification.error("Erreur lors de la sauvegarde du commentaire utilisateur");
        });
    }

    requestCertification = (user: UserInfos) => {
        const userIndex = this.state.users.indexOf(user);

        this.setState(prevState => {
            const users = prevState.users;
            users[userIndex] = {
                ...users[userIndex],
                certificationRequestDate: (new Date()).toISOString(),
            };
            return {
                ...prevState,
                users,
            };
        })
        requestCertification(user.userId).then(() => {
            Notification.success("La demande de certification par mail a bien été envoyée");
        }, () => {
            Notification.error("Erreur lors de l'envoi de la demande de certification par mail");
        });
    }

    validateCertificationStep2 = (user: UserInfos) => {
        validateStep2(user.userId).then(() => {
            this.setState(prevState => {
                const userIndex = prevState.users.indexOf(user);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    certificationStep2: (new Date()).toISOString(),
                };

                return {
                    ...prevState,
                    users: usersCopy,
                };
            });

            Notification.success("L'identité de l'utilisateur a bien été confirmée");
        }, () => {
            Notification.error("Erreur lors de la certification de l'utilisateur");
        });
    }

    suspendUser = (user: UserInfos) => {
        adminRequests.suspendUser(user.userId).then((result) => {
            this.setState(prevState => {
                const userIndex = prevState.users.indexOf(user);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    ...result.data,
                };

                return {
                    ...prevState,
                    users: usersCopy,
                };
            });

            Notification.success("Utilisateur suspendu avec succès");
        }, () => {
            Notification.error("Échec lors de la suspension de l'utilisateur");
        });
    }

    unsuspendUser = (user: UserInfos) => {
        adminRequests.unsuspendUser(user.userId).then(() => {
            this.setState(prevState => {
                const userIndex = prevState.users.indexOf(user);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    suspensionDate: null,
                };

                return {
                    ...prevState,
                    users: usersCopy,
                };
            });

            Notification.success("Suspension révoquée avec succès");
        }, () => {
            Notification.error("Échec lors de la révocation de la suspension de l'utilisateur");
        });
    }

    deleteUser = () => {
        const userId = (this.state.userToDelete as UserInfos).userId;

        adminRequests.deleteUser(userId).then(() => {
            this.setState(prevState => {
                const userIndex = prevState.users.findIndex(stateUser => stateUser.userId === userId);
                const usersCopy = [...prevState.users];

                usersCopy.splice(userIndex, 1);

                return {
                    ...prevState,
                    users: usersCopy,
                    userToDelete: null,
                };
            });

            Notification.success("Utilisateur supprimé avec succès");
        }, () => {
            Notification.error("Erreur lors de la suppression de l'utilisateur");
        });
    }

    processUserStart = (user: UserInfos) => {
        adminRequests.processUserStart(user.userId).then((result) => {
            this.setState(prevState => {
                const userIndex = prevState.users.indexOf(user);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    ...result.data,
                };

                return {
                    ...prevState,
                    users: usersCopy,
                };
            });

            Notification.success("Utilisateur mis à traiter avec succès");
        }, () => {
            Notification.error("Échec lors de la mise à traiter de l'utilisateur");
        });
    }

    processUserStop = (user: UserInfos) => {
        adminRequests.processUserStop(user.userId).then(() => {
            this.setState(prevState => {
                const userIndex = prevState.users.indexOf(user);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    processDate: null,
                };

                return {
                    ...prevState,
                    users: usersCopy,
                };
            });

            Notification.success("Utilisateur traité avec succès");
        }, () => {
            Notification.error("Échec lors du traitement de l'utilisateur");
        });
    }

    updateSelectedUsers = (isChecked: boolean, user: UserInfos) => {
        this.setState(prevState => {
            const selectedUsersIndexesCopy = [...prevState.selectedUsersIndexes];
            const userIndex = prevState.users.indexOf(user);

            if (isChecked) {
                selectedUsersIndexesCopy.push(userIndex);
            } else {
                selectedUsersIndexesCopy.splice(selectedUsersIndexesCopy.indexOf(userIndex), 1);
            }

            return {
                ...prevState,
                selectedUsersIndexes: selectedUsersIndexesCopy,
            };
        });
    }

    openConfirmDeleteUser = (user: UserInfos) => {
        this.setState(({userToDelete: user}));
    }

    closeConfirmDeleteUser = () => {
        this.setState(({userToDelete: null}));
    }

    openUserHistory = (user: UserInfos) => {
        this.setState(({userHistory: user}));
    }

    closeUserHistory = () => {
        this.setState(({userHistory: null}));
    }

    validateChanges = () => {
        const userToValidate = this.state.userHistory as UserInfos;

        adminRequests.validateChanges(userToValidate.userId).then((result) => {
            this.setState(prevState => {
                const userIndex = prevState.users.findIndex(user => userToValidate.userId === user.userId);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    ...result.data,
                };

                return {
                    ...prevState,
                    users: usersCopy,
                    userHistory: null,
                };
            });
            Notification.success("Modifications utilisateur validées avec succès");
        }, () => {
            Notification.error("Échec lors de la validation des modifications utilisateur");
        });
    }

    refuseChanges = () => {
        const userToValidate = this.state.userHistory as UserInfos;

        adminRequests.refuseChanges(userToValidate.userId).then((result) => {
            this.setState(prevState => {
                const userIndex = prevState.users.findIndex(user => userToValidate.userId === user.userId);
                const usersCopy = [...prevState.users];

                usersCopy[userIndex] = {
                    ...usersCopy[userIndex],
                    ...result.data,
                };

                return {
                    ...prevState,
                    users: usersCopy,
                    userHistory: null,
                };
            });
            Notification.success("Modifications utilisateur refusées avec succès");
        }, () => {
            Notification.error("Échec lors de la validation des modifications utilisateur");
        });
    }

    getLabelUsingKey = (key: string): string => {
        switch (key) {
            case "firstname":
                return "Prénom";
            case "lastname":
                return "Nom";
            case "birthdate":
                return "Date de naissance";
            case "email":
                return "Mail";
            case "tel":
                return "Téléphone";
            default:
                return "";
        }
    }

    render() {
        const currentUserHistory = this.state.userHistory as (UserInfos & IndexableWithString);

        return (
            <>
                <div className="dark-bg">
                    <div className="uk-container">
                        <div className="main-content">
                            <div className="uk-container">
                                <Helmet title={'Utilisateurs | Paanteon'} />
                                <h1 className="page-title">
                                    Utilisateurs ({this.state.users.length})
                                </h1>

                                <div className="margin-bottom">
                                    <div className="margin-bottom">
                                        <Link to="^">&lt; Retour</Link>
                                        {
                                            this.state.selectedUsersIndexes.length > 0
                                                ? <a
                                                    className="btn btn-1 margin-left"
                                                    href={`mailto:?bcc=${this.state.selectedUsersIndexes.map(index => this.state.users[index].email).join(',')}`}
                                                >
                                                    Envoyer un message aux utilisateurs sélectionnés
                                                </a>
                                                : null
                                        }
                                    </div>

                                    <label>
                                        <input type="checkbox" checked={this.state.searchOnIds} onChange={this.toggleSearchOnIds}/>
                                        Rechercher uniquement sur les IDs
                                    </label>

                                    <input type="search" placeholder="Rechercher..." className="input block" onChange={this.updateSearch}/>
                                </div>

                                <div className="margin-top">
                                    <table className="uk-table" style={{borderSpacing: "10px"}}>
                                        <thead>
                                        <tr>
                                            <th/>
                                            <th>Membres</th>
                                            <th>Certification</th>
                                            <th>Compte</th>
                                            <th>Modifs</th>
                                            <th>Discussion</th>
                                            <th>Statistiques</th>
                                            <th>Commentaires</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.getSearch.map((user, index) => {
                                                return <tr key={user.userId} className="body-m">
                                                    <td>
                                                        <input type="checkbox" onChange={(ev) => this.updateSelectedUsers(ev.currentTarget.checked, user)}/>
                                                    </td>
                                                    <td className="body-s">
                                                        <div>ID : {user.userId}</div>
                                                        <div className="body"><Link to="connected.paanteon.user" params={{userId: user.userId, username: user.username}}>{user.firstname} {user.lastname}</Link></div>
                                                        <div>INSCRIPTION : {moment(user.createdAt).format('LLL')}</div>
                                                        <div>
                                                            DERNIÈRE CONNEXION :
                                                            <div>{user.lastLoginDate ? moment(user.lastLoginDate).format('LLL') : ''}</div>
                                                        </div>
                                                        <div>DERNIÈRE IP : {user.lastKnownIP}</div>
                                                    </td>
                                                    <td>
                                                        <div>ÉTAT : {user.certificationDate ? 'Certifié(e)' : 'Non-certifié(e)'}</div>
                                                        {
                                                            !user.certificationDate
                                                                ? <>
                                                                    <div><button className={user.certificationStep0 ? 'btn-orange' : ''}
                                                                                 disabled={!!user.certificationStep0}
                                                                                 onClick={() => this.requestCertification(user)}
                                                                    >
                                                                        {user.certificationStep0 ? 'EN COURS' : 'DEMANDER'}
                                                                    </button></div>
                                                                    { user.certificationStep1 && !user.certificationStep2 && (
                                                                        <>
                                                                            { user.identityPaperFrontUrl ? <div><button onClick={() => downloadFile(user.identityPaperFrontUrl)}>Papier identité 1</button></div> : null }
                                                                            { user.identityPaperBackUrl ? <div><button onClick={() => downloadFile(user.identityPaperBackUrl)}>Papier identité 2</button></div> : null }
                                                                            <div><button onClick={() => this.validateCertificationStep2(user)}>VALIDER</button></div>
                                                                        </>
                                                                    ) }
                                                                </> : null
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>ÉTAT : {user.processDate
                                                            ? 'A traiter'
                                                            : user.suspensionDate
                                                                ? 'Suspendu'
                                                                : hasValidEmail(user)
                                                                    ? 'Actif'
                                                                    : 'Non-actif'
                                                        }</div>
                                                        <button onClick={() => user.suspensionDate ? this.unsuspendUser(user) : this.suspendUser(user)}>
                                                            {user.suspensionDate ? 'RÉACTIVER' : 'SUSPENDRE'}
                                                        </button>
                                                        <button onClick={() => this.openConfirmDeleteUser(user)}>SUPPRIMER</button>
                                                        <button onClick={() => user.processDate ? this.processUserStop(user) : this.processUserStart(user)}>
                                                            {user.processDate ? 'TRAITÉ' : 'A TRAITER'}
                                                        </button>
                                                    </td>
                                                    <td>
                                                        Dernières modifs :
                                                        {user.lastUpdate ? moment(user.lastUpdate).format('LLL') : ''}
                                                        <button className={user.certifiedChanges ? 'btn-orange' : ''} onClick={() => this.openUserHistory(user)}>VOIR AVANT/APRÈS</button>
                                                    </td>
                                                    <td>
                                                        <a href={`mailto:?bcc=${user.email}`}>ENVOYER UN MESSAGE</a>
                                                    </td>
                                                    <td className="body-s">
                                                        <div>G : {user.galleryCount}</div>
                                                        <div>C : {user.collectionCount}</div>
                                                        <div>V : {user.tributeCount}</div>
                                                    </td>
                                                    <td>
                                                        <textarea placeholder="Commentaire privé" value={user.adminComment || ''} onChange={ev => this.updateUserComment(ev.target.value, user)} />
                                                        <button onClick={() => this.saveUserComment(user)}>
                                                            Enregistrer
                                                        </button>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                {
                    this.state.userToDelete && <ConfirmModal onCancel={this.closeConfirmDeleteUser} onConfirm={this.deleteUser}>Êtes-vous sûr de vouloir supprimer l'utilisateur "{this.state.userToDelete.firstname} {this.state.userToDelete.lastname}" ?</ConfirmModal>
                }
                {
                    this.state.userHistory && <Modal closeButton onClose={this.closeUserHistory}>
                        <div className="pop-up-title centered">
                            Modifications de "{this.state.userHistory.firstname} {this.state.userHistory.lastname}"
                        </div>

                        <table className="uk-table uk-width-1-1" style={{textAlign: "center"}}>
                            <thead>
                                <tr>
                                    <th/>
                                    <th>Avant</th>
                                    <th>{!!currentUserHistory.certificationDate ? "A valider" : "Actuellement"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ["firstname", "lastname", "birthdate", "email", "tel"].map(key => {
                                        return (<tr key={key}>
                                            <th>{this.getLabelUsingKey(key)}</th>
                                            {
                                                !!currentUserHistory.certificationDate
                                                    ? <>
                                                        <td>{currentUserHistory[key]}</td>
                                                        <td>{currentUserHistory["tmp_" + key] || "Aucun"}</td>
                                                      </>
                                                    : <>
                                                        <td>{currentUserHistory["tmp_" + key] || "Aucun"}</td>
                                                        <td>{currentUserHistory[key]}</td>
                                                      </>
                                            }
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </table>

                        <div className="pop-up-buttons centered">
                            {
                                this.state.userHistory.certifiedChanges
                                    ? <button className="btn btn-1" onClick={this.refuseChanges}>Refuser</button>
                                    : null
                            }
                            <button className="btn btn-outlined" onClick={this.closeUserHistory}>{this.state.userHistory.certifiedChanges ? 'Annuler' : 'Ok'}</button>
                            {
                                this.state.userHistory.certifiedChanges
                                    ? <button className="btn btn-1" onClick={this.validateChanges}>Valider</button>
                                    : null
                            }
                        </div>
                    </Modal>
                }
            </>
        );
    }
}

export default UsersPage;
