import request from "./request";

const friendsRequests = {
    friendsRequest: function(userId: number) {
        return request.post('/paanteon/friendships', {friendId: userId}, false);
    },

    deleteFriendship: function(friendshipId: number) {
        return request.post('/paanteon/friendships/' + friendshipId + '/delete', {}, false);
    },

    acceptFriendship: function(friendshipId: number) {
        return request.post('/paanteon/friendships/' + friendshipId + '/accept', {}, false);
    },

    refuseFriendship: function(friendshipId: number) {
        return request.post('/paanteon/friendships/' + friendshipId + '/refuse', {}, false);
    },

    blockFriendship: function(friendshipId: number) {
        return request.post('/paanteon/friendships/' + friendshipId + '/block', {}, false);
    },
};

export default friendsRequests;
