/* eslint-disable no-console */
import React from 'react';
import Page from "./Page";
import stringHelper from "../api/stringHelper";
import {Followings, User} from "../api/types";
import {Link} from "../api/injectors";
import Toggleable from "../components/Toggleable";
import {stopPropagation} from "../api/helpers";
import moment from "moment";
import userRequests from "../api/userRequests";
import Notification from "../api/notification";
import localStorage from "../api/localStorage";
import Helmet from "../components/Helmet";

type Props = {
    follows: { data: Followings }
}

type State = {
    followers: (User & { follow: { createdAt: string, updatedAt: string }, mutual: number | null, tributeCount: number, commonFriends: number })[]
    selectedOrder: string | null
}

class Followers extends Page<Props, State> {
    private selfUserId: number = localStorage.getLocalUser().userId;
    readonly state: State = {
        followers: this.props.follows.data.FollowedBy,
        selectedOrder: null,
    }

    follow = (userId: number) => {
        userRequests.follow(userId).then(() => {
            Notification.success('Abonnement réalisé');
            userRequests.getSuggestedFriends(this.selfUserId).then((res) => {
                this.setState(prevState => ({...prevState, suggestedFriends: res.data}));
            }, function(err) {
                console.log(err);
            });
            userRequests.getFollows(this.selfUserId).then(res => {
                this.setState(prevState => ({...prevState, followers: res.data.FollowedBy}));
            }, err => {
                console.log(err);
            });
        }, function() {
            Notification.error("Erreur lors de l'abonnement")
        });
    }

    unfollow = (userId: number) => {
        userRequests.unfollow(userId).then(() => {
            Notification.success("Désabonnement pris en compte avec succès")
            userRequests.getFollows(this.selfUserId).then(res => {
                this.setState(prevState => ({...prevState, followers: res.data.FollowedBy}));
            }, err => {
                console.log(err);
            });
        });
    }

    getOrder = (value: string) => {
        if (value === 'youngest') return "Les plus récents";
        if (value === 'inspirational') return "Les plus inspirants";
        return "";
    }

    setSelectedOrder = (order: string | null) => {
        this.setState(prevState => ({
            ...prevState,
            selectedOrder: order,
        }));
    }

    render() {
        let filteredFollowers = [...this.state.followers];
        if (this.state.selectedOrder === 'youngest') filteredFollowers.sort((a, b) => moment(b.follow.createdAt).diff(moment(a.follow.createdAt)));
        if (this.state.selectedOrder === 'inspirational') filteredFollowers.sort((a, b) => b.tributeCount - a.tributeCount);

        return (
            <div className="dark-bg">
                <div className="uk-container">
                    <Helmet title={`Mes abonnés (${this.state.followers.length}) | Paanteon`} />
                    <h1 className="page-title">Mes abonnés <span
                        className="thin">({this.state.followers.length})</span></h1>
                    <div className="sidebar">
                        <div className="sidebar-last-followers sidebar-block dark-blue">
                            <p className="block-title">Mes derniers abonnés</p>
                            <div className="uk-grid uk-grid-small margin-top">
                                <div className="uk-width-1-1">
                                    {
                                        !this.state.followers.length && <div className="tile user-tile sep">
                                            <div className="tile-body">
                                                <p className="user-name">Aucun abonné</p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        this.state.followers.sort((a, b) => moment(b.follow.createdAt).diff(moment(a.follow.createdAt))).slice(0, 3).map((user) => {
                                            return <div className="tile user-tile sep" key={user.userId}>
                                                <div className="tile-body">
                                                    <div className="profile-pic"
                                                         style={{backgroundImage: 'url(' + user.picturePath + ')'}}/>
                                                    <p className="user-name"><Link className="btn link-btn black"
                                                                                   to="connected.paanteon.user.galleries.condensed"
                                                                                   params={{userId: user.userId, username: user.username}}>{user.firstname && stringHelper.capitalizeFirstLetter(user.firstname)} {user.lastname && stringHelper.capitalizeFirstLetter(user.lastname.toUpperCase())}</Link>
                                                    </p>
                                                    { user.mutual ?
                                                        <button className="btn btn-outlined"
                                                                onClick={() => user.userId && this.unfollow(user.userId)}>NE PLUS SUIVRE
                                                        </button>
                                                    :
                                                        <button className="btn btn-outlined"
                                                                onClick={() => user.userId && this.follow(user.userId)}>SUIVRE
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="sidebar-my-follows sidebar-block dark-blue">
                            <p className="block-title">Mes abonnements</p>
                            <div className="uk-grid uk-grid-small margin-top">
                                <div className="h-center uk-width">
                                    <Link to="^.following" className="btn btn-outlined light">VOIR</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-content no-padding-top">
                        <div className="uk-container">
                            <div className="margin-top flex flex-baseline" style={{position: "relative"}}>
                                <Toggleable>
                                    <div className="toggle-popin-menu" key={1}>
                                        <span className="toggle-button active margin-right">FILTRER PAR</span>

                                        {
                                            this.state.selectedOrder &&
                                            <span className="active-filter-chip" style={{position: 'relative'}}
                                                  onClick={stopPropagation}>
                                                        {this.getOrder(this.state.selectedOrder)}

                                                <button className="btn btn-icon btn-icon-close"
                                                        onClick={() => this.setSelectedOrder(null)}/>
                                                    </span>
                                        }

                                        <div className="popin-menu block chevron-left">
                                            <div className="uk-grid uk-grid-small" onClick={stopPropagation}>
                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button disabled className="btn btn-block btn-outlined light">Les
                                                        plus proches de chez moi
                                                    </button>
                                                </div>

                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button
                                                        className={"btn btn-block light" + (this.state.selectedOrder === 'youngest' ? ' btn-1' : ' btn-outlined')}
                                                        onClick={() => this.setSelectedOrder('youngest')}>Les plus
                                                        récents
                                                    </button>
                                                </div>

                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button
                                                        className={"btn btn-block light" + (this.state.selectedOrder === 'inspirational' ? ' btn-1' : ' btn-outlined')}
                                                        onClick={() => this.setSelectedOrder('inspirational')}>Les plus
                                                        inspirants
                                                    </button>
                                                </div>

                                                <div className="uk-width-1-3@m uk-grid-margin">
                                                    <button disabled className="btn btn-block btn-outlined light">Taux
                                                        d'affinité
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <React.Fragment key={2}/>
                                </Toggleable>
                            </div>
                            <div className="margin-top">
                                {
                                    filteredFollowers.map(item => {
                                        return <div className="tile condensed sep" key={item.userId}>
                                            <div className="tile-body">
                                                <div className="feed-item">
                                                    <div className="profile-pic"
                                                         style={{backgroundImage: "url(" + item.picturePath + ')'}}/>
                                                    <div>
                                                        <div className="flex flex-baseline">
                                                            <div>
                                                                <div>
                                                                    <Link style={{fontSize: "16px"}} className="btn link-btn black"
                                                                                   to="connected.paanteon.user.galleries.condensed"
                                                                                   params={{userId: item.userId, username: item.username}}>{item.firstname && stringHelper.capitalizeFirstLetter(item.firstname)} {item.lastname && stringHelper.capitalizeFirstLetter(item.lastname.toUpperCase())}</Link>
                                                                </div>
                                                                <div className="light">{item.commonFriends} ami(s) en commun</div>
                                                            </div>
                                                            <div className="right" style={{flex: 1}}>
                                                                { item.mutual ?
                                                                <button className="btn btn-outlined dark-blue"
                                                                        onClick={() => this.unfollow(item.userId as number)}>
                                                                    NE PLUS SUIVRE
                                                                </button>
                                                                :
                                                                <button className="btn btn-outlined dark-blue"
                                                                        onClick={() => this.follow(item.userId as number)}>
                                                                    SUIVRE
                                                                </button>
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="margin-top light">
                                                            {item.bio}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Followers;
